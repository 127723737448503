/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.loadingWrapper {
  position: relative;
  z-index: 149; }
  .loadingWrapper .loading {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #e5e5e5;
    opacity: 0.3;
    z-index: 150; }
  .loadingWrapper .hide {
    display: none; }
  .loadingWrapper .spinner {
    top: 75vh;
    left: 50vw;
    position: fixed;
    height: 2rem;
    width: 2rem;
    color: red; }

.quantityWrapper {
  height: auto; }
  .quantityWrapper > div {
    height: 2.5rem; }

.arrowUp:after, .arrowDown:after {
  position: relative;
  margin-left: 0.25rem;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.arrowUp:after {
  top: -10px;
  border-bottom: 5px solid black; }

.arrowDown:after {
  bottom: -10px;
  border-top: 5px solid black; }

.topArea {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between; }
  .topArea .suppliers {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    .topArea .suppliers input {
      width: 350px; }
    .topArea .suppliers .showOnlyLocked {
      flex: 1;
      margin-left: 2rem; }
  .topArea .topButtonArea {
    margin-bottom: 1rem; }
    .topArea .topButtonArea button:not(:first-child) {
      margin-left: 0.25rem; }

.sectionWrapper,
.main {
  background: #f5f5f5;
  padding: 0 0.5rem 1rem; }

.markupArea .markupButtonArea {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }
  .markupArea .markupButtonArea .quantityWrapper {
    height: 2.5rem;
    margin-right: 1rem; }

.importantMessage {
  color: rgba(247, 89, 77, 0.8); }

.tableArea {
  margin: 0 auto; }
  .tableArea .table {
    width: 100%; }
    .tableArea .table .tableHeader.code {
      white-space: nowrap;
      width: 11%; }
      .tableArea .table .tableHeader.code label {
        float: left;
        padding-right: 1px; }
    .tableArea .table .tableHeader.displayName {
      width: 17rem;
      padding-left: 3rem; }
    .tableArea .table .tableHeader a {
      cursor: pointer;
      text-decoration: underline; }
    .tableArea .table td {
      font-weight: bold; }
      .tableArea .table td a {
        cursor: pointer; }
      .tableArea .table td .icon {
        font-size: 1.5rem; }
      .tableArea .table td .displayName {
        padding-left: 3rem; }
        .tableArea .table td .displayName input {
          text-align: center; }
          .tableArea .table td .displayName input:disabled {
            color: #666 !important; }
      .tableArea .table td.displayName {
        width: 50%;
        padding-left: 3rem; }
        .tableArea .table td.displayName > div {
          padding-bottom: 0.5rem; }
          .tableArea .table td.displayName > div:first-of-type {
            padding-top: 0.5rem; }
        .tableArea .table td.displayName span {
          padding-left: 0.5rem; }
      .tableArea .table td.icons {
        text-align: center; }
        .tableArea .table td.icons a,
        .tableArea .table td.icons button,
        .tableArea .table td.icons span {
          display: inline-block;
          padding: 0.5rem; }
      .tableArea .table td .price input {
        text-align: right; }
      .tableArea .table td .pricewrapper {
        white-space: nowrap; }
      .tableArea .table td.code {
        white-space: nowrap;
        width: 11%; }
    .tableArea .table .tableHeader,
    .tableArea .table td {
      padding: 0.5rem 0.25rem;
      vertical-align: top; }
    .tableArea .table .tableDataPrice {
      text-align: right; }
    .tableArea .table .tableRow {
      margin: 0;
      border-bottom: 1px solid #fff; }
      .tableArea .table .tableRow span.disabled {
        color: #666; }
      .tableArea .table .tableRow td > label {
        float: left;
        padding-right: 1px; }
    .tableArea .table .tableRowLast {
      border: none; }
      .tableArea .table .tableRowLast td {
        padding-top: 1em; }
        .tableArea .table .tableRowLast td .inputArea {
          margin: auto 1rem auto 0; }
          .tableArea .table .tableRowLast td .inputArea input {
            margin-left: 0.5rem;
            width: 323px; }

.deleteArticleList {
  list-style: square; }
  .deleteArticleList .listItem {
    font-weight: bold; }

.hide {
  display: none; }

.requiredField {
  color: #ff0000;
  font-weight: bold; }

.clearboth {
  clear: both; }

.row {
  display: flex;
  margin-bottom: 1.5rem; }
  .row.stack {
    flex-direction: column; }

.addSupplierWrapper {
  max-width: 100%;
  width: 90vw; }

.divHalf {
  flex: 0 0 calc(50% - 1rem);
  align-self: center;
  margin-right: 1rem; }
  .divHalf small {
    display: block;
    font-size: 12px; }

.mobile1of3 {
  flex: 0 0 calc(33% - 1rem);
  align-self: flex-start; }
  .mobile1of3 .button {
    max-height: 44px;
    line-height: 44px;
    margin-top: 1.5rem;
    margin-left: 1rem; }

.mobile2of3 {
  flex: 0 0 calc(66% - 1rem); }

.divMobile {
  flex: 0 0 calc(100% - 0.85714rem); }
  .divMobile small {
    display: block;
    font-size: 12px; }

.divOneThree {
  width: calc(33% - 1rem);
  float: left;
  margin-right: 1rem; }
  .divOneThree > button {
    margin-left: 2rem; }

.addModal {
  overflow: auto;
  width: 50rem; }

.addModalOverflowVisible {
  overflow: visible;
  width: 50rem; }

.addModalMobile {
  width: calc(100% - 0.85714rem);
  overflow: auto; }

.marginbottom {
  margin-bottom: 1rem; }

.fullWidth {
  width: 100%; }
  .fullWidth > div {
    flex: 1; }
    .fullWidth > div > input[name='quantity'] {
      width: unset; }

.spacing input {
  padding: 0.5rem 1rem; }

.feedbackBottomWrap {
  display: flex;
  justify-content: space-between; }

#pagingMainContainer {
  display: block;
  width: 750px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 45px; }

#paginationContent {
  width: 100%;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; }

.paginate {
  width: 100%;
  font-size: 1rem;
  padding: 35px 0 0 0;
  margin: auto;
  text-align: center; }

.paginate li {
  display: inline-block; }

.paginate.page {
  font-size: 1rem; }

.paginate.page li a {
  display: inline-block;
  color: #363842;
  text-decoration: none;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s linear; }

.paginate.page li a:hover {
  color: #363842;
  background: #d0dfcf; }

.paginate.page li a:active {
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.55); }

.paginate.page li.navpage a {
  padding: 9px 13px;
  background: #f8584c;
  color: #fff;
  cursor: pointer; }

.paginate.page li.navpage a:hover {
  background: #f8584c; }

.paginate.page li.notallowednavpage a {
  padding: 9px 13px;
  background: #e8e5e3;
  color: #fff; }

.paginate.page li.single,
.paginate.page li.current {
  display: inline-block;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  color: #fff;
  background: #343d46; }

.mobilePagingLane {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: #f8584c;
  margin-top: 20px;
  padding: 0.5rem 1rem; }
  .mobilePagingLane .prevContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .prevContainer > * {
      color: #fff; }
  .mobilePagingLane .paging {
    padding: 0 1rem;
    flex: 1;
    font-size: 1rem;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .paging > * {
      width: 100%;
      color: #fff; }
  .mobilePagingLane .nextContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .nextContainer > * {
      color: #fff; }

.deleteNutrientButton {
  display: block;
  text-align: center; }

@media screen and (min-width: 768px) {
    .topArea {
      flex-direction: row; }
          .tableArea .table td.displayName {
            width: 17rem;
            word-break: break-all; }
          .tableArea .table td.code {
            white-space: unset;
            word-break: break-all; }
        .tableArea .table .tableHeader,
        .tableArea .table td {
          vertical-align: middle;
          padding: 0.5rem; } }

@media screen and (min-width: 480px) {
  .markupArea {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; }
    .markupArea div:not(:first-child) {
      margin-left: 0.25rem; } }

@media screen and (min-width: 992px) {
    .addSupplierWrapper {
      width: 420px; }
    .mobile1of3 {
      flex: 0 0 calc(50% - 1rem); }
    .mobile2of3 {
      flex: 0 0 calc(50% - 1rem); } }

@media screen and (min-height: 500px) {
  .disableScrollInModal {
    overflow: hidden; } }
