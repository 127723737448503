/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.layout {
  composes: full from "../../../../../../styles/objects/layout.scss";
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .layout .space {
    display: flex;
    justify-content: space-between; }

.BottomBar {
  background: #fff;
  max-height: 61px;
  min-height: 61px;
  border-bottom: 1px solid #e5e5e5;
  transition: all 300ms linear; }

.BottomBar__Column {
  display: flex;
  flex-grow: 1;
  min-height: 61px;
  max-height: 61px;
  padding-right: 0rem;
  position: relative; }
  .BottomBar__Column:before {
    position: absolute;
    content: "";
    border-left: 1px solid #e5e5e5;
    width: 0;
    height: 41px;
    left: 0;
    top: 10px;
    display: block; }
  .BottomBar__Column__Search {
    flex-grow: 0; }
  .BottomBar__Column__Mobile {
    padding-right: 0rem;
    flex-grow: 0; }

.MenuWrapper {
  display: flex;
  flex-grow: 1; }
  .MenuWrapper p {
    margin: 0; }
  .MenuWrapper a {
    display: block;
    text-decoration: none; }
  @media screen and (min-width: 992px) {
    .layout {
      position: relative; }
    .BottomBar {
      max-height: 170px;
      min-height: 170px;
      border-bottom: none; }
      .BottomBar form, .BottomBar nav, .BottomBar svg {
        will-change: top, padding;
        transition: top 320ms linear, padding 320ms linear; }
      .BottomBar.small {
        min-height: 71px; }
    .BottomBar__Column {
      position: unset;
      min-height: 170px;
      max-height: unset;
      flex-grow: 1;
      align-items: center;
      padding-right: 1rem; }
      .small .BottomBar__Column {
        min-height: 61px; }
      .BottomBar__Column:before {
        display: none; }
      .BottomBar__Column__Search {
        padding-left: 1rem;
        flex-grow: 0; }
        .BottomBar__Column__Search > form {
          margin-left: auto;
          min-width: 180px;
          max-width: 325px;
          padding-top: 2rem; }
          .small .BottomBar__Column__Search > form {
            padding-top: 0; }
            .small .BottomBar__Column__Search > form > div + div {
              top: 65px; } }
  @media screen and (min-width: 1200px) {
      .BottomBar__Column__Search > form {
        min-width: 250px; } }
