/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.Delivery__Outer {
  margin-bottom: 1rem;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  display: flex; }

.Delivery__List {
  -webkit-animation: fade 500ms linear;
          animation: fade 500ms linear;
  background: transparent; }

.Delivery__Row__Outer {
  background: #ffffff;
  margin-bottom: .5rem !important;
  transition: border 150ms ease; }

.Delivery__Row__Outer_Active {
  border: 0.5px solid #f8584c; }

.Delivery__Row {
  display: flex;
  background: #ffffff;
  color: #5C5D5D;
  width: 100%;
  border: 1px solid #E5E5E5;
  padding: .25rem 2rem;
  flex-wrap: wrap;
  position: relative; }
  .Delivery__Row__Header {
    background: #343D46;
    color: #FFFFFF;
    margin-bottom: .5rem !important;
    padding: .45rem 0 .25rem !important;
    display: none; }
  .Delivery__Row__Open {
    background: rgba(248, 88, 76, 0.1);
    color: #5C5D5D;
    border: none; }

.Delivery__Cell {
  font-weight: 700;
  min-width: 50%;
  max-width: 50%;
  margin: 0 !important;
  padding: .25rem;
  text-align: left;
  cursor: pointer; }
  .Delivery__Cell:first-child {
    text-align: left;
    min-width: 30px;
    max-width: 30px;
    position: absolute;
    left: 0; }
  .Delivery__Cell.checkbox {
    position: absolute;
    right: 0;
    min-width: 30px;
    max-width: 30px;
    text-align: right; }
    .Delivery__Cell.checkbox > label {
      display: block; }
  .Delivery__Cell.link {
    max-width: unset;
    position: unset; }
  .Delivery__Cell__Mobile_label {
    color: #999;
    font-weight: normal;
    display: block; }

.Pagination__Container {
  text-align: center; }

.Payslip__Link {
  display: none;
  color: #f8584c;
  font-size: .855rem;
  font-weight: bold; }
  .Payslip__Link svg {
    margin-left: .25rem;
    height: 1rem;
    width: 1rem; }

.Button__Toggle {
  font-weight: bold;
  height: 1rem;
  width: 1rem;
  position: relative;
  vertical-align: middle;
  left: .25rem;
  top: -.1rem; }
  .Button__Toggle span {
    display: none; }
  .Button__Toggle::before, .Button__Toggle::after {
    position: absolute;
    display: block;
    content: "";
    height: 1rem;
    width: 1rem;
    top: 0;
    left: 0; }
  .Button__Toggle__On::before {
    border-top: 1px solid #343D46;
    top: .5rem;
    margin-top: -1px; }
  .Button__Toggle__Off::before {
    border-top: 1px solid #343D46;
    top: .5rem;
    margin-top: -1px; }
  .Button__Toggle__Off::after {
    border-bottom: 1px solid #343D46;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    left: .5rem;
    margin-left: -1px; }

.Products__Outer {
  margin-bottom: 1rem;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  display: flex;
  padding: 0 1rem; }

.Products__List {
  background: transparent;
  -webkit-animation: fade 500ms linear;
          animation: fade 500ms linear; }

.Products__Row {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #5C5D5D;
  width: 100%;
  padding: 0 .25rem 1rem; }
  .Products__Row:last-of-type {
    border: none; }
  .Products__Row__Border {
    border-bottom: 1px solid #E5E5E5; }
  .Products__Row__Header {
    color: #23272A !important;
    font-weight: 700;
    margin-bottom: .5rem !important;
    border-bottom: 1px solid #6C737A;
    display: none; }
    .Products__Row__Header > div {
      cursor: pointer; }
  .Products__Row__PlannedHeader {
    color: #23272A !important;
    font-weight: 700;
    margin-bottom: .5rem !important;
    border-bottom: 1px solid #f8584c;
    display: none; }
    .Products__Row__PlannedHeader > div {
      cursor: pointer; }

.Products__Cell {
  flex: 1;
  margin: 0;
  margin-left: 100px;
  padding: 0 .25rem;
  text-align: left;
  order: 3;
  color: #0D0F11; }
  .Products__Cell:nth-of-type(1) {
    order: 1;
    font-size: 12px;
    padding: .55rem .25rem 0;
    min-width: 105px;
    max-width: 105px; }
  .Products__Cell:nth-of-type(2) {
    order: 0;
    min-width: 130px;
    max-width: 130px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100px;
    margin-left: 0;
    margin-bottom: -100px; }
    .Products__Cell:nth-of-type(2) > div {
      min-width: 85px;
      max-width: 85px; }
      .Products__Cell:nth-of-type(2) > div img {
        transition: all 150ms ease-in-out; }
  .Products__Cell:nth-of-type(3) {
    order: 2;
    padding: 0 .25rem 1rem; }
    .Products__Cell:nth-of-type(3) .Description {
      font-size: 12px; }
  .Products__Cell:nth-of-type(4) .Description {
    color: #23272A; }
    .Products__Cell:nth-of-type(4) .Description::before {
      content: ","; }
  .Products__Cell:last-child > label {
    display: block; }
  .Products__Cell__Mobile_label {
    color: #0D0F11;
    font-weight: normal;
    display: inline;
    margin-right: .5rem; }
    .Products__Cell__Mobile_label:after {
      content: ":"; }

.Products__Cell__Wide {
  min-width: 250px; }

.mobileImage {
  display: block; }

.planned {
  cursor: inherit; }

.completed {
  cursor: inherit; }

.Title {
  color: #23272A;
  font-weight: normal; }

.Description {
  color: #6C737A;
  font-weight: normal; }

.sortableLabel {
  display: block;
  margin: .5rem 0;
  white-space: pre; }
  .sortableLabel svg {
    height: 10px;
    width: 10px; }

.Recycle__List_Header {
  border-bottom: 1px solid #000 !important; }
  .Recycle__List_Header strong.Title {
    font-weight: bold; }

.Recycle__Row {
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  color: #5C5D5D;
  width: 100%;
  padding: 0 .25rem; }
  .Recycle__Row:last-child {
    border-bottom: none; }

.Recycle__Cell {
  flex: 1;
  margin: 0 !important;
  padding: .25rem;
  text-align: right; }
  .Recycle__Cell:first-child {
    text-align: left; }
  .Recycle__Cell:last-child {
    text-align: right;
    min-width: 130px;
    max-width: 130px; }

/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.toolbar {
  display: flex;
  width: 100%;
  padding: .25rem; }
  .toolbar__LeftSection {
    align-self: flex-start;
    margin: 0 !important; }
  .toolbar__RightSection {
    align-self: flex-end;
    margin: 0;
    margin-left: auto; }
  .toolbar button + button {
    margin-left: 1rem; }
  .toolbarbutton {
    border: 1px solid #f8584c !important; }
    .toolbarbutton__Outline {
      border: 1px solid #0D0F11 !important; }

.Print__Link {
  color: #f8584c;
  font-size: .855rem;
  font-weight: bold; }
  .Print__Link svg {
    margin-left: 0;
    margin-right: .25rem;
    height: 1rem;
    width: 1rem; }

.TooltipWrapper {
  display: inline-block;
  position: relative; }

.Tooltip {
  opacity: 1;
  -webkit-animation: fade 500ms linear;
          animation: fade 500ms linear;
  position: absolute;
  border-radius: .25rem;
  top: -100%;
  left: -50%;
  min-width: 160px;
  max-width: 190px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 1rem;
  color: #000;
  background: #FFFFFF;
  font-size: .85rem;
  font-family: sans-serif;
  line-height: 1.5;
  z-index: 100;
  border: 1px solid #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }
  .Tooltip::before {
    content: " ";
    left: 75%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 14px;
    margin-left: -14px; }

.top {
  top: auto;
  bottom: 100%;
  margin-bottom: 1rem; }
  .top::before {
    top: 100%;
    border-top-color: #FFFFFF; }

.right {
  left: 100%;
  margin-left: 1rem;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }
  .right::before {
    left: -14px;
    top: 50%;
    -webkit-transform: translateX(0) translateY(-50%);
            transform: translateX(0) translateY(-50%);
    border-right-color: #FFFFFF; }

.bottom {
  top: 100%;
  margin-top: 1rem; }
  .bottom::before {
    bottom: 100%;
    border-bottom-color: #FFFFFF; }

.left {
  left: auto;
  right: 100%;
  margin-right: 1rem;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }
  .left::before {
    left: auto;
    right: -28px;
    top: 50%;
    -webkit-transform: translateX(0) translateY(-50%);
            transform: translateX(0) translateY(-50%);
    border-left-color: #FFFFFF; }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.loading {
  opacity: .95; }

.show__Desktop_Only {
  display: none; }

.show__Mobile_Only {
  display: unset; }

.printLoader {
  margin-bottom: -2.5rem; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 2.5rem;
  position: relative; }

@media screen and (min-width: 992px) {
    .Delivery__Row {
      padding: .25rem 0 0; }
      .Delivery__Row__Header {
        display: flex; }
  .Delivery__Cell {
    flex: 1;
    margin: 0 !important;
    padding: .25rem;
    text-align: right;
    font-weight: normal;
    min-width: unset;
    max-width: unset; }
    .Delivery__Cell:first-child {
      text-align: left;
      max-width: 50px;
      position: unset; }
    .Delivery__Cell:nth-of-type(0) {
      text-align: left; }
    .Delivery__Cell:nth-of-type(1) {
      text-align: left; }
    .Delivery__Cell:nth-of-type(2) {
      text-align: left;
      max-width: 300px; }
    .Delivery__Cell:nth-of-type(3) {
      max-width: 100px; }
    .Delivery__Cell:nth-of-type(4) {
      max-width: 225px; }
    .Delivery__Cell.link {
      max-width: 150px;
      position: unset; }
    .Delivery__Cell:last-child, .Delivery__Cell.checkbox {
      max-width: 85px;
      position: unset; }
      .Delivery__Cell:last-child > label, .Delivery__Cell.checkbox > label {
        display: block; }
    .Delivery__Cell__Mobile_label {
      display: none; }
  .time {
    text-align: left; }
    .Products__Row {
      flex-direction: row;
      padding: .5rem .25rem 0rem; }
      .Products__Row__Header {
        display: flex; }
      .Products__Row__PlannedHeader {
        display: flex; }
  .Products__Cell {
    flex: 1;
    margin: 0 !important;
    padding: .25rem;
    text-align: right;
    order: unset;
    color: #23272A; }
    .Products__Cell:first-child, .Products__Cell:nth-of-type(1), .Products__Cell:nth-of-type(2), .Products__Cell:nth-of-type(3), .Products__Cell:nth-of-type(4) {
      font-size: 1rem;
      text-align: left;
      min-height: unset;
      order: unset;
      padding: .25rem; }
      .Products__Cell:first-child .Description, .Products__Cell:nth-of-type(1) .Description, .Products__Cell:nth-of-type(2) .Description, .Products__Cell:nth-of-type(3) .Description, .Products__Cell:nth-of-type(4) .Description {
        font-size: 14px; }
    .Products__Cell:nth-of-type(3) {
      min-width: 300px; }
    .Products__Cell:nth-of-type(4) .Description {
      display: block;
      margin-bottom: 1rem;
      color: #6C737A; }
      .Products__Cell:nth-of-type(4) .Description::before {
        content: unset; }
    .Products__Cell:last-child {
      order: unset;
      max-width: 130px;
      min-width: 130px; }
      .Products__Cell:last-child > label {
        display: block; }
    .Products__Cell__Mobile_label {
      display: none; }
    .Description {
      font-size: 1rem; }
    .show__Desktop_Only {
      display: unset; }
    .show__Mobile_Only {
      display: none; } }
