/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
:global(.epi-editContainer).row {
  min-height: 0 !important; }

.gutter {
  margin: -1rem 0 0 0 !important; }

.block {
  display: block; }
  .gutter .block {
    margin: 1rem 0 0 0 !important; }
  @media screen and (min-width: 320px) {
  .row,
  .column {
    display: flex;
    flex-wrap: wrap; }
  .gutter {
    margin-left: -1rem !important; }
    .gutter .block {
      margin-left: 1rem !important; }
  .block {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .\1\:3.column .block, .row > .\1\:4.block, .\1\:4.column .block {
    flex-basis: 50%;
    min-width: 0; }
  .\1\:3.column --gutter.block, .gutter .\1\:3.column .block, .row > --gutter.\1\:4.block, .gutter > .\1\:4.block, .\1\:4.column --gutter.block, .gutter .\1\:4.column .block {
    flex-basis: calc(50% - 1rem);
    min-width: 0; }
  .row > .\1\:2.block, .\1\:2.column, .\1\:2.column .block, .row > .\1\:3.block, .\1\:3.column, .\1\:4.column, .row > .\2\:3.block, .\2\:3.column, .\2\:3.column .block, .row > .\3\:4.block, .\3\:4.column, .\3\:4.column .block {
    flex-basis: 100%;
    min-width: 0; } }
  @media screen and (min-width: 768px) {
  .row,
  .column {
    flex-wrap: nowrap; }
  .row > .\1\:2.block,
  .row .column .\1\:2.block, .\1\:2.column {
    flex-basis: 50%;
    min-width: 0; }
  .row > .\1\:3.block,
  .row .column .\1\:3.block, .\1\:3.column {
    flex-basis: 33.33333%;
    min-width: 0; }
  .row > .\1\:4.block,
  .row .column .\1\:4.block, .\1\:4.column {
    flex-basis: 25%;
    min-width: 0; }
  .row > .\2\:3.block,
  .row .column .\2\:3.block, .\2\:3.column {
    flex-basis: 66.66667%;
    min-width: 0; }
  .row > .\3\:4.block,
  .row .column .\3\:4.block, .\3\:4.column {
    flex-basis: 75%;
    min-width: 0; }
  .\1\:2.column, .\1\:3.column, .\1\:4.column, .\2\:3.column, .\3\:4.column {
    flex-direction: column; }
  
  html:global(.ios) .row .column .\1\:2.block, .row .column html:global(.ios) .\1\:2.block,
  html:global(.ios) .row .column .\1\:3.block, .row .column html:global(.ios) .\1\:3.block,
  html:global(.ios) .row .column .\1\:4.block, .row .column html:global(.ios) .\1\:4.block,
  html:global(.ios) .row .column .\2\:3.block, .row .column html:global(.ios) .\2\:3.block,
  html:global(.ios) .row .column .\3\:4.block, .row .column html:global(.ios) .\3\:4.block {
    flex-basis: auto; }
  
  html:global(.sf) .row .column .\1\:2.block, .row .column html:global(.sf) .\1\:2.block,
  html:global(.sf) .row .column .\1\:3.block, .row .column html:global(.sf) .\1\:3.block,
  html:global(.sf) .row .column .\1\:4.block, .row .column html:global(.sf) .\1\:4.block,
  html:global(.sf) .row .column .\2\:3.block, .row .column html:global(.sf) .\2\:3.block,
  html:global(.sf) .row .column .\3\:4.block, .row .column html:global(.sf) .\3\:4.block {
    flex-basis: auto; }
  
  html:global(.ie) .row .column .\1\:2.block, .row .column html:global(.ie) .\1\:2.block,
  html:global(.ie) .row .column .\1\:3.block, .row .column html:global(.ie) .\1\:3.block,
  html:global(.ie) .row .column .\1\:4.block, .row .column html:global(.ie) .\1\:4.block,
  html:global(.ie) .row .column .\2\:3.block, .row .column html:global(.ie) .\2\:3.block,
  html:global(.ie) .row .column .\3\:4.block, .row .column html:global(.ie) .\3\:4.block {
    flex: 0 0 auto; } }
