.hidden {
  display: none !important; }

.shown {
  display: block !important; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media screen and (min-width: 320px) {
  .hiddenmc {
    display: none !important; }
  .shownmc {
    display: block !important; } }

@media screen and (min-width: 480px) {
  .hiddentn {
    display: none !important; }
  .showntn {
    display: block !important; } }

@media screen and (min-width: 768px) {
  .hiddensm {
    display: none !important; }
  .shownsm {
    display: block !important; } }

@media screen and (min-width: 992px) {
  .hiddenmd {
    display: none !important; }
  .shownmd {
    display: block !important; } }

@media screen and (min-width: 1200px) {
  .hiddenlg {
    display: none !important; }
  .shownlg {
    display: block !important; } }

@media screen and (min-width: 1560px) {
  .hiddenhg {
    display: none !important; }
  .shownhg {
    display: block !important; } }
