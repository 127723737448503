/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.productItem {
  position: relative; }
  .productItem .displayName {
    font-weight: bold; }
  .productItem .supplier {
    overflow: ellipsis; }
  .productItem .small {
    font-size: 11.2px; }
  .productItem .package {
    padding: 0 0 1rem;
    float: right; }
  .productItem .campaign {
    margin-left: 1rem;
    color: #ff0000; }
  .productItem .stock, .productItem .icon {
    height: 1rem;
    width: 1rem;
    position: absolute;
    right: 0;
    float: right;
    border-radius: 50%; }
  .productItem .stock {
    top: 0; }
    .productItem .stock.zero {
      background-color: #99d800; }
    .productItem .stock.one {
      background-color: #f0942d; }
    .productItem .stock.two {
      background-color: #db1501; }
    .productItem .stock.three {
      background-color: #00b3d5; }
    .productItem .stock.four {
      background-color: #333; }
  .productItem .icon {
    right: 0; }

.information {
  display: flex;
  justify-content: space-between;
  font-size: 11.2px; }
  .information .productValues {
    flex: 1 auto; }
  .information .area {
    text-align: right; }

.price.flex {
  flex: auto; }

.price.left {
  float: left; }

.price.right {
  float: right; }
