/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }

.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }

.paragraph {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.base {
  width: 100%;
  background-color: #b0406f; }
  .base.green {
    background-color: #24733b;
    color: #fff; }
  .base.warning {
    background-color: #b0406f;
    color: #fff; }
  .base.yellow {
    background-color: #f2b52e;
    color: #0d0f11; }
  .base .inner {
    max-width: 97.14286rem;
    margin: 0 auto;
    padding: 1.42857rem 1rem;
    text-align: left; }
    .base .inner .link {
      color: inherit;
      font-weight: 700;
      text-decoration: none; }
      .base .inner .link:hover {
        color: inherit;
        text-decoration: underline; }
    .base .inner p {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 115%;
      margin-bottom: 0.35714rem; }
    .base .inner h4 {
      margin: 0;
      margin-bottom: 0.35714rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.14286rem;
      line-height: 115%; }
    @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; } }
