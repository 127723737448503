/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.item {
  padding: 1rem 0.5rem;
  margin: auto; }
  .item:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .item.compact {
    display: flex; }
    .item.compact > div {
      margin: auto; }
    .item.compact > div + div {
      margin-left: 16px; }
    .item.compact .product {
      width: 100%; }
    .item.compact .price {
      width: auto; }
    .item.compact .quantity {
      width: 40px; }
  .item.editing {
    display: block; }
    .item.editing label + input[type='text'] {
      display: inherit; }
    .item.editing button + a {
      margin-left: 0.5rem; }
    .item.editing input {
      margin-top: 0.5rem; }
    .item.editing button {
      margin-top: 1rem; }
    .item.editing input:-moz-read-only {
      background-color: #eff0f2;
      color: #999999 !important; }
    .item.editing input:read-only {
      background-color: #eff0f2;
      color: #999999 !important; }
    .item.editing .taxEdit {
      width: 37px;
      height: 25px;
      margin: 0; }
    .item.editing .productInfo {
      text-align: center;
      margin: 1rem 0 1rem 0; }
      .item.editing .productInfo .row {
        display: flex;
        justify-content: center;
        margin: 10px 0 10px 0;
        padding: 0; }
        .item.editing .productInfo .row li {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          width: 50%;
          margin: 0; }
        .item.editing .productInfo .row svg {
          margin-left: 5px; }
        .item.editing .productInfo .row .inactiveLock {
          opacity: 0.2; }

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end; }

.main .part:nth-child(2n + 1) {
  background-color: #eff0f2; }

.main .part {
  padding: 0 1rem; }

.emptyRows {
  padding: 2rem 0; }
