/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  padding-top: 0; }

.addButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px; }

.list {
  margin-top: 0.5rem;
  width: 100%;
  border-spacing: 0 1px;
  border-collapse: separate; }
  .list thead {
    display: none; }
  .list tbody {
    width: 100%; }
    .list tbody tr {
      background-color: #fff; }
      .list tbody tr:not(:first-child) {
        border-top: 1px solid #fff; }
  .list tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem; }
  .list td, .list th {
    border-bottom: 1px solid #e7e7e7;
    padding: 0.25rem; }
    .list td:before, .list th:before {
      content: attr(data-label);
      margin-right: .5rem;
      min-width: 120px;
      display: inline-block; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap;
    font-size: 0.85714rem;
    font-weight: normal;
    vertical-align: bottom;
    color: #000; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }
  .list .userName {
    text-decoration: underline; }
  .list .right {
    text-align: right; }
  @media screen and (min-width: 768px) {
      .list thead {
        display: table-header-group; }
      .list tr {
        display: table-row;
        flex-direction: row;
        margin-bottom: 0; }
        .list td:before, .list th:before {
          display: none; }
      .list td, .list th {
        border-bottom: none;
        padding: 0; }
        .list td:first-child, .list th:first-child {
          padding-left: 1rem; }
        .list td:last-child, .list th:last-child {
          padding-right: 3rem; } }
