/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.box {
  flex: 1;
  border: solid;
  border-width: 0.063rem;
  border-color: #e7e7e7;
  margin: 1rem 0;
  position: relative; }
  .box.selected {
    border-color: #f2b52e; }
  .boxTop {
    display: flex;
    flex-direction: column;
    position: relative; }

.header {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e7e7e7;
  color: #6c737a;
  text-align: center;
  padding: 0.625rem;
  letter-spacing: 0.05em; }
  .header.selected {
    color: #0d0f11;
    background-color: #f2b52e; }

.description {
  text-align: center;
  padding: 1.25rem;
  background-color: #f5f5f5;
  color: #6c737a;
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  height: 10.5rem; }
  .description.selected {
    color: #0d0f11;
    background-color: #fcf0d5; }

.price {
  text-align: center;
  height: 4.688rem;
  width: 4.688rem;
  line-height: 4.688rem;
  border-radius: 50%;
  border-color: #e7e7e7;
  border: 0.063rem solid;
  color: #6c737a;
  background-color: white;
  margin-left: auto;
  position: absolute;
  top: 9.5rem;
  right: 0.3rem;
  font-size: 1rem; }
  .price.selected {
    border-color: #f2b52e;
    color: #0d0f11; }
  .price > span {
    line-height: 1.1;
    display: inline-flex;
    align-self: center;
    vertical-align: middle; }

.card {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column; }
  .card ul {
    width: 100%;
    margin: inherit 0;
    padding: 0;
    list-style: none; }

.contentList {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  color: #6c737a; }
  .contentList.selected {
    color: #0d0f11; }

li.contentList {
  margin: 1rem 1.5rem 0.25rem; }
  li.contentList svg {
    margin-right: .5rem; }

.checkMark {
  color: #6c737a;
  font-size: 1rem; }
  .checkMark.selected {
    color: #f2b52e; }
  @media screen and (min-width: 992px) {
    .box {
      margin: 1rem; }
      .box:first-of-type {
        margin-left: 0; }
      .box:last-of-type {
        margin-right: 0; }
    li.contentList {
      text-indent: -1rem; } }
