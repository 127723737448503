/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f9f9f9;
  position: fixed;
  width: 100%;
  bottom: 0vh;
  left: 0;
  padding: 2rem 3rem;
  z-index: 10000; }
  .wrapper > div {
    padding: 0 1rem;
    white-space: nowrap;
    width: calc(100% / 7); }
    .wrapper > div div {
      padding: 0.5rem 0; }
    .wrapper > div.vatWrapper {
      width: calc(2 * 100% / 7); }
    .wrapper > div .vat {
      padding: 0;
      display: flex; }
    .wrapper > div .vatInput {
      width: 50%; }
      .wrapper > div .vatInput input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .wrapper > div .vatCalculated {
      width: 50%;
      display: inline-block;
      border: 1px solid #d9d9d9;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      line-height: 2.5;
      text-align: right;
      padding: 0 1rem;
      margin: 0.5rem 0;
      height: 38px;
      align-self: flex-end; }
  .wrapper input {
    padding-right: 2.5rem;
    height: 2.7rem; }
  .wrapper .symbol {
    position: relative;
    left: -2rem;
    line-height: 2; }
  .wrapper .price {
    margin: 16px 0 1rem 0;
    display: block; }
  @media print {
    .wrapper {
      position: static;
      page-break-after: auto; } }
