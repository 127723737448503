/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.field {
  flex: 1;
  display: flex; }

.open {
  z-index: 100099;
  transition: inherit; }
  .open > div > div:first-child {
    background-color: #fff;
    opacity: 1; }
  .open .base {
    display: block; }
    .open .base:hover, .open .base:focus,
    .open .base svg {
      color: #23272a !important; }

.clear {
  composes: bare from "../../../../../../styles/objects/buttons.scss";
  font-size: 1rem;
  height: 46px;
  line-height: 46px;
  color: black;
  background: transparent;
  position: absolute;
  right: 3.5rem;
  top: 0;
  bottom: 0;
  height: auto;
  width: 20px; }
  .clear svg {
    position: absolute;
    top: 2px;
    right: 0px;
    bottom: 0px;
    display: block;
    left: 5px;
    margin: auto;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }

.hiddenSearchButton {
  opacity: 1;
  position: absolute;
  right: 1rem;
  width: 2.5rem; }
