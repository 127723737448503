/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  -webkit-tap-highlight-color: transparent;
  composes: textMaxWidth from '../../../styles/generic/typography.scss';
  position: relative; }
