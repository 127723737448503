/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.useNewDesign {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 150px; }
  .useNewDesign > * {
    flex: 1;
    max-height: 35px; }
  .useNewDesign .inputWrapper {
    margin-top: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    overflow: hidden; }
    .useNewDesign .inputWrapper input {
      flex: 1;
      border-radius: 0px;
      background-color: #ffffff;
      border: none;
      min-width: 0;
      width: 100%; }
    .useNewDesign .inputWrapper .filler {
      background-color: transparent;
      opacity: 0;
      padding-left: 4px; }
    .useNewDesign .inputWrapper .units {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: transparent;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none; }
      .useNewDesign .inputWrapper .units .center {
        background-color: transparent;
        display: flex; }
        .useNewDesign .inputWrapper .units .center .number {
          opacity: 0; }

.inputWrapper {
  position: relative;
  margin-top: 0.5rem; }
  .inputWrapper .selectedValue {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    padding: 0 10px;
    white-space: nowrap;
    display: flex;
    align-items: center; }

.base {
  font-weight: bold;
  white-space: nowrap; }

.small {
  font-size: inherit; }

.medium {
  font-size: inherit; }

.large {
  font-size: inherit; }

.huge {
  font-size: inherit; }

.gigantic {
  font-size: inherit; }

.current {
  display: block;
  white-space: nowrap; }

.unit {
  padding-left: 0.313em; }

.comparePrice {
  color: #999;
  display: block;
  white-space: nowrap;
  font-size: smaller; }
