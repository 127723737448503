/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.searchList div > div.row {
  border-top: 1px solid #e5e5e5; }

.row {
  margin: 0 0.25rem;
  overflow: auto; }
  .row:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .row .small {
    font-size: 11.2px; }

.flex {
  display: flex;
  justify-content: flex-end;
  align-items: baseline; }
  .flex button {
    margin: 0 0.2rem;
    letter-spacing: 0;
    padding: 0.25rem 1rem; }

.right {
  float: right; }

.xsinput {
  width: 50px; }

.onlyOne {
  padding: 7px; }

button.inactiveButton {
  background-color: #A0A0A0; }

.emptyRows {
  padding: 2rem 1rem;
  background-color: #eff0f2; }

.part {
  padding: 0 1rem; }

@media screen and (min-width: 768px) {
    .row {
      margin: 0 0.5rem; }
      .flex button {
        letter-spacing: 0.1rem;
        padding: 0.5rem 1rem; } }
