.recipeGroupTable .recipeGroupItem {
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  display: flex;
  align-items: center; }
  .recipeGroupTable .recipeGroupItem .adminButton {
    flex: 1; }
    .recipeGroupTable .recipeGroupItem .adminButton button {
      float: right;
      margin-right: 5px; }
  .recipeGroupTable .recipeGroupItem .recipeGroupName {
    flex: 8;
    padding-left: 15px; }
  .recipeGroupTable .recipeGroupItem .recipeGroupItem {
    flex: 2; }

.recipeGroupTable .recipeGroupItem:nth-child(even) {
  background-color: rgba(225, 225, 225, 0.72); }

.heading {
  padding-left: 15px; }

.modalInput {
  width: 100%; }
