/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.productSearchWrapper {
  width: 100%;
  flex: 1 1 100%; }

.addWrapper {
  position: relative; }
  .addWrapper input {
    width: 100%;
    padding: 0.9rem; }
    .addWrapper input::-ms-clear {
      display: none; }

.emptySearchBtn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-right: 10px; }

.loaderSpinner {
  position: absolute;
  width: 3rem;
  padding: 0.5rem;
  color: #f7594d;
  top: 50%;
  left: calc(50% - 3rem / 2);
  border-radius: 50%;
  opacity: 1;
  pointer-events: none;
  z-index: 100109;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 0 1rem 0 rgba(255, 255, 255, 0.1), inset 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.visible {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.resultWrapper {
  position: relative; }
  .resultWrapper .autocomplete {
    top: 0;
    left: 0;
    z-index: 1;
    max-height: 50vh;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    position: absolute; }
  @media screen and (min-width: 992px) {
    .emptySearchBtn {
      margin-right: 14px; } }
