/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  padding: 2rem 0; }
  .base .title {
    text-align: center;
    font-family: SFNS_bold, sans-serif;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 1.7rem;
    line-height: 1.7rem; }

.addToCartRecommendations {
  padding: 0;
  background: white;
  margin-bottom: 2rem;
  transition: max-height 300ms ease-in;
  overflow: hidden;
  max-height: 520px; }

.invisible {
  max-height: 0; }

.visible {
  max-height: 520px; }

.cardWrapper {
  display: none;
  width: calc(50%);
  margin: 0;
  border-left: 1px solid #dee1e4; }
  .cardWrapper:first-child {
    border-left: 0; }
  .cardWrapper:nth-child(-n+2) {
    display: flex; }
  .cardWrapper .card {
    width: 100%; }
  @media screen and (min-width: 480px) {
      .base .title {
        margin-top: 2.2rem; }
    .cardWrapper {
      width: calc(33%); }
      .cardWrapper:nth-child(3) {
        display: flex; } }
  @media screen and (min-width: 992px) {
      .base .title {
        line-height: 2.85714rem;
        font-size: 2.5rem;
        margin-top: 2rem; }
    .cardWrapper {
      width: calc(16.66%); }
      .cardWrapper:nth-last-child(-n+2) {
        display: flex; } }
  @media screen and (min-width: 768px) {
    .cardWrapper {
      width: calc(25%); }
      .cardWrapper:nth-child(4) {
        display: flex; } }
