/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.footer {
  font-style: italic; }

.text {
  font-size: 1.14286rem;
  margin: 0; }

.hero {
  margin-bottom: 3rem; }
