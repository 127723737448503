/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.changedItemsListing {
  margin-bottom: 14px; }

.card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 19px;
  border-bottom: 1px solid #e5e5e5;
  flex-direction: column; }
  .card:last-child {
    border-bottom: none; }

.cardInner {
  display: flex;
  flex: 2;
  width: 100%; }

.cardColumn {
  margin-left: 12px; }

.imageWrap {
  flex: 0 0 55px; }

.articleNumber {
  font-family: SFNS_bold, sans-serif;
  font-size: 12px;
  color: #999; }

.detailsWrap {
  flex: 1 0;
  padding-left: 12px; }

.title {
  line-height: inherit;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 15px; }

.itemInfoWrap {
  flex: 1; }

.itemMessage {
  margin-bottom: 10px; }

.subDeliveryTitle,
.itemInfoTitle {
  font-family: SFNS_bold, sans-serif;
  color: #23272a; }

.subDeliveryContentWrap {
  display: flex;
  flex: 1;
  align-items: center; }

.subDeliveryIcon {
  width: 85px;
  height: 19px;
  color: #c2c5c8;
  margin-right: 10px; }

.subDeliveryContent {
  font-size: 13px;
  flex-direction: column; }

.currentDeliveryDate {
  text-decoration: line-through;
  color: #23272a; }

.newDeliveryDate {
  color: #6fb172; }

.deliveryItemDate {
  font-family: SFNS_bold, sans-serif; }

.base {
  margin-top: 1rem;
  margin-left: -1rem;
  display: flex; }
  .base .buttonWrap {
    flex: 1 1 auto;
    padding-left: 1rem; }
  .base .button {
    width: 100%; }
  @media screen and (min-width: 992px) {
    .card {
      flex-direction: row; } }
  @media screen and (min-width: 1200px) {
    .card {
      line-height: 20px; }
    .cardColumn {
      margin-left: 14px; }
    .detailsWrap {
      padding-left: 14px; }
    .title {
      font-size: 16px; }
    .subDeliveryIcon {
      width: 55px;
      height: 20px;
      margin-right: 28px; }
    .subDeliveryContent {
      font-size: 15px;
      display: flex;
      flex-direction: row;
      flex: 1; } }
