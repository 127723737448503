/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.breadcrumbsWrap {
  margin-top: 20px; }

.bottomBorder {
  position: relative; }

.searchPhraseHeading {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 25px;
  line-height: 35px; }

.searchTextContentWrap {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 20px; }
  .searchTextContentWrap.noContent {
    margin-top: 5px; }
  .searchTextContentWrap .didYouMean .didYouMeanWrap:after {
    content: ", "; }
  .searchTextContentWrap .didYouMean .didYouMeanWrap:last-child:after {
    content: ""; }
  .searchTextContentWrap .didYouMean .didYouMeanWrap a {
    color: #f7594d; }

.hasDidYouMean {
  margin: 0 !important; }

.hasProducts {
  margin: 0 !important; }

.lists {
  display: block; }
  .lists h2 {
    font-size: 1.42857rem;
    margin-bottom: 0.5rem;
    line-height: 1.25; }
  .lists ul {
    padding: 0;
    margin: 0; }
    .lists ul > li {
      padding: 0.125rem; }

.noSearchHitsIconWrap {
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  flex-shrink: 0; }

.noSearchHitsIcon {
  color: #f7594d;
  width: 100%;
  height: 100%; }

@media screen and (min-width: 992px) {
    .bottomBorder {
      margin-top: 0 !important; }
      .bottomBorder:after {
        content: "";
        display: block;
        border-bottom: 1px solid #F7594D;
        height: 0px;
        width: 100%;
        position: absolute;
        bottom: -50px; }
    .searchTextContentWrap {
      font-size: 20px;
      align-items: center; } }

@media screen and (min-width: 768px) {
    .searchPhraseHeading {
      font-size: 35px;
      line-height: 45px; } }

@media screen and (min-width: 1200px) {
    .searchPhraseHeading {
      font-size: 40px;
      line-height: 50px; } }

@media screen and (min-width: 480px) {
    .lists.noProducts {
      padding: 1rem 0.5rem 2rem; }
    .lists {
      padding-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5rem; }
      .lists > div {
        width: 33.33%;
        padding: 0 1rem; } }
