/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.placeholderText {
  font-size: 12px;
  padding-left: 10px;
  width: 50px;
  padding-right: 0;
  height: 34px;
  line-height: 34px; }

.button {
  color: #999;
  margin-left: 1rem;
  font-family: SFNS_thin, sans-serif; }
  .button select {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 10px; }
  .button.selected {
    font-family: SFNS_bold, sans-serif;
    color: #000;
    background-color: #c2c5c8;
    padding: 3px 10px;
    border-radius: 2rem;
    align-self: center; }
  .button:last-of-type {
    margin-right: 1rem; }
