/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.rowHeader {
  font-weight: bold; }

.end {
  text-align: right; }

.list tr:not(:first-child) {
  border-top: 1px solid #e5e5e5; }

.list tr:nth-child(2n) {
  background-color: #eff0f2; }

.list td:first-child, .list th:first-child {
  padding-left: 1rem; }

.list td:last-child, .list th:last-child {
  padding-right: 1rem; }

.list td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: middle; }

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end; }

.part {
  padding: 0 1rem;
  margin-top: 2rem; }
