/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.exhibitionsWrapper .calendar {
  cursor: pointer; }
  .exhibitionsWrapper .calendar svg {
    height: 1.3em; }

.exhibitionsWrapper .headers {
  display: flex;
  margin: 1rem 0;
  padding: 0 2rem; }
  .exhibitionsWrapper .headers > * {
    font-weight: bold; }

.exhibitionsWrapper .datarow {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  background-color: #e5e5e5;
  padding: 1rem;
  margin-top: 1.5rem;
  width: 100%; }
  .exhibitionsWrapper .datarow.changed {
    background-color: #c2c5c8; }
  .exhibitionsWrapper .datarow select {
    background-color: #fff; }

.exhibitionsWrapper .commonCol, .exhibitionsWrapper .col3, .exhibitionsWrapper .col4, .exhibitionsWrapper .col5, .exhibitionsWrapper .col6 {
  display: flex;
  align-items: center;
  padding-right: 10px; }
  .exhibitionsWrapper .commonCol input, .exhibitionsWrapper .col3 input, .exhibitionsWrapper .col4 input, .exhibitionsWrapper .col5 input, .exhibitionsWrapper .col6 input {
    max-width: 120px; }
  .exhibitionsWrapper .commonCol div, .exhibitionsWrapper .col3 div, .exhibitionsWrapper .col4 div, .exhibitionsWrapper .col5 div, .exhibitionsWrapper .col6 div {
    margin-left: 0.5rem; }

.exhibitionsWrapper .col1 {
  flex: 5;
  padding-right: 1rem; }
  .exhibitionsWrapper .col1 input {
    width: 100%; }

.exhibitionsWrapper .col2 {
  flex: 2; }

.exhibitionsWrapper .col3 {
  flex: 2; }

.exhibitionsWrapper .col4 {
  flex: 2; }

.exhibitionsWrapper .col5 {
  flex: 2; }

.exhibitionsWrapper .col6 {
  flex: 2; }

.exhibitionsWrapper .col7 {
  flex: 1; }

.exhibitionsWrapper .col8 {
  flex: 1; }

.exhibitionsWrapperMobile input {
  width: 100%; }

.exhibitionsWrapperMobile .calendar {
  cursor: pointer; }

.exhibitionsWrapperMobile .mobileRowWrapper {
  background-color: #e5e5e5;
  margin-top: 1rem;
  display: flex; }
  .exhibitionsWrapperMobile .mobileRowWrapper .calendar {
    padding-left: 15px; }
  .exhibitionsWrapperMobile .mobileRowWrapper.changed {
    background-color: #c2c5c8; }
  .exhibitionsWrapperMobile .mobileRowWrapper .headers {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem; }
    .exhibitionsWrapperMobile .mobileRowWrapper .headers > * {
      display: flex;
      font-weight: bold;
      margin-top: 0.5rem;
      height: 35px;
      align-items: center; }
  .exhibitionsWrapperMobile .mobileRowWrapper .datarow {
    flex: 3;
    display: block;
    flex-direction: column;
    align-items: center;
    padding-right: 1rem; }
    .exhibitionsWrapperMobile .mobileRowWrapper .datarow .col1 {
      padding-right: 28px; }
    .exhibitionsWrapperMobile .mobileRowWrapper .datarow select {
      background-color: #fff;
      width: 100%; }
    .exhibitionsWrapperMobile .mobileRowWrapper .datarow > * {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 0.5rem;
      height: 35px; }
