/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.row {
  padding: 1rem 0.5rem;
  overflow: auto; }
  .row.discontinued {
    background: #fef6f5; }
  .row:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .row .small {
    flex: auto;
    font-size: 11.2px; }

.controls {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline; }

.xsinput {
  align-items: flex-start;
  width: 50px; }

.unitType {
  margin: 0 6px; }
  .unitType button.inactiveButton {
    background-color: #A0A0A0; }

.arrow {
  padding: 7px 10px 0px 10px;
  cursor: pointer; }

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end; }

.discontinuedText {
  margin-right: auto; }
