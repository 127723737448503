/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.small {
  cursor: pointer; }

.listAdd {
  padding-left: 7px; }

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  color: #6c737a;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  transition: background-color 200ms ease-in-out; }
  .circle svg {
    position: relative;
    height: 16px;
    width: 16px; }
  .circle.activeIcon {
    background: #343d46;
    color: #fff;
    border: 1px solid #343d46; }
    .circle.activeIcon:hover {
      background: #6c737a !important;
      border-color: #6c737a !important;
      color: #fff; }
  .circle:hover, .circle:focus, .circle:active {
    color: #000;
    background: #dedede !important; }

.list {
  display: block; }

.card {
  position: relative; }

.header {
  display: flex;
  font-size: 14px; }

.cardInner,
.headerInner {
  display: flex;
  align-items: flex-start;
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 20px; }

.headerFlexWrapper {
  flex: 3 0 2px; }

.headerInner {
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background-color: #f9f9f9;
  align-items: center; }

.emvHeaderSpace {
  flex: 1;
  max-width: 360px; }

.emvTitleWrapper {
  height: 100%;
  margin-left: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden; }

.emvTitle {
  margin: 0 10px 0 0; }

.helpButton {
  width: 1.5rem;
  font-size: 1.1rem;
  height: 1.5rem;
  padding: 0 !important; }

.tooltip {
  padding: 2rem;
  width: 250px; }

.cardInner {
  font-family: SFNS, sans-serif;
  font-size: 11px; }

.row {
  flex-direction: row;
  display: flex;
  width: 100%; }

.column {
  flex: 1;
  padding: .25rem; }
  .column:last-of-type {
    background: #f9f9f9; }
  .column .row {
    height: 100%; }

.top,
.bottom {
  flex-direction: row;
  display: flex;
  justify-content: flex-end; }

.top {
  margin-bottom: auto; }

.bottom {
  margin-top: auto; }

.quantityAndVariantWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: .5rem;
  padding-right: 1rem;
  min-width: 200px; }
  .quantityAndVariantWrap:after {
    border-right: 1px solid #e7e7e7;
    height: 100%;
    width: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0; }

.imageWrap {
  max-width: 85px; }

.detailsWrap {
  flex: 2; }
  .detailsWrap.listStyle {
    padding-left: 0; }

.sumWrap {
  width: 125px;
  font-size: 14px;
  font-weight: 600;
  padding: .5rem;
  transition: opacity 200ms ease-out;
  text-align: right; }

.quickSearchList .header,
.quickSearchList .card {
  padding-right: 14px;
  padding-left: 14px; }

.quickSearchList .disabledIconWrap {
  left: 14px; }

.productSampleWrap {
  display: flex;
  flex: 0 0 130px;
  white-space: pre; }

.typeAndUnitWrap {
  max-width: 175px; }

.priceWrap {
  max-width: 175px; }
  .priceWrap.supplierSalesmanDiscountWrap div,
  .priceWrap.supplierSalesmanDiscountWrap input {
    width: 100%; }

.actionsWrap {
  min-width: 40px; }

.marksWrap {
  margin-top: 7px; }

.signUpWrap {
  flex: 0 0 75px; }

.availabilityWrap > div > div:first-of-type + div:before {
  content: "";
  border-left: 1px solid #e7e7e7;
  width: 0;
  height: 1rem;
  display: inline;
  margin-right: 5px; }

.listBtnsWrap {
  flex: 0 0 63px; }

.loginToBuyWrap {
  flex: 0 0 173px;
  justify-content: flex-end; }

.disabledWrap {
  width: calc(30% + 54px); }

.isPending {
  opacity: 0.25; }

.calendar svg {
  bottom: 1px; }

.calendar:hover {
  cursor: pointer; }

.calendar.disabled {
  color: #999999;
  cursor: default; }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: 0;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100%;
  height: 100%; }

.availabilityCheckboxWrap {
  padding: 7px 14px 14px;
  display: flex; }

@media screen and (min-width: 1200px) {
    .circle {
      height: 32px;
      width: 32px; }
      .circle svg {
        height: 18px;
        width: 18px; }
    .cardInner {
      font-size: 12px; }
    .signUpWrap {
      margin-right: 7px; }
  .availabilityWrap {
    margin-right: 7px; }
    .listBtnsWrap {
      flex: 0 0 71px;
      margin-right: 7px; }
    .disabledWrap {
      width: calc(30% + 65px); } }
