/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrap {
  height: 34px;
  flex-shrink: 0; }

.ordinal {
  padding: 0.25rem 0.125rem;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  max-width: 31px;
  max-height: 30px;
  padding: 0.42rem !important; }
  .ordinal[disabled] {
    background: #fafafa; }
