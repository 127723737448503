/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.full {
  width: 100%; }

.info {
  text-align: center; }

.shows {
  font-size: 1.42857rem;
  font-weight: bold;
  display: block;
  float: left;
  color: #999; }

.largeFont {
  font-size: 2.14286rem;
  font-weight: bold; }

.sortbar {
  width: 100%;
  text-align: right;
  margin-bottom: 1rem;
  display: inline-block; }

.sortAndViewBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .75rem 0.25rem;
  margin: -32px -.75rem 1.25rem;
  border-bottom: 1px solid #f7594d;
  position: relative; }

.productCount {
  font-family: SFNS, sans-serif;
  font-size: 1.286rem; }

.sortAndViewActions {
  display: flex;
  align-items: center;
  margin-left: -24px; }

.sortAndViewActionItem {
  padding-left: 24px; }

.sortDropDown {
  border: none; }
  .sortDropDown > select {
    height: 40px;
    padding-left: 18px; }
  .sortDropDown > span {
    padding-right: 5px; }
  .sortDropDown .hover {
    text-decoration: underline; }

.columnTitle {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  font-size: 1.286rem;
  font-family: SFNS, sans-serif;
  padding: 0 .25rem;
  padding-left: 1rem; }

.scorePoints .fill {
  line-height: 0.85;
  font-size: 1.71429rem;
  color: #f7594d;
  cursor: default; }

.scorePoints .empty {
  line-height: 0.85;
  font-size: 1.71429rem;
  color: #e5e5e5;
  cursor: default; }

.scorePoints .votes {
  color: #999; }

.scorePoints .cursor {
  cursor: pointer; }

.energyChart {
  width: 100%;
  text-align: center; }
  .energyChart svg {
    margin: 3rem 0; }
  .energyChart ul {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0; }
  .energyChart ul li {
    display: inline-block;
    padding-right: 3rem; }
  .energyChart ul li > span {
    display: inline-block;
    padding-right: 0.5rem;
    font-size: 1.85714rem;
    line-height: 0.85; }
  @media screen and (min-width: 992px) {
    .sortAndViewBar {
      padding: 0 .75rem 0.25rem 0;
      margin: 0rem 0 1rem 0;
      border-bottom: none; } }
  @media screen and (min-width: 768px) {
  .scorePoints {
    font-size: 1.14286rem; } }
