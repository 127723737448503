/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h4 {
  font-family: SFNS_bold, sans-serif;
  font-size: 1.1rem;
  font-weight: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }
@media screen and (min-width: 480px) {
    .h4 {
      margin-top: 2.2rem; } }
@media screen and (min-width: 992px) {
    .h4 {
      font-size: 1.14286rem;
      font-weight: 1.42857rem; } }
