/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: center; }
  .base .leftColumn {
    flex: 0 0 auto; }
  .base .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem;
    min-width: 300px; }
    .base .rightColumn p {
      margin-bottom: 0.25rem; }
    @media screen and (min-width: 992px) {
    .base {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .base .rightColumn p {
        margin-bottom: 0.5rem; } }
