/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  padding-top: 0; }

.leftChildBlock {
  display: inline-block;
  width: 100%; }
  .leftChildBlock__item {
    width: 100%;
    height: auto; }

.rightChildBlock {
  display: inline-block;
  width: 100%; }
  .rightChildBlock__item {
    width: 100%;
    height: auto; }

.fullChildBlock {
  width: 100%; }
  .fullChildBlock__item {
    width: 100%;
    height: auto; }

.heroBlock {
  display: flex;
  justify-content: space-between; }
  .heroBlock__preamble h2 span {
    color: #f8584c; }
  .heroBlock__preamble p {
    font-size: 1.4em; }
  .heroBlock__userInfo {
    display: none; }

.leftPaneMenu {
  float: left;
  width: 15%;
  text-align: center; }

.rightBlock {
  width: 85%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  float: left; }
  .rightBlock__item {
    width: 100%;
    height: auto; }

.overviewSection {
  display: block; }

.orderOverviewTitle {
  font-size: 1.6rem;
  font-weight: bold; }

@media screen and (min-width: 768px) {
    .leftChildBlock {
      width: 50%;
      padding: 0 1rem; }
    .rightChildBlock {
      width: 50%;
      padding: 0 1rem; }
      .heroBlock__userInfo {
        display: inherit;
        padding-bottom: 20px; }
    .rightBlock {
      width: 85%;
      padding: 0 1rem; }
    .overviewSection {
      display: flex;
      justify-content: space-between; } }
