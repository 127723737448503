/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.toolbarWrap {
  display: flex;
  gap: 8px; }

.toolButton {
  width: 100%; }
  .toolButton > div {
    width: 100%; }

.markAll {
  padding: 0 0 0 10px;
  display: flex; }

.validationFeeback {
  border: 1px solid #b0406f;
  padding: 1rem;
  margin-top: 2rem;
  background: #fff; }
  .validationFeeback svg {
    color: #b0406f;
    font-size: 1.5rem;
    vertical-align: bottom;
    margin-right: 1rem; }
  @media screen and (max-width: 767px) {
    .toolbarWrap {
      flex-direction: column; }
  .buttonWrapper a {
    display: block;
    text-align: center; } }
  @media screen and (min-width: 992px) {
    .toolbarWrap {
      gap: 10px; }
  .buttonWrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; } }
  @media screen and (min-width: 768px) {
    .toolButton {
      width: 19rem; }
    .markAll {
      padding: 0 0 0 14px; } }
