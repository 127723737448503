/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.item {
  visibility: hidden; }

.item.onShow {
  flex-wrap: wrap;
  background-color: #fff;
  visibility: visible; }
  .item.onShow + .item.onShow {
    padding-top: 1rem;
    border-top: 1px solid #e5e5e5; }

.rowHead {
  display: flex;
  border-bottom: 1px dashed #e5e5e5;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }
  .rowHead .checkBox {
    min-width: 0rem; }
  .rowHead .mediaDetailHead {
    width: 85px; }
    html:global(.ie) .rowHead .mediaDetailHead img {
      width: auto; }

.rowDetails {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px dashed #e5e5e5;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }
  .rowDetails > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    flex-basis: 100%; }
  .rowDetails a > * {
    flex-basis: 12%; }
  .rowDetails > a > div {
    text-align: left;
    margin-left: 4rem; }

.checkBox {
  justify-content: center;
  flex-grow: 0;
  max-width: 60px; }

.orderDetailsSummary {
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .orderDetailsSummary > div {
    font-size: 1.5rem;
    flex: 0 0 20%;
    padding: 0 1rem; }
    .orderDetailsSummary > div.orderDetailsSummaryTitle {
      text-align: right;
      flex: 1 0 0;
      padding: 0; }

.media {
  min-width: 2rem;
  max-width: 7rem;
  flex-basis: 10%; }

.mediaDetail {
  composes: base 1to1 center from "../../../../../styles/objects/ratio.scss";
  display: block;
  width: 85px; }
  html:global(.ie) .mediaDetail img {
    width: auto;
    height: auto; }

.descriptionDetails {
  flex: 1 0 156px;
  padding: 0 0 0 1rem; }

.unitDetails {
  flex: 1 0 105px;
  padding: 0 0 0 1rem; }

.quantityDetails {
  flex: 1 0 50px;
  padding: 0 0 0 1rem; }

.priceDetails {
  width: 20%;
  padding: 0 0 0 1rem; }

.deliveryDateDetails,
.orderNumberDetails {
  white-space: nowrap;
  max-width: 120px;
  padding: 0 0 0 1rem;
  width: 100%; }

.mobileRowItem {
  visibility: hidden; }

.mobileRowItem.onShow {
  background-color: #fff;
  visibility: visible; }
  .mobileRowItem.onShow .mobileTopRowData {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px dashed #e5e5e5;
    padding: 14px 10px;
    font-size: 12px; }
    .mobileRowItem.onShow .mobileTopRowData .imageWrap {
      width: 85px;
      flex-shrink: 0;
      padding-right: 10px; }
      .mobileRowItem.onShow .mobileTopRowData .imageWrap img {
        max-height: auto; }
    .mobileRowItem.onShow .mobileTopRowData .rowLbl {
      font-weight: bold; }
    .mobileRowItem.onShow .mobileTopRowData .productInformationWrap {
      display: flex;
      flex-direction: column;
      flex: 1; }
    .mobileRowItem.onShow .mobileTopRowData .topRowWrap {
      margin-bottom: 10px; }
    .mobileRowItem.onShow .mobileTopRowData .leftRightRowContentWrap {
      display: flex; }
    .mobileRowItem.onShow .mobileTopRowData .leftRowContent {
      display: block;
      flex: 1; }
      .mobileRowItem.onShow .mobileTopRowData .leftRowContent .rowLbl:nth-child(2) {
        padding-left: 0.5rem; }
      .mobileRowItem.onShow .mobileTopRowData .leftRowContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }
    .mobileRowItem.onShow .mobileTopRowData .rightRowContent {
      display: block;
      text-align: right; }
      .mobileRowItem.onShow .mobileTopRowData .rightRowContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }
  .mobileRowItem.onShow .mobileBottomSummary {
    width: 100%; }
    .mobileRowItem.onShow .mobileBottomSummary .mobileOrderDetailsSummary {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 1rem; }
      .mobileRowItem.onShow .mobileBottomSummary .mobileOrderDetailsSummary .totalOrderPrice {
        margin-left: 5px; }
    .mobileRowItem.onShow .mobileBottomSummary .mobileBottomCopyToCart {
      display: flex;
      padding-bottom: 1rem; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 5em;
  margin-top: 5rem;
  position: relative; }

@media (max-width: 425px) {
        .mobileRowItem.onShow .mobileBottomSummary .mobileBottomCopyToCart {
          width: 100%;
          align-items: center;
          justify-content: center; } }

@media (min-width: 430px) {
        .mobileRowItem.onShow .mobileBottomSummary .mobileBottomCopyToCart {
          width: 50%;
          padding-left: 1rem;
          flex-flow: row wrap;
          justify-content: space-between; } }
