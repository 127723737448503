/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit;
  z-index: 1; }

.TopBar {
  background: #fff;
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center; }
  .TopBar.isLoggedIn {
    background: #343d46;
    color: #fff; }
  .TopBarSection {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between; }
  .TopBarColumn {
    display: block;
    flex-grow: 0;
    position: relative; }
    .TopBarColumn > * {
      text-transform: uppercase; }
    .TopBarColumn__wide {
      flex-grow: 1; }
    .TopBarColumn__first {
      min-width: 175px; }
      .small .TopBarColumn__first {
        max-width: 345px; }
    .TopBarColumn__info {
      margin-right: auto;
      flex-grow: 1; }
    .TopBarColumn__last {
      flex-grow: 0; }
      .TopBarColumn__last > div {
        white-space: pre; }
  .TopBarRow {
    display: block;
    padding: 0 1rem; }

.divider:before, .divider:after {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  opacity: 1;
  transition: opacity ease-in-out 100ms; }

.divider:hover:before, .divider:hover:after {
  opacity: 0; }

.divider.left:before {
  content: "";
  border-left: 1px solid #e5e5e5;
  width: 0;
  height: 20px;
  left: 0; }

.divider.right:after {
  content: "";
  border-right: 1px solid #e5e5e5;
  width: 0;
  height: 20px;
  right: 0rem; }

.divider:empty:before, .divider:empty:after {
  opacity: 0; }

.right:hover + .right:after {
  opacity: 0; }

.left:hover + .left:before {
  opacity: 0; }

.layout {
  composes: full from "../../../../../../styles/objects/layout.scss";
  display: flex;
  top: 0px;
  position: absolute;
  align-items: center;
  justify-content: flex-start; }

.userInfoWrapper {
  display: flex; }
  .userInfoWrapper .userInfoItem {
    font-family: SFNS, sans-serif;
    white-space: pre; }
  .userInfoWrapper div > div {
    top: .75rem;
    left: 40%; }
    .userInfoWrapper div > div * {
      text-transform: none; }

.suppliersalesmanWrapper {
  position: relative;
  font-size: .875rem; }
  .suppliersalesmanWrapper .userInfoItemFirst {
    font-family: SFNS, sans-serif;
    padding-right: 0.5rem; }
  .suppliersalesmanWrapper .suppliersalesmanInfo {
    display: inline-block;
    padding-right: 0.5rem; }
  .suppliersalesmanWrapper .suppliersalesmanExhibition {
    display: inline-block; }
  .suppliersalesmanWrapper .userInfoItem {
    font-family: SFNS, sans-serif;
    white-space: pre; }

.helperLinksHolder {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  white-space: nowrap;
  flex-grow: 1;
  flex-direction: column; }
  .helperLinksHolder .helperLinks {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0; }
    .helperLinksHolder .helperLinks li {
      display: inline-block;
      margin-right: 2rem; }
      .helperLinksHolder .helperLinks li a {
        text-decoration: none; }
        .helperLinksHolder .helperLinks li a:hover {
          text-decoration: underline;
          color: #23272a; }
        @media screen and (min-width: 992px) {
  .spacer {
    max-width: 165px;
    min-width: 95px;
    margin-left: auto;
    width: 100%; }
    .layout {
      position: relative; }
    .userInfoWrapper {
      width: 100%;
      max-width: 300px; }
    .suppliersalesmanWrapper {
      min-width: 300px; } }
