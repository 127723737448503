/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.cardColumn {
  margin-left: 12px; }

.rowHeaderWrap {
  padding: 0 14px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background: #fff; }

.rowHeader {
  margin-left: -12px;
  padding: 14px 0;
  display: flex;
  align-items: flex-end; }
  .rowHeader .imageWrap {
    height: auto; }
  .rowHeader .placeholder {
    flex: 1 1 auto; }
  .rowHeader .sortable {
    cursor: pointer;
    text-decoration: underline; }
  .rowHeader .arrowUp:after, .rowHeader .arrowDown:after {
    position: relative;
    margin-left: 0.25rem;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; }
  .rowHeader .arrowUp,
  .rowHeader .arrowDown {
    padding-right: 0; }
  .rowHeader .arrowUp:after {
    top: -10px;
    border-bottom: 5px solid black; }
  .rowHeader .arrowDown:after {
    bottom: -10px;
    border-top: 5px solid black; }

.innerFlexDiv {
  display: flex;
  width: calc(100% - 30px); }

.flexWrap {
  flex-wrap: wrap; }

.topRow {
  display: flex;
  align-items: center; }

.titleWrap {
  flex: 1;
  min-width: 0; }

.bottomRow {
  display: flex; }

.contentRow {
  display: flex;
  margin-bottom: 10px; }

.outerFlexWrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - 85px);
  padding-left: 10px; }

.imageWrap {
  flex: 0 0 65px; }

.marksAndDisabledInfoWrap {
  display: flex;
  flex-direction: column;
  text-align: right; }

.detailsWrap {
  flex: 1 0; }

.typeAndUnitWrap {
  width: 50%; }

.priceWrap {
  width: 50%; }

.marksWrap {
  margin-top: 7px; }

.signUpWrap {
  flex: 0 0 75px; }

.availabilityWrap {
  flex: 0 0 74px; }

.listBtnsWrap {
  flex: 0 0 63px;
  margin-top: 4px; }

.quantityAndVariantWrap {
  margin-top: 4px;
  flex: 0 0 154px;
  display: flex; }

.disabledWrap {
  width: calc(30% + 98px); }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: 36px;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: calc(1em + 3px * 2);
  height: calc(1em + 3px * 2);
  border: 3px solid #fff;
  line-height: 1; }

.disabledIcon {
  color: #f7594d; }

.ordinal {
  padding: 0.25rem 0.125rem;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  max-width: 7rem;
  max-height: 2.5rem; }
  .ordinal[disabled] {
    background: #fafafa; }

.sortable {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap; }

.arrowUp,
.arrowDown {
  padding-right: 0; }

.arrowUp:after {
  top: -10px;
  border-bottom: 5px solid black; }

.arrowDown:after {
  bottom: -10px;
  border-top: 5px solid black; }

.row {
  transition: all 0.3s ease-out;
  height: 100%; }
  .row.highlighted {
    box-shadow: 0 0 10px #e5e5e5; }

.btn {
  display: flex; }

.modalText {
  display: flex;
  justify-content: center; }

.ordinalHeaderWrap {
  flex: 0 0 83px; }

@media screen and (min-width: 1200px) {
    .cardColumn {
      margin-left: 14px; }
    .rowHeader {
      margin-left: -14px; }
    .imageWrap {
      flex: 0 0 85px; }
    .signUpWrap {
      margin-right: 7px; }
    .availabilityWrap {
      margin-right: 7px; }
    .listBtnsWrap {
      flex: 0 0 71px;
      margin-right: 7px; }
    .disabledWrap {
      width: calc(30% + 109px); }
    .ordinalHeaderWrap {
      flex: 0 0 87px; } }

@media screen and (min-width: 992px) {
    .typeAndUnitWrap {
      width: 15%; }
    .priceWrap {
      width: 15%; }
    .disabledIconWrap {
      left: 60px; } }
