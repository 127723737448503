/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.row, .orderHeaderContainer .header {
  display: flex;
  align-items: center; }
  .row > *, .orderHeaderContainer .header > * {
    word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    flex: 2.7 0 0px; }

.item {
  flex-wrap: wrap; }
  .item + .item {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #fff; }

.checkBox {
  justify-content: center;
  flex-grow: 0;
  max-width: 23px;
  margin-right: 12px !important; }

.detailsContent {
  composes: row;
  background: #f9f9f9;
  width: 100%;
  height: 0;
  transition: 0.5s;
  opacity: 0;
  padding: 0;
  visibility: hidden; }
  .detailsContent .row, .detailsContent .orderHeaderContainer .header, .orderHeaderContainer .detailsContent .header {
    margin: 0; }

.detailsContent.show {
  flex-wrap: wrap;
  opacity: 1;
  visibility: visible;
  padding-bottom: 1rem;
  height: auto; }
  .detailsContent.show + .detailsContent.show {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #fff; }

.detailsContent {
  composes: row;
  background-color: #999999;
  width: 100%;
  height: 0;
  transition: 0.5s;
  opacity: 0;
  padding: 0;
  visibility: hidden; }

.detailsContent.show {
  flex-wrap: wrap;
  opacity: 1;
  visibility: visible;
  padding: 10px;
  height: auto; }
  .detailsContent.show + .detailsContent.show {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #fff; }

.orderNumber {
  min-width: 2rem;
  max-width: 25rem;
  flex-basis: 10%; }

.deliveryDate {
  min-width: 2rem;
  max-width: 25rem;
  flex-basis: 10%; }

.type {
  min-width: 2rem;
  max-width: 25rem;
  flex-basis: 10%; }

.orderSummary {
  width: 14rem; }

.collapse {
  flex-basis: 1.5em;
  flex: 1.3 0 0; }

.collapseBtn {
  margin-top: -0.75em; }

.price {
  width: 6.813em; }

.mobileColumn {
  display: flex;
  flex-direction: column; }

.mobileRow {
  margin-top: 5px;
  border-bottom: 1px dashed #e5e5e5;
  padding: 0.5rem 1rem; }
  .mobileRow .mobileRowData {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .mobileRow .mobileRowData .collapseWrap {
      width: 15%;
      flex: 1;
      align-self: center;
      text-align: center; }
      .mobileRow .mobileRowData .collapseWrap img {
        max-height: 4rem; }
    .mobileRow .mobileRowData .leftHeaderContent {
      padding: 0 1rem;
      display: block;
      flex: 3;
      font-size: 0.75em; }
      .mobileRow .mobileRowData .leftHeaderContent .rowLbl {
        font-weight: bold; }
      .mobileRow .mobileRowData .leftHeaderContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }
    .mobileRow .mobileRowData .rightHeaderContent {
      padding: 0 1rem;
      display: block;
      flex: 3 0 0;
      font-size: 0.75em; }
      .mobileRow .mobileRowData .rightHeaderContent .rowLbl {
        font-weight: bold; }
      .mobileRow .mobileRowData .rightHeaderContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }

.row, .orderHeaderContainer .header {
  display: flex;
  flex-flow: row wrap; }

.orderHeaderContainer {
  border: 1px solid #e5e5e5; }
  .orderHeaderContainer .orderHeaderWrap {
    border-bottom: 1px solid #e5e5e5; }
    .orderHeaderContainer .orderHeaderWrap:last-child {
      border-bottom: 0; }
  .orderHeaderContainer .header {
    background: #f1f1f1;
    cursor: pointer;
    padding: 0.5rem 1rem; }
    .orderHeaderContainer .header.isOpen {
      background: #fff; }
    .orderHeaderContainer .header div {
      margin: 0; }

.tableHeader {
  background: #e5e5e5;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem 1rem; }
  .tableHeader div {
    flex-basis: 25%; }
