/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10002; }
  .base.isOpen {
    top: 60px; }

.toggler {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  composes: alignmiddle from '../../../styles/objects/icon.scss';
  width: 100%;
  height: 3.5rem;
  padding: 1rem !important;
  pointer-events: auto;
  background: #343D46 !important;
  border-radius: 0 !important;
  text-align: center; }
  .toggler[disabled] * {
    display: none; }
  .filtering .toggler {
    text-align: center;
    text-transform: uppercase; }
    .filtering .toggler > svg {
      margin-left: 0.5rem;
      height: .85rem;
      width: .85rem;
      vertical-align: unset; }
    .filtering .toggler .facetIndicator {
      position: absolute;
      background: #f7594d;
      height: 1.75rem;
      line-height: 1.75rem;
      margin-top: -0.25rem;
      margin-left: 0.5rem;
      width: 1.75rem;
      border-radius: 1.75rem;
      color: #fff;
      text-align: center; }
  .sorting .toggler {
    text-align: right; }
    .sorting .toggler > svg {
      margin-left: 0.5rem; }
  .openAndSelected .toggler {
    -webkit-transform: translateX(75%);
            transform: translateX(75%); }

.overlay {
  top: 60px;
  pointer-events: auto; }

.section {
  -webkit-transform: translateY(100%) translateY(-3.5rem) translateY(-2px);
          transform: translateY(100%) translateY(-3.5rem) translateY(-2px);
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
  will-change: transform; }
  .section.open {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    pointer-events: auto; }

.filteringOverlayContainer {
  height: 100%; }

.filtering {
  composes: section;
  position: relative;
  width: 40vw;
  height: 100%; }
  .filtering.sortingDisabled {
    width: 100vw; }
    .filtering.sortingDisabled.open {
      width: 40vw; }

.sorting {
  composes: section;
  width: 60vw;
  max-height: 100%;
  overflow: auto;
  border-top: 2px solid #dee1e4;
  background: white; }

.sortList {
  margin: 0;
  padding: 0;
  list-style: none;
  visibility: hidden; }
  .sortList > li {
    display: block; }
  .open .sortList {
    visibility: visible; }
  .sortList li {
    border-top: 1px solid #dee1e4;
    line-height: 3rem; }

.sortOptionButton {
  composes: bare from '../../../styles/objects/buttons.scss';
  width: 100%;
  position: relative;
  padding-left: 2.5rem; }

.selectedSortOptionCheck,
.selectedFacetCheck {
  position: absolute;
  left: 1rem;
  color: #343d46; }

.selectedFacetCheck {
  left: 0rem; }

.facets {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
  border-top: 2px solid #dee1e4;
  background: #343D46; }

.facetList {
  visibility: hidden;
  background: #eff0f2;
  height: calc(100% - 3.5rem); }
  .open .facetList {
    visibility: visible; }
  .facetList ul {
    margin: 0;
    padding: 0;
    list-style: none;
    height: calc(100% - 3rem);
    overflow: auto; }
    .facetList ul > li {
      display: block; }
    .facetList ul li {
      border-bottom: 1px solid #dee1e4;
      line-height: 3rem; }

.setFacets {
  composes: bare from '../../../styles/objects/buttons.scss';
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 3rem; }

.clearFacets {
  composes: bare from '../../../styles/objects/buttons.scss';
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 3rem;
  border-top: 1px solid #dee1e4; }
  .clearFacets:focus {
    background: #eff0f2 !important; }
  .clearFacets span {
    vertical-align: text-bottom; }
  .clearFacets svg {
    margin-right: 0.5rem; }

.openFacetButton {
  composes: bare from '../../../styles/objects/buttons.scss';
  width: 100%;
  padding: 0 1rem 0 1rem;
  display: flex; }
  html:global(.ie) .openFacetButton {
    justify-content: space-between; }
  .openFacetButton .facetName {
    flex: 1;
    line-height: 1.5;
    padding: 0.75rem 0; }
  .openFacetButton svg {
    height: 3rem; }
  .openFacetButton .facetIndicator {
    background: #f7594d;
    height: 1.75rem;
    line-height: 1.75rem;
    margin-top: 0.625rem;
    margin-right: -0.5rem;
    width: 1.75rem;
    border-radius: 50%;
    color: white;
    text-align: center;
    display: block; }
  .openFacetButton.isOpen {
    background: #fff; }

.facetValueButton {
  composes: bare from '../../../styles/objects/buttons.scss';
  display: block;
  position: relative;
  padding-left: 1.5rem;
  width: 100%; }

.facetValue {
  position: relative;
  display: flex;
  width: 100%; }
  .facetValue .facetValueText {
    display: inline-block;
    max-width: calc(100% - 3rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .facetValue .count {
    max-width: 3rem;
    display: inline-block; }

.closeHeader {
  text-align: right;
  display: block;
  width: 100%;
  padding-right: 1rem;
  background: #343D46 !important; }

.closeFacets {
  composes: bare from '../../../styles/objects/buttons.scss';
  height: 3.5rem;
  pointer-events: auto;
  color: #fff; }

.filterFloatingButton {
  letter-spacing: 0.1rem;
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  color: #fff;
  z-index: 10009; }
  .filterFloatingButton span {
    vertical-align: text-bottom;
    display: inline-block;
    margin-right: 0.5rem; }
  .filterFloatingButton svg {
    height: .75rem;
    width: .75rem;
    vertical-align: text-top; }

.filters {
  position: absolute;
  width: 60vw;
  height: 100%;
  border-top: 2px solid #dee1e4;
  background: white;
  -webkit-transform: translateX(-21vw);
          transform: translateX(-21vw);
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
  will-change: transform; }
  .filters.open {
    -webkit-transform: translateX(39.9vw);
            transform: translateX(39.9vw);
    width: 61vw; }
  .filters .searchArea {
    position: relative; }
    .filters .searchArea .filterSearch {
      width: 100%;
      height: 3rem;
      margin: 1rem 0 0.5rem; }
    .filters .searchArea ::-webkit-input-placeholder {
      text-transform: uppercase; }
    .filters .searchArea ::-ms-input-placeholder {
      text-transform: uppercase; }
    .filters .searchArea ::placeholder {
      text-transform: uppercase; }
    .filters .searchArea .clearFilterSearch {
      position: absolute;
      top: 1rem;
      right: 0;
      background: none;
      height: 3rem; }
  .filters .facetValueList {
    position: absolute;
    width: 100%;
    padding: 0 1rem; }
  .filters .valuesHeading {
    margin: 0.5rem 0.5rem;
    color: #999999;
    font-family: SFNS_bold, sans-serif;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-transform: none;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .filters .values {
    height: calc(100vh - 3.5rem - 60px - 3rem);
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    .filters .values ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .filters .values ul > li {
        display: block; }
    .filters .values.ios ul:last-child {
      margin-bottom: 6rem; }
    .filters .values.searchable {
      height: calc(100vh - 3.5rem - 60px); }
    .filters .values.filteredList {
      height: calc( 100vh - 3.5rem - 60px - 6rem - 3.5rem); }
    .filters .values li {
      line-height: 2.875rem; }
    .filters .values .count {
      color: #999999;
      padding-left: 0.25rem; }

.popular {
  border-bottom: 1px solid #dee1e4; }

.viewPortBottomMargin {
  margin-bottom: 3.5rem; }

.disabled {
  color: #999999; }

@media screen and (min-width: 992px) {
      .filters .valuesHeading {
        font-size: 1rem;
        line-height: 1.42857rem; } }
