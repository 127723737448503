/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: absolute;
  width: 100%;
  -webkit-animation: presentation 200ms ease-out;
          animation: presentation 200ms ease-out;
  will-change: opacity;
  z-index: 10012; }
  .base.top {
    bottom: 100%; }
  .base.bottom {
    top: 100%; }
  .base.vLeft {
    right: calc(100% + 1.55377rem - 10px);
    bottom: 35px; }

.base:before, .base:after {
  content: "";
  position: absolute;
  left: calc(50% - 1.55377rem / 2); }
  .top.base:before, .top.base:after {
    bottom: 0; }
  .vLeft.base:before, .vLeft.base:after {
    bottom: -40px; }

.base:before {
  width: 1.55377rem;
  height: 1.55377rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2); }
  .top.base:before {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: translateX(-1.55377rem) rotate(45deg);
            transform: translateX(-1.55377rem) rotate(45deg); }
  .bottom.base:before {
    top: 0;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .vLeft.base:before {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transform: rotate(45deg) translate(15px, -19px);
            transform: rotate(45deg) translate(15px, -19px); }

.base:after {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .top.base:after {
    border-top: 1rem solid white; }
  .bottom.base:after {
    border-bottom: 1rem solid white; }
  .vLeft.base:after {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transform: rotate(90deg) translate(-9px, -39px);
            transform: rotate(90deg) translate(-9px, -39px);
    border-bottom: 1rem solid white; }

@-webkit-keyframes presentation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes presentation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.content {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  max-width: 100vw;
  color: #23272a;
  border-radius: 0.125rem;
  background: white; }
  .content.top {
    bottom: 0;
    margin-bottom: 1rem; }
  .content.bottom {
    top: 0;
    margin-top: 1rem; }
  .content.vLeft {
    top: 0;
    margin-top: 1rem; }
  .content.left {
    right: 0; }
  .content.center {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .content.right {
    left: 0; }

.close {
  composes: bare from "../../../styles/objects/buttons.scss";
  position: absolute;
  top: 3px;
  right: 5px;
  display: flex;
  width: 7px; }

@media screen and (min-width: 768px) {
      .base.vLeft {
        bottom: 40px; } }
