/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }

.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }

.paragraph {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.headerImage {
  margin-bottom: 3rem; }
  .headerImage img {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block; }
  @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; }
    .headerImage {
      display: block;
      max-height: 20rem;
      margin-bottom: 1rem;
      overflow: hidden; }
  .bottomBorder {
    position: relative; }
    .bottomBorder:after {
      content: "";
      display: block;
      border-bottom: 1px solid #f7594d;
      height: 0px;
      position: absolute;
      top: 35px;
      right: 12px;
      left: 12px; } }
