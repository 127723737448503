/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.small {
  cursor: pointer; }

.listAdd {
  padding-left: 7px; }

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  color: #6c737a;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  transition: background-color 200ms ease-in-out; }
  .circle svg {
    position: relative;
    height: 16px;
    width: 16px; }
  .circle.activeIcon {
    background: #343d46;
    color: #fff;
    border: 1px solid #343d46; }
    .circle.activeIcon:hover {
      background: #6c737a !important;
      border-color: #6c737a !important;
      color: #fff; }
  .circle:hover, .circle:focus, .circle:active {
    color: #000;
    background: #dedede !important; }

.list {
  display: block; }

.card {
  position: relative; }

.card,
.header {
  border-bottom: 1px solid #e7e7e7;
  padding-left: 0.25rem; }

.header {
  background-color: #f9f9f9;
  font-size: 14px; }

.cardInner,
.headerInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  line-height: 20px; }

.cardInner {
  font-family: SFNS, sans-serif;
  font-size: 12px; }

.row {
  flex-direction: row;
  display: flex;
  flex: 1; }

.column {
  flex: 1;
  padding: .25rem; }
  .column:last-of-type {
    background: #f9f9f9; }
  .column .row {
    height: 100%; }

.top,
.bottom {
  flex-direction: row;
  display: flex;
  justify-content: flex-end; }

.top {
  margin-bottom: auto; }

.bottom {
  margin-top: auto; }

.cardColumn {
  margin-left: 12px; }

.imageWrap {
  flex: 0 0 65px; }

.detailsWrap {
  flex: 1 0;
  padding-left: 12px; }
  .detailsWrap.listStyle {
    padding-left: 0; }

.typeAndUnitWrap {
  width: 15%; }

.priceWrap {
  width: 15%; }

.marksWrap {
  margin-top: 0.125rem; }

.signUpWrap {
  flex: 0 0 75px; }

.availabilityWrap {
  flex: 0 0 125px; }
  .availabilityWrap > div > div:first-of-type + div:before {
    content: "";
    border-left: 1px solid #e7e7e7;
    width: 0;
    height: 1rem;
    display: inline;
    margin-right: 5px; }

.headerAvailabilityWrap {
  flex: 0 0 86px; }

.sumWrap {
  width: 125px;
  font-size: 14px;
  font-weight: 600;
  padding: .5rem;
  transition: opacity 200ms ease-out;
  text-align: right; }

.quantityAndVariantWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: .5rem;
  padding-right: 1rem;
  min-width: 200px; }
  .quantityAndVariantWrap:after {
    border-right: 1px solid #e7e7e7;
    height: 100%;
    width: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0; }

.sumWrap {
  flex: 0 0 125px;
  font-size: 14px; }

.removeItemWrap {
  flex: 0 0 40px;
  text-align: right; }

@media screen and (min-width: 1200px) {
    .circle {
      height: 32px;
      width: 32px; }
      .circle svg {
        height: 18px;
        width: 18px; }
    .cardColumn {
      margin-left: 14px; }
    .imageWrap {
      flex: 0 0 85px; }
    .detailsWrap {
      padding-left: 14px; }
    .signUpWrap {
      margin-right: 7px; }
    .headerAvailabilityWrap {
      flex: 0 0 88px; } }
