/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.mmicon {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  stroke-width: 1;
  stroke: currentColor;
  fill: none;
  vertical-align: middle;
  shape-rendering: geometricprecision; }
  .mmicon svg, .mmicon path, .mmicon rect {
    fill: none;
    shape-rendering: crispEdges; }
