/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  padding-top: .5rem; }

.card {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.75rem;
  flex: 0 0 auto;
  min-width: 100%;
  width: 100%; }

.carouselProductCard > div[data-list-type='viewLayout_Grid'] {
  margin: 1px; }

.cardInner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  line-height: 20px; }

.imageWrap {
  padding: 1.5rem 0.75rem;
  max-width: 200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.row {
  width: 100%;
  display: flex;
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .row.extraBottomMargin {
    margin-bottom: 1rem; }
  .row .column {
    min-height: 0px;
    flex: 1 1 50%; }
    .row .column.narrow {
      flex: 1 1 25%; }
    .row .column.wide {
      flex: 1 1 75%; }

.productAreaWrap {
  text-align: right;
  position: relative;
  height: 100%; }
  .productAreaWrap > div {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    right: 0; }

.contentWrap {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem; }

.contentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.75rem; }

.contentFooter {
  height: 67px;
  background-color: #f9f9f9;
  padding-top: 0.75rem;
  margin-top: auto;
  position: relative; }

.contentFooterAlternativeLink {
  background-color: #f9f9f9; }
  .contentFooterAlternativeLink > div {
    margin: .5rem 0.5rem 0 auto !important; }

.marksWrap {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  max-width: 150px;
  z-index: 1;
  text-align: right; }

.futureDeliveryDate {
  align-self: flex-end;
  text-align: right;
  padding-bottom: .25rem; }
  .futureDeliveryDate > div {
    padding: .5rem .25rem 0 0; }

.campaignDatesWrap {
  font-size: 11px;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  z-index: 1;
  background: #fff;
  padding: 1px 5px; }

.titleAndAvailabilityWrap {
  align-items: flex-start; }

.titleWrap {
  flex: 1 0; }
  .titleWrap h2 {
    margin-bottom: 3px;
    line-height: 20px; }

.availabilityWrap {
  flex: 0 0 74px;
  text-align: right; }

.detailsWrap {
  flex-direction: row-reverse; }

.typeAndUnitAndPriceWrap,
.disabledInfoWrap {
  align-items: flex-start; }

.priceWrap {
  text-align: right; }

.loginToBuyWrap {
  margin: 0 auto; }

.quantityAndVariantWrap {
  display: flex;
  justify-content: flex-end; }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100%;
  height: 100%; }

.quickSearchList {
  padding: 0 .75rem; }
  .quickSearchList .cardInner .contentRow {
    padding-right: 10px;
    padding-left: 10px; }
  .quickSearchList .disabledIconWrap {
    left: 10px; }

.recommendationsPanelProductsListGallery {
  padding: 12px;
  display: flex;
  flex-wrap: wrap; }

@media screen and (min-width: 480px) {
    .grid {
      margin-right: 0; }
    .card {
      padding-left: 0.75rem; }
    .cardInner {
      border: 1px solid #e5e5e5; } }

@media screen and (min-width: 768px) {
    .card {
      min-width: 50%;
      width: 50%; }
  .carouselProductCard .card {
    min-width: 50% !important;
    width: 50% !important; } }

@media screen and (min-width: 992px) {
    .card {
      min-width: 33.33333%;
      width: 33.33333%; }
  .carouselProductCard .card {
    min-width: 33.33333% !important;
    width: 33.33333% !important; } }

@media screen and (min-width: 1200px) {
  .carouselProductCard .card {
    min-width: 25% !important;
    width: 25% !important; } }
