.userInfo {
  margin-top: 2rem;
  padding: 0px 10px;
  text-align: right;
  max-width: 120px;
  max-height: 120px;
  display: flex;
  flex-direction: column; }
  .userInfo__header {
    color: #999;
    display: block; }
  .userInfo__content {
    color: #343D46;
    font-weight: bold; }
  .userInfo__link {
    color: #f8584c;
    padding-top: 10px;
    align-self: flex-end;
    display: flex;
    margin-top: auto; }
