/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  font-family: SFNS_bold, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%;
  letter-spacing: -0.32px; }

.description {
  font-family: SFNS_thin, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%; }
  .description--medium {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%; }
  .description--title {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase; }

.subtext {
  font-family: SFNS, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 115%;
  letter-spacing: 0.24px; }

.prices {
  margin-bottom: -10px;
  line-height: inherit;
  font-size: inherit;
  white-space: pre; }

.price {
  padding-bottom: 10px;
  line-height: 20px;
  font-weight: 400;
  color: #0d0f11; }

.currentPrice {
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 14px;
  white-space: nowrap;
  color: inherit;
  font-weight: 600;
  text-transform: lowercase; }
  .currentPrice.useNewDesign {
    margin-bottom: 0px; }

.noAvailablePrice {
  margin-top: 0;
  font-size: 14px; }

.originalPrice {
  text-decoration: line-through;
  font-weight: 400; }

.campaignPrice {
  color: #f7594d !important; }

.comparePrice {
  color: #343d46; }

.smallestUnitPrice {
  color: #343d46;
  text-align: right; }

@media screen and (min-width: 768px) {
    .title {
      font-size: 18px !important;
      letter-spacing: -0.36px; }
    .description {
      font-size: 14px !important; }
      .description--medium {
        font-size: 14px !important; } }
