/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.footer {
  font-style: italic; }

.flexwrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.385rem 0.385rem;
  overflow: hidden; }
  .flexwrapper.row {
    justify-content: space-between;
    height: auto; }
  .flexwrapper a {
    color: #23272a;
    text-decoration: none; }
  @media screen and (min-width: 768px) {
    .flexwrapper {
      margin: 0 -0.73529vw 0.73529vw; } }
  @media screen and (min-width: 1360px) {
    .flexwrapper {
      margin: 0 -0.714rem 0.714rem !important; }
      .flexwrapper.row {
        height: auto !important; } }
