/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.btnWrap {
  width: 100%; }

.btn {
  width: 100%; }

.tenderProductDescriptionWrapper {
  display: block; }
  .tenderProductDescriptionWrapper span {
    display: block; }

.broad {
  max-width: 50%; }
  .broad h2 {
    color: #6c737a;
    margin-bottom: 0; }
  .broad > div > button {
    right: 1.5rem !important;
    top: 1.5rem !important;
    font-size: 20px !important; }
  .broad h3 {
    color: #343d46;
    margin: 0 0 1rem 0;
    font-size: 2rem; }

.artnummer {
  color: #6c737a; }

.tenderInputWrapper__Top {
  display: flex;
  width: 100%; }
  .tenderInputWrapper__Top > * {
    padding: 1rem 0;
    flex: 1 0 50%; }
  .tenderInputWrapper__Top > * + * {
    margin-left: 1rem; }
  .tenderInputWrapper__Top input {
    width: 100%;
    display: block; }
  .tenderInputWrapper__Top label {
    font-weight: bold;
    display: block; }

.wrapper {
  padding: 1rem 0;
  width: 100%;
  display: block; }
  .wrapper input {
    width: 100%; }
  .wrapper label {
    font-weight: bold;
    display: block; }

textarea {
  width: 100%; }
