/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
@media screen and (min-width: 992px) {
  .topBorder {
    position: relative; }
    .topBorder:before {
      content: "";
      display: block;
      border-bottom: 1px solid #f7594d;
      height: 0px;
      width: 100%;
      position: absolute;
      top: 35px; } }
