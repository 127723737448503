/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px; }

.accountSettingsWrapper {
  display: flex;
  flex-direction: column;
  width: 600px; }

.userInputField {
  margin-bottom: 2rem; }

.infoForAdmin {
  font-weight: bold;
  margin-top: 1rem; }

.list {
  margin-top: 0.5rem; }
  .list tr:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .list tr:nth-child(2n) {
    background-color: #eff0f2; }
  .list tr:last-child {
    border-bottom: 1px solid #e5e5e5; }
  .list tr.link {
    color: #343d46; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem;
    width: 70px; }
  .list td:nth-child(2n), .list th:nth-child(2n) {
    max-width: 100px; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem;
    float: right; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }
