/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.marks {
  color: #fff;
  font-size: 11px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-bottom: -5px;
  justify-content: flex-end; }
  .marks.large {
    font-size: 14px; }

.mark {
  padding-left: 5px;
  padding-bottom: 5px; }

.markInner {
  padding: 1px 7px; }

.markInQuotation {
  background-color: #000; }

.markCampaign,
.markNonStockable,
.markCloseOutCampaign,
.markIsUtf {
  background-color: #f7594d; }

.markIsNew {
  background-color: #24733b; }

@media screen and (min-width: 992px) {
    .marks {
      justify-content: flex-start; } }
