/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.hero .heroTitle {
  font-family: SFNS_bold, sans-serif;
  font-size: 2.5rem;
  line-height: 2.5rem; }

.base {
  padding-top: 0; }
  .base:empty {
    margin: 0 !important;
    padding: 0 !important; }

.topContent {
  line-height: 1; }
  .topContent > div > * + * {
    margin-top: 0; }

.hero {
  overflow: hidden;
  position: relative;
  padding: 0rem 0.7rem 0.7rem 0.7rem; }
  .hero .bottomSection {
    color: black; }
  .hero .heroImage {
    width: 100%;
    height: 30rem;
    background-size: cover;
    background-position-y: 35%;
    background-position-x: 50%; }
  .hero .heroTitle {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); }
  @media screen and (min-width: 768px) {
    .hero .heroTitle {
      font-family: SFNS_bold, sans-serif;
      font-size: 3rem;
      line-height: 3rem; }
    .base {
      padding-top: 1.5rem; }
      .hero .bottomSection {
        position: absolute;
        bottom: 3rem;
        left: 3rem;
        color: white; }
      .hero .heroImage {
        height: 35rem; } }
  @media screen and (min-width: 992px) {
    .hero .heroTitle {
      font-family: SFNS_bold, sans-serif;
      font-size: 3.71429rem;
      line-height: 3.85714rem; } }
