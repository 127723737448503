/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit;
  z-index: 1; }

.deliveryButton {
  width: 4rem;
  height: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem;
  color: #fff;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  transition: background-color 200ms ease-in-out;
  text-align: left;
  cursor: pointer; }
  .deliveryButton:focus, .deliveryButton:hover, .deliveryButton:active {
    color: #fff;
    background: rgba(255, 255, 255, 0.15) !important;
    text-decoration: none; }
  .open .deliveryButton {
    color: #23272a;
    min-height: 61px; }
  .deliveryButton > div > div:not(.validationError) {
    min-width: 185px; }
  .deliveryButton strong {
    font-weight: 400; }
  .deliveryButton > svg {
    height: 30px;
    width: 30px;
    margin-left: 0rem;
    color: #000;
    top: 3px;
    position: relative; }
  .deliveryButton .validationError {
    line-height: normal;
    max-width: 185px;
    padding: 0 1rem; }

.deliveryDateWrapper {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .deliveryDateWrapper.mobileError {
    display: block;
    position: absolute;
    top: 4rem;
    white-space: nowrap;
    min-width: 20rem;
    right: 0; }
    .deliveryDateWrapper.mobileError .validationError {
      display: inline; }
  .deliveryDateWrapper.error {
    display: flex;
    align-items: center; }
  .deliveryDateWrapper.open {
    position: relative;
    color: #23272a;
    background: #fff;
    z-index: 10009; }

.deliveryDatesWrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 1rem;
  z-index: 100000;
  background: #fff;
  top: 4rem;
  left: 0rem;
  right: 0rem;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #23272a; }
  :global(.ie) .deliveryDatesWrapper {
    min-height: 550px;
    min-width: 400px;
    -webkit-transform: translateX(-145px);
            transform: translateX(-145px); }

.mobileTopWrapper {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1; }
  .mobileTopWrapper .top {
    display: block;
    padding: 1rem;
    color: #fff;
    background: #F7594D;
    font-size: 20px; }
  .mobileTopWrapper .close {
    position: absolute;
    right: 0rem;
    top: 0;
    height: 60px;
    width: 60px; }
    .mobileTopWrapper .close button {
      padding: 0;
      background: transparent;
      height: 60px;
      width: 60px; }
      .mobileTopWrapper .close button:hover, .mobileTopWrapper .close button:focus {
        background: transparent !important; }
      .mobileTopWrapper .close button svg {
        width: 1.25rem; }

.calendarWrapper {
  margin: 0 auto;
  padding: 0 0 3rem;
  max-width: 340px; }
  .calendarWrapper > div > section [role='application'] {
    margin: 0 auto !important; }
  @media screen and (min-width: 992px) {
    .deliveryButton {
      min-width: 240px; }
    .deliveryButton {
      order: initial;
      width: auto; }
      .deliveryButton > svg {
        color: currentColor;
        margin-left: 1rem; }
  .deliveryDateHolder {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 99999; }
    .deliveryDateHolder:before {
      position: absolute;
      content: "";
      border-radius: 50%;
      border: 2px solid transparent;
      border-bottom: 4px solid #f9f9f9;
      height: 15px;
      width: 15px;
      left: -12px;
      right: unset;
      bottom: unset;
      top: -11px;
      -webkit-transform: rotate(-40deg);
              transform: rotate(-40deg);
      display: none; }
    .deliveryDatesWrapper {
      width: auto;
      position: absolute;
      top: 0rem;
      right: 0rem;
      left: unset;
      bottom: unset;
      max-width: unset; } }
  @media screen and (min-width: 992px) and (min-width: 992px) {
      .deliveryDateHolder:before {
        display: inherit; } }
