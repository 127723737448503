/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.main {
  width: 100%;
  container-type: inline-size; }
  .main .grid {
    position: relative;
    grid-auto-flow: dense; }
    .main .grid::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: 0px 1px 0px #ffffff, 1px 0px 0px #ffffff; }
    .main .grid[data-list-type="viewLayout_List"] {
      display: flex;
      flex-direction: column;
      gap: 1px; }
    .main .grid[data-list-type="viewLayout_Grid"] {
      display: grid;
      gap: 1px;
      grid-template-columns: repeat(auto-fill, minmax(232px, 1fr)); }

@container (width > 630px) and (width < 768px) {
  .main .grid[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr 1fr; } }
    .main .grid.isCart {
      padding: 0 1rem; }
      .main .grid.isCart::after {
        width: calc(100% - 1rem);
        height: calc(100% - 1rem); }

@container (width > 630px) and (width < 768px) {
  .main .grid.isCheckout[data-list-type="viewLayout_List"], .main .grid.isCart[data-list-type="viewLayout_List"], .main .grid.isProductPage[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr; } }

@container (width < 630px) {
  .main .grid[data-list-type="viewLayout_Grid"] {
    border: 0px;
    display: flex;
    flex-direction: column;
    gap: 1px; } }
    .main .grid .ads {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
      border-bottom: 1px solid #e5e5e5; }
      .main .grid .ads > div {
        border: unset; }
        .main .grid .ads > div > div {
          margin: 0px; }

.base {
  right: 0;
  position: relative;
  z-index: 10009;
  background: #fff; }
  .base:not(.flyout) {
    width: auto; }

.flyout {
  composes: noscroll from '../../Shared/Flyout/base.scss';
  display: flex;
  flex-direction: column; }

.scroll {
  composes: scroll from '../../Shared/Flyout/base.scss'; }

.flyout .body {
  flex: 1 1 auto; }

.close {
  position: absolute;
  right: 0;
  top: 0; }
  .close button {
    padding: 0;
    height: 60px;
    width: 60px;
    background: transparent; }
    .close button:hover, .close button:focus {
      background: transparent; }
    .close button svg {
      width: 1.25rem; }

.mobileTopWrapper {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1; }
  .mobileTopWrapper .top {
    display: block;
    padding: 1rem;
    color: #fff;
    background: #f7594d; }
    .mobileTopWrapper .top span {
      font-size: 20px; }
  .mobileTopWrapper .total {
    background-color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5; }
    .mobileTopWrapper .total .sumWrap {
      display: flex; }
      .mobileTopWrapper .total .sumWrap .sum {
        margin-left: 5px; }
    .mobileTopWrapper .total div {
      white-space: nowrap; }
      .mobileTopWrapper .total div b {
        padding-right: 0.5rem; }
    .mobileTopWrapper .total button {
      max-width: 50%; }

.mobileBottomWrapper {
  margin-top: 116px;
  display: inline-block;
  width: 100%;
  height: 100%; }
  html:global(.ios) .mobileBottomWrapper {
    margin-bottom: 5rem; }

.summary {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }
  .summary > li {
    display: block; }

.empty {
  margin-top: 1rem;
  text-align: center;
  font-style: italic;
  white-space: nowrap; }

.flyout .button {
  flex: 0 0 auto; }

.cartItemSection {
  overflow: auto;
  max-height: 80vh; }
  .cartItemSection > div {
    display: flex;
    flex-direction: column;
    gap: 1px; }

.tablefooter {
  display: flex;
  padding: 14px;
  justify-content: flex-end;
  background: #f9f9f9; }
  .tablefooter .toPayText {
    margin-right: 0.5rem; }
  .tablefooter .priceSummary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1; }
  .tablefooter .removePlaceholder {
    flex: 1; }
