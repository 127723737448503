/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.classificationTable a {
  text-decoration: underline; }

.classificationTable td, .classificationTable th {
  padding: 0.25rem 0.5rem;
  vertical-align: middle; }

.classificationTable tr:nth-child(even) {
  background-color: rgba(225, 225, 225, 0.72); }

.classificationTable tr th:last-child,
.classificationTable tr td:last-child {
  text-align: right; }

.buttonWrapper {
  padding: 1rem 0;
  display: flex;
  flex: 1; }
  .buttonWrapper > * {
    flex: 1;
    width: 100%;
    margin: 1rem; }

.error {
  color: red;
  font-weight: bold; }

div.modalInput {
  width: 100%;
  margin-bottom: 1em; }
