/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  -webkit-tap-highlight-color: transparent;
  composes: textMaxWidth from '../../../styles/generic/typography.scss';
  position: relative;
  transition: max-height .2s;
  overflow: hidden;
  max-height: 22.5rem; }
  .base.fewLines {
    max-height: 6rem; }
  .base.centeredText {
    margin: 0 auto;
    text-align: center; }
  .base.hasShowAllButton {
    cursor: default; }
  .base:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    height: 1.5rem;
    background: white;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white); }
  .base.showAll.category {
    cursor: default; }
  .base.showAll:after {
    display: none; }

.hideH2 h2 {
  font-family: SFNS, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #23272a;
  display: inline;
  margin: 0 0; }

.button {
  composes: bare from '../../../styles/objects/buttons.scss';
  border-bottom: 1px solid #343d46;
  margin: 0.5rem 0 1rem; }
