/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  font-family: SFNS_bold, sans-serif;
  background: white;
  position: fixed;
  top: 7rem;
  left: 50%;
  width: 100%;
  max-width: 25rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.floatingCloseBtn {
  position: absolute;
  top: -2.5rem;
  right: -1rem;
  background: none !important; }
  .floatingCloseBtn span {
    margin-right: 0.5rem; }

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold; }
  .tabs > li {
    display: block; }
  .tabs li {
    flex: 1 0 auto;
    width: 50%; }

.slides {
  margin-top: 3rem; }
