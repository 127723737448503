/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.pageInfo {
  position: absolute; }

.articleType {
  font-weight: bold; }

.hero {
  margin-top: 2rem;
  margin-bottom: 3rem; }

.footer {
  font-style: italic; }

.base {
  margin-top: 3rem; }

.sideBar .header {
  margin-left: 10px; }

.sideBar .box {
  background-color: #666;
  color: #fff;
  padding: 10px;
  margin: 10px 0; }
  .sideBar .box .header {
    margin-left: 10px; }
  .sideBar .box a {
    color: #fff; }
  .sideBar .box .rightAlign {
    width: 100%;
    text-align: right; }
  @media screen and (min-width: 768px) {
    .base {
      margin-top: 1rem; } }
