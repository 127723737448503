/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  font-family: SFNS_bold, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%;
  letter-spacing: -0.32px; }

.description {
  font-family: SFNS_thin, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%; }
  .description--medium {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%; }
  .description--title {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase; }

.subtext {
  font-family: SFNS, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 115%;
  letter-spacing: 0.24px; }

.placeholderText {
  color: #6c737a;
  font-size: 12px;
  padding-right: calc(14px / 2);
  padding-left: 0.3rem;
  height: 34px;
  line-height: 34px;
  font-family: SFNS, sans-serif;
  cursor: pointer; }
  .placeholderText:hover:not(.disabled) {
    text-decoration: underline; }
  .placeholderText.selected {
    font-weight: 600;
    color: #0d0f11;
    cursor: unset; }
    .placeholderText.selected:hover {
      text-decoration: none; }
  .placeholderText.disabled {
    pointer-events: none;
    cursor: default; }
  @media screen and (min-width: 768px) {
    .title {
      font-size: 18px !important;
      letter-spacing: -0.36px; }
    .description {
      font-size: 14px !important; }
      .description--medium {
        font-size: 14px !important; } }
