/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.logotype {
  flex: 0 0 auto; }
  .logotype div {
    display: flex; }
    .logotype div svg {
      display: block;
      color: white; }
