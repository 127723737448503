/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.logotypeWrapper {
  transition: unset; }

.logotype svg {
  height: 100%;
  width: 100%;
  transition: all 150ms ease;
  max-height: 61px;
  max-width: 91.5px; }

@media screen and (min-width: 992px) {
    .logotypeWrapper {
      width: 100%;
      max-width: 179px;
      padding-left: 1rem;
      padding-top: 0; }
    .logotypeWrapper.small {
      margin-left: -9px; }
    .logotype svg {
      max-height: 170px;
      max-width: 170px; } }

@media screen and (min-width: 992px) and (min-width: 992px) {
    .small .logotype svg {
      min-width: 91.5px;
      max-width: 91.5px; } }

@media screen and (min-width: 1200px) {
    .logotype svg {
      min-width: 170px; } }
