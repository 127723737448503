/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.indent {
  color: #c2c5c8;
  padding-left: 1rem; }

.tableRow td, .tableSubRow td {
  padding: .5rem; }

.tableRow:nth-child(odd), .tableSubRow:nth-child(odd) {
  background: #e5e5e5; }

.tableRow .right, .tableSubRow .right {
  text-align: right; }

.tableSubRow td {
  padding-left: 2rem; }

.tableHeading {
  color: #999;
  text-transform: uppercase; }
  .tableHeading th:last-child {
    text-align: right; }
