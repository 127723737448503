.image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

.backgroundImg {
  display: block;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

:global(.ie) .image, :global(.ie) .link {
  min-height: 100%;
  height: auto; }
