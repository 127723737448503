/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  font-family: SFNS_bold, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%;
  letter-spacing: -0.32px; }

.description {
  font-family: SFNS_thin, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%; }
  .description--medium {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%; }
  .description--title {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase; }

.subtext {
  font-family: SFNS, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 115%;
  letter-spacing: 0.24px; }

.card {
  display: flex;
  flex: 1;
  box-shadow: 0px 1px 0px #e7e7e7; }
  .card.cartItem {
    flex-direction: column; }
    .card.cartItem.startCollapse {
      max-height: var(--maxHeight, 300); }
    .card.cartItem.collapse {
      transition: .5s;
      transition-timing-function: cubic-bezier(1, 0, 0.5, 1);
      overflow: hidden;
      max-height: 0px !important; }
  .card .cardTop {
    background: white;
    min-height: 114px;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    color: #343d46; }
    .card .cardTop .first {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      position: relative; }
      .card .cardTop .first .above {
        width: 100%;
        display: none;
        flex-direction: row;
        justify-content: space-between; }
        .card .cardTop .first .above.hasPrimaryCampaign {
          display: flex; }
        .card .cardTop .first .above .aboveLeft {
          display: flex; }
          .card .cardTop .first .above .aboveLeft .campaign {
            display: flex; }
        .card .cardTop .first .above .aboveRight {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 4px;
          display: none; }
          .card .cardTop .first .above .aboveRight > .useNewDesign {
            margin: 0;
            gap: 4px;
            min-width: 64px;
            min-height: 30px; }
            .card .cardTop .first .above .aboveRight > .useNewDesign > div {
              padding: 0px; }
              .card .cardTop .first .above .aboveRight > .useNewDesign > div > div > div {
                background-color: #f5f5f5;
                border: 1px solid #f5f5f5;
                width: 30px;
                height: 30px;
                transition: 200ms ease-in-out; }
                .card .cardTop .first .above .aboveRight > .useNewDesign > div > div > div:hover {
                  color: #343d46;
                  border: 1px solid #dedede; }
                .card .cardTop .first .above .aboveRight > .useNewDesign > div > div > div.useNewDesign {
                  border: 1px solid #343d46;
                  background: #343d46;
                  color: #fff; }
      .card .cardTop .first .under {
        width: 115px;
        height: 90px;
        position: relative;
        display: flex;
        justify-content: center; }
        .card .cardTop .first .under.campaignSize {
          height: 60px; }
        .card .cardTop .first .under a {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          cursor: pointer; }
          .card .cardTop .first .under a img {
            -o-object-fit: contain;
               object-fit: contain;
            max-width: 100% !important;
            max-height: 90px;
            pointer-events: none; }
        .card .cardTop .first .under > img {
          -o-object-fit: contain;
             object-fit: contain;
          pointer-events: none; }
      .card .cardTop .first .carbonCloud {
        font-family: SFNS_medium, sans-serif;
        font-size: 10px;
        color: #6c737a;
        background: #f5f5f5;
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 15px;
        line-height: 16px;
        border-radius: 4px;
        padding: 0 4px; }
        .card .cardTop .first .carbonCloud a {
          color: #6c737a;
          text-decoration: none !important; }
          .card .cardTop .first .carbonCloud a:hover, .card .cardTop .first .carbonCloud a:focus {
            color: #6c737a; }
    .card .cardTop .second {
      display: flex;
      flex-direction: column;
      flex: 1; }
      .card .cardTop .second .secondTop {
        flex: 1;
        padding: 12px 0;
        display: flex;
        flex-direction: row;
        gap: 16px; }
        .card .cardTop .second .secondTop .informations {
          display: flex;
          flex-direction: row;
          gap: 12px;
          flex: 1; }
          .card .cardTop .second .secondTop .informations .information {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 4px; }
            .card .cardTop .second .secondTop .informations .information .primaryInformation {
              margin: 0; }
              .card .cardTop .second .secondTop .informations .information .primaryInformation a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* autoprefixer: ignore next */
                -webkit-box-orient: vertical;
                overflow: hidden;
                cursor: pointer;
                padding-bottom: 1px;
                margin-bottom: -1px;
                padding-top: 2px;
                margin-top: -2px; }
            .card .cardTop .second .secondTop .informations .information .secondaryInformations {
              display: flex;
              flex-direction: row;
              gap: 12px; }
              .card .cardTop .second .secondTop .informations .information .secondaryInformations .secondaryInformation {
                display: flex;
                flex-direction: row;
                gap: 4px;
                flex-wrap: wrap; }
                .card .cardTop .second .secondTop .informations .information .secondaryInformations .secondaryInformation > div {
                  text-wrap: nowrap; }
                  .card .cardTop .second .secondTop .informations .information .secondaryInformations .secondaryInformation > div:not(:last-child)::after {
                    content: ','; }
                .card .cardTop .second .secondTop .informations .information .secondaryInformations .secondaryInformation img {
                  height: 14px; }
              .card .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
                display: none;
                width: 34px;
                height: 40px; }
                .card .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid svg {
                  height: 100%; }
            .card .cardTop .second .secondTop .informations .information .tertiaryInformation {
              display: flex;
              flex-direction: row;
              gap: 4px;
              flex-wrap: wrap; }
              .card .cardTop .second .secondTop .informations .information .tertiaryInformation .label {
                font-family: SFNS_medium, sans-serif;
                font-size: 12px;
                color: #ffffff;
                padding: 0 4px;
                border-radius: 4px;
                height: 16px;
                align-self: center; }
                .card .cardTop .second .secondTop .informations .information .tertiaryInformation .label.discontinuedLabel {
                  background: #b0406f; }
          .card .cardTop .second .secondTop .informations .produceLogoMobile {
            display: none;
            width: 34px;
            height: 40px; }
        .card .cardTop .second .secondTop .units {
          flex: 1;
          min-width: 240px;
          display: flex;
          flex-direction: column;
          gap: 12px; }
          .card .cardTop .second .secondTop .units.isSupplier {
            min-width: 0; }
          .card .cardTop .second .secondTop .units .row {
            display: flex;
            flex-direction: column;
            gap: 4px; }
            .card .cardTop .second .secondTop .units .row .variants {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 4px; }
              .card .cardTop .second .secondTop .units .row .variants .toggleVariant {
                display: flex;
                flex-direction: row;
                gap: 12px; }
                .card .cardTop .second .secondTop .units .row .variants .toggleVariant > div {
                  padding: 0;
                  height: auto;
                  line-height: 16px;
                  display: flex;
                  flex-direction: row;
                  gap: 4px;
                  color: #343d46; }
                  .card .cardTop .second .secondTop .units .row .variants .toggleVariant > div::before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    display: block;
                    border: 1px solid #e7e7e7;
                    background: transparent; }
                  .card .cardTop .second .secondTop .units .row .variants .toggleVariant > div:only-child::before {
                    content: unset; }
                  .card .cardTop .second .secondTop .units .row .variants .toggleVariant > div.selected::before {
                    border: 1px solid #343d46;
                    background: radial-gradient(circle, #343d46 0%, #343d46 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%); }
              .card .cardTop .second .secondTop .units .row .variants .singleVariant {
                line-height: 16px;
                font-size: 12px;
                font-weight: 600; }
              .card .cardTop .second .secondTop .units .row .variants .oldPrice {
                text-decoration: line-through; }
            .card .cardTop .second .secondTop .units .row .price {
              display: flex;
              flex-direction: row;
              align-items: flex-end; }
              .card .cardTop .second .secondTop .units .row .price .priceUnit {
                cursor: pointer; }
              .card .cardTop .second .secondTop .units .row .price .filler {
                flex: 1;
                height: 1px;
                position: relative;
                pointer-events: none; }
                .card .cardTop .second .secondTop .units .row .price .filler::before {
                  content: '';
                  position: absolute;
                  bottom: 3px;
                  left: 8px;
                  width: calc(100% - 16px);
                  height: 100%;
                  background-image: linear-gradient(to right, #343d46 12.5%, rgba(255, 255, 255, 0) 0%);
                  background-position: bottom;
                  background-size: 8px 1px;
                  background-repeat: repeat-x; }
              .card .cardTop .second .secondTop .units .row .price .isCampaign {
                color: #f7594d; }
            .card .cardTop .second .secondTop .units .row .smallestUnit {
              margin-top: 4px; }
              .card .cardTop .second .secondTop .units .row .smallestUnit > div > div {
                display: flex;
                flex-direction: row;
                gap: 4px; }
        .card .cardTop .second .secondTop .noPrice {
          background: transparent; }
        .card .cardTop .second .secondTop .supplier {
          display: flex;
          flex-direction: row;
          gap: 16px;
          justify-content: flex-end; }
          .card .cardTop .second .secondTop .supplier .toggle {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: flex-end; }
        .card .cardTop .second .secondTop .actions {
          display: flex;
          flex-direction: row;
          gap: 4px; }
          .card .cardTop .second .secondTop .actions .left {
            display: flex;
            flex-direction: column;
            gap: 12px; }
            .card .cardTop .second .secondTop .actions .left .leftTop {
              display: flex;
              flex-direction: row;
              margin-top: 1px;
              gap: 4px; }
              .card .cardTop .second .secondTop .actions .left .leftTop > .useNewDesign {
                margin: 0;
                gap: 4px;
                min-width: 64px;
                min-height: 30px; }
                .card .cardTop .second .secondTop .actions .left .leftTop > .useNewDesign > div {
                  padding: 0px; }
                  .card .cardTop .second .secondTop .actions .left .leftTop > .useNewDesign > div > div > div {
                    background-color: #f5f5f5;
                    border: 1px solid #f5f5f5;
                    width: 30px;
                    height: 30px;
                    transition: 200ms ease-in-out; }
                    .card .cardTop .second .secondTop .actions .left .leftTop > .useNewDesign > div > div > div:hover {
                      color: #343d46;
                      border: 1px solid #dedede; }
                    .card .cardTop .second .secondTop .actions .left .leftTop > .useNewDesign > div > div > div.useNewDesign {
                      border: 1px solid #343d46;
                      background: #343d46;
                      color: #fff; }
              .card .cardTop .second .secondTop .actions .left .leftTop .subDeliveryButton {
                width: 30px;
                height: 30px;
                background-color: #f5f5f5;
                color: #6c737a;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                transition: 200ms ease-in-out;
                cursor: pointer; }
                .card .cardTop .second .secondTop .actions .left .leftTop .subDeliveryButton:hover {
                  background-color: #dedede;
                  color: #343d46; }
                .card .cardTop .second .secondTop .actions .left .leftTop .subDeliveryButton svg {
                  width: 18px;
                  height: 18px; }
          .card .cardTop .second .secondTop .actions .right {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%; }
            .card .cardTop .second .secondTop .actions .right .rightTop {
              display: flex;
              flex-direction: row;
              gap: 4px;
              align-items: center;
              justify-content: flex-end; }
              .card .cardTop .second .secondTop .actions .right .rightTop .quantity {
                height: 32px;
                max-width: 132px; }
                .card .cardTop .second .secondTop .actions .right .rightTop .quantity.notLoggedIn {
                  width: 152px; }
                  .card .cardTop .second .secondTop .actions .right .rightTop .quantity.notLoggedIn button {
                    width: 100%; }
                .card .cardTop .second .secondTop .actions .right .rightTop .quantity div {
                  width: 100%; }
              .card .cardTop .second .secondTop .actions .right .rightTop .remove {
                cursor: pointer;
                position: relative; }
                .card .cardTop .second .secondTop .actions .right .rightTop .remove svg {
                  width: 20px;
                  height: 20px;
                  margin-top: -2px;
                  position: relative;
                  z-index: 1; }
            .card .cardTop .second .secondTop .actions .right .disabled {
              display: flex;
              flex-direction: column; }
        .card .cardTop .second .secondTop .priceTotalTop {
          height: 32px;
          line-height: 32px;
          min-width: 75px;
          text-align: right; }
      .card .cardTop .second .secondBottom {
        display: flex;
        flex-direction: column;
        gap: 8px; }
        .card .cardTop .second .secondBottom .secondBottomTop {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: flex-end; }
          .card .cardTop .second .secondBottom .secondBottomTop .produceLogoList {
            width: 90px;
            height: 20px;
            display: flex; }
          .card .cardTop .second .secondBottom .secondBottomTop .stock {
            height: 26px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 8px; }
        .card .cardTop .second .secondBottom .subdeliveries {
          display: none;
          flex-direction: column;
          gap: 8px;
          padding: 12px 0 12px 0;
          border-top: 1px dashed #6c737a; }
          .card .cardTop .second .secondBottom .subdeliveries.isVisible {
            display: flex; }
        .card .cardTop .second .secondBottom .priceTotal {
          display: none;
          padding-top: 16px; }
  .card[data-list-type="viewLayout_List"] {
    /* this is the default */ }
  .card[data-list-type="viewLayout_Grid"] {
    box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
    .card[data-list-type="viewLayout_Grid"] .cardTop {
      display: flex;
      flex-direction: column;
      padding: 16px 12px;
      gap: 16px; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .above {
        display: flex; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .first .above .aboveRight {
          display: flex; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .under {
        width: 180px;
        height: 140px; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .first .under img {
          max-height: 140px; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .carbonCloud {
        left: unset;
        right: 0; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop {
        flex-direction: column;
        padding: 0; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations {
          flex: 1.5; }
          .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations .information {
            flex: 1.5; }
            .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
              display: flex; }
          .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations .produceLogoMobile {
            display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .units {
          flex: unset;
          max-width: 100%;
          min-width: 100%; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .left {
          display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .right .quantity {
          width: 100%;
          max-width: 100%; }
          .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .right .quantity.notLoggedIn {
            width: 100%; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop {
        flex-direction: row; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop .produceLogoList {
          display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop .stock {
          margin-top: 12px;
          flex: 1; }

@container (width < 630px) {
  .card[data-list-type="viewLayout_Grid"] {
    box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
    .card[data-list-type="viewLayout_Grid"] .cardTop {
      padding: 16px 12px;
      display: flex;
      flex-direction: row; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .above {
        display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .first .above.hasPrimaryCampaign {
          display: flex; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .first .above .aboveRight {
          display: none; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .under {
        height: 120px;
        width: 100px; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .first .under img {
          max-height: 120px; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .first .carbonCloud {
        left: 0;
        right: unset; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop {
        flex-direction: column;
        padding: 0px;
        gap: 12px; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
          display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .informations .produceLogoMobile {
          display: flex; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop.hasSubDelivery {
          padding-bottom: 12px; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .units {
          max-width: 100%;
          min-width: 0; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions {
          justify-content: space-between; }
          .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .left {
            display: flex; }
          .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .right {
            width: 100%;
            max-width: 156px; }
            .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .right.wide {
              max-width: unset; }
            .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .actions .right .quantity {
              width: 100%; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .second .secondTop .priceTotalTop {
          display: none; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop {
        flex-direction: row; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop .produceLogoList {
          display: none; }
        .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .secondBottomTop .stock {
          margin-top: 12px;
          margin-right: 3px;
          flex: 1; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .priceTotal {
        display: flex; }
      .card[data-list-type="viewLayout_Grid"] .cardTop .secondBottom .subdeliveries {
        padding: 12px 0 0 0; } }

@container (width < 768px) {
  .card[data-list-type="viewLayout_List"] {
    box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
    .card[data-list-type="viewLayout_List"] .cardTop {
      padding: 16px 12px;
      display: flex;
      flex-direction: row; }
      .card[data-list-type="viewLayout_List"] .cardTop .first .above {
        display: none; }
        .card[data-list-type="viewLayout_List"] .cardTop .first .above.hasPrimaryCampaign {
          display: flex; }
        .card[data-list-type="viewLayout_List"] .cardTop .first .above .aboveRight {
          display: none; }
      .card[data-list-type="viewLayout_List"] .cardTop .first .under {
        height: 120px;
        width: 100px; }
        .card[data-list-type="viewLayout_List"] .cardTop .first .under img {
          max-height: 120px; }
      .card[data-list-type="viewLayout_List"] .cardTop .first .carbonCloud {
        left: 0;
        right: unset; }
      .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop {
        flex-direction: column;
        padding: 0px;
        gap: 12px; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
          display: none; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .produceLogoMobile {
          display: flex; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop.hasSubDelivery {
          padding-bottom: 12px; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .units {
          max-width: 100%;
          min-width: 0; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions {
          justify-content: space-between; }
          .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .left {
            display: flex; }
          .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right {
            width: 100%;
            max-width: 156px; }
            .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right.wide {
              max-width: unset; }
            .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right .quantity {
              width: 100%; }
        .card[data-list-type="viewLayout_List"] .cardTop .second .secondTop .priceTotalTop {
          display: none; }
      .card[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop {
        flex-direction: row; }
        .card[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .produceLogoList {
          display: none; }
        .card[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .stock {
          margin-top: 12px;
          margin-right: 3px;
          flex: 1; }
      .card[data-list-type="viewLayout_List"] .cardTop .secondBottom .priceTotal {
        display: flex; }
      .card[data-list-type="viewLayout_List"] .cardTop .secondBottom .subdeliveries {
        padding: 12px 0 0 0; } }
  .card.isMobile {
    box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
    .card.isMobile .cardTop {
      padding: 16px 12px;
      display: flex;
      flex-direction: row; }
      .card.isMobile .cardTop .first .above {
        display: none; }
        .card.isMobile .cardTop .first .above.hasPrimaryCampaign {
          display: flex; }
        .card.isMobile .cardTop .first .above .aboveRight {
          display: none; }
      .card.isMobile .cardTop .first .under {
        height: 120px;
        width: 100px; }
        .card.isMobile .cardTop .first .under img {
          max-height: 120px; }
      .card.isMobile .cardTop .first .carbonCloud {
        left: 0;
        right: unset; }
      .card.isMobile .cardTop .second .secondTop {
        flex-direction: column;
        padding: 0px;
        gap: 12px; }
        .card.isMobile .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
          display: none; }
        .card.isMobile .cardTop .second .secondTop .informations .produceLogoMobile {
          display: flex; }
        .card.isMobile .cardTop .second .secondTop.hasSubDelivery {
          padding-bottom: 12px; }
        .card.isMobile .cardTop .second .secondTop .units {
          max-width: 100%;
          min-width: 0; }
        .card.isMobile .cardTop .second .secondTop .actions {
          justify-content: space-between; }
          .card.isMobile .cardTop .second .secondTop .actions .left {
            display: flex; }
          .card.isMobile .cardTop .second .secondTop .actions .right {
            width: 100%;
            max-width: 156px; }
            .card.isMobile .cardTop .second .secondTop .actions .right.wide {
              max-width: unset; }
            .card.isMobile .cardTop .second .secondTop .actions .right .quantity {
              width: 100%; }
        .card.isMobile .cardTop .second .secondTop .priceTotalTop {
          display: none; }
      .card.isMobile .cardTop .secondBottom .secondBottomTop {
        flex-direction: row; }
        .card.isMobile .cardTop .secondBottom .secondBottomTop .produceLogoList {
          display: none; }
        .card.isMobile .cardTop .secondBottom .secondBottomTop .stock {
          margin-top: 12px;
          margin-right: 3px;
          flex: 1; }
      .card.isMobile .cardTop .secondBottom .priceTotal {
        display: flex; }
      .card.isMobile .cardTop .secondBottom .subdeliveries {
        padding: 12px 0 0 0; }
  .card.isProductPage {
    box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
    .card.isProductPage .cardTop {
      padding: 16px 12px;
      display: flex;
      flex-direction: row; }
      .card.isProductPage .cardTop .first .above {
        display: none; }
        .card.isProductPage .cardTop .first .above.hasPrimaryCampaign {
          display: flex; }
        .card.isProductPage .cardTop .first .above .aboveRight {
          display: none; }
      .card.isProductPage .cardTop .first .under {
        height: 120px;
        width: 100px; }
        .card.isProductPage .cardTop .first .under img {
          max-height: 120px; }
      .card.isProductPage .cardTop .first .carbonCloud {
        left: 0;
        right: unset; }
      .card.isProductPage .cardTop .second .secondTop {
        flex-direction: column;
        padding: 0px;
        gap: 12px; }
        .card.isProductPage .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
          display: none; }
        .card.isProductPage .cardTop .second .secondTop .informations .produceLogoMobile {
          display: flex; }
        .card.isProductPage .cardTop .second .secondTop.hasSubDelivery {
          padding-bottom: 12px; }
        .card.isProductPage .cardTop .second .secondTop .units {
          max-width: 100%;
          min-width: 0; }
        .card.isProductPage .cardTop .second .secondTop .actions {
          justify-content: space-between; }
          .card.isProductPage .cardTop .second .secondTop .actions .left {
            display: flex; }
          .card.isProductPage .cardTop .second .secondTop .actions .right {
            width: 100%;
            max-width: 156px; }
            .card.isProductPage .cardTop .second .secondTop .actions .right.wide {
              max-width: unset; }
            .card.isProductPage .cardTop .second .secondTop .actions .right .quantity {
              width: 100%; }
        .card.isProductPage .cardTop .second .secondTop .priceTotalTop {
          display: none; }
      .card.isProductPage .cardTop .secondBottom .secondBottomTop {
        flex-direction: row; }
        .card.isProductPage .cardTop .secondBottom .secondBottomTop .produceLogoList {
          display: none; }
        .card.isProductPage .cardTop .secondBottom .secondBottomTop .stock {
          margin-top: 12px;
          margin-right: 3px;
          flex: 1; }
      .card.isProductPage .cardTop .secondBottom .priceTotal {
        display: flex; }
      .card.isProductPage .cardTop .secondBottom .subdeliveries {
        padding: 12px 0 0 0; }
    .card.isProductPage .cardTop {
      padding: 0px !important; }
      .card.isProductPage .cardTop .first {
        display: none; }
      .card.isProductPage .cardTop .second .secondTop .information .primaryInformation {
        display: none; }
      .card.isProductPage .cardTop .second .secondTop .information .secondaryInformation > div {
        font-size: 14px !important; }
      .card.isProductPage .cardTop .second .secondTop .information .secondaryInformation img {
        height: 14px; }
      .card.isProductPage .cardTop .second .secondTop .actions .right .rightTop .quantity {
        max-width: unset; }

@container (width < 630px) {
  .card .cardTop {
    padding: 16px 0px !important; } }

.pushDownOnePixel {
  margin-top: 1px; }

.separateRow {
  width: 100%;
  display: flex; }
  .separateRow > div::after {
    content: ", ";
    display: inline;
    margin-right: 4px; }
  .separateRow > div:last-of-type::after {
    display: none; }
  @media screen and (min-width: 768px) {
    .title {
      font-size: 18px !important;
      letter-spacing: -0.36px; }
    .description {
      font-size: 14px !important; }
      .description--medium {
        font-size: 14px !important; }
        .card.isProductPage .cardTop .second .secondTop .information .secondaryInformation > div {
          font-size: 16px !important; }
        .card.isProductPage .cardTop .second .secondTop .information .secondaryInformation img {
          height: 16px; } }
  @media screen and (min-width: 992px) {
      .card .cardTop {
        padding: 12px; } }
  @media (max-width: 991px) {
    .card.isCheckoutPage[data-list-type="viewLayout_List"] {
      box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
      .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop {
        padding: 16px 12px;
        display: flex;
        flex-direction: row; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .above {
          display: none; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .above.hasPrimaryCampaign {
            display: flex; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .above .aboveRight {
            display: none; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .under {
          height: 120px;
          width: 100px; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .under img {
            max-height: 120px; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .carbonCloud {
          left: 0;
          right: unset; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop {
          flex-direction: column;
          padding: 0px;
          gap: 12px; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
            display: none; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .produceLogoMobile {
            display: flex; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop.hasSubDelivery {
            padding-bottom: 12px; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .units {
            max-width: 100%;
            min-width: 0; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions {
            justify-content: space-between; }
            .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .left {
              display: flex; }
            .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right {
              width: 100%;
              max-width: 156px; }
              .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right.wide {
                max-width: unset; }
              .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right .quantity {
                width: 100%; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .second .secondTop .priceTotalTop {
            display: none; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop {
          flex-direction: row; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .produceLogoList {
            display: none; }
          .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .stock {
            margin-top: 12px;
            margin-right: 3px;
            flex: 1; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .secondBottom .priceTotal {
          display: flex; }
        .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .secondBottom .subdeliveries {
          padding: 12px 0 0 0; }
      .card.isCheckoutPage[data-list-type="viewLayout_List"] .cardTop .first .under {
        width: 95px; }
    .card.isCart[data-list-type="viewLayout_List"] {
      box-shadow: 0px 1px 0px #e7e7e7, 1px 0px 0px #e7e7e7; }
      .card.isCart[data-list-type="viewLayout_List"] .cardTop {
        padding: 16px 12px;
        display: flex;
        flex-direction: row; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .above {
          display: none; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .above.hasPrimaryCampaign {
            display: flex; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .above .aboveRight {
            display: none; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .under {
          height: 120px;
          width: 100px; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .under img {
            max-height: 120px; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .first .carbonCloud {
          left: 0;
          right: unset; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop {
          flex-direction: column;
          padding: 0px;
          gap: 12px; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .information .secondaryInformations .produceLogoGrid {
            display: none; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .informations .produceLogoMobile {
            display: flex; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop.hasSubDelivery {
            padding-bottom: 12px; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .units {
            max-width: 100%;
            min-width: 0; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions {
            justify-content: space-between; }
            .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .left {
              display: flex; }
            .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right {
              width: 100%;
              max-width: 156px; }
              .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right.wide {
                max-width: unset; }
              .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .actions .right .quantity {
                width: 100%; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .second .secondTop .priceTotalTop {
            display: none; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop {
          flex-direction: row; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .produceLogoList {
            display: none; }
          .card.isCart[data-list-type="viewLayout_List"] .cardTop .secondBottom .secondBottomTop .stock {
            margin-top: 12px;
            margin-right: 3px;
            flex: 1; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .secondBottom .priceTotal {
          display: flex; }
        .card.isCart[data-list-type="viewLayout_List"] .cardTop .secondBottom .subdeliveries {
          padding: 12px 0 0 0; } }
