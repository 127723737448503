/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.topRow {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.printContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: auto; }
  .printContainer button {
    flex: 0 0 auto;
    margin-right: 10px;
    margin-bottom: 10px; }

.orderOverHeader {
  width: 100%;
  height: auto;
  border-bottom: 1px dashed #999999;
  margin-bottom: 1rem; }

.singleOrderContainer {
  background: #f1f1f1;
  transition: 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 1rem; }

.orderOptions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem; }

.orderStateLine {
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-around; }
  .orderStateLine button {
    flex: 0 0 auto;
    margin-right: 10px;
    margin-bottom: 10px; }

.mobileGroupFilters, .groupFilters {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 0;
  width: 100%;
  height: auto; }
  .mobileGroupFilters span:first-child, .groupFilters span:first-child {
    font-weight: bold;
    font-size: 1.42857rem; }
  .mobileGroupFilters > *, .groupFilters > * {
    flex: 1 1 auto; }

.groupFilters {
  margin: 0;
  width: auto; }

.viewLane {
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 10px;
  margin-top: 5px; }

.emptyViewLane {
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 10px;
  margin-top: 5px;
  text-align: left; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 5em;
  margin-top: 5rem;
  position: relative; }

.mobileOrderStateLine {
  display: flex;
  flex-direction: row;
  height: auto;
  margin: 1rem 0; }
  .mobileOrderStateLine > button {
    width: 100%;
    font-size: 12px;
    margin-right: 5px; }
  .mobileOrderStateLine .mobileViewLane {
    background-color: #fff;
    width: 102%;
    height: auto;
    padding: 10px;
    margin-top: 5px; }

ol,
ul {
  list-style: none;
  padding-left: 1.25rem;
  padding-top: 0.4rem; }

#pagingMainContainer {
  display: block;
  width: 750px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 45px; }

#paginationContent {
  width: 100%;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; }

.paginate {
  width: 100%;
  font-size: 1rem;
  padding: 35px 0 0 0;
  margin: auto;
  text-align: center; }

.paginate li {
  display: inline-block; }

.paginate.page {
  font-size: 1rem; }

.paginate.page li a {
  display: inline-block;
  color: #363842;
  text-decoration: none;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s linear; }

.paginate.page li a:hover {
  color: #363842;
  background: #d0dfcf; }

.paginate.page li a:active {
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.55); }

.paginate.page li.navpage a {
  padding: 9px 13px;
  background: #d3600d;
  color: #fff;
  cursor: pointer; }

.paginate.page li.navpage a:hover {
  background: #e8711c; }

.paginate.page li.notallowednavpage a {
  padding: 9px 13px;
  background: #e8e5e3;
  color: #fff; }

.paginate.page li.single,
.paginate.page li.current {
  display: inline-block;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  color: #fff;
  background: #009a3d; }

.mobilePagingLane {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: #d3600d;
  margin-top: 20px;
  padding: 0.5rem 1rem; }
  .mobilePagingLane .prevContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .prevContainer > * {
      color: #fff; }
  .mobilePagingLane .paging {
    padding: 0 1rem;
    flex: 1;
    font-size: 1rem;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .paging > * {
      width: 100%;
      color: #fff; }
  .mobilePagingLane .nextContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .nextContainer > * {
      color: #fff; }

.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }
  .wrap span {
    margin-right: 0.5rem;
    margin-left: 1rem; }
  @media screen and (min-width: 992px) {
    .topRow {
      flex-wrap: nowrap; }
    .orderOptions .orderStateLine {
      flex-basis: 50%; }
    .orderOptions .groupFilters {
      flex-basis: 75%; } }
  @media screen and (min-width: 768px) {
    .orderOptions .orderStateLine {
      flex-basis: 50%; }
    .orderOptions .groupFilters {
      flex-basis: 75%; }
      .orderOptions .groupFilters > div > div:first-child {
        width: 100%; }
    .orderStateLine button {
      flex: 0 0 auto; } }
