/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  padding-top: 0; }

.breadcrumbsWrap {
  margin-top: 20px;
  margin-bottom: 10px; }

.list .listBorderWrap {
  border-top: 0;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5; }
  .list .listBorderWrap .actionbar {
    background: #f9f9f9; }

.list tr:not(:first-child) {
  border-top: 1px solid #e5e5e5; }

.list tr:nth-child(2n) {
  background-color: #eff0f2; }

.list tr:last-child {
  border-bottom: 1px solid #e5e5e5; }

.list tr.link {
  color: #343d46; }

.list td:first-child,
.list th:first-child {
  padding-left: 1rem; }

.list td:last-child,
.list th:last-child {
  padding-right: 1rem; }

.list th {
  padding: 0.5rem 0; }

.list td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: middle; }

.part {
  padding: 0 1rem; }

.row {
  display: flex;
  padding: 0 2rem 0 1rem;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  position: relative; }
  .row .hiddenRemove {
    opacity: 0;
    pointer-events: none; }
  .row.discontinued {
    background: #fef6f5; }

.list {
  margin-bottom: 10px;
  position: relative; }

.actionbar {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
  flex-wrap: wrap; }
  .actionbar > * {
    margin: 0.5rem 0.5rem; }
  .actionbar .addToMenu {
    min-width: 15rem; }
  .actionbar .actionbarInner {
    position: relative;
    justify-content: space-between;
    width: 100%; }
  .actionbar .actionMenuWrap {
    display: flex;
    flex-direction: column; }
    .actionbar .actionMenuWrap > div:last-child {
      margin-top: 1rem; }
    .actionbar .actionMenuWrap.searchBarWrap {
      flex: 1; }
      .actionbar .actionMenuWrap.searchBarWrap [id^=result] {
        position: unset; }
        .actionbar .actionMenuWrap.searchBarWrap [id^=result] > div {
          top: 68px; }
    .actionbar .actionMenuWrap .actionMenu {
      min-width: 15rem;
      margin: 0 0 1rem 0; }
      .actionbar .actionMenuWrap .actionMenu:last-child {
        margin: 0; }
  .actionbar .buttonWrap {
    margin: 1rem 0 -1rem; }
    .actionbar .buttonWrap button {
      width: 100%;
      height: 44px; }
  .actionbar .searchInListWrap {
    min-width: 15rem;
    margin-top: 1rem; }
    .actionbar .searchInListWrap .searchInList {
      width: 100%;
      height: 48px;
      line-height: 44px; }

.toolbar {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 0.5rem 0; }
  .toolbar > * {
    margin: 0.5rem 0.5rem; }
  .toolbar .buttonWrapper {
    margin-bottom: 35px;
    flex: 1; }

.actionMenu {
  min-width: 15rem; }

.rowHeader {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #e5e5e5; }

.dialog {
  overflow: inherit; }
  .dialog .listoptions {
    overflow-y: auto;
    max-height: 60vh; }

.wrapper {
  height: 10rem; }

.toggleSelected {
  padding: 0.5rem 0;
  width: 10%; }

.ordinalWrapper {
  padding: 0.5rem;
  flex: 0 0 8rem;
  overflow: hidden;
  flex: 3;
  min-width: 8rem;
  text-align: right; }

.ordinal {
  padding: 0.25rem 0.125rem;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  max-width: 7rem;
  max-height: 2.5rem; }
  .ordinal[disabled] {
    background: #fafafa; }

.topRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }

.deleteDialog,
.moveDialog {
  display: flex;
  justify-content: center; }

.deletedByMasterIcon {
  color: #ef7172 !important; }

.newOption span span {
  background: #24733b;
  color: #fff;
  display: inline-block;
  font-size: 0.857rem;
  padding: 0 .35rem;
  font-family: SFNS, sans-serif;
  margin-left: .25rem;
  font-weight: 400; }

.customProductFormWrapper {
  min-width: 320px;
  max-width: 100%; }

.spinner {
  height: 5rem;
  width: 5rem;
  opacity: .9;
  margin: 4rem auto; }

@media screen and (min-width: 992px) {
  .pageWrapper {
    margin-top: 15px !important; }
        .actionbar .actionMenuWrap.searchBarWrap {
          margin-right: 1rem; }
            .actionbar .actionMenuWrap.searchBarWrap [id^=result] > div {
              top: 48px; }
      .actionbar .buttonWrap {
        margin: 0; }
        .actionbar .buttonWrap button {
          width: unset; }
    .customProductFormWrapper {
      min-width: 640px;
      width: 720px; } }

@media screen and (min-width: 768px) {
    .row {
      flex-wrap: nowrap; }
    .list {
      margin-bottom: 14px; }
    .actionbar {
      flex-flow: row wrap;
      padding: 0.5rem; }
      .actionbar .actionbarInner {
        display: flex; }
      .actionbar .actionMenuWrap {
        flex-direction: row; }
        .actionbar .actionMenuWrap .actionMenu {
          margin: 0 1rem 0 0; }
          .actionbar .actionMenuWrap .actionMenu:last-child {
            margin: 0; }
      .actionbar .searchInListWrap {
        margin: 0; }
    .toolbar {
      flex-flow: row wrap; }
    .toggleSelected {
      width: initial; } }

@media screen and (min-width: 480px) {
      .actionbar .actionMenuWrap div:last-child {
        margin-top: 0; } }

@media screen and (min-height: 500px) {
  .disableScrollInModal {
    overflow: hidden; } }
