/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.dropdownParentDiv {
  min-width: 15rem;
  color: black;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  margin: 1rem; }
  .dropdownParentDiv:hover {
    border-color: rgba(0, 0, 0, 0.2); }
  .dropdownParentDiv button {
    width: 100%; }
  .dropdownParentDiv > div {
    border-color: rgba(0, 0, 0, 0.15) !important; }
    .dropdownParentDiv > div:hover {
      color: black; }
    .dropdownParentDiv > div > button {
      text-align: left;
      display: flex;
      justify-content: space-between; }
  .dropdownParentDiv .dropdownOptions {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
    .dropdownParentDiv .dropdownOptions:hover {
      background: rgba(0, 0, 0, 0.02); }
    @media screen and (min-width: 480px) {
      .dropdownParentDiv > div {
        min-width: 20rem; } }
