/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.reset {
  margin: 4rem 0 0 0 !important; }

.punchOutTakeoverMessage {
  margin-bottom: 40px; }

.punchOutWrapper {
  max-width: 97.14286rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin: 0 auto; }
  .punchOutWrapper .logotypeWrapper .logotype {
    transition: 0.3s;
    float: right; }
    .punchOutWrapper .logotypeWrapper .logotype svg {
      height: 5rem;
      width: 5rem; }

.punchOutCartWrap {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5; }

.punchOutCartHeader,
.total {
  background: #f1f1f1;
  padding: 14px;
  display: flex; }

.punchOutCartHeader {
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between; }

.quantityWrap,
.priceWrap {
  margin-left: 5px; }

.productWrap {
  flex: 2;
  min-width: 120px; }

.quantityWrap {
  flex: 1;
  min-width: 35px; }

.priceWrap {
  flex: 0 0 100px;
  text-align: right; }

.total {
  font-family: SFNS_bold, sans-serif;
  justify-content: flex-end;
  background: none; }

.punchOutCartItem {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  border-bottom: 1px solid #e5e5e5; }
  .punchOutCartItem .productWrap {
    font-family: SFNS_bold, sans-serif; }
  .punchOutCartItem:last-child {
    border-bottom: 0; }

.base fieldset {
  margin-bottom: 2rem; }
  .base fieldset legend {
    padding: 0 0.25rem; }

.input {
  margin-bottom: 0px !important; }

.searchCustomer {
  composes: input;
  composes: onehalf from "../../Shared/Fields/trumps.scss"; }

.searchInputWrapper {
  position: relative;
  padding-bottom: 1rem;
  width: 100%; }
  .searchInputWrapper input {
    width: 100%;
    padding: 0.6rem; }
  .searchInputWrapper button {
    margin-top: 3rem; }
  .searchInputWrapper .loginOut {
    display: flex;
    margin-left: -10px;
    margin-right: -10px; }
    .searchInputWrapper .loginOut > * {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px; }
  .searchInputWrapper .list {
    margin-top: 0.5rem; }
    .searchInputWrapper .list tr:not(:first-child) {
      border-top: 1px solid #e5e5e5; }
    .searchInputWrapper .list tr:nth-child(2n) {
      background-color: #eff0f2; }
    .searchInputWrapper .list tr:last-child {
      border-bottom: 1px solid #e5e5e5; }
    .searchInputWrapper .list tr.link {
      color: #343d46; }
    .searchInputWrapper .list td:first-child,
    .searchInputWrapper .list th:first-child {
      padding-left: 1rem;
      width: 70px; }
    .searchInputWrapper .list td:nth-child(2n),
    .searchInputWrapper .list th:nth-child(2n) {
      max-width: 100px; }
    .searchInputWrapper .list td:last-child,
    .searchInputWrapper .list th:last-child {
      padding-right: 1rem;
      float: right; }
    .searchInputWrapper .list th {
      padding: 0 1rem 0.5rem 0;
      white-space: nowrap; }
    .searchInputWrapper .list td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;
      vertical-align: middle; }
    .searchInputWrapper .list .tdInputWrapper {
      white-space: nowrap; }
      .searchInputWrapper .list .tdInputWrapper input {
        width: 4.5rem;
        margin-right: 0.1rem; }
    .searchInputWrapper .list button {
      margin-top: 0; }

.generalSpinner {
  height: 2rem;
  width: 2rem;
  color: #f7594d;
  text-align: center; }

.secondChoice {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  flex-flow: column; }
  .secondChoice button {
    margin-right: 4rem;
    margin-top: 2rem;
    padding: 0.5rem 1rem; }
    .secondChoice button:last-child {
      margin-right: 0; }

.spinnerWrapper {
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: #f7594d; }

@media screen and (min-width: 768px) {
    .reset {
      margin-left: 0;
      margin-right: 0; }
    .searchInputWrapper {
      width: 740px; } }

@media screen and (min-width: 992px) {
      .punchOutWrapper .logotypeWrapper .logotype {
        position: relative;
        top: -2.5rem; }
        .punchOutWrapper .logotypeWrapper .logotype svg {
          height: 9rem;
          width: 9rem; } }
