/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.carbonCloud {
  background: #e6ece8;
  display: flex;
  padding: 20px;
  width: 100%;
  margin-bottom: 3rem;
  color: #24733b;
  vertical-align: middle;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  /* 18.4px */ }
  .carbonCloud a, .carbonCloud a:visited {
    font-weight: 600;
    color: #24733b;
    text-decoration: none; }
    .carbonCloud a:hover, .carbonCloud a:visited:hover {
      color: #24733b;
      text-decoration: underline; }
    .carbonCloud a svg, .carbonCloud a:visited svg {
      height: 16px; }
  .carbonCloud .first {
    flex: 1 0 100%;
    display: flex;
    gap: 12px; }
    .carbonCloud .first > div {
      flex: 1 0; }
  .carbonCloud .last {
    flex: 1 0 100%; }
  .carbonCloud * {
    align-items: center;
    vertical-align: middle; }
  .carbonCloud .banner {
    display: flex;
    max-width: 150px; }
    .carbonCloud .banner .svg {
      flex: 1 0;
      margin-right: 8px; }
      .carbonCloud .banner .svg svg {
        width: 37px;
        height: 34px; }
      .carbonCloud .banner .svg stroke {
        color: #24733B; }
    .carbonCloud .banner .text {
      flex: 1 0; }
      .carbonCloud .banner .text strong {
        display: block; }
  .carbonCloud .big_text {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    /* 46px */
    letter-spacing: -0.4px;
    margin-top: -4px;
    overflow: hidden;
    text-align: right; }
  @media screen and (min-width: 768px) {
      .carbonCloud .first {
        flex: 1 0; }
      .carbonCloud .last {
        flex: 1 0 60%; } }
  @media screen and (min-width: 1200px) {
      .carbonCloud .big_text {
        text-align: left; } }
