/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  font-family: SFNS_bold, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%;
  letter-spacing: -0.32px; }

.description {
  font-family: SFNS_thin, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%; }
  .description--medium {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%; }
  .description--title {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase; }

.subtext {
  font-family: SFNS, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 115%;
  letter-spacing: 0.24px; }

.subDeliveryWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px; }
  .subDeliveryWrapper.loading {
    opacity: 0.25; }
  .subDeliveryWrapper .top {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px; }
    .subDeliveryWrapper .top .info {
      display: flex;
      flex-direction: column;
      gap: 4px; }
      .subDeliveryWrapper .top .info .date {
        display: flex;
        gap: 4px;
        flex-direction: column; }
      .subDeliveryWrapper .top .info .label {
        font-family: SFNS_medium, sans-serif;
        font-size: 12px;
        color: #ffffff;
        padding: 0 4px;
        border-radius: 4px;
        height: 16px;
        background: #b0406f;
        align-self: start; }
    .subDeliveryWrapper .top .actions {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center; }
      .subDeliveryWrapper .top .actions .quantity {
        height: 32px;
        max-width: 132px; }
        .subDeliveryWrapper .top .actions .quantity div {
          width: 100%; }
      .subDeliveryWrapper .top .actions .remove {
        cursor: pointer;
        position: relative; }
        .subDeliveryWrapper .top .actions .remove svg {
          width: 20px;
          height: 20px;
          margin-top: -2px;
          position: relative;
          z-index: 1; }
  .subDeliveryWrapper .bottom {
    text-align: left;
    line-height: unset;
    height: unset;
    min-width: unset; }
  .subDeliveryWrapper.isMobile {
    flex-direction: column;
    gap: 12px; }
    .subDeliveryWrapper.isMobile .top .info .date {
      flex-direction: column; }
    .subDeliveryWrapper.isMobile .bottom {
      text-align: left;
      line-height: unset;
      height: unset;
      min-width: unset; }

.infoText {
  color: #b0406f;
  font-style: italic; }
  .infoText .punchoutDate {
    font-weight: bold; }
  .infoText .input {
    flex: 3.5;
    padding: 0 0.5rem; }
  .infoText .removeSub {
    padding: 0 0 0 0.5rem;
    flex: 6;
    width: initial;
    text-align: right; }
    .infoText .removeSub > * {
      display: inline-block; }
    @media screen and (min-width: 768px) {
    .title {
      font-size: 18px !important;
      letter-spacing: -0.36px; }
    .description {
      font-size: 14px !important; }
      .description--medium {
        font-size: 14px !important; } }
    @media screen and (min-width: 992px) {
    .subDeliveryWrapper {
      flex-direction: row;
      gap: 16px; }
          .subDeliveryWrapper .top .info .date {
            flex-direction: row; }
      .subDeliveryWrapper .bottom {
        height: 32px;
        line-height: 32px;
        min-width: 75px;
        text-align: right; } }
