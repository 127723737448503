/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.box {
  flex: 1;
  border: solid;
  border-width: 0.063rem;
  border-color: #e7e7e7;
  margin: 1rem 0;
  position: relative; }
  .box.selected {
    border-color: #24733b; }
  .boxTop {
    display: flex;
    flex-direction: column;
    position: relative; }

.header {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e7e7e7;
  color: #6c737a;
  text-align: center;
  padding: 0.625rem; }
  .header.selected {
    color: white;
    background-color: #24733b; }

.description {
  text-align: center;
  padding: 1.25rem;
  background-color: #f5f5f5;
  color: #6c737a;
  -webkit-clip-path: ellipse(95% 100% at 50% 0%);
          clip-path: ellipse(95% 100% at 50% 0%);
  height: 10rem;
  font-size: 1rem; }
  .description.selected {
    background-color: #e6ece8;
    color: #0d0f11; }

.price {
  text-align: center;
  height: 4.688rem;
  width: 4.688rem;
  line-height: 4.688rem;
  border-radius: 50%;
  border-color: #e7e7e7;
  border: 0.063rem solid;
  color: #6c737a;
  background-color: white;
  margin-left: auto;
  position: absolute;
  top: 11.5rem;
  right: 0.3rem;
  font-size: 1rem; }
  .price.selected {
    border-color: #24733b;
    color: #0d0f11; }
  .price > span {
    line-height: 1.1;
    display: inline-flex;
    align-self: center;
    vertical-align: middle; }

.card {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column; }
  .card ul {
    width: 100%;
    margin: inherit 0;
    padding: 0;
    list-style: none; }

.contentHeader {
  text-transform: uppercase;
  text-align: center;
  color: #6c737a;
  font-size: 1.143rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  padding-bottom: 0.875rem; }
  .contentHeader.selected {
    color: #0d0f11; }

.contentList {
  margin-bottom: 0.5rem;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  color: #6c737a; }
  .contentList.selected {
    color: #0d0f11; }

li.contentList {
  margin: 0 1rem 0.25rem; }
  li.contentList svg {
    margin-right: .5rem; }

.checkMark {
  color: #6c737a;
  font-size: 1rem; }
  .checkMark.selected {
    color: #24733b; }

.explanation {
  color: #6c737a;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .45rem; }
  .explanation.selected {
    color: #0d0f11; }
  @media screen and (min-width: 992px) {
    .box {
      margin: 1rem; }
      .box:first-of-type {
        margin-left: 0; }
      .box:last-of-type {
        margin-right: 0; }
    .description {
      -webkit-clip-path: ellipse(80% 100% at 50% 0%);
              clip-path: ellipse(80% 100% at 50% 0%);
      height: 9rem; }
    .price {
      -webkit-clip-path: ellipse(80% 100% at 50% 0%);
              clip-path: ellipse(80% 100% at 50% 0%);
      top: 9.5rem;
      right: 1rem; }
    li.contentList {
      text-indent: -1rem; } }
