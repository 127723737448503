/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.fullRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dee1e4;
  background: #fef6f5; }
  .fullRow div {
    vertical-align: top; }

.col1 {
  padding: 0 0.5rem 0 0;
  flex: 2;
  width: 7.5%; }

.col2 {
  padding: 0 0.5rem;
  flex: 5;
  color: #23272a; }
  .col2 a {
    text-decoration: none; }
  .col2 p {
    margin-bottom: 0; }

.title {
  font-family: SFNS_bold, sans-serif;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #f7594d; }

.message {
  flex: 20; }

.text {
  font-style: italic; }

.highlighted {
  transition: .3s;
  background: #f8d2bb; }

.discontinuedSection a,
.discontinuedSectionMobile a {
  color: #23272a;
  margin-top: 0.25rem;
  text-decoration: none; }

.discontinuedSection {
  flex: 13;
  display: flex;
  padding: 1.2rem 0rem; }
  .discontinuedSection .text {
    margin: auto 0; }
  .discontinuedSection .discontinuedProduct {
    align-items: center;
    padding: 1rem 0;
    padding: 0 2rem; }

.discontinuedSectionMobile {
  display: block;
  padding: 0rem 0rem 1.2rem; }
  .discontinuedSectionMobile .discontinuedProduct {
    padding: 0.5rem 0; }

.discontinuedSectionBox {
  margin-top: 1rem; }
  .discontinuedSectionBox a {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    justify-content: space-between; }

.lightGray {
  color: #999; }

.mobileRow {
  background: #fef6f5;
  border-top: 1px solid #eff0f2;
  padding: 1rem 0 0.5rem;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap; }

.mobileLeft {
  flex: 1; }

.mobileRight {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 6rem; }
  .mobileRight .mobileProductRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border-bottom: none;
    padding: 0;
    align-items: center; }
  .mobileRight .mobileInfo {
    align-self: center;
    width: 100%; }

.mobileCompactRow {
  background: #fef6f5;
  padding: 0.25rem 1.5rem;
  margin: 0 0 0.25rem; }

@media screen and (min-width: 480px) {
    .title {
      margin-top: 2.2rem; } }

@media screen and (min-width: 992px) {
    .title {
      font-size: 1.14286rem;
      line-height: 1.42857rem; } }
