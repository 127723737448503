/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  line-height: inherit;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: SFNS, sans-serif;
  color: #0d0f11;
  font-weight: 600; }

.trademark {
  line-height: 16px;
  font-size: 12px;
  color: #0d0f11;
  font-family: SFNS_thin, sans-serif; }

.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }
  .link:hover {
    color: inherit;
    text-decoration: underline !important; }
