/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.background {
  top: -1rem;
  position: relative;
  background-color: #f5f5f5;
  padding: 2rem 0 4rem;
  margin-bottom: -2rem !important; }

.base {
  padding-top: 0; }

.leftChildBlock {
  display: inline-block;
  width: 100%; }
  .leftChildBlock__item {
    width: 100%;
    height: auto; }

.rightChildBlock {
  display: inline-block;
  width: 100%; }
  .rightChildBlock__item {
    width: 100%;
    height: auto; }

.fullChildBlock {
  width: 100%; }
  .fullChildBlock__item {
    width: 100%;
    height: auto; }

.heroBlock {
  display: flex;
  justify-content: space-between; }
  .heroBlock__preamble h1 {
    font-weight: normal;
    font-family: 'SFNS';
    font-size: 1.78571rem;
    line-height: 2.14286rem;
    margin-bottom: 5px; }
    .heroBlock__preamble h1 span {
      color: #f8584c; }
  .heroBlock__preamble p {
    font-size: 1.14286rem;
    line-height: 1.42857rem; }
  .heroBlock__userInfo {
    display: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }

.leftPaneMenu {
  float: left;
  width: 15%;
  text-align: center; }
  .leftPaneMenu:empty {
    width: 0px; }
  .leftPaneMenu:empty + .rightBlock {
    width: 100%; }

.rightBlock {
  width: 85%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  float: left; }
  .rightBlock__item {
    width: 100%;
    height: auto; }

.overviewSection {
  display: block; }

.orderOverviewTitle {
  font-size: 1.6rem;
  font-weight: bold; }

@media screen and (min-width: 768px) {
    .background {
      top: auto;
      position: unset; }
    .leftChildBlock {
      width: 50%; }
    .rightChildBlock {
      width: 50%;
      padding: 0 1rem; }
      .heroBlock__preamble h1 {
        font-size: 1.78571rem;
        line-height: 2.14286rem; }
      .heroBlock__userInfo {
        display: inherit;
        padding-bottom: 20px; }
    .rightBlock {
      width: 85%;
      padding: 0 1rem; }
    .overviewSection {
      display: flex;
      justify-content: space-between; } }

@media screen and (min-width: 992px) {
    .leftChildBlock {
      padding: 0 1rem; }
    .fullChildBlock {
      padding: 0 0 0 1rem; }
      .heroBlock__preamble h1 {
        font-size: 2.85714rem;
        line-height: 3.57143rem; }
      .heroBlock__preamble p {
        font-size: 1.42857rem;
        line-height: 1.71429rem; } }
