/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  height: 70px;
  background: #343d46;
  display: flex;
  flex: 1;
  width: 100vw; }
  .base .searchInput {
    flex: 1;
    height: 100%;
    border: none !important;
    font-size: 1.14286rem;
    box-sizing: border-box;
    padding: 1rem;
    background: #343d46;
    color: #fff; }
    .base .searchInput::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff; }
    .base .searchInput::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff; }
    .base .searchInput::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff; }

.clear {
  composes: bare from "../../../styles/objects/buttons.scss";
  font-size: 1.14286rem;
  height: 70px;
  line-height: 70px;
  width: 70px;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center; }
