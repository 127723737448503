/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.campaignHeader {
  position: relative;
  background: #fff;
  background: linear-gradient(90deg, #F5E8E7 0, #F5E8E7 40%, #fff 40%, #fff 100%);
  overflow: hidden;
  margin: 0 -.75rem -40px;
  padding-bottom: 40px; }
  .campaignHeader h1 {
    font-family: SFNS, sans-serif; }
    .campaignHeader h1 + p {
      font-weight: 400;
      font-size: 1.357rem;
      line-height: 1.857rem; }
  .campaignHeader::before {
    content: "";
    position: absolute;
    background: #F5E8E7;
    left: -100px;
    bottom: -10px;
    right: 0;
    height: 535px;
    border-radius: 0 0 735px 535px; }
  .campaignHeader .inner {
    position: relative;
    padding: 1rem .75rem; }

.campaignList {
  margin: 0;
  margin-left: -.5rem;
  padding: 0; }
  .campaignList li {
    display: inline-block;
    padding: 0.5rem 0.5rem;
    font-size: 1.143rem; }
    .campaignList li svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0;
      margin-top: -2px; }
  .campaignList .button {
    display: block;
    background: #fff;
    border: solid 1px #343D46;
    padding: 0.545rem 1.25rem;
    font-size: 1rem;
    line-height: 1.1;
    color: #0D0F11;
    text-transform: capitalize;
    margin-right: .5rem;
    letter-spacing: 0.1rem;
    border-radius: 500px;
    white-space: pre;
    transition: background-color 200ms ease-in-out; }
    .campaignList .button:hover, .campaignList .button:focus, .campaignList .button:active {
      color: #000;
      background: #DEDEDE !important;
      text-decoration: none; }
  .campaignList .link {
    color: #000; }
    .campaignList .link:hover {
      text-decoration: underline; }
    .campaignList .link:hover, .campaignList .link:focus, .campaignList .link:active {
      color: #000; }
    .campaignList .link + svg {
      margin-left: .25rem; }

.categoryName {
  text-align: left; }

.bottomBorder {
  padding-bottom: 40px; }

.heroImageWrapper {
  margin: 0 -.75rem; }

@media screen and (min-width: 992px) {
    .campaignHeader {
      margin: 0 0 1rem 0;
      padding-bottom: 0;
      background: #F5F5F5; }
      .campaignHeader .inner {
        padding: 2rem 2rem 1.25rem 8.5rem; }
      .campaignList li {
        padding: 0.33333rem 0.5rem; }
    .bottomBorder {
      position: relative;
      margin-top: 0 !important;
      padding-bottom: 0; }
      .bottomBorder h3 {
        min-height: 1.57143rem; }
      .bottomBorder:after {
        content: "";
        display: block;
        border-bottom: 1px solid #F7594D;
        height: 0px;
        width: 100%;
        position: absolute;
        bottom: -50px; }
    .heroImageWrapper {
      margin: 0 0 1.5rem; } }

@media screen and (min-width: 768px) {
      .campaignHeader::before {
        border-radius: 100%;
        background: #cbdbd0;
        background: linear-gradient(180deg, #F5E8E7 0, #F5E8E7 100%);
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -175px;
        right: unset;
        bottom: unset;
        max-width: unset;
        max-height: unset; }
      .campaignList .button {
        margin: 0;
        display: inline-block; } }
