/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.RecipeMenu {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #fff; }
  .RecipeMenu .removeAll {
    float: right;
    margin-right: 2rem;
    text-decoration: underline; }
  .RecipeMenu .Header {
    background-color: #e5e5e5;
    cursor: pointer; }
    .RecipeMenu .Header .title {
      margin: 1rem 0;
      display: inline-block; }
    .RecipeMenu .Header .spinner {
      display: inline-block;
      width: 1.5rem;
      margin-left: 1rem; }
    .RecipeMenu .Header .innerHeader {
      display: flex;
      width: 100%;
      padding: 0.25rem 1rem; }
      .RecipeMenu .Header .innerHeader:hover {
        transition: .3s;
        background: #f9f9f9; }
      .RecipeMenu .Header .innerHeader .checkBox {
        flex: 0.1;
        align-items: center;
        justify-content: center;
        display: flex; }
      .RecipeMenu .Header .innerHeader .displayName {
        flex: 2; }
      .RecipeMenu .Header .innerHeader .remove {
        padding-right: 1rem;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex: 0.1;
        padding-right: 0.5rem; }
        .RecipeMenu .Header .innerHeader .remove button {
          float: right; }
    .RecipeMenu .Header .price {
      display: inline-block; }
    .RecipeMenu .Header .table {
      width: 100%;
      background: #f9f9f9; }
      .RecipeMenu .Header .table .recipeSearch {
        padding: 0.5rem;
        margin: 0 1rem;
        position: relative; }
        .RecipeMenu .Header .table .recipeSearch input {
          height: 3.5rem;
          width: 50%;
          max-width: 50rem; }
      .RecipeMenu .Header .table .tablerow {
        border-bottom: 1px solid #fff;
        padding: 0.4rem 1.2rem 4rem 1.2rem;
        display: flex;
        flex-wrap: wrap; }
        .RecipeMenu .Header .table .tablerow:last-child {
          border: none; }
        .RecipeMenu .Header .table .tablerow .name {
          font-size: 1.5em;
          flex: 3; }
        .RecipeMenu .Header .table .tablerow .imageWrap {
          border: 1px solid #e5e5e5;
          width: 100%;
          overflow: hidden; }
          .RecipeMenu .Header .table .tablerow .imageWrap img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle; }
        .RecipeMenu .Header .table .tablerow .meal img {
          position: relative;
          background-color: #ffffff; }
        .RecipeMenu .Header .table .tablerow .meal:before {
          content: ' ';
          position: absolute;
          top: 0.25rem;
          left: 0.25rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border: 1px solid #e5e5e5; }
      .RecipeMenu .Header .table .AddToCartWrapper {
        padding: 0.4rem 1.2rem 2rem 1.5rem; }
      .RecipeMenu .Header .table .tableheader {
        display: flex;
        padding: 0.4rem 1.2rem;
        border-bottom: 1px solid #fff;
        color: #343d46; }
      .RecipeMenu .Header .table .tablecell, .RecipeMenu .Header .table .col0, .RecipeMenu .Header .table .col1, .RecipeMenu .Header .table .col2, .RecipeMenu .Header .table .col3, .RecipeMenu .Header .table .col4, .RecipeMenu .Header .table .col5, .RecipeMenu .Header .table .portionsHeader {
        vertical-align: top; }
      .RecipeMenu .Header .table .col0 {
        flex: none;
        width: 100%; }
      .RecipeMenu .Header .table .col1 {
        margin-top: 1.2rem;
        flex: none; }
      .RecipeMenu .Header .table .col2 {
        padding-top: 0.3rem;
        padding-right: 1rem;
        flex: none; }
      .RecipeMenu .Header .table .col3 {
        flex: 1; }
      .RecipeMenu .Header .table .col4 {
        width: 100%;
        margin-top: 1rem;
        padding-right: 1rem;
        vertical-align: top;
        flex: none; }
      .RecipeMenu .Header .table .col5 {
        width: 100%;
        flex: none; }
      .RecipeMenu .Header .table .portionsHeader {
        padding-right: 2rem;
        flex: 1.2; }
    .RecipeMenu .Header .addToCartbtn {
      margin-left: 2rem;
      margin-bottom: 2rem; }
    .RecipeMenu .Header .textWrapper {
      flex: 1;
      flex-grow: 4;
      min-width: 5rem; }
      .RecipeMenu .Header .textWrapper .linkText {
        color: #f66d62;
        padding-bottom: 0.5rem;
        display: block; }
      .RecipeMenu .Header .textWrapper .mobile {
        padding-top: 0.5rem; }
    .RecipeMenu .Header .linkText em {
      display: block;
      padding: 0.25rem 0; }
    .RecipeMenu .Header .imageWrap {
      display: inline-block;
      vertical-align: middle; }
    .RecipeMenu .Header .base {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .RecipeMenu .Header .base .card {
        min-width: 22.572rem;
        max-width: 22.572rem;
        height: 26.072rem;
        flex: 1;
        border: 1px solid #e5e5e5;
        border-radius: 0.25rem;
        margin-bottom: 1rem;
        position: relative;
        display: flex;
        flex-direction: column; }
        .RecipeMenu .Header .base .card .remove {
          position: absolute;
          top: 0.5rem;
          right: 0; }
        .RecipeMenu .Header .base .card .lock {
          min-width: 2rem;
          text-align: right; }
        .RecipeMenu .Header .base .card .imageWrap {
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
          height: 15.286rem;
          flex: 1 0 0;
          align-items: center;
          display: inline-flex; }
          .RecipeMenu .Header .base .card .imageWrap a {
            width: 100%; }
          .RecipeMenu .Header .base .card .imageWrap img {
            max-height: 15.286rem; }
        .RecipeMenu .Header .base .card .info {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 2rem 2rem 1rem;
          flex: 1; }
        .RecipeMenu .Header .base .card .cardRow {
          width: 100%;
          display: flex;
          justify-content: space-between; }
          .RecipeMenu .Header .base .card .cardRow .price, .RecipeMenu .Header .base .card .cardRow sup {
            color: #999;
            font-weight: normal; }
          @media screen and (min-width: 768px) {
          .RecipeMenu .Header .table .tablerow {
            padding: 0.4rem 1.2rem;
            flex-wrap: nowrap; }
            .RecipeMenu .Header .table .tablerow .name {
              font-size: inherit; }
          .RecipeMenu .Header .table .tablerow .remove button {
            padding: 0; }
          .RecipeMenu .Header .table .tablecell, .RecipeMenu .Header .table .col0, .RecipeMenu .Header .table .col1, .RecipeMenu .Header .table .col2, .RecipeMenu .Header .table .col3, .RecipeMenu .Header .table .col4, .RecipeMenu .Header .table .col5, .RecipeMenu .Header .table .portionsHeader {
            padding: 1.2rem 0rem; }
          .RecipeMenu .Header .table .col0 {
            flex: none;
            width: 10rem; }
          .RecipeMenu .Header .table .col1 {
            margin-top: 0;
            padding-right: 1rem;
            flex: 2; }
          .RecipeMenu .Header .table .col2 {
            padding-right: 1rem;
            flex: 1; }
          .RecipeMenu .Header .table .col3 {
            margin-right: 1rem;
            margin-left: 1rem;
            max-width: 2rem; }
          .RecipeMenu .Header .table .col4 {
            width: 8rem;
            margin-top: 0;
            padding-right: 1rem;
            flex: 1; }
          .RecipeMenu .Header .table .col5 {
            flex: 6;
            margin-right: 2rem;
            margin-left: 2rem; } }
          @media screen and (min-width: 992px) {
          .RecipeMenu .Header .table .col1 {
            padding-right: 2rem;
            flex: 1; }
          .RecipeMenu .Header .table .col4 {
            width: 100%; } }
