/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  padding-top: 0; }

.breadcrumbsWrap {
  margin-top: 20px;
  margin-bottom: 10px; }

.highlighted {
  transition: 0.3s;
  background: #d2e7d3 !important; }

.list .listBorderWrap {
  border-top: 0;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5; }

.list tr:not(:first-child) {
  border-top: 1px solid #e5e5e5; }

.list tr:nth-child(2n) {
  background-color: #eff0f2; }

.list tr:last-child {
  border-bottom: 1px solid #e5e5e5; }

.list tr.link {
  color: #343d46; }

.list td:first-child,
.list th:first-child {
  padding-left: 1rem; }

.list td:last-child,
.list th:last-child {
  padding-right: 1rem; }

.list th {
  padding: 0.5rem 0; }

.list td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: middle; }

.right {
  float: right; }

.part {
  padding: 0 1rem; }

.list {
  margin-bottom: 10px;
  position: relative; }

.headerflex {
  display: flex;
  flex-wrap: nowrap; }

.header {
  display: flex;
  background: #f1f1f1;
  padding: 10px;
  border: 1px solid #e5e5e5; }
  .header:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: #e5e5e5; }
  .header.isOpen {
    background: #e5e5e5; }
  .header > span {
    flex: 2 1;
    display: inherit; }
  .header .spec {
    width: 21px; }
  .header .spinner {
    height: 16px;
    width: 16px;
    margin-right: 5px; }
  .header .placeholder {
    height: 21px;
    width: 21px; }
  .header .info {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .header .info > span {
      display: inherit;
      flex: 2 1; }
  .header .displayName {
    font-weight: bold; }
  .header .countWrap {
    flex: 2 2;
    position: relative;
    font-weight: bold; }
    .header .countWrap .count {
      font-weight: normal; }
  .header .iconsWrap {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 8rem; }
    .header .iconsWrap div {
      margin-left: 10px; }
    .header .iconsWrap .removeListButtonWrap {
      font-size: 11px;
      display: flex; }

.actionbar {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
  flex-wrap: wrap; }
  .actionbar > * {
    margin: 0.5rem 0.5rem; }
  .actionbar .addToMenu {
    min-width: 15rem; }
  .actionbar .actionbarInner {
    justify-content: space-between;
    width: 100%; }
  .actionbar .actionMenuWrap {
    display: flex;
    flex-direction: column; }
    .actionbar .actionMenuWrap div:last-child {
      margin-top: 1rem; }
    .actionbar .actionMenuWrap .actionMenu {
      min-width: 15rem;
      margin: 0 0 1rem 0; }
      .actionbar .actionMenuWrap .actionMenu:last-child {
        margin: 0; }
  .actionbar .searchInListWrap {
    min-width: 15rem;
    margin-top: 1rem; }
    .actionbar .searchInListWrap .searchInList {
      width: 100%; }

button.clearList {
  font-family: SFNS_bold, sans-serif;
  margin: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 28px;
  font-size: 12px; }

.actionMenu {
  min-width: 15rem; }

.ordinalWrapper {
  padding: 0.5rem;
  flex: 0 0 5rem;
  overflow: hidden; }

.dialog {
  overflow: inherit; }
  .dialog .listoptions {
    overflow-y: auto;
    max-height: 60vh; }

.wrapper {
  max-height: 10rem; }

.toolbar {
  margin-top: 28px;
  padding-bottom: 28px; }

.deleteDialog,
.moveDialog {
  display: flex;
  justify-content: center; }

.deletedByMasterIcon {
  color: #ef7172 !important; }

.headerRecommendations {
  background-color: #6c737a;
  padding: 0.92857rem 1.21429rem;
  display: flex;
  flex-direction: column;
  color: #fff; }
  .headerRecommendations:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: #525a62; }
  .headerRecommendations .displayName {
    color: inherit; }
  .headerRecommendations .showToggle {
    display: flex;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    align-items: inherit;
    margin-top: 14px; }
    .headerRecommendations .showToggle span {
      font-size: 1.14286rem;
      line-height: 1.28571rem; }
    .headerRecommendations .showToggle svg {
      margin-left: 11px;
      height: 1.14286rem;
      width: 1.14286rem; }
  .headerRecommendations > div {
    flex: 1; }
    .headerRecommendations > div.spec {
      max-width: 0; }
  .headerRecommendations.isLoading > div.spec {
    max-width: 21px; }
  @media screen and (min-width: 768px) {
    .list {
      margin-bottom: 14px; }
    .header {
      padding: 0 14px;
      align-items: center;
      height: 60px; }
      .header .info {
        align-items: center;
        flex-direction: row;
        margin-left: 1rem; }
      .header .iconsWrap {
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap; }
    .actionbar {
      padding: 0.5rem; }
      .actionbar > * {
        flex: 1;
        flex: initial; }
        .actionbar > *:not(:first-child) {
          margin-left: 0.5rem; }
      .actionbar .actionbarInner {
        display: flex; }
      .actionbar .actionMenuWrap {
        flex-direction: row; }
        .actionbar .actionMenuWrap .actionMenu {
          margin: 0 1rem 0 0; }
          .actionbar .actionMenuWrap .actionMenu:last-child {
            margin: 0; }
      .actionbar .searchInListWrap {
        margin: 0; }
    .ordinalWrapper {
      flex: 0 0 8rem; }
    .headerRecommendations {
      align-items: center;
      flex-direction: row; }
      .headerRecommendations .showToggle {
        margin-top: 0;
        max-width: 150px;
        justify-content: flex-end; } }
  @media screen and (min-width: 992px) {
      .header .iconsWrap {
        flex: 0 0 15rem; }
        .header .iconsWrap div {
          margin-left: 0; } }
  @media screen and (min-width: 1200px) {
        .header .iconsWrap .removeListButtonWrap {
          font-size: 12px; } }
  @media screen and (min-width: 480px) {
      .actionbar .actionMenuWrap div:last-child {
        margin-top: 0; } }
