/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative; }
  .base.loading :global(.react-swipe-container) > div {
    max-height: 350px; }
  .base .prev,
  .base .next {
    z-index: 1; }
    .base .prev.disabled,
    .base .next.disabled {
      background: #c2c5c8; }
  .base .prev {
    left: 0.75rem; }
  .base .next {
    right: 0.75rem; }
  .base .carouselHeader {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .base .carouselHeader h2 {
      margin: 0; }
  .base .swiperWrap {
    position: relative; }
  .base .mobileNavigationWrap {
    position: relative; }
  .base .navigationButtonsWrap {
    display: flex; }
    .base .navigationButtonsWrap .prev {
      margin-right: 5px; }
  .base .swiperPagination {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 1rem;
    position: relative;
    display: flex;
    justify-content: center; }
    .base .swiperPagination li {
      display: inline-block;
      margin: 0 0.25rem;
      width: 1rem;
      height: 1rem;
      border: solid 1px #999;
      border-radius: 50%;
      cursor: pointer; }
      .base .swiperPagination li.active {
        background: #999;
        background-clip: content-box; }

.swiper {
  margin: 0 2.5rem; }

.productListContainer {
  position: absolute;
  top: 0; }

.slide {
  display: flex;
  justify-content: flex-start; }
  .slide.tall {
    padding-bottom: 0; }
    .slide.tall .carouselCard > div {
      flex-direction: column;
      align-items: initial; }
      .slide.tall .carouselCard > div > div:first-child a > div {
        max-height: 150px;
        max-width: 150px;
        margin: 0 auto; }
      .slide.tall .carouselCard > div > div:last-child {
        padding-left: 0;
        flex-basis: auto;
        text-align: center; }
        .slide.tall .carouselCard > div > div:last-child h5 {
          font-size: 2.28571rem; }
  .slide > * {
    margin: 0 7px;
    padding: 1rem;
    box-shadow: 0 0rem 0.14rem 0 rgba(0, 0, 0, 0.1), 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.07), 0 0.3rem 0.1rem -0.12rem rgba(0, 0, 0, 0.1);
    border: thin solid rgba(0, 0, 0, 0.14);
    border-radius: 0.2rem;
    margin-bottom: 0.5rem; }

.carouselCard {
  border: 0;
  padding: 1rem;
  width: 100%; }
  .carouselCard > div > div:last-child {
    padding-left: 1rem;
    flex-basis: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .carouselCard > div > div:last-child button {
      background: #f7594d;
      color: white;
      margin-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 50%;
      min-width: 6rem; }
      .carouselCard > div > div:last-child button:before {
        color: white !important; }
    .carouselCard > div > div:last-child h5 {
      font-size: 1.2rem; }
    .carouselCard > div > div:last-child h5 + span span:first-child {
      font-size: 3rem; }

.size-1 {
  width: calc(100% - 1rem); }

.size-2 {
  width: calc(50% - 1rem); }

.size-3 {
  width: calc(33.33% - 1rem); }

.size-4 {
  width: calc(25% - 1rem); }

@media screen and (min-width: 992px) {
      .base .carouselHeader {
        margin: 2rem 0 2rem;
        padding-top: 2rem; }
        .base .navigationButtonsWrap .prev {
          margin-right: 10px; } }
