/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.card {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 14px;
  height: 100%;
  background: #fff; }
  .card.isRecommendation {
    min-height: 230px; }

.cardInner {
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  font-family: SFNS_thin, sans-serif;
  font-size: 11px;
  height: 100%;
  justify-content: space-between; }

.listMethodsWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 12px; }
  .listMethodsWrap label {
    align-self: flex-start;
    margin-top: 2px; }

.innerFlexDiv {
  display: flex; }

.listBtnsWrap {
  display: flex;
  align-items: center;
  width: 85px;
  justify-content: center; }

.ordinalWrap {
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  flex-grow: 1;
  align-self: flex-start; }
  .ordinalWrap .ordinalTitle {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    color: #0d0f11;
    font-family: SFNS_thin, sans-serif;
    margin-right: 14px;
    align-self: flex-start;
    margin-top: 5px; }

.quantityAndVariantWrap {
  display: flex;
  flex: 0 0 152px; }
  .quantityAndVariantWrap > div {
    display: flex; }
  .quantityAndVariantWrap.pantryQuantity {
    flex: 1;
    justify-content: flex-end; }

.loginToBuyWrap {
  flex: 1;
  display: flex;
  justify-content: flex-end; }

.typeAndUnitWrap {
  font-size: 12px;
  font-family: SFNS_thin, sans-serif;
  color: #0d0f11; }
  .typeAndUnitWrap > div > div {
    margin-bottom: 0; }

.topRow {
  display: flex; }

.contentRow {
  display: flex;
  margin-bottom: 14px; }

.bottomRow {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 14px;
  padding-right: 14px; }
  .bottomRow .left,
  .bottomRow .right {
    flex: 0 0 50%;
    display: flex;
    align-items: baseline; }
  .bottomRow .right {
    flex-direction: column; }
    .bottomRow .right > div {
      margin-left: auto; }

.gropupedItems {
  display: flex;
  flex: 1;
  margin-left: auto; }

.gropupedItemsFavorites > div {
  display: flex; }

.priceWrap {
  display: flex;
  flex: 1;
  text-align: right;
  flex-direction: column; }

.marksAndDisabledInfoWrap {
  display: flex;
  flex-direction: column; }

.marksWrap {
  display: flex;
  justify-content: flex-end; }

.pantryDisabledInfoWrap {
  margin-bottom: 14px; }

.detailsWrap {
  flex: 1; }
  .detailsWrap .carbonCloud {
    font-family: SFNS_thin, sans-serif;
    font-size: 12px;
    color: #343d46;
    margin-top: -1px; }
    .detailsWrap .carbonCloud a {
      color: #343d46; }
      .detailsWrap .carbonCloud a:hover, .detailsWrap .carbonCloud a:focus {
        color: #343d46; }

.availabilityWrap {
  margin-right: 14px;
  flex-shrink: 0; }

.outerFlexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 28px; }

.titleWrap {
  flex: 1;
  min-width: 160px;
  max-width: 160px;
  text-overflow: ellipsis;
  padding-right: 5px; }

.title {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: 0; }

.imageWrap {
  flex: 0 0 85px;
  max-width: 85px;
  margin-left: 28px; }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 57px;
  left: 11px;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100% !important;
  height: 100% !important; }

.toggleSelected {
  padding: 0; }

.removeButtonWrap,
.lockButtonWrap {
  display: flex; }

.lockButtonWrap {
  position: absolute;
  right: 1rem;
  top: .75rem; }

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  color: #6c737a;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  margin-top: 3px;
  transition: background-color 200ms ease-in-out; }
  .circle svg {
    position: relative;
    height: 16px;
    width: 16px; }
  .circle.activeIcon {
    background: #343d46;
    color: #fff;
    border: 1px solid #343d46; }
    .circle.activeIcon:hover {
      background: #6c737a !important;
      border-color: #6c737a !important;
      color: #fff; }
  .circle:hover, .circle:focus, .circle:active {
    color: #000;
    background: #dedede !important; }

.quantityNoInput {
  text-align: right;
  padding-right: 14px; }

@media screen and (min-width: 1200px) {
    .cardInner {
      font-size: 12px; }
    .circle {
      height: 32px;
      width: 32px; }
      .circle svg {
        height: 18px;
        width: 18px; } }
