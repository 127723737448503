/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrap {
  position: relative;
  cursor: pointer; }
  .wrap.showDescription {
    display: flex;
    gap: 8px;
    line-height: 12px; }
    .wrap.showDescription .dots {
      line-height: 12px !important;
      margin-left: 0px !important;
      display: flex;
      align-items: center; }
    .wrap.showDescription .description {
      font-size: 12px;
      color: #6c737a;
      text-wrap: nowrap; }
    .wrap.showDescription .showDescriptionRow {
      display: flex;
      flex-direction: row;
      gap: 8px; }

.tooltip {
  padding: 10px 5px 5px 5px;
  text-align: center;
  min-width: 80px;
  min-height: 35px; }

.dots {
  margin-left: -2px;
  font-size: 0;
  line-height: 22px; }

.dot {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 2px; }

.inStock {
  background-color: #6fb172; }

.lowStock {
  background-color: #efc372; }

.outOfStock {
  background-color: #b0406f; }

.warehouse {
  background-color: #7778f9; }

.availabilityDate {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
  left: 5px;
  position: relative;
  bottom: -1px; }

.availabilityDateBold {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #000;
  display: block;
  padding: .75rem .5rem; }

@media screen and (min-width: 992px) {
    .dots {
      line-height: inherit; } }
