/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.list {
  display: block; }
  .list .card:last-of-type {
    border-bottom: 1px solid #f7594d; }

.card {
  position: relative; }

.card {
  border-bottom: 1px solid #e5e5e5; }

.header {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f9f9f9;
  font-size: 13px; }

.cardInner,
.headerInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: -12px;
  line-height: 19px; }

.cardInner {
  font-family: SFNS_bold, sans-serif;
  font-size: 11px; }

.quickSearchList .header,
.quickSearchList .card {
  padding-right: 14px;
  padding-left: 14px; }

.quickSearchList .disabledIconWrap {
  left: 14px; }

.cardColumn {
  margin-left: 12px; }

.imageWrap {
  flex: 0 0 65px; }

.detailsWrap {
  flex: 1 0;
  padding-left: 12px; }

.typeAndUnitWrap {
  width: 15%; }

.priceWrap {
  width: 15%; }

.marksWrap {
  margin-top: 7px; }

.signUpWrap {
  flex: 0 0 75px; }

.availabilityWrap {
  flex: 0 0 74px; }

.listBtnsWrap {
  flex: 0 0 63px; }

.headerAvailabilityWrap {
  flex: 0 0 149px; }

.loginToBuyWrap {
  flex: 0 0 173px;
  justify-content: flex-end; }

.headerLoginToBuyWrap {
  flex: 0 0 248px; }

.quantityAndVariantWrap {
  flex: 0 0 152px;
  display: flex; }

.disabledWrap {
  width: calc(30% + 98px); }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: 0;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100%;
  height: 100%; }

@media screen and (min-width: 1200px) {
    .header {
      font-size: 14px; }
    .cardInner,
    .headerInner {
      margin-left: -14px;
      line-height: 20px; }
    .cardInner {
      font-size: 12px; }
    .cardColumn {
      margin-left: 14px; }
    .imageWrap {
      flex: 0 0 85px; }
    .detailsWrap {
      padding-left: 14px; }
    .signUpWrap {
      margin-right: 7px; }
    .availabilityWrap {
      margin-right: 7px; }
    .listBtnsWrap {
      flex: 0 0 71px;
      margin-right: 7px; }
    .headerAvailabilityWrap {
      flex: 0 0 173px; }
    .headerLoginToBuyWrap {
      flex: 0 0 258px;
      margin-right: 7px; }
    .disabledWrap {
      width: calc(30% + 109px); } }
