/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.list {
  display: block; }

.card {
  height: 100%;
  padding-right: 14px;
  padding-left: 14px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  background: #fff; }

.header {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f9f9f9;
  font-size: 13px; }

.quickSearchResultsContainer .headerInner,
.quickSearchResultsContainer .cardInner {
  padding-right: 14px; }

.cardInner,
.headerInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: -12px;
  line-height: 19px; }

.cardInner {
  font-size: 11px;
  justify-content: unset; }

.cardColumn {
  margin-left: 12px; }

.checkboxWrap {
  flex: 0 0 35px; }

.imageWrap {
  flex: 0 0 65px; }

.detailsWrap {
  flex: 1 0;
  padding-left: 12px; }
  .detailsWrap .carbonCloud {
    font-family: SFNS, sans-serif;
    font-size: 12px;
    color: #343d46;
    margin-top: -1px; }
    .detailsWrap .carbonCloud a {
      color: #343d46; }
      .detailsWrap .carbonCloud a:hover, .detailsWrap .carbonCloud a:focus {
        color: #343d46; }

.typeAndUnitWrap {
  width: 12%; }

.priceWrap {
  width: 12%; }

.marksWrap {
  margin-top: 7px; }

.signUpWrap {
  flex: 0 0 75px; }

.availabilityWrap {
  flex: 0 0 74px; }

.headerAvailabilityWrap {
  flex: 0 0 149px; }

.loginToBuyWrap {
  flex: 0 0 173px;
  justify-content: flex-end; }

.headerLoginToBuyWrap {
  flex: 0 0 248px; }

.quantityAndVariantWrap_big, .quantityAndVariantWrap_small {
  justify-content: flex-end;
  display: flex; }
  .quantityAndVariantWrap_big > div, .quantityAndVariantWrap_small > div {
    display: flex; }

.quantityAndVariantWrap_big {
  flex: 0 0 139px;
  flex-wrap: wrap; }
  .quantityAndVariantWrap_big:not(.amountAndSum) > div {
    padding-top: 0;
    white-space: pre; }

.quantityAndVariantWrap_small {
  flex: 0 0 77px; }

.disabledWrap {
  width: calc(24% + 12px); }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: calc(59px);
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100% !important;
  height: 100% !important; }

.rowHeader .listItemInformationWrap .unitAndPriceWrap {
  margin-bottom: 0; }

.rowActionWrap {
  display: flex;
  flex: 0 0 73px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; }
  .rowActionWrap > div {
    flex: 1; }
  .rowActionWrap .removeButtonWrap {
    display: flex;
    justify-content: flex-end; }
    .rowActionWrap .removeButtonWrap svg {
      height: 1.5rem; }
  .rowActionWrap .listBtnsWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    flex: 1 1 100%; }
  .rowActionWrap .ordinalWrap {
    min-width: 34px; }

.lockButtonWrap {
  display: flex; }

.toggleSelected {
  padding: 0; }

.unitAndPriceWrap {
  display: flex; }

.listItemInformationWrap {
  display: flex;
  flex-direction: column; }
  .listItemInformationWrap_big {
    min-width: calc(23% + 12px * 2);
    width: calc(23% + 12px * 2); }
  .listItemInformationWrap_small {
    min-width: calc(17% + 12px * 2);
    width: calc(17% + 12px * 2); }
  .listItemInformationWrap_smaller {
    min-width: calc(7% + 12px * 2);
    width: calc(7% + 12px * 2); }
  .listItemInformationWrap .unitAndPriceWrap {
    gap: 14px;
    margin-bottom: 12px; }
    .listItemInformationWrap .unitAndPriceWrap > div {
      flex: 1; }
  .listItemInformationWrap .disabledWrap {
    width: 100%; }

.quantityNoInput {
  line-height: 34px; }

.previousAmount {
  width: 100%;
  justify-content: flex-end; }

.amountAndSum {
  justify-content: flex-end;
  margin-right: 20px; }

.previousTotalSum {
  margin-top: 8px; }

@media screen and (min-width: 1200px) {
    .header {
      font-size: 14px; }
    .cardInner,
    .headerInner {
      margin-left: -14px;
      line-height: 20px; }
    .cardInner {
      font-size: 12px; }
    .cardColumn {
      margin-left: 14px; }
    .imageWrap {
      flex: 0 0 85px; }
    .detailsWrap {
      padding-left: 14px; }
    .typeAndUnitWrap {
      width: 15%; }
    .priceWrap {
      width: 15%; }
    .signUpWrap {
      margin-right: 7px; }
    .availabilityWrap {
      margin-right: 7px; }
    .headerAvailabilityWrap {
      flex: 0 0 173px; }
    .headerLoginToBuyWrap {
      flex: 0 0 258px;
      margin-right: 7px; }
    .quantityAndVariantWrap_big {
      flex: 0 0 177px; }
    .quantityAndVariantWrap_small {
      flex: 0 0 94px;
      flex-wrap: wrap; }
    .disabledWrap {
      width: calc(30% + 14px); }
    .disabledIconWrap {
      left: calc(60px); }
      .listItemInformationWrap_big {
        min-width: calc(27% + 14px * 2);
        width: calc(27% + 14px * 2); }
      .listItemInformationWrap_small {
        min-width: calc(20% + 14px * 2);
        width: calc(20% + 14px * 2); }
      .listItemInformationWrap_smaller {
        min-width: calc(10% + 14px * 2);
        width: calc(10% + 14px * 2); } }

@media screen and (max-width: 1199px) {
    .quantityAndVariantWrap_big .quantityWrap > div > input[type=text] {
      width: 1rem; } }
