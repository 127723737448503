/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.small {
  cursor: pointer; }

.listAdd {
  padding-left: 7px; }

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  color: #6c737a;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  transition: background-color 200ms ease-in-out; }
  .circle svg {
    position: relative;
    height: 16px;
    width: 16px; }
  .circle.activeIcon {
    background: #343d46;
    color: #fff;
    border: 1px solid #343d46; }
    .circle.activeIcon:hover {
      background: #6c737a !important;
      border-color: #6c737a !important;
      color: #fff; }
  .circle:hover, .circle:focus, .circle:active {
    color: #000;
    background: #dedede !important; }
  @media screen and (min-width: 1200px) {
    .circle {
      height: 32px;
      width: 32px; }
      .circle svg {
        height: 18px;
        width: 18px; } }
