/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  background-repeat: repeat;
  width: 100%;
  padding: 5%;
  position: relative;
  /* If you want text inside of it */ }

.preamble {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 2rem; }

.align {
  padding-bottom: 1rem; }

.secondary-text {
  color: #fff; }

.primary-text {
  color: #23272a; }

.tint3 {
  background-color: #e5e5e5; }

.tint4 {
  background-color: #f9f9f9; }

.tint5 {
  background-color: #fafafa; }

@media screen and (min-width: 992px) {
    .preamble {
      font-size: 1.428rem;
      line-height: 1.857rem; } }

@media screen and (min-width: 768px) {
    .align {
      margin: auto;
      width: 60%; } }
