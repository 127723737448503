/*TODO - detailed colorschema will come later*/
input,
button,
select,
textarea {
  font: inherit;
  color: #23272a;
  max-width: 100%; }

input:not([type]),
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="month"],
input[type="password"],
select,
textarea {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #e5e5e5;
  border-radius: 2rem;
  box-shadow: none;
  background: white;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none; }
  input:not([type])[disabled], input:not([type])[readonly],
  input[type="text"][disabled],
  input[type="text"][readonly],
  input[type="email"][disabled],
  input[type="email"][readonly],
  input[type="tel"][disabled],
  input[type="tel"][readonly],
  input[type="search"][disabled],
  input[type="search"][readonly],
  input[type="number"][disabled],
  input[type="number"][readonly],
  input[type="month"][disabled],
  input[type="month"][readonly],
  input[type="password"][disabled],
  input[type="password"][readonly],
  select[disabled],
  select[readonly],
  textarea[disabled],
  textarea[readonly] {
    background: #fafafa;
    color: #666 !important; }
  html:global(.ios) input:not([type]), html:global(.ios)
  input[type="text"], html:global(.ios)
  input[type="email"], html:global(.ios)
  input[type="tel"], html:global(.ios)
  input[type="search"], html:global(.ios)
  input[type="number"], html:global(.ios)
  input[type="month"], html:global(.ios)
  input[type="password"], html:global(.ios)
  select, html:global(.ios)
  textarea {
    font-size: 16px; }

input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
  padding: 0.42rem; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

fieldset {
  border: 0;
  padding: 0;
  margin: 0; }
