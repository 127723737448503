/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base li {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.base {
  position: absolute;
  right: 0;
  top: 70px;
  background: white;
  width: 100%;
  max-height: calc(100vh - 70px);
  overflow: auto;
  border-top: 1px solid #f9f9f9; }
  .base .section {
    margin: 0 1rem 1rem; }
  .base .sectionProducts {
    margin-bottom: 1rem; }
    .base .sectionProducts h4 {
      margin-left: 1rem;
      margin-right: 1rem; }
  .base .noHitsSection {
    padding: 1rem;
    text-align: center; }
  .base h4 {
    color: #f66d62; }
    .base h4.searchHeading {
      padding: 0 10px; }
  .base ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -0.5rem; }
  .base li {
    display: inline-block;
    padding: 0.5rem 0.5rem; }
  .base a {
    text-decoration: none; }

.noSearchResults {
  padding: 14px; }

.active {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.05); }
  .active .base {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5; }
  @media screen and (min-width: 992px) {
    .base li {
      font-family: SFNS, sans-serif;
      font-size: 1rem;
      line-height: 2.28571rem; }
      .base li {
        padding: 0.25rem 0.5rem; } }
  @media screen and (min-width: 480px) {
      .base .section {
        flex: 1; }
    .base .sectionLists {
      display: flex; } }
  @media screen and (min-width: 768px) {
        .base h4.searchHeading {
          padding: 0 14px; } }
