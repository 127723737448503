/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  padding: 1rem; }

.tint3 {
  background-color: #e5e5e5; }

.tint4 {
  background-color: #f9f9f9; }

.tint5 {
  background-color: #fafafa; }
