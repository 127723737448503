/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.newsletter h2 {
  color: #f7594d;
  margin-bottom: 0; }

.newsletter ul {
  margin-top: 0;
  margin-bottom: 0; }

.newsletter li {
  list-style: initial;
  padding: 3px 0;
  font-size: 1.1em; }

.newsletter form {
  padding-top: 0; }

.newsletter .checkboxWrapper {
  margin-top: 10px;
  margin-bottom: 10px; }
  .newsletter .checkboxWrapper p {
    display: inline; }

.newsletter button {
  float: right;
  background: #24733B; }

@media screen and (min-width: 992px) {
    .newsletter h2 {
      font-size: 2.3rem; } }
