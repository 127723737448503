/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.tint3 {
  background-color: #e5e5e5; }

.tint4 {
  background-color: #f9f9f9; }

.tint5 {
  background-color: #fafafa; }

.base {
  padding: 1rem; }
  .base h4:first-of-type, .base h3:first-of-type {
    margin-top: 1rem; }
  .base ul, .base ol {
    padding: 0 0 0 0; }
  .base ul > li:before {
    content: " ";
    height: 0.75rem;
    width: 0.75rem;
    display: inline-block;
    background-color: rgba(247, 89, 77, 0.8);
    border-radius: 100%;
    vertical-align: baseline;
    margin: 0 0.5rem 0 0; }
  .base ol {
    counter-reset: item; }
  .base ol > li:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: rgba(247, 89, 77, 0.8);
    font-weight: bold; }
