/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit; }
  .base.ios {
    position: static; }

.cartnotempty {
  width: inherit;
  color: #fff;
  background: #f7594d; }
  .cartnotempty > svg {
    margin: 0.4rem 0.1rem 0rem 0.7rem; }
  .cartnotempty:hover {
    color: #fff;
    background: #f98b82; }

.cartempty:hover {
  color: #f7594d; }

.cartempty > svg {
  margin: 0.4rem 0.1rem 0rem 0.5rem; }

.cartIcon {
  position: relative;
  bottom: -6px; }

.button {
  composes: button from './base.scss';
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center; }
  .button > svg {
    font-size: 35px; }
  .button[data-highlight] {
    -webkit-animation: highlight 500ms ease-in-out 200ms 2;
            animation: highlight 500ms ease-in-out 200ms 2; }

.orderRows {
  display: none; }

.button:not([data-count^='0'])[data-count]:before {
  display: block;
  content: attr(data-count);
  position: absolute;
  top: calc(45% - 17.5px - 0.75em);
  left: calc(45% + 1.125em);
  right: -1.6rem;
  height: 2rem;
  width: 2rem;
  color: white;
  padding-top: 5px;
  font-size: 0.85714rem;
  font-weight: bold;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: color 200ms ease-out, border-color 200ms ease-out; }

.button:not([data-total^='0'])[data-total]:after {
  display: none; }

@-webkit-keyframes highlight {
  0% {
    box-shadow: inset 0 0 0 70px rgba(47, 55, 63, 0); }
  50% {
    box-shadow: inset 0 0 0 70px #2f373f; }
  100% {
    box-shadow: inset 0 0 0 70px rgba(47, 55, 63, 0); } }

@keyframes highlight {
  0% {
    box-shadow: inset 0 0 0 70px rgba(47, 55, 63, 0); }
  50% {
    box-shadow: inset 0 0 0 70px #2f373f; }
  100% {
    box-shadow: inset 0 0 0 70px rgba(47, 55, 63, 0); } }

@media screen and (min-width: 992px) {
    .cartnotempty {
      max-width: 11rem;
      min-width: 8rem; }
      .cartnotempty > svg {
        margin: 0.4rem 0.8rem 0 0.3rem; }
    .orderRows {
      display: block;
      position: absolute;
      right: 1.5rem;
      top: 10px;
      font-size: 0.85714rem;
      font-weight: bold; }
    .button:not([data-count^='0'])[data-count]:before {
      display: none; }
    .button:not([data-total^='0'])[data-total]:after {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      content: attr(data-total);
      position: relative;
      bottom: -10px;
      right: 1rem;
      display: block;
      line-height: 1;
      font-size: 1rem; } }
