/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.main {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
  flex: 1; }
  .main:hover .info {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .main .link {
    text-decoration: underline;
    color: #f7594d;
    font-size: 12px;
    line-height: 115%;
    letter-spacing: 0.24px; }
  .main .info {
    position: relative;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    flex: 1;
    aspect-ratio: 1;
    max-width: 26px;
    transition: .3s;
    transition-timing-function: ease-out;
    display: flex;
    justify-content: center;
    align-items: center; }
    .main .info img {
      max-width: 75% !important;
      aspect-ratio: 1;
      -o-object-fit: contain;
         object-fit: contain; }
    .main .info.isList {
      min-width: 26px; }
    .main .info .counter {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #f7594d;
      color: white;
      text-align: center;
      line-height: 13px;
      position: absolute;
      right: -3px;
      bottom: -3px;
      font-size: 8.125px;
      font-style: normal;
      font-family: SFNS_bold, sans-serif;
      letter-spacing: -0.163px; }
