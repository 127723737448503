/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base > div {
  border-bottom: 1px #e5e5e5 solid;
  position: relative; }

.accordionItemHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem; }
  .accordionItemHeader.active {
    background-color: #e5e5e5; }
  .accordionItemHeader + div main {
    padding-top: 1.25rem; }
  @media screen and (min-width: 992px) {
    .base > div:after {
      position: absolute;
      content: "";
      height: 0;
      border-top: 1px #e5e5e5 solid;
      bottom: -1px;
      left: -100%;
      right: -100%; }
    .base > div:first-of-type {
      border-top: 1px #e5e5e5 solid;
      position: relative; }
      .base > div:first-of-type:before {
        position: absolute;
        content: "";
        height: 0;
        border-top: 1px #e5e5e5 solid;
        top: -1px;
        left: -100%;
        right: -100%; }
    .accordionItemHeader {
      font-size: 2.28571rem;
      padding-left: 0;
      padding-top: 40px;
      padding-bottom: 40px; }
      .accordionItemHeader :hover {
        cursor: pointer; }
      .accordionItemHeader svg {
        width: 0.7em;
        height: 0.7em; }
        .accordionItemHeader svg :hover {
          cursor: pointer; }
      .accordionItemHeader.active {
        background-color: inherit; } }
