/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
@-webkit-keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }
@keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }

.base {
  resize: none;
  transition: all ease 500ms; }
  .base:hover:not(:focus) {
    border-color: #6c737a;
    background-color: rgba(0, 0, 0, 0.05); }
  .base:invalid:not(:focus) {
    border-color: #b0406f;
    outline: 1px solid #b0406f; }
  .base:focus {
    -webkit-animation-name: shrink;
            animation-name: shrink;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; }
  .base.disabled, .base:disabled {
    background: #e7e7e7 !important; }

.inputLabel {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px !important;
  display: flex; }
  .inputLabel span {
    margin-left: 4px; }

.base[required]:invalid:not(:focus) + .requiredField {
  display: flex; }

.requiredField {
  color: #b0406f;
  display: none;
  line-height: 20px;
  padding: 2px;
  margin-top: 8px;
  font-size: 14px;
  vertical-align: middle; }
  .requiredField svg {
    width: 20px;
    height: 20px;
    margin-right: 0.35714rem;
    vertical-align: text-top; }
