/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base.inline {
  position: relative; }

.productMarkWrapper {
  position: absolute;
  z-index: 1; }

.productMarkWrapperList {
  max-width: 120px;
  position: absolute;
  z-index: 1; }

.productMark {
  background-color: #343d46;
  padding: 0rem 0.4rem;
  color: #fff;
  font-size: 0.85714rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0.1rem;
  position: relative;
  display: inline-block;
  top: -2rem; }
  .productMark.primary {
    background-color: #f7594d;
    position: relative; }
  .productMark.secondary {
    background-color: #343d46;
    position: relative; }
  .productMark.campaign {
    background-color: #f7594d;
    border-radius: 100%;
    text-align: center;
    white-space: nowrap;
    height: 4rem;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    padding: 1.2rem 0.5rem;
    width: 4rem;
    position: relative; }
  @media screen and (min-width: 992px) {
    .productMark {
      padding: 0rem 0.5rem; } }
  @media screen and (min-width: 768px) {
    .productMark {
      top: 0rem; } }
