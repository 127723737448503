/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.overviewBox {
  background-color: white; }

.header {
  margin-bottom: 1rem;
  font-size: 1.143rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: #0d0f11; }

.preamble {
  color: #0d0f11;
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px; }

.contentBox {
  display: flex;
  flex-direction: column; }

.priceExplanation {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  color: #6c737a; }

@media screen and (min-width: 992px) {
    .contentBox {
      flex-direction: row; } }
