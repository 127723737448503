/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.shippingDateInfo td {
  text-align: center;
  vertical-align: middle; }

.shippingDateInfo_Layout2 {
  padding: 1rem; }

.shippingTime {
  list-style: none;
  padding: 0; }
  .shippingTime > li {
    margin-bottom: 1rem; }

.error {
  color: #ef7172; }

.headerWrapper {
  display: block; }
  .headerWrapper .header {
    width: 100%;
    text-align: center; }

.headerLayout2 {
  min-width: none; }
  .headerLayout2 .header {
    min-width: none; }

.deliveryHeader {
  background: #fafafa;
  display: block;
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0.75rem 2rem;
  border-bottom: 1px solid #e5e5e5; }
  .deliveryHeader h4,
  .deliveryHeader h5 {
    margin: 0; }

.positionRelative {
  position: relative; }

.myOrdersLink {
  margin: 0.5rem 0;
  display: block;
  color: #f8584c;
  position: relative;
  bottom: -1rem;
  text-transform: uppercase;
  text-align: left; }
  .myOrdersLink svg {
    height: 14px;
    width: 14px; }

.ownWrapper {
  border: 1px solid #dee1e4;
  display: table;
  margin: auto; }

.dropdownWrapper {
  width: 100%;
  display: block;
  padding: 1rem 0; }
  .dropdownWrapper > * {
    width: 100%; }
  .dropdownWrapper .datePicker {
    margin: auto;
    padding: 0rem 1rem; }

.datePicker button {
  background: white;
  color: inherit;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  margin: auto;
  display: block;
  padding: inherit; }
  .datePicker button:hover {
    background-color: white !important;
    color: initial; }
  .datePicker button svg {
    color: black; }

.pushup {
  min-width: none;
  top: 0; }

.helpButtonWrapper {
  position: relative;
  top: -3rem;
  height: 0;
  display: inline-block;
  width: 100%; }
  .helpButtonWrapper .helpSectionButtonWrapper {
    display: block;
    margin: 1rem; }
  .helpButtonWrapper .tooltipWrapper {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 1.5rem;
    z-index: 100009; }
    .helpButtonWrapper .tooltipWrapper .tooltip {
      position: relative;
      display: block;
      padding: 2rem; }
    .helpButtonWrapper .tooltipWrapper .helpSelected {
      width: 2rem;
      height: 2.4rem;
      background: #343d46;
      color: white;
      font-size: 1rem; }
    .helpButtonWrapper .tooltipWrapper .helpCurrentOrders {
      width: 2rem;
      height: 2.4rem;
      font-size: 1rem;
      border: 1px solid #343d46;
      background: none;
      color: #000; }
      .helpButtonWrapper .tooltipWrapper .helpCurrentOrders:hover {
        background: none !important; }
    .helpButtonWrapper .tooltipWrapper .helpButtonWrapper {
      display: block;
      margin: 1rem 0; }
  .helpButtonWrapper .helpButton {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0; }

.closeDatePicker {
  padding: 0.75rem;
  display: inline-flex;
  position: absolute;
  right: 0rem;
  top: 0rem; }
  .closeDatePicker:hover {
    cursor: pointer;
    background: rgba(52, 61, 70, 0.3);
    transition: 0.3s; }
  @media print {
  .datePicker {
    display: none; } }
  @media screen and (min-width: 992px) {
      .helpButtonWrapper .tooltipWrapper {
        left: 11rem; } }
