/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.headers {
  display: flex;
  padding: 2rem 0; }
  .headers > * {
    font-weight: bold; }

.formWrapper {
  display: flex; }
  .formWrapper .calendar {
    cursor: pointer;
    padding: 7px 10px; }
    .formWrapper .calendar svg {
      height: 1.3em; }

.dateColumn {
  flex: 1;
  display: flex;
  padding-right: 1rem; }
  .dateColumn input {
    width: auto !important;
    max-width: 120px; }

.customerColumn {
  display: flex;
  flex: 2;
  padding-right: 1rem; }
  .customerColumn input {
    width: auto !important; }
  .customerColumn .customerValue {
    margin-left: 10px; }

.formWrapperMobile {
  display: flex;
  flex-direction: column; }
  .formWrapperMobile input {
    width: 100%; }
  .formWrapperMobile .calendar {
    cursor: pointer; }
