@font-face {
  font-family: 'SFNS';
  src: url("../fonts/Apercu-Regular.woff2") format("woff2"), url("../fonts/Apercu-Regular.woff") format("woff"), url("../fonts/Apercu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFNS_thin';
  src: url("../fonts/Apercu-Light.woff2") format("woff2"), url("../fonts/Apercu-Light.woff") format("woff"), url("../fonts/Apercu-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFNS_thin_italic';
  src: url("../fonts/apercu-light-italic-pro.woff2") format("woff2"), url("../fonts/apercu-light-italic-pro.woff") format("woff"), url("../fonts/apercu-light-italic-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFNS_bold';
  src: url("../fonts/Apercu-Bold.woff2") format("woff2"), url("../fonts/Apercu-Bold.woff") format("woff"), url("../fonts/Apercu-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFNS_medium';
  src: url("../fonts/Apercu-Medium.woff2") format("woff2"), url("../fonts/Apercu-Medium.woff") format("woff"), url("../fonts/Apercu-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SFNS_medium_italic';
  src: url("../fonts/Apercu-Medium-Italic.woff2") format("woff2"), url("../fonts/Apercu-Medium-Italic.woff") format("woff"), url("../fonts/Apercu-Medium-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*TODO - detailed colorschema will come later*/
html {
  font-family: SFNS, sans-serif;
  font-size: 0.875em;
  line-height: 1.5;
  color: #23272a; }
  html ::-moz-selection {
    background: #343d46;
    color: white; }
  html ::selection {
    background: #343d46;
    color: white; }

h1, h2, h3, h4, h5, .ingress, .blurbhead, .blurbbody, .body, .text1, .text2, .text3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit; }

h1 {
  font-family: SFNS, sans-serif;
  font-size: 2.5rem;
  line-height: 2.6rem; }

h2 {
  font-family: SFNS, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

h3 {
  font-family: SFNS, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

h4 {
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

h5 {
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-size: 0.8rem;
  line-height: 0.8rem; }

.ingress {
  margin-bottom: 1rem;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.3rem;
  line-height: 1.3rem; }

.blurbhead {
  margin-bottom: 1rem;
  font-family: SFNS, sans-serif;
  font-size: 1.3rem;
  line-height: 1.3rem; }

.blurbbody {
  margin-bottom: 1rem;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.3rem;
  line-height: 1.3rem; }

.body {
  margin-bottom: 1rem;
  font-family: SFNS, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.text1 {
  margin-bottom: 1rem;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.text2 {
  margin-bottom: 1rem;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.text3 {
  margin-bottom: 1rem;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

a {
  color: #23272a; }
  a:hover, a:focus {
    text-decoration: underline;
    color: #f7594d; }
  @media screen and (min-width: 480px) {
    h1 {
      margin-top: 2.2rem; }
    h2 {
      margin-top: 2.2rem; }
    h3 {
      margin-top: 2.2rem; }
    h4 {
      margin-top: 2.2rem; }
    h5 {
      margin-top: 2.2rem; }
    .ingress {
      margin-top: 2.2rem; }
    .blurbhead {
      margin-top: 2.2rem; }
    .blurbbody {
      margin-top: 2.2rem; }
    .body {
      margin-top: 2.2rem; }
    .text1 {
      margin-top: 2.2rem; }
    .text2 {
      margin-top: 2.2rem; }
    .text3 {
      margin-top: 2.2rem; } }
  @media screen and (min-width: 768px) {
    h1 {
      font-family: SFNS, sans-serif;
      font-size: 3rem;
      line-height: 1.15; }
    h2 {
      font-family: SFNS, sans-serif;
      font-size: 1.7rem;
      line-height: 1.15; }
    h3 {
      font-family: SFNS, sans-serif;
      font-size: 1.4rem;
      line-height: 1.15; }
    h4 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.1rem;
      line-height: 1.42857rem; }
    h5 {
      font-family: SFNS_bold, sans-serif;
      font-size: 0.8rem;
      line-height: 1.42857rem; }
    .ingress {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.3rem;
      line-height: 2.28571rem; }
    .blurbhead {
      font-family: SFNS, sans-serif;
      font-size: 1.3rem;
      line-height: 1.85714rem; }
    .blurbbody {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.3rem;
      line-height: 1.85714rem; }
    .body {
      font-family: SFNS, sans-serif;
      font-size: 1.1rem;
      line-height: 1.71429rem; }
    .text1 {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.1rem;
      line-height: 2.28571rem; }
    .text2 {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.1rem;
      line-height: inherit; }
    .text3 {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.1rem;
      line-height: inherit; } }
  @media screen and (min-width: 992px) {
    h1 {
      font-family: SFNS, sans-serif;
      font-size: 2.85714rem;
      line-height: 1.15; }
    h2 {
      font-family: SFNS, sans-serif;
      font-size: 2.28571rem;
      line-height: 1.15;
      margin-top: 2rem; }
    h3 {
      font-family: SFNS, sans-serif;
      font-size: 1.71429rem;
      line-height: 1.15; }
    h4 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.42857rem; }
    h5 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1rem;
      line-height: 1.42857rem; }
    .ingress {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.71429rem;
      line-height: 2.28571rem; }
    .blurbhead {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.42857rem;
      line-height: 1.85714rem; }
    .blurbbody {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.42857rem;
      line-height: 1.85714rem; }
    .body {
      font-family: SFNS, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.71429rem; }
    .text1 {
      font-family: SFNS, sans-serif;
      font-size: 1rem;
      line-height: 2.28571rem; }
    .text2 {
      font-family: SFNS_bold, sans-serif;
      font-size: 0.85714rem;
      line-height: inherit; }
    .text3 {
      font-family: SFNS, sans-serif;
      font-size: 0.85714rem;
      line-height: inherit; } }
