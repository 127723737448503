/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  max-height: 98vh;
  max-width: 98vh;
  overflow: visible;
  left: 50%;
  background-color: white;
  height: auto;
  min-height: 70px;
  opacity: 0;
  top: 0;
  -webkit-transform: translate3d(-50%, 20%, 0) scale(0.95);
          transform: translate3d(-50%, 20%, 0) scale(0.95);
  transition: opacity 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, opacity 200ms ease;
  transition: transform 200ms ease, opacity 200ms ease, -webkit-transform 200ms ease; }
  .base.innerModal.active {
    background: #fff;
    z-index: 100101; }
  .base.active {
    -webkit-transform: translate3d(-50%, 0, 0) scale(1);
            transform: translate3d(-50%, 0, 0) scale(1);
    opacity: 1; }
  .base .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 16px; }
  .base.fadeModal {
    max-width: 98vw;
    width: 100%; }
    .base.fadeModal button {
      text-transform: uppercase; }
    .base.fadeModal > div > div > input + div {
      max-width: 100%;
      margin-top: .5rem;
      flex-direction: column; }
      .base.fadeModal > div > div > input + div > div {
        width: 100%;
        margin-top: 1rem; }
        .base.fadeModal > div > div > input + div > div + div button:last-of-type {
          background: #fff !important;
          color: #0d0f11;
          border: 1px solid #0d0f11; }
          .base.fadeModal > div > div > input + div > div + div button:last-of-type:hover {
            border: solid 1px #dedede !important;
            background: #dedede !important; }

.innerModalBackground {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(73, 81, 89, 0.5);
  z-index: 100100; }

.title {
  margin: 0 0 1rem 0;
  display: inline-block; }
  .title.reserveSpaceForCloseButton {
    margin-right: 50px; }

.iconWrapper {
  text-align: center;
  font-size: 3rem; }

.innerContainer {
  position: relative;
  padding: 10px; }
  .innerContainer .reserveSpaceForCloseButton {
    margin-right: 30px; }

.checkoutBase {
  position: fixed;
  max-height: 98vh;
  max-width: 88vh;
  overflow: auto;
  left: 50%;
  background-color: #F7594D;
  height: auto;
  min-height: 70px;
  top: 0;
  -webkit-transform: translate3d(-50%, 20%, 0) scale(0.95);
          transform: translate3d(-50%, 20%, 0) scale(0.95);
  transition: opacity 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, opacity 200ms ease;
  transition: transform 200ms ease, opacity 200ms ease, -webkit-transform 200ms ease; }

.checkoutInnerContainer {
  position: relative;
  padding: 10px 0; }
  .checkoutInnerContainer .reserveSpaceForCloseButton {
    margin-right: 30px; }

.checkoutTitleWarning {
  margin: 0 0 1rem 0;
  display: inline-block;
  color: #fff;
  align-items: center;
  display: flex; }
  .checkoutTitleWarning > span {
    display: block;
    padding-right: .5rem; }
  .checkoutTitleWarning.reserveSpaceForCloseButton {
    margin-right: 50px; }

.image {
  float: left;
  height: 70px;
  width: 67px;
  margin: 0 .5rem; }

@media screen and (min-width: 992px) {
    .base {
      max-height: 70%;
      max-width: 95%;
      top: 15%;
      overflow-y: auto; }
      .base.fadeModal {
        max-width: 700px; }
    .innerContainer {
      padding: 2rem; }
    .checkoutInnerContainer {
      padding: 2rem 4rem; }
      .checkoutTitleWarning > span {
        padding-right: 0; }
    .image {
      height: 140px;
      width: 134px;
      margin-right: 1.5rem; } }

@media screen and (min-width: 768px) {
      .base.fadeModal {
        max-width: 600px; }
        .base.fadeModal button {
          display: inline-block;
          padding: .5rem 1.5rem; }
        .base.fadeModal > div > div > input + div > div {
          width: auto;
          flex: 0 1 auto; }
        .base.fadeModal > div > div > input + div {
          max-width: 80%;
          margin-top: 1.5rem;
          flex-direction: row; } }
