/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.alert {
  flex: 0 0 100%;
  line-height: inherit;
  font-size: 0.7rem;
  color: #343d46; }
