/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.list {
  display: block; }
  .list .card:last-of-type > div {
    border-bottom: 1px solid #f7594d; }
  .list .card:last-of-type > div.recommendationsPanel {
    border-bottom: 1px solid #e5e5e5; }

.card {
  position: relative; }
  .card > div {
    border-bottom: 1px solid #e5e5e5; }

.header {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f9f9f9;
  font-size: 13px; }

.cardInner,
.headerInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 19px; }

.cardInner {
  font-family: SFNS, sans-serif;
  font-size: 12px;
  color: #343d46;
  line-height: 20px; }

.quickSearchList .header,
.quickSearchList .card {
  padding-right: 14px;
  padding-left: 14px; }

.quickSearchList .disabledIconWrap {
  left: 14px; }

.cardColumn {
  margin-left: 12px; }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }
  .row .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: .25rem; }
    .row .column:last-of-type {
      background: #f9f9f9;
      align-items: flex-end;
      padding: .5rem;
      min-width: 213px; }

.top {
  margin-bottom: auto; }

.bottom {
  margin-top: auto; }

.imageWrap {
  padding: 0;
  max-width: 100px; }
  [data-list-type='viewLayout_Compact'] .imageWrap {
    display: none; }

.productInformationWrap {
  min-width: 220px; }
  .productInformationWrap h2 {
    line-height: 20px;
    margin-bottom: 3px; }
  .productInformationWrap div:last-of-type {
    margin-bottom: 0; }
  .productInformationWrap > div:not(:empty) {
    margin-top: 0.143rem; }

.detailsWrap {
  flex: 1 0;
  padding-left: 12px; }

.marksWrap {
  margin-top: 0.571rem; }

.listBtnsWrap {
  margin-top: auto;
  flex: 0 0 63px; }

.availabilityWrap > div > div:first-of-type + div:before {
  content: "";
  border-left: 1px solid #e7e7e7;
  width: 0;
  height: 1rem;
  display: inline;
  margin-right: 5px; }

.headerAvailabilityWrap {
  flex: 0 0 149px; }

.loginToBuyWrap {
  flex: 0 0 173px;
  justify-content: flex-end; }

.headerLoginToBuyWrap {
  flex: 0 0 248px; }

.quantityAndVariantWrap {
  flex: 0 0 152px;
  display: flex; }

.disabledWrap {
  flex: 3 !important; }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: 0;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }
  [data-list-type='viewLayout_Compact'] .disabledIconWrap {
    position: relative;
    top: 0; }

.disabledIcon {
  color: #f7594d;
  width: 100%;
  height: 100%; }

.recommendationsPanelProductsList {
  padding: 12px; }

@media screen and (min-width: 1200px) {
    .header {
      font-size: 14px; }
    .cardInner,
    .headerInner {
      line-height: 20px; }
    .cardColumn {
      margin-left: 14px; }
    .detailsWrap {
      padding-left: 14px; }
    .listBtnsWrap {
      flex: 0 0 71px;
      margin-right: 7px; }
    .headerAvailabilityWrap {
      flex: 0 0 173px; }
    .headerLoginToBuyWrap {
      flex: 0 0 258px;
      margin-right: 7px; } }
