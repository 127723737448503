/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
  min-width: 250px; }

.fadeDropDown {
  overflow: visible;
  color: #000; }
  .fadeDropDown:hover {
    color: #000;
    text-decoration: underline; }
  .fadeDropDown > span > svg {
    font-size: inherit;
    padding: 0; }
  .fadeDropDown .sortList {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    z-index: 1000;
    background: #fff;
    right: -.75rem;
    top: -6px;
    display: none;
    border: 1px solid #dee1e4;
    border-top: none;
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms linear;
    transition-delay: 100ms; }
    .fadeDropDown .sortList > li {
      display: block; }
    .fadeDropDown .sortList li {
      border-top: 1px solid #dee1e4; }
  .fadeDropDown input:checked + .sortList {
    display: block;
    opacity: 1;
    overflow: visible; }
  .fadeDropDown .selectedValue {
    font-family: SFNS, sans-serif;
    font-size: 1.286rem;
    cursor: pointer; }
    .fadeDropDown .selectedValue svg {
      height: 1.25rem; }
  .fadeDropDown .sortOptionButton {
    border-radius: 0;
    width: 100%;
    position: relative;
    padding: 0;
    text-align: left; }
    .fadeDropDown .sortOptionButton:hover {
      background: #e7e7e7 !important; }
    .fadeDropDown .sortOptionButton label {
      display: block;
      padding: 0.5rem 2.5rem;
      display: block;
      cursor: pointer; }
      .fadeDropDown .sortOptionButton label svg:last-of-type {
        height: 1.25rem;
        margin: 2px;
        position: absolute;
        right: .5rem; }
  .fadeDropDown .selectedSortOptionCheck {
    position: absolute;
    left: 2.5rem;
    color: #343d46; }
  @media screen and (min-width: 992px) {
      .fadeDropDown .sortList {
        margin-right: -2px;
        top: -1px; } }
