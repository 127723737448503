/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.base {
  padding: 0; }
  .base h1 {
    word-wrap: break-word; }

.productDescription {
  background: white;
  padding: 0.8rem; }

.title {
  text-align: left;
  margin-bottom: 2rem; }

.flexWrapper {
  display: flex; }
  .flexWrapper__left {
    flex: 1;
    order: 1; }
  .flexWrapper__right {
    flex: 1;
    order: 2; }
    .flexWrapper__right .vendorWrapper {
      display: inline-block; }
      .flexWrapper__right .vendorWrapper img {
        height: 5rem;
        margin-right: 1rem; }

.indent {
  color: #c2c5c8;
  padding-left: 1rem; }

.tableRow td, .tableSubRow td {
  padding: 0.5rem; }

.tableRow:nth-child(odd), .tableSubRow:nth-child(odd) {
  background: #e5e5e5; }

.tableRow .right, .tableSubRow .right {
  text-align: right; }

.tableSubRow td {
  padding-left: 2rem; }

.tableHeading {
  color: #999;
  text-transform: uppercase; }
  .tableHeading th:last-child {
    text-align: right; }
  @media screen and (min-width: 480px) {
    .title {
      margin-top: 2.2rem; } }
  @media screen and (min-width: 992px) {
    .title {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
    .productDescription {
      padding: 0rem; }
    .title {
      margin-top: 2rem; } }
