/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }

.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }

.paragraph {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.hero {
  display: flex;
  flex-direction: column; }
  .hero main {
    padding: 0 20px; }
  .hero .aspectRatio {
    width: 100%;
    aspect-ratio: 3 / 2; }
    .hero .aspectRatio img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: 50% 50%;
         object-position: 50% 50%; }
  .hero .header {
    color: #0d0f11;
    width: 100%; }
    .hero .header h1 {
      margin-top: 40px;
      margin-bottom: 40px; }

.content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .content main {
    padding: 0 20px;
    padding-bottom: 40px; }
    .content main .mainWrapper {
      color: #0d0f11;
      width: 100%; }
      .content main .mainWrapper .leftColumn {
        width: 100%;
        margin-bottom: 40px; }
        .content main .mainWrapper .leftColumn .preambleColumn {
          margin-bottom: 80px; }
      .content main .mainWrapper .centerColumn {
        width: 100%;
        clear: both; }
        .content main .mainWrapper .centerColumn h2 {
          margin-top: 0; }
        .content main .mainWrapper .centerColumn .mainContent {
          margin: 40px 0; }
          .content main .mainWrapper .centerColumn .mainContent > div {
            display: flex;
            flex-direction: column;
            gap: 40px; }
            .content main .mainWrapper .centerColumn .mainContent > div > div {
              margin: 0; }
      .content main .mainWrapper .rightColumn {
        width: 100%; }
  .content .byLine {
    color: rgba(13, 15, 17, 0.6); }
  @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; }
      .hero main {
        padding: 0 40px; }
        .hero .header h1 {
          margin-top: 80px;
          margin-bottom: 80px; }
      .content main {
        padding: 0 40px;
        padding-bottom: 80px; }
          .content main .mainWrapper .leftColumn {
            margin-bottom: 0; }
          .content main .mainWrapper .leftColumn .byLineColumn {
            float: left;
            width: 50%; }
            .content main .mainWrapper .leftColumn .preambleColumn {
              float: right;
              width: 50%;
              margin-bottom: 0; }
          .content main .mainWrapper .centerColumn {
            padding-top: 80px; } }
  @media screen and (min-width: 1200px) and (max-height: 800px) {
  .hero {
    flex-direction: column-reverse; }
    .content main {
      padding-top: 80px !important; } }
  @media screen and (min-width: 1200px) {
      .hero main {
        padding: 0 1rem; }
      .hero .aspectRatio {
        height: 480px; }
        .hero .header h1 {
          padding-left: calc(25% + 24px);
          padding-right: calc(25% + 24px); }
      .content main {
        padding: 0 1rem; }
          .content main .mainWrapper .leftColumn {
            float: left;
            width: 25%;
            padding-right: 24px; }
          .content main .mainWrapper .leftColumn .byLineColumn {
            width: 100% !important; }
          .content main .mainWrapper .centerColumn {
            clear: none;
            float: left;
            width: 50%;
            margin-bottom: 8rem;
            padding-top: 0;
            padding: 0 24px; }
          .content main .mainWrapper .rightColumn {
            float: left;
            width: 25%;
            padding-left: 24px; }
    .content .preambleDesktop {
      margin-bottom: 84px; } }
