/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.overlayCheckout {
  z-index: 10011; }

.overlayCheckoutSpinner {
  position: fixed;
  left: 45%;
  top: 45%; }

.base {
  background: #eff0f2;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0; }
  .base h1 {
    margin: 0;
    padding: 0 1rem; }
  .base form {
    margin: 0.625em auto;
    max-width: 50em;
    padding-top: 0; }

.limitWarning ul > li:before {
  content: ' ';
  height: 0.75rem;
  width: 0.75rem;
  display: inline-block;
  background-color: rgba(247, 89, 77, 0.8);
  border-radius: 100%;
  vertical-align: baseline;
  margin: 0 0.5rem 0 0; }

.deliveryAndOrderSummary {
  display: flex; }

.deliveryDatesWrap {
  border: 1px solid #dee1e4;
  margin-right: 1rem; }

.orderSummaryWrap {
  display: flex;
  flex-direction: column;
  width: 100%; }

.summaryTitle {
  margin-top: 0; }

.halfborder:after {
  content: '';
  height: 1px;
  display: block;
  padding: 0.5rem 0;
  margin: auto;
  border-bottom: 1px solid #dee1e4; }

.carbonFootprintBanner {
  color: #24733b;
  line-height: 16.1px;
  padding: 0px 14px 0px 14px !important; }
  .carbonFootprintBanner .banner {
    display: flex;
    flex-direction: row; }
  .carbonFootprintBanner .svg {
    flex: 1 0;
    margin-right: 8px; }
    .carbonFootprintBanner .svg svg {
      width: 37px;
      height: 34px; }
    .carbonFootprintBanner .svg stroke {
      color: #24733B; }
  .carbonFootprintBanner a {
    color: #24733b;
    display: inline-flex; }
  .carbonFootprintBanner p {
    margin-bottom: 0px !important; }
  .carbonFootprintBanner .row {
    margin-bottom: 5px !important;
    font-family: SFNS_medium, sans-serif !important; }
  .carbonFootprintBanner .content {
    padding: 0 !important;
    flex: 1 0;
    display: flex;
    flex-direction: column; }

.orderinfo {
  display: flex;
  flex-wrap: wrap; }

.inputbase {
  flex: 1;
  width: 45%;
  background: #fafafa;
  padding: 1rem;
  margin-right: 1rem; }
  .inputbase label {
    display: block; }
    .inputbase label span {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-weight: bold; }
  .inputbase input {
    width: 100%;
    margin-bottom: 1rem; }

.orderlbl {
  width: 100%; }

.markinglbl {
  width: 100%; }

.checkout__contentWrapper .row {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start; }
  .checkout__contentWrapper .row .left {
    flex: 0 1 auto; }
  .checkout__contentWrapper .row .right {
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-left: 0.5rem;
    display: inherit; }
  .checkout__contentWrapper .row .underlined {
    text-decoration: underline; }
  .checkout__contentWrapper .row .red {
    color: #ef7172; }

.checkout__contentWrapper .summary {
  margin: 0; }

.checkout__contentWrapper .deliveryAndOrderSummaryWrap {
  width: 100%;
  height: 100%;
  margin-top: 2rem; }
  .checkout__contentWrapper .deliveryAndOrderSummaryWrap .header {
    border-bottom: 1px solid #dee1e4;
    background: #fafafa;
    font-weight: bold;
    padding: 1rem; }
  .checkout__contentWrapper .deliveryAndOrderSummaryWrap .deliveryInfo p {
    margin-bottom: 0; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap .deliveryInfo p:last-of-type {
      display: inline-block;
      margin: 1rem 0; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap .deliveryInfo p:after {
      content: ':';
      padding-right: 0.25rem; }
  .checkout__contentWrapper .deliveryAndOrderSummaryWrap .content {
    padding: 1rem;
    background: white; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap .content .bigger {
      font-size: 1.2rem; }
  .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile {
    margin: 0;
    padding: 0 0 1rem;
    border: none; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .completePurchase {
      padding: 0 0.5rem; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .orderinfo {
      padding: 1rem 0;
      background: #fafafa; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .header {
      padding: 1.5rem 1rem 0.5rem;
      border-top: 1px solid #dee1e4;
      border-bottom: none;
      position: relative; }
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .header button {
        position: absolute;
        right: 1rem; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .content {
      background: #fafafa; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-bottom: 0; }
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo p,
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo b {
        display: block;
        margin: 0 0 1rem;
        padding: 0;
        white-space: nowrap; }
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo p:after {
        content: ''; }
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo b {
        text-align: right; }
      .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .deliveryInfo .left {
        text-align: left; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile.closed .header,
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile.closed .deliveryInfo {
      background-color: white; }
    .checkout__contentWrapper .deliveryAndOrderSummaryWrap.orderInfoMobile .inputbase {
      background: #fafafa;
      border-top: 1px solid #dee1e4;
      border-bottom: 1px solid #dee1e4;
      width: 100%; }

.topWrapper {
  display: flex;
  position: relative;
  height: 2.643rem;
  margin-bottom: 0.5rem; }
  .topWrapper button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.85714rem; }
  .topWrapper > *:not(:last-child) {
    margin-right: 1rem; }
  .topWrapper .right {
    right: 0;
    top: 0;
    position: absolute;
    width: 15rem; }
  .topWrapper > div {
    flex: 3;
    position: relative; }
    .topWrapper > div > *:not(:last-child) {
      margin-right: 1rem; }
    .topWrapper > div + div {
      flex: 1; }

.generalSpinner {
  height: 2rem;
  width: 2rem;
  color: #f7594d;
  text-align: center; }

.fullCartWrapper {
  position: relative;
  transition: 0.3s;
  margin-top: 1rem; }
  .fullCartWrapper .spinner {
    width: 3rem;
    height: 3rem;
    margin: auto;
    display: block;
    position: absolute;
    top: 2rem;
    left: 50%;
    color: #f7594d;
    z-index: 200; }
  .fullCartWrapper .loadingWrapper {
    position: relative;
    -webkit-filter: blur(3px);
            filter: blur(3px);
    z-index: 149; }
    .fullCartWrapper .loadingWrapper .loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #e5e5e5;
      opacity: 0.3;
      z-index: 150; }

.empty {
  width: 100% !important; }

.topForm {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.part {
  background: white;
  padding: 1rem;
  position: relative;
  margin-top: 0rem !important; }
  .part.promotions {
    padding: 1rem 3rem;
    border-top: 2px solid #f7594d;
    border-bottom: 2px solid #f7594d; }
  .part:before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translateX(-100%);
    border-radius: 2px;
    background: white; }
  .part.promotions:before {
    width: 28px;
    height: 28px;
    border-right: 2px solid #f7594d;
    border-bottom: 2px solid #f7594d; }

.emptyCartCheckout {
  flex-direction: column; }
  .emptyCartCheckout.mobileTop h1 {
    margin-left: 0; }

.addtocart {
  display: initial; }
  .addtocart button {
    margin-left: 1rem; }
    .addtocart button:before {
      color: #fff; }

.content {
  padding: 2rem 0; }

.subHeading {
  margin-bottom: 2rem;
  display: block; }

.subHeadingObserve {
  color: #f7594d; }

.requiredField {
  color: #ff0000;
  font-weight: bold; }

.alignButton {
  text-align: center; }

.alignButton button {
  width: 200px;
  color: black;
  background: white; }

.childTitle {
  color: #d1cfcd; }

.whiteBackground {
  background-color: white;
  margin-bottom: 2.5rem;
  padding: 0rem 1rem; }

.whiteBackground ul {
  padding-bottom: 5px; }

.userNotVerifiedMessage {
  display: block;
  padding: 1rem 0;
  color: #000;
  font-size: 1rem; }

@media screen and (min-width: 992px) {
  .checkout__contentWrapper {
    display: flex;
    flex-direction: column; }
    .topForm {
      padding: 0 1rem 1rem !important; }
    .userNotVerifiedMessage {
      font-size: 1.35rem; } }

@media print {
  .checkout__contentWrapper svg {
    max-width: 10px !important;
    max-height: 10px !important; } }

@media screen and (min-width: 768px) {
    .part {
      margin-bottom: 3rem !important; }
    .part {
      padding: 2rem; }
      .part.promotions {
        padding: 1rem 6rem;
        border-left: 2px solid #f7594d;
        border-right: 2px solid #f7594d; } }
