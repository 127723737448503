/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.overviewBox {
  background-color: white;
  padding: 1.25rem 0rem;
  margin-top: 1.875rem; }

.bodyText {
  margin-bottom: 1rem;
  font-size: 1rem; }

.content {
  flex-direction: column;
  display: flex;
  width: 100%; }

@media screen and (min-width: 992px) {
    .content {
      flex-direction: row; } }
