/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.headerWrapperTop {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.placeholder {
  height: 60px; }

.base {
  width: 100%;
  position: relative;
  z-index: 10006; }

.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: inherit;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
  will-change: transform; }
  .sticky.visible {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .sticky.smaller {
    display: block; }

.lastinorder {
  order: 3; }

.topActionsHolder {
  order: 1;
  display: flex;
  height: 60px; }

.actionsHolder {
  display: flex;
  height: 60px;
  justify-content: space-between;
  overflow-y: hidden; }
  .actionsHolder.actionsHolderRight > div {
    border-right: 1px solid #e5e5e5; }
    .actionsHolder.actionsHolderRight > div:first-of-type {
      border-left: 1px solid #e5e5e5; }
  .actionsHolder.actionsHolderRight > div.mobileError {
    border-right: 0; }

.deliveryAction {
  width: 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 1.5rem;
  color: #23272a;
  cursor: pointer; }

.deliveryActionMobile {
  width: 4.3rem;
  height: 4rem;
  flex-basis: 100%;
  color: #23272a;
  display: flex;
  align-items: center;
  justify-content: center; }
  .deliveryActionMobile svg {
    height: 1.45rem;
    width: 1.4rem;
    margin-right: 0; }

.deliveryDateWrapper {
  flex: 0 0 auto; }
  .deliveryDateWrapper.mobileError {
    display: block;
    position: absolute;
    top: 4rem;
    white-space: nowrap;
    min-width: 20rem;
    right: 0; }
    .deliveryDateWrapper.mobileError .validationError {
      display: inline; }
  .deliveryDateWrapper.error {
    display: flex;
    align-items: center; }
    .deliveryDateWrapper.error .validationError {
      padding: 0rem;
      line-height: normal; }

.deliveryDatesWrapper {
  display: block;
  position: fixed;
  padding: 2rem;
  z-index: 100000;
  background: white;
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.2);
  top: 4rem;
  left: 0rem;
  width: 100%; }

.helperLinksHolder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap; }
  .helperLinksHolder .helperLinks {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0; }
    .helperLinksHolder .helperLinks li {
      display: inline-block;
      margin-right: 2rem; }
      .helperLinksHolder .helperLinks li a {
        text-decoration: none; }

.mainMenuHolder {
  font-size: 1.14286rem;
  font-weight: bold;
  position: relative;
  color: #333;
  order: 2;
  display: flex;
  width: 100%; }
  .mainMenuHolder.medium {
    font-size: 1rem; }
  .mainMenuHolder p {
    margin: 0; }
  .mainMenuHolder a {
    display: block;
    text-decoration: none; }
  .mainMenuHolder > nav {
    display: inline-flex;
    width: 100%;
    justify-content: space-between; }
    .mainMenuHolder > nav.hasSixOrMoreMenuItems {
      font-size: 14px; }

.secondaryMenuItem {
  line-height: 60px;
  padding: 0 0.5rem;
  white-space: nowrap; }

.layout {
  composes: full from "../../../styles/objects/layout.scss";
  display: flex;
  top: 0px;
  position: absolute;
  align-items: center;
  justify-content: flex-start; }

.headerWrapperTop {
  height: 60px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  text-transform: uppercase; }

.headerWrapperMiddle {
  min-height: 60px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.04); }
  .headerWrapperMiddle .space {
    display: flex;
    justify-content: space-between; }

.headerWrapperBottom {
  width: initial; }
  .headerWrapperBottom .secondaryMenuList ul {
    display: inline-block; }

.rightTopButtonsHolder {
  justify-content: space-between; }

.button {
  composes: bare from "../../../styles/objects/buttons.scss";
  width: 60px;
  transition: 0.3s; }

.cartnotempty {
  background: #f7594d;
  width: 100%; }

.iconbutton {
  composes: button;
  font-size: 24px; }

.iconbuttonSecondary {
  composes: iconbutton; }
  .iconbuttonSecondary:hover, .iconbuttonSecondary:focus {
    color: #f7594d; }

.menu {
  composes: iconbuttonSecondary;
  flex: 0 0 auto; }
  .menu.open {
    z-index: 10009; }
    .menu.open .nav {
      display: none; }
    .menu.open .close {
      display: block; }
  .menu .nav {
    display: block; }
  .menu .close {
    display: none; }

.nav,
.close {
  composes: base 1to1 center from "../../../styles/objects/ratio.scss"; }

.miniCartWrapper {
  position: absolute;
  right: 0;
  width: 100%; }
  .miniCartWrapper main {
    padding: 0; }

.miniCart {
  height: 60px;
  width: 8rem; }

:global(.ie) .quickSearch {
  flex: none;
  width: 30rem; }

:global .CalendarMonth__day {
  text-align: center; }

:global .CalendarMonthGrid--horizontal {
  width: inital; }

:global .CalendarMonth__day--selected {
  background: none;
  color: white !important;
  border: none; }
  :global .CalendarMonth__day--selected :after {
    background: #343d46;
    border-radius: 100%;
    content: "";
    z-index: 1;
    height: 2rem;
    width: 2rem;
    left: 0.4rem;
    top: 0.3rem;
    z-index: -1;
    display: inline-block;
    position: absolute; }

:global .CalendarMonth__day:not(.CalendarMonth__day--blocked-out-of-range) {
  color: #fff;
  color: black; }
  :global .CalendarMonth__day:not(.CalendarMonth__day--blocked-out-of-range):hover {
    background: #495159;
    transition: 0.3s; }

.logotype {
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  float: left;
  margin-left: 1rem; }
  .logotype svg {
    height: 100%;
    width: 100%;
    max-height: 70px; }

.validationError {
  color: #ef7172;
  font-weight: bold; }

.noscript {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  padding: 0.5rem;
  background: #f66d62; }

.userInfoItem {
  font-family: SFNS, sans-serif; }

.userInfoWrapper {
  margin-left: 11rem; }
  .userInfoWrapper .suppliersalesmanWrapper {
    position: relative; }
    .userInfoWrapper .suppliersalesmanWrapper .userInfoItemFirst {
      font-family: SFNS, sans-serif;
      padding-right: 0.5rem; }
    .userInfoWrapper .suppliersalesmanWrapper .suppliersalesmanInfo {
      display: inline-block;
      padding-right: 0.5rem; }
    .userInfoWrapper .suppliersalesmanWrapper .suppliersalesmanExhibition {
      display: inline-block; }
    @media screen and (min-width: 992px) {
    .headerWrapperTop {
      font-family: SFNS_bold, sans-serif;
      font-size: 0.85714rem;
      line-height: inherit; }
    .placeholder {
      height: 122px; }
    .actionsHolder {
      flex-grow: 1;
      padding-right: 0;
      flex-grow: 0; }
      .actionsHolder.actionsHolderRight > div:first-of-type {
        border-left: none;
        width: 19vw;
        max-width: 500px; }
    .deliveryAction {
      order: initial;
      width: auto;
      text-align: center; }
    .deliveryDatesWrapper {
      width: 28rem;
      position: absolute;
      top: 0rem;
      right: 0rem;
      left: inherit; }
    .layout {
      position: relative; }
    .headerWrapperTop {
      width: 100%;
      position: relative; }
    .headerWrapperMiddle {
      width: initial; }
  .logotypeWrapper {
    width: 9rem;
    padding-left: 0;
    padding-top: 0; }
    .logotype {
      position: relative; }
      .logotype svg {
        height: 100%;
        width: 100%; }
    .userInfoWrapper {
      padding-right: 0.5rem; } }
    @media print {
    .placeholder {
      display: none; } }
    @media screen and (min-width: 1200px) {
    .secondaryMenuItem {
      padding: 0 1rem; }
      .hasSixOrMoreMenuItems .secondaryMenuItem {
        padding: 0 0.5rem; } }
