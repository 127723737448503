/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.discountFreightBlock {
  display: flex;
  flex-flow: row wrap;
  background-color: #FAFAFA; }

.limit {
  width: 65%;
  float: left; }

.freightContainer {
  width: 100%;
  max-width: 450px;
  padding: 15px; }

.discountContainer {
  width: 100%;
  max-width: 450px;
  padding: 15px; }

.percent {
  width: 35%;
  float: left; }

.discountItem {
  max-width: 500px;
  width: 100%;
  float: left;
  padding: 5px 0;
  border-bottom: 1px solid #E5E5E5; }
