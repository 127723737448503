/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.main {
  width: 100%;
  container-type: inline-size; }
  .main .grid {
    position: relative;
    grid-auto-flow: dense; }
    .main .grid::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: 0px 1px 0px #ffffff, 1px 0px 0px #ffffff; }
    .main .grid[data-list-type="viewLayout_List"] {
      display: flex;
      flex-direction: column;
      gap: 1px; }
    .main .grid[data-list-type="viewLayout_Grid"] {
      display: grid;
      gap: 1px;
      grid-template-columns: repeat(auto-fill, minmax(232px, 1fr)); }

@container (width > 630px) and (width < 768px) {
  .main .grid[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr 1fr; } }
    .main .grid.isCart {
      padding: 0 1rem; }
      .main .grid.isCart::after {
        width: calc(100% - 1rem);
        height: calc(100% - 1rem); }

@container (width > 630px) and (width < 768px) {
  .main .grid.isCheckout[data-list-type="viewLayout_List"], .main .grid.isCart[data-list-type="viewLayout_List"], .main .grid.isProductPage[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr; } }

@container (width < 630px) {
  .main .grid[data-list-type="viewLayout_Grid"] {
    border: 0px;
    display: flex;
    flex-direction: column;
    gap: 1px; } }
    .main .grid .ads {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
      border-bottom: 1px solid #e5e5e5; }
      .main .grid .ads > div {
        border: unset; }
        .main .grid .ads > div > div {
          margin: 0px; }
