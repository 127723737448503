/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.labelHeadings {
  display: flex;
  padding: 15px 0;
  border: 1px solid #e5e5e5;
  color: #343d46;
  background-color: #fafafa; }
  .labelHeadings div:first-child {
    width: 30%;
    padding-left: 10px; }
  .labelHeadings div:nth-child(2) {
    width: 60%;
    padding-left: 25px; }
  .labelHeadings div:nth-child(3) {
    width: 10%; }

.feedbackButton {
  max-width: 100px;
  width: 100%; }

.returnItemRow,
.returnItemRowActive {
  display: flex;
  border-bottom: 0.5px solid #e5e5e5;
  padding: 30px 0;
  align-items: center;
  background-color: #fff; }

.returnItemRowActive {
  background-color: #fafafa; }

.description {
  width: 60%;
  padding: 0 25px; }

.feedbackButtonContainer {
  float: right;
  padding: 20px; }

.productId {
  width: 12%;
  padding-left: 10px; }

.numberInput {
  width: 10%;
  padding-right: 10px; }

.imgContainer {
  width: 18%;
  text-align: center; }
  .imgContainer img {
    max-height: 110px; }

.labelForProp {
  flex: 1; }

.customerInfo {
  flex: 8; }

.customerInput {
  max-width: 270px;
  width: 100%;
  flex: 8; }

.row {
  display: flex;
  max-width: 400px;
  width: 100%;
  padding: 5px 0; }
  .row div:first-child {
    width: 40%; }
  .row div:nth-child(2) {
    width: 60%; }

.returnItemsContainer {
  padding: 0; }

.customerInfoContainer {
  padding: 20px;
  background: #fff; }
  .customerInfoContainer h3 {
    margin-top: 0; }

.errorText {
  color: #f7594d; }

.modal {
  width: 500px; }

@media (max-width: 640px) {
      .labelHeadings div:nth-child(2) {
        width: 55%; }
      .labelHeadings div:nth-child(3) {
        width: 15%; }
    .description {
      width: 70%; }
    .numberInput {
      width: 15%; }
    .imgContainer {
      display: none; } }
