/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.header {
  text-align: left;
  padding: 6rem 2rem; }

.flex {
  width: 10rem;
  display: inline-block; }

.rightSection {
  float: right;
  width: 50%;
  text-align: right; }

.bottomBorder {
  padding-bottom: 40px; }

.selectionNavigatorWrapper {
  background: none;
  float: right;
  display: inline-flex;
  width: 50%; }
  .selectionNavigatorWrapper.fullWidth {
    width: 100%;
    float: inherit; }
  .selectionNavigatorWrapper button {
    max-height: 4rem;
    overflow: hidden;
    flex: 1;
    color: #23272a;
    background: #fff;
    padding: 1rem;
    border-radius: 0;
    border: 1px solid #e5e5e5;
    border-left: none; }
    .selectionNavigatorWrapper button:first-child {
      border-left: 1px solid #e5e5e5;
      border-radius: 3rem 0 0 3rem; }
    .selectionNavigatorWrapper button:last-child {
      border-radius: 0 3rem 3rem 0; }
    .selectionNavigatorWrapper button:hover {
      background: #e5e5e5 !important;
      transition: 0.3s; }
    .selectionNavigatorWrapper button:active {
      background: #fff; }
  .selectionNavigatorWrapper .selected {
    background: #f9f9f9; }

.searchInput {
  width: 100%; }

.navigationWrapper {
  margin: .5rem 0; }

@media screen and (min-width: 768px) {
    .header {
      padding: 6rem; } }

@media screen and (min-width: 992px) {
    .bottomBorder {
      position: relative;
      margin-top: 0 !important;
      padding-bottom: 0; }
      .bottomBorder:after {
        content: "";
        display: block;
        border-bottom: 1px solid #f7594d;
        height: 0px;
        width: 100%;
        position: absolute;
        bottom: -40px; } }

@media (max-width: 768px) {
    .selectionNavigatorWrapper {
      display: block;
      border: 1px solid #e5e5e5; }
      .selectionNavigatorWrapper button {
        width: 100%;
        border: none;
        border-bottom: 1px solid #e5e5e5; }
        .selectionNavigatorWrapper button:first-child {
          border-left: none;
          border-radius: inherit; }
        .selectionNavigatorWrapper button:last-child {
          border: none; } }
