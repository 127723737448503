/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.h4, .productSheetLink {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

h1 {
  margin-bottom: 0px; }

.h4 {
  margin-bottom: 1rem; }

.base h1 {
  overflow: visible; }

.mobileBase {
  padding: 0 1.4rem; }

.secondaryProductHeadingSection {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap; }
  .secondaryProductHeadingSection .productMarking__right {
    text-align: right; }
    .secondaryProductHeadingSection .productMarking__right > * {
      display: inline-block;
      margin: 0 0.2rem; }
    .secondaryProductHeadingSection .productMarking__right p {
      font-size: 1rem;
      bottom: 0.2rem;
      position: relative; }
  .secondaryProductHeadingSection .left {
    flex: 1; }
  .secondaryProductHeadingSection .right {
    align-items: flex-end; }

.iconWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center; }
  .iconWrapper .icon {
    display: flex;
    align-items: center;
    font-size: 2.71429rem;
    margin-right: 2rem; }
    .iconWrapper .icon img {
      height: 2.71429rem; }
    .iconWrapper .icon span {
      font-size: 1.42857rem;
      padding-right: 0.5rem;
      font-weight: bold; }

.leftSection {
  position: relative;
  margin-top: 0rem; }

.markerWrapper span {
  font-weight: bold; }

.productInformation {
  padding-top: 2rem;
  position: relative;
  z-index: 1; }
  .productInformation h1 {
    margin-top: 0;
    margin-bottom: 8px; }
  .productInformation .priceAvailabilityWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    max-width: 400px;
    margin: 2rem 0 1rem 0; }
    .productInformation .priceAvailabilityWrapper > div:last-of-type {
      display: flex;
      flex-direction: column;
      justify-self: end; }
  .productInformation .expiration .h4 {
    margin-top: 0;
    margin-bottom: 0; }
  .productInformation .article {
    width: 100%; }
    .productInformation .article ul {
      margin-top: 0.5rem; }
    .productInformation .article ul li {
      border-bottom: 1px #e5e5e5 solid; }
      .productInformation .article ul li a {
        text-decoration: none; }
  .productInformation .description {
    position: relative;
    height: inherit; }
    .productInformation .description h2 {
      font-size: 2.28571rem; }
    .productInformation .description h4,
    .productInformation .description .h4,
    .productInformation .description p {
      margin-top: 0.5rem; }
    .productInformation .description .descriptionLinks {
      width: 100%; }
      .productInformation .description .descriptionLinks a {
        color: #23272a;
        display: block;
        font-weight: bold;
        margin-top: 0.5rem;
        text-decoration: underline; }

.main {
  flex-grow: 1; }
  .main .mainNarrow {
    padding: 0 165px; }

.campaign,
.campaignPrice {
  margin-left: 1rem;
  color: #ff0000;
  font-weight: bold;
  font-size: 1.2rem; }

.campaignDatesWrap {
  background: #fff;
  padding: 1px 5px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1); }

.strikethrough {
  color: #666;
  text-decoration: line-through; }

.productContent {
  max-width: 610px; }

.unitDropdown {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%; }
  .unitDropdown span {
    display: flex;
    align-items: center; }

.productVideo {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .productVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.availability {
  display: flex;
  flex-direction: column; }
  .availability .stock {
    display: flex;
    flex-direction: row; }
  .availability b {
    margin-left: 0.5rem;
    border-left: 1px #343d46 solid;
    padding-left: 0.5rem; }

.priceDetails {
  color: #23272a;
  font-weight: lighter;
  padding: 0 0.5rem 1rem; }

.listQuantityWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 1.5rem 0; }
  .listQuantityWrapper > div:not(:first-of-type):last-of-type {
    justify-self: end; }

.productDisabledInfoWrap {
  font-family: SFNS_bold, sans-serif; }

.addRemoveList {
  margin-right: 1rem; }

.descriptionWrapper {
  background: #f9f9f9;
  margin-top: 3rem; }
  .descriptionWrapper > main:not(:empty) {
    padding-top: 3rem; }
  .descriptionWrapper > main:not(:empty) + main:not(:empty) {
    margin-top: 0;
    padding-top: 0; }
  .descriptionWrapper > main:not(:empty) + main + main:not(:empty) {
    padding-top: 0; }
  .descriptionWrapper > main + main:not(:empty) + main:not(:empty) {
    padding-top: 0; }

.descriptionInner {
  background: #f9f9f9;
  display: flex;
  padding: 0 calc(165px + 1rem);
  gap: 2rem; }
  .descriptionInner:empty {
    padding: 0 !important;
    margin-top: 0 !important;
    gap: 0rem !important; }

.handyInformationRow {
  background: #f9f9f9;
  margin-top: 0; }
  .handyInformationRow .h2 {
    margin-top: 0; }
  .handyInformationRow main {
    background: #f9f9f9; }

.handyInformationRow {
  max-width: 600px;
  padding: 0 0 2rem 0; }

.relatedContent {
  margin-top: 0; }
  .relatedContent main {
    padding: 0.5rem 1.5rem; }

.nutritionRow h2 {
  margin-bottom: 0; }

.nutritionRow .nutritionTable div:first-child {
  padding-top: 0; }

.nutritionRow .nutritionInfo {
  padding: 0;
  margin: 0;
  margin-left: 1rem; }
  .nutritionRow .nutritionInfo a {
    text-decoration: underline; }
  .nutritionRow .nutritionInfo > div {
    margin-bottom: 3rem; }
    .nutritionRow .nutritionInfo > div .h4 {
      margin-top: 0; }

.accordionPadding {
  margin-left: 1rem; }

.mobileTopNode {
  padding: 0.5rem; }

.mobilePriceWrapper {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap; }
  .mobilePriceWrapper .availability b {
    margin-left: 0;
    border-left: none;
    display: block;
    white-space: nowrap; }
  .mobilePriceWrapper .availability .inlineAvailability {
    text-align: right; }

.mobileDropDown {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 0.5rem; }

.recommendationRow {
  padding: 0; }

.carousel {
  margin-top: 50px; }

.productSheetLink {
  margin-bottom: 1.5rem;
  display: block;
  margin-top: 1.25rem !important; }
  .productSheetLink:hover {
    color: #23272a;
    text-decoration: none; }
    .productSheetLink:hover span {
      text-decoration: underline; }

.productSheetLinkText {
  text-transform: uppercase;
  position: relative;
  bottom: -2px;
  margin-bottom: 1.5rem; }
  .productSheetLinkText + svg {
    height: 1.25rem;
    width: 1.25rem; }

.accordionDesktop {
  padding: 0 calc(165px + 1rem); }

@media screen and (min-width: 480px) {
    .h2 {
      margin-top: 2.2rem; }
    .h3 {
      margin-top: 2.2rem; }
    .h4, .productSheetLink {
      margin-top: 2.2rem; } }

@media screen and (min-width: 992px) {
    .h2 {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
    .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; }
    .h4, .productSheetLink {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.42857rem; }
  .mainProduct {
    max-width: 400px; }
  .h2 {
    margin-top: 2rem; }
      .iconWrapper .icon {
        font-size: 3.85714rem; }
        .iconWrapper .icon img {
          height: 3.85714rem; }
    .markerWrapper span {
      font-family: SFNS_bold, sans-serif;
      font-weight: 400;
      font-size: 1.14286rem; }
    .productInformation .articleWrapper {
      padding-left: 0 !important; }
      .productInformation .article {
        max-width: 400px;
        margin-right: 0; }
        .productInformation .article ul li {
          border-bottom: none; }
    .main {
      margin-top: 2rem; }
    .availability {
      flex-direction: row; }
      .availability b {
        min-height: 1.5rem;
        display: inline-block; }
    .priceDetails {
      padding: 1rem 0.5rem 0.5rem 0; }
    .listQuantityWrapper {
      max-width: 400px; }
    .descriptionInner:first-child > main {
      padding-left: 0 !important; }
    .accordionPadding {
      margin-left: inherit; } }

@media screen and (min-width: 768px) {
  .base h1 {
    font-size: 2.85714rem; }
      .relatedContent main {
        padding: 0.5rem 0; }
    .nutritionRow .nutritionInfo {
      margin-left: inherit; }
    .nutritionRow .nutritionInfo {
      padding: 0; } }

@media screen and (min-width: 1200px) {
    .mobilePriceWrapper .expiration {
      padding: 0 0.5rem 1rem; } }
