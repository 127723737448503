/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.email {
  min-width: 300px; }
  .email textarea {
    width: 100%;
    height: 8rem; }

.row {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column; }

.column {
  flex: 1 0 50%;
  margin-bottom: 1rem; }
  .column label {
    margin-bottom: 4px;
    display: inline-flex; }

.sectionOne {
  width: 100%; }
  .sectionOne .row:last-child {
    padding-top: 1rem;
    border-top: 1px solid #6c737a;
    margin-bottom: 2rem; }

.sectionTwo {
  width: 100%; }

.sectionThree {
  width: 100%; }
  .sectionThree .row {
    flex-direction: column; }
  .sectionThree .column {
    flex: 1 0 30%; }

.settingLabel {
  font-weight: bold; }

.requiredField {
  color: #b0406f;
  display: flex;
  line-height: 20px;
  padding: 2px;
  margin-top: 8px;
  font-size: 14px;
  vertical-align: middle; }
  .requiredField svg {
    width: 20px;
    height: 20px;
    margin-right: 0.35714rem;
    vertical-align: text-top; }

.select {
  background-color: #fff; }

.small {
  font-size: 0.857rem;
  line-height: 1.3;
  display: block;
  padding: .5rem .125rem; }

@media screen and (min-width: 768px) {
    .email {
      width: 45%; }
    .row {
      flex-direction: row; }
    .column {
      margin-bottom: 0; }
    .sectionOne .column:first-child {
      padding-right: 2rem; }
    .sectionTwo .column:first-child {
      padding-right: 2rem; }
      .sectionThree .row {
        flex-direction: row; }
      .sectionThree .column {
        padding-left: 2rem; }
        .sectionThree .column:first-child {
          padding-left: 0rem; } }

@media screen and (min-width: 992px) {
    .sectionOne {
      width: 60%; }
    .sectionTwo {
      width: 60%; }
    .sectionThree {
      width: 60%; } }
