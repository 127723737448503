/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.highlighted {
  transition: .3s;
  background: #d2e7d3; }

.buttons {
  margin-top: 5px; }

.selectAllCheckbox {
  margin-left: 19px;
  margin-bottom: 15px; }

.spinnerWrapper {
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: #f7594d; }

.list {
  margin-top: 0.5rem;
  background-color: white;
  position: absolute;
  max-width: 700px; }
  .list tr:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .list tr:nth-child(2n) {
    background-color: #eff0f2; }
  .list tr:last-child {
    border-bottom: 1px solid #e5e5e5; }
  .list tr.link {
    color: #343d46; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem;
    width: 70px; }
  .list td:nth-child(2n), .list th:nth-child(2n) {
    max-width: 100px; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem;
    float: right; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }
  .list .tdInputWrapper {
    white-space: nowrap; }
    .list .tdInputWrapper input {
      width: 4.5rem;
      margin-right: 0.1rem; }

.btnRows {
  padding: 10px 0;
  display: flex;
  max-width: 700px;
  width: 100%; }
  .btnRows div:first-child {
    width: 40%; }
  .btnRows div:nth-child(2) {
    width: 60%; }
  .btnRows input {
    margin-right: 15px;
    margin-top: 5px; }

section {
  margin: 0 !important; }

.checkboxRow {
  background: #e5e5e5;
  padding: 1.5rem 1rem;
  margin: 0 5px;
  margin-bottom: 10px;
  cursor: pointer; }
  .checkboxRow:hover {
    transition: .3s;
    background-color: #f9f9f9; }

.checkboxActive {
  background-color: #d2e7d3 !important; }

.list {
  margin-bottom: 0.5rem;
  position: relative; }
