/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  padding-top: 0; }

.breadcrumbsWrap {
  margin-top: 20px;
  margin-bottom: 10px; }

.invoiceContainer {
  background: #f1f1f1;
  transition: 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 1rem;
  width: 100%;
  margin: auto; }

.borderHeader {
  border: 1px solid #e5e5e5; }

.header {
  background: #e5e5e5;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: bold; }

.baseFlex {
  flex-basis: 7%; }

.baseMargin {
  margin-right: 0.5rem; }

.invoiceWrapItems {
  border-bottom: 1px solid #e5e5e5; }

.itemHeader {
  background: #f1f1f1;
  padding: 0.8rem 0.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.aLink {
  text-decoration: none;
  background-color: #343d46;
  color: white;
  border-radius: 25px;
  padding: 9px 12px;
  margin: 0.1rem 0rem;
  text-transform: uppercase;
  margin: 0.1rem 0rem; }

.aLink:hover {
  background: #32383d;
  cursor: pointer;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold; }

.iconPdf {
  position: relative;
  bottom: -6px; }

.cellTop {
  width: 6rem; }

.cell {
  width: 7.5rem;
  text-align: right; }

#pagingMainContainer {
  display: block;
  width: 750px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 45px; }

#paginationContent {
  width: 100%;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; }

.paginate {
  width: 100%;
  font-size: 1rem;
  padding: 35px 0 0 0;
  margin: auto;
  text-align: center; }

.paginate li {
  display: inline-block; }

.paginate.page {
  font-size: 1rem; }

.paginate.page li a {
  display: inline-block;
  color: #363842;
  text-decoration: none;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s linear; }

.paginate.page li a:hover {
  color: #363842;
  background: #d0dfcf; }

.paginate.page li a:active {
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.55); }

.paginate.page li.navpage a {
  padding: 9px 13px;
  background: #f8584c;
  color: #fff;
  cursor: pointer;
  font-weight: bold; }

.paginate.page li.navpage a:hover {
  background: #f8584c; }

.paginate.page li.notallowednavpage a {
  padding: 9px 13px;
  background: #e8e5e3;
  color: #fff; }

.paginate.page li.single,
.paginate.page li.current {
  display: inline-block;
  padding: 9px 12px;
  margin-right: 6px;
  border-radius: 10px;
  color: #fff;
  background: #343d46;
  font-weight: bold; }

.arrowUp:after, .arrowDown:after {
  position: relative;
  margin-left: 0.25rem;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.sortable {
  cursor: pointer;
  text-decoration: underline; }

.arrowUp,
.arrowDown {
  padding-right: 0; }

.arrowUp:after {
  top: -10px;
  border-bottom: 5px solid black; }

.arrowDown:after {
  bottom: -10px;
  border-top: 5px solid black; }

.notification {
  margin: 0 auto;
  text-align: center;
  padding: 1rem 0; }

.mobilePagingLane {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding: 0.5rem 1rem; }
  .mobilePagingLane .prevContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center;
    border-radius: 25px;
    background-color: #f8584c;
    color: white; }
    .mobilePagingLane .prevContainer > * {
      color: white; }
  .mobilePagingLane .prevContainer span {
    color: white; }
    .mobilePagingLane .prevContainer span > * {
      color: white; }
  .mobilePagingLane .paging {
    padding: 0 1rem;
    flex: 1;
    font-size: 1rem;
    align-self: center;
    text-align: center; }
    .mobilePagingLane .paging > * {
      width: 100%;
      color: black; }
  .mobilePagingLane .nextContainer {
    width: 15%;
    flex: 1;
    align-self: center;
    text-align: center;
    border-radius: 25px;
    background-color: #f8584c;
    color: white; }
    .mobilePagingLane .nextContainer > * {
      color: white; }
  .mobilePagingLane .nextContainer span {
    color: white; }
    .mobilePagingLane .nextContainer span > * {
      color: white; }

.mobileStateLine {
  display: flex;
  flex-direction: row;
  height: auto;
  margin: 1rem 0; }
  .mobileStateLine > button {
    width: 100%;
    font-size: 12px;
    margin-right: 5px; }
  .mobileStateLine .mobileViewLane {
    background-color: #fff;
    width: 102%;
    height: auto;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px; }

.mobileHeader {
  border: 1px solid gainsboro;
  background-color: #f1f1f1;
  margin: 0.5rem 0;
  padding-bottom: 0.3rem; }

.mobileCell {
  padding: 0.2rem 0.5rem;
  width: 100%;
  letter-spacing: 1px; }

.mobileCell span {
  font-weight: bold;
  font-stretch: semi-expanded; }

.invoiceFilterWrapper {
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-around; }
  .invoiceFilterWrapper button {
    flex: 0 0 auto;
    margin-right: 10px;
    margin-bottom: 10px; }

.creditInvoiceCell {
  display: flex;
  align-items: center;
  justify-content: center; }

.creditInvoiceIcon {
  font-size: 20px; }

.loaderSpinner {
  position: absolute;
  width: 3rem;
  padding: 0.5rem;
  color: #f7594d;
  top: 50%;
  left: calc(50% - 3rem / 2);
  border-radius: 50%;
  opacity: 1;
  pointer-events: none;
  z-index: 100109;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 0 1rem 0 rgba(255, 255, 255, 0.1), inset 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.visible {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.yearOptions {
  width: 25%; }
  .yearOptions .yearOptionsInner {
    margin-bottom: calc(28px); }
    .yearOptions .yearOptionsInner h4 {
      margin: calc(20px) 0 10px 0; }
    .yearOptions .yearOptionsInner .selectWrapper {
      display: flex;
      flex-direction: column; }
      .yearOptions .yearOptionsInner .selectWrapper .dropdownWrap {
        width: 100%;
        margin-bottom: 14px; }
        .yearOptions .yearOptionsInner .selectWrapper .dropdownWrap > div {
          width: 100%; }
        @media screen and (min-width: 768px) {
    .invoiceFilterWrapper button {
      flex: 0 0 auto; }
        .yearOptions .yearOptionsInner h4 {
          margin: 0 0 14px 0; } }
