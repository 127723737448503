.mainContentBlock {
  width: 270px;
  height: 145px;
  display: flex; }

.parentContentBlock {
  background-color: #f2f2f2;
  width: 270px;
  height: 185px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: left; }
  .parentContentBlock {
    display: inline-block; }

.displayImage {
  display: inline-block;
  position: relative;
  font-size: 3.5rem;
  margin: 20px; }

.statisticsDetails {
  display: inline-block;
  margin: 20px; }
  .statisticsDetails .title {
    font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial';
    font-weight: 700;
    font-size: 18px; }
  .statisticsDetails .details {
    font-family: 'Georgia-Bold', 'Georgia Bold', 'Georgia';
    font-weight: 700;
    font-style: normal;
    line-height: 14px; }

.viewMoreBase {
  background: #c9c9c9;
  border-width: 0px;
  width: 270px;
  height: 40px;
  text-align: right; }
  .viewMoreBase .textDiv {
    padding-top: 13px;
    padding-right: 15px;
    width: 269px;
    -webkit-transform-origin: 141px 7px 0px;
            transform-origin: 141px 7px 0px; }
  .viewMoreBase p {
    font-family: 'ArialMT', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #333333;
    text-align: right;
    line-height: normal; }

.verticalLineSeparator {
  height: 85%;
  width: 1px;
  display: inline-block;
  background: #717171;
  border-right: 1px solid #313030;
  position: relative;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%);
          transform: perspective(1px) translateY(-50%); }
