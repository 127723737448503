/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.toolButton {
  width: 100%;
  margin-top: 1rem; }
  .toolButton > div {
    width: 100%; }

.btn {
  display: block; }
  .btn button {
    width: 100%; }
  @media screen and (min-width: 768px) {
    .toolButton {
      width: 19rem; }
    .btn {
      display: inline-block; } }
