/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit;
  z-index: 1; }

button.logout,
button.logout:hover {
  position: relative;
  background: transparent !important;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.86rem;
  line-height: 1; }
  button.logout span > svg,
  button.logout:hover span > svg {
    height: 14px;
    width: 14px;
    position: relative;
    margin-left: 0.25rem; }
  button.logout > span,
  button.logout:hover > span {
    background: transparent !important; }

button.logout:hover span {
  text-decoration: underline; }

.myMenigoButton {
  text-transform: uppercase;
  background: transparent;
  border: solid 1px #fff;
  padding: 0.5rem 1.25rem;
  white-space: pre;
  font-weight: bold; }
  .myMenigoButton:hover, .myMenigoButton:focus, .myMenigoButton:active {
    color: #fff;
    background: rgba(255, 255, 255, 0.15) !important; }
  .myMenigoButton svg {
    margin-right: 1rem; }
  .myMenigoButton.current {
    color: #343D46;
    background: #F9F9F9 !important; }
    .myMenigoButton.current svg {
      color: #343D46; }
    .myMenigoButton.current:hover {
      border: solid 1px #DEDEDE !important;
      background: #DEDEDE !important; }

.parentDropdown {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1), 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.125rem;
  position: absolute;
  right: 0;
  z-index: 10009;
  width: 25rem; }
  .parentDropdown > div {
    position: relative;
    top: 0; }

.loginWrapper {
  position: relative;
  z-index: 10009; }
  .loginWrapper div > button,
  .loginWrapper div > button:hover {
    text-transform: uppercase;
    letter-spacing: inherit;
    color: #fff;
    padding: 0.5rem 1.25rem; }
    .loginWrapper div > button svg,
    .loginWrapper div > button:hover svg {
      color: #fff;
      margin-right: 0.25rem; }
