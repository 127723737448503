/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  top: 10vh;
  left: calc(50vw - 1.25rem);
  width: 3rem;
  padding: 0.5rem;
  color: #f7594d;
  opacity: 1;
  pointer-events: none;
  border-radius: 50%;
  z-index: 100109;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 0 1rem 0 rgba(255, 255, 255, 0.1), inset 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.visible {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }
