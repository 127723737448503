/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.contactBox {
  padding: 1.5rem 0;
  margin-top: 1.875rem;
  display: flex;
  border-top: 1px solid #E7E7E7; }
  .contactBox__imageColumn {
    margin-right: 1.35714rem; }
    .contactBox__imageColumn > svg {
      height: 5.14286rem;
      width: 5.14286rem; }
  .contactBox__textHeader {
    font-weight: 500;
    font-size: 1.14286rem; }
  .contactBox__textBody {
    white-space: pre-line; }

.contactButton {
  color: #0d0f11;
  font-size: 1rem;
  background-color: white;
  border-color: #343D46;
  border-width: 0.063rem;
  border-style: solid;
  padding: 0.688rem 1.25rem;
  border-radius: 100px;
  flex-direction: column;
  display: block;
  text-align: center;
  margin: 0 auto 1.28571rem;
  max-width: 300px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.05em; }
  .contactButton:hover {
    background-color: #E7E7E7;
    color: #343D46;
    text-decoration: none; }
  .contactButton > svg {
    position: relative;
    top: -1px; }

.contactForm h2 {
  font-size: 2.71429rem; }

.contactForm__form {
  max-width: 21.42857rem; }
  .contactForm__form__element {
    margin-bottom: 2.14286rem; }
  .contactForm__form__changeLabel {
    margin-bottom: 0.71429rem; }
  .contactForm__form__changeElement {
    font-size: 0.85714rem;
    margin-bottom: 0.71429rem; }

.contactForm__preamble {
  font-size: 1.14286rem;
  white-space: pre-line; }

.contactForm__messageText {
  width: 21.42857rem;
  height: 12.14286rem; }

.contactForm__buttons {
  margin-top: 5rem; }
  .contactForm__buttons__sendButton {
    margin-right: 0.71429rem;
    padding: 0.688rem 1.25rem !important; }
  .contactForm__buttons__cancelButton {
    color: #0d0f11 !important;
    font-size: 1rem !important;
    background-color: white !important;
    border-color: #343D46 !important;
    border-width: 0.063rem !important;
    border-style: solid !important;
    padding: 0.688rem 1.25rem !important;
    border-radius: 100px !important; }
  .contactForm__buttons__closeButton {
    padding: 0.688rem 1.25rem !important; }

.requiredField {
  color: #b0406f;
  display: flex;
  line-height: 20px;
  padding: 2px;
  margin-top: 8px;
  font-size: 14px;
  vertical-align: middle; }
  .requiredField svg {
    width: 20px;
    height: 20px;
    margin-right: 0.35714rem;
    vertical-align: text-top; }

.inputInvalid input {
  border-color: #b0406f; }

.show {
  display: block; }

.hide {
  display: none; }

@media screen and (min-width: 992px) {
    .contactButton {
      text-align: left;
      display: inline-block;
      margin: 0 0 1.28571rem 0;
      max-width: unset; }
  .contactForm {
    width: 51.35714rem; } }
