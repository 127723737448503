/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.spinnerWrapper {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 9rem;
  top: 0.75rem;
  color: #f7594d; }

.spinnerWrapperSearchUser {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0.2rem;
  top: 1.8rem;
  color: #f7594d; }

.recipeList_autoComplete_Wrapper {
  position: absolute;
  border: 1px solid lightgray;
  width: 100%;
  z-index: 10;
  background: white;
  display: block;
  padding: 0;
  margin: 0;
  top: -1px;
  box-shadow: 0 0.5rem 1rem #e5e5e5;
  max-height: 43rem;
  overflow-y: scroll; }
  .recipeList_autoComplete_Wrapper .listWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .recipeList_autoComplete_Wrapper .listWrapper:hover {
      background: #e5e5e5;
      transition: .3s;
      cursor: pointer; }
    .recipeList_autoComplete_Wrapper .listWrapper .portionCost {
      width: 10rem; }

.autocompleteWrapper {
  position: relative; }
  .autocompleteWrapper .autocomplete {
    top: -1rem;
    z-index: 1;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    position: absolute; }

.selected {
  background-color: #d2e7d3 !important; }

.price {
  display: inline-block; }

.listWrapper {
  display: inline-block;
  width: 100%;
  padding: large;
  height: 6rem; }
  .listWrapper:nth-child(odd) {
    background-color: #fff; }
  .listWrapper:nth-child(even) {
    background-color: #f9f9f9; }
  .listWrapper .img {
    width: 8rem;
    display: inline-flex;
    height: 100%;
    margin-right: 1rem;
    padding: 0.25rem;
    justify-content: center; }
    .listWrapper .img img {
      max-height: 100%; }
  .listWrapper .name {
    width: 40%;
    display: inline-block;
    margin-right: .5rem; }
  .listWrapper .portionCost {
    width: 5rem;
    display: inline-block;
    margin-right: .5rem; }
  .listWrapper .grade {
    width: 7rem;
    display: inline-block; }
  .listWrapper .votes {
    width: 5rem;
    display: inline-block; }
  .listWrapper .remove {
    width: 5rem;
    display: inline-block;
    text-align: right; }
