/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.base {
  margin-bottom: 1rem; }

.main {
  background: none; }

.RawWrapper {
  padding: 0 1rem; }
  .RawWrapper .RawBody {
    display: flex;
    flex-flow: wrap;
    margin-left: -30px; }
  @media screen and (min-width: 480px) {
    .h2 {
      margin-top: 2.2rem; } }
  @media screen and (min-width: 992px) {
    .h2 {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
  .h2 {
    margin-top: 2rem; } }
