/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  top: 0;
  height: 100%;
  width: 23rem;
  max-width: calc(100% - 4rem);
  background: white;
  will-change: transform;
  transition: -webkit-transform 250ms cubic-bezier(0.2, 0.58, 0.47, 0.97);
  transition: transform 250ms cubic-bezier(0.2, 0.58, 0.47, 0.97);
  transition: transform 250ms cubic-bezier(0.2, 0.58, 0.47, 0.97), -webkit-transform 250ms cubic-bezier(0.2, 0.58, 0.47, 0.97); }
  .base > :first-child {
    height: 100%; }
    .base > :first-child > :last-child {
      height: auto; }

.fillWidth {
  width: 100%;
  max-width: 100%; }

.scroll, .base > :first-child > :last-child:not(.noscroll) {
  overflow: auto; }

.left {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0); }
  .left.open {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0); }

.right {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0); }
  .right.open {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0); }
