/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .wrapper input {
    width: 100%; }
  .wrapper .removeAll {
    margin-top: 2rem; }

.list {
  width: 100%; }
  .list tbody {
    width: 100%; }
  .list tr {
    background-color: #eff0f2; }
  .list tbody tr:nth-child(odd) {
    background-color: #FFF; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem; }
  .list td.arrowDown::after, .list th.arrowDown::after {
    bottom: -10px;
    border-top: 5px solid black; }
  .list th {
    padding: 1rem;
    white-space: nowrap;
    color: black;
    background: #fff; }
  .list td {
    padding: 1rem;
    vertical-align: middle; }
  .list .right {
    float: right; }

.arrowUp:after, .arrowDown:after {
  position: relative;
  margin-left: 0.25rem;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  cursor: pointer; }

.arrowUp:after {
  top: -10px;
  border-bottom: 5px solid black; }

.arrowDown:after {
  bottom: -10px;
  border-top: 5px solid black; }
