/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  composes: button from '../../../../../SiteLayout/Header/base.scss';
  composes: base 1to1 center from '../../../../../../styles/objects/ratio.scss';
  margin-right: 0;
  font-size: 28px; }
  .base:hover, .base:focus {
    color: #f7594d; }
