/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  white-space: pre; }

.text {
  display: block;
  letter-spacing: 0; }
