/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.info {
  text-align: center;
  position: relative; }

button.showMoreBtn {
  font-family: SFNS_bold, sans-serif;
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 60px;
  font-size: 12px; }

.sortAndViewBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .75rem 0.25rem;
  margin: -32px -.75rem 1.25rem;
  border-bottom: 1px solid #f7594d;
  position: relative; }

.productCount {
  font-family: SFNS, sans-serif;
  font-size: 1.286rem; }

.columnTitle {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  font-size: 1.286rem;
  font-family: SFNS, sans-serif;
  padding: 0 .25rem;
  padding-left: 1rem; }

.sortAndViewActions {
  display: flex;
  align-items: center;
  margin-left: -24px; }

.sortAndViewActionItem {
  padding-left: 24px; }

.sortDropDown {
  border: none; }
  .sortDropDown > select {
    height: 40px;
    padding-left: 18px; }
  .sortDropDown > span {
    padding-right: 5px; }
  .sortDropDown .hover {
    text-decoration: underline; }

.facetListPartWrap {
  margin-top: 0 !important; }

.clearFacets {
  text-align: left;
  font-size: 12px !important;
  padding: 0 0.5rem !important;
  margin-bottom: 1rem !important;
  border: 1px solid #e7e7e7 !important;
  display: inline-block !important;
  border-radius: 0 !important; }
  .clearFacets svg {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
    vertical-align: baseline; }
  .clearFacets:hover, .clearFacets:focus, .clearFacets:active {
    background-color: transparent; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 1.5em; }

.hiddenWhileLoading > span > span {
  visibility: hidden; }

:global(.VirtualScroll) {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

:global(.Grid__cell) {
  position: absolute; }

:global(.Grid__innerScrollContainer) {
  box-sizing: border-box;
  overflow: hidden;
  position: relative; }

:global(.Grid) {
  position: relative;
  overflow: visible !important;
  -webkit-overflow-scrolling: touch; }

.mobileFilterWrapper {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10008; }
  .mobileFilterWrapper.open {
    top: 0px; }
    .mobileFilterWrapper.open .toggler {
      text-align: left;
      align-items: center;
      display: flex; }
      .mobileFilterWrapper.open .toggler svg {
        display: flex; }
        .mobileFilterWrapper.open .toggler svg + svg {
          display: flex;
          margin-left: auto; }
  .mobileFilterWrapperBottom {
    width: 100%;
    background: #f5f5f5;
    display: flex;
    display: none; }
    .open .mobileFilterWrapperBottom {
      display: flex; }
  .mobileFilterWrapperMiddle {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    display: none; }
    .open .mobileFilterWrapperMiddle {
      display: block; }
  .mobileFilterWrapperTop {
    width: 100%;
    background: #f5f5f5; }
  .mobileFilterWrapperFiltersList {
    background: #f5f5f5;
    transition: all 300ms ease-in-out;
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    z-index: 10010; }
    .mobileFilterWrapperFiltersList.open {
      overflow-y: auto;
      height: 100%;
      top: 60px; }
  .mobileFilterWrapper .toggler {
    border-radius: 0 !important;
    width: 100%;
    background: #343d46 !important;
    text-transform: uppercase;
    font-size: 1.429rem;
    line-height: 1.2; }
    .mobileFilterWrapper .toggler span + span {
      padding: 0 .25rem; }
    .mobileFilterWrapper .toggler svg {
      height: 1rem;
      vertical-align: initial; }
      .mobileFilterWrapper .toggler svg + svg {
        display: none; }
  .mobileFilterWrapper .closeButton {
    display: block !important;
    margin: 2rem auto;
    background: #343d46 !important; }
  @media screen and (min-width: 992px) {
    .sortAndViewBar {
      padding: 0 .75rem 0.25rem 0;
      margin: 0rem 0 1rem 0;
      border-bottom: none; } }
