/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.canvas {
  margin-top: 0.5rem; }
  .canvas canvas, .canvas video {
    max-width: 100%;
    width: 100%; }
  .canvas canvas {
    position: absolute; }
  .canvas.scandit {
    height: 300px; }

.iosPreview {
  width: 100%;
  height: 300px; }
