/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  background: #f1f1f1;
  padding-bottom: 0; }
  .base h1 {
    margin: 0;
    padding: 0 1rem; }
  .base h2 {
    text-align: center; }
  .base form {
    margin: 0 auto;
    max-width: 800px;
    padding-top: 0; }
  .base.disabled {
    cursor: default; }

.myMenigoMenuItems div, .mobileMenuItems div {
  display: inline-block; }

.myMenigoMenuItems__item {
  padding: 1rem;
  position: relative;
  z-index: 5;
  color: #23272a;
  display: inline-block;
  text-decoration: none; }
  .myMenigoMenuItems__item:hover, .myMenigoMenuItems__item:active {
    text-decoration: none;
    background-color: #e5e5e5; }

.mobileMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem; }
  .mobileMenuHeader .activeMenuItemWrap {
    font-size: 16px;
    font-family: SFNS_bold, sans-serif; }
  .mobileMenuHeader button svg {
    margin-right: 0; }

.mobileMenuItems div {
  display: block; }

.mobileMenuItems .myMenigoMenuItems__item {
  width: 100%; }

.isCurrent {
  background-color: #e5e5e5; }
  .isCurrent .disabled {
    color: #999; }
    .isCurrent .disabled:hover, .isCurrent .disabled:active {
      color: #999; }

.disabled {
  color: #e5e5e5;
  pointer-events: none; }
  .disabled:hover, .disabled:active {
    color: #e5e5e5;
    background: inherit; }

.menuBlock {
  display: block;
  padding-top: 2rem;
  margin-top: -1.4rem; }
  .menuBlock ul {
    padding-left: 0;
    border-bottom: 1px solid #E5E5E5; }
  .menuBlock a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .menuBlock .menuItem:not(.isActive):hover {
    background-color: rgba(247, 89, 77, 0.1);
    color: #23272A; }
    .menuBlock .menuItem:not(.isActive):hover a {
      color: #23272A; }

.menuItem {
  width: 100%;
  color: #23272A;
  font-size: 1.1rem;
  line-height: inherit;
  background-color: white;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  border: 1px solid #E5E5E5;
  display: block;
  border-bottom: none; }
  .menuItem svg {
    margin-right: .75rem;
    overflow: visible; }
  .menuItem img {
    height: 1.5em;
    width: 1.2em;
    display: inline-block;
    margin-right: .8em; }

.menuItem a {
  display: block;
  text-decoration: none;
  padding: 0.6rem 0.25rem 0.6rem 1rem; }
  .menuItem a span {
    vertical-align: middle; }

.menuItem:hover {
  display: block;
  background-color: #E5E5E5;
  color: #f8584c;
  text-decoration: none; }
  .menuItem:hover img {
    -webkit-filter: invert(43%) sepia(96%) saturate(1238%) hue-rotate(329deg) brightness(96%) contrast(102%);
            filter: invert(43%) sepia(96%) saturate(1238%) hue-rotate(329deg) brightness(96%) contrast(102%); }

.isActive {
  display: block;
  background-color: #f8584c;
  text-decoration: none;
  color: #ffffff;
  cursor: default;
  border-color: #f8584c; }
  .isActive a {
    color: #ffffff; }
  .isActive:hover a {
    background-color: #f8584c;
    text-decoration: none;
    color: #ffffff; }
    .isActive:hover a svg, .isActive:hover a path, .isActive:hover a rect {
      stroke: #ffffff; }

.menuTextMark {
  background-color: #24733b;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.2rem 0.5rem;
  margin-left: 1rem; }

@media screen and (min-width: 768px) {
      .base h1 {
        text-align: center; } }
