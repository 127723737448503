.base {
  opacity: 1;
  transition: opacity 0.5s ease-in; }

.fadedOut {
  opacity: 0 !important; }

.noImageHolder {
  width: 100%;
  height: inherit;
  text-align: center; }
  .noImageHolder:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .noImageHolder img {
    vertical-align: middle;
    max-width: 100%;
    pointer-events: none; }

.stretchOnWidth {
  width: 100%; }

.stretchOnHeigt {
  height: 100%; }

.missingImage {
  max-width: 75% !important; }

.disableClicks {
  pointer-events: none; }
