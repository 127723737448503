/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.toolbar {
  padding: 0.5rem; }
  .toolbar .searchbar {
    display: flex; }
    .toolbar .searchbar form,
    .toolbar .searchbar input[type="search"] {
      width: 100%; }
    .toolbar .searchbar button {
      margin: 0 0.2rem; }
    .toolbar .searchbar .clear.base {
      height: 0.5rem; }
  .toolbar .noResults {
    background-color: maroon; }

.item {
  padding: 1rem 0; }
  .item:not(:first-child) {
    border-top: 1px solid #e5e5e5; }

.messageWrapperBox {
  padding: 1rem; }
