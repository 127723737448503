/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper .h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.wrapper {
  overflow: hidden; }
  .wrapper .h3 {
    margin-left: 1rem; }
  .wrapper .flexwrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 .375rem; }
    .wrapper .flexwrapper a {
      color: #23272a;
      text-decoration: none; }
    @media screen and (min-width: 480px) {
    .wrapper .h3 {
      margin-top: 2.2rem; } }
    @media screen and (min-width: 992px) {
    .wrapper .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; }
      .wrapper .h3 {
        margin-left: 0.5rem; }
      .wrapper .flexwrapper {
        padding: 0; } }
