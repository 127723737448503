/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.contentCardText,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }


.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }


.paragraph,
.contentCardText {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

h2.inspiration {
  margin-top: 0; }

h2.inspiration_big {
  margin-top: 0; }

h3.inspiration {
  margin-bottom: 0;
  margin-top: 0; }


.preamble {
  color: #6c737a; }

.base {
  display: flex;
  max-width: 154.28571rem;
  margin: 0 auto;
  padding: 0 0.71429rem;
  flex-direction: column; }
  .base.white {
    background-color: #fff; }
  .base.green {
    background-color: #e6ece8; }
  .base.red {
    background-color: #f5e8e7; }
  .base.yellow {
    background-color: #fcf0d5; }
  .base.gray {
    background-color: #f9f9f9; }
  .base .inner {
    max-width: 100rem;
    width: 100%;
    margin: 0 auto;
    padding: 10px 7px; }
    .base .inner .row {
      display: flex;
      flex-direction: column;
      padding: 1.42857rem 0 0; }
      .base .inner .row.reversed {
        flex-direction: column-reverse; }
        .base .inner .row.reversed .imageCell {
          margin-bottom: 1.42857rem; }
    .base .inner .contentCell,
    .base .inner .imageCell {
      flex: 0 0 100%; }
    .base .inner .imageCell img {
      aspect-ratio: 3/2; }

.contentCardsSection {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
  padding: 0; }

.contentCardsRow {
  display: flex;
  flex-wrap: wrap;
  padding: 1.42857rem 0;
  flex-direction: row; }

.contentCard {
  padding: 1.42857rem 0;
  flex: 1 0 100%; }
  .contentCard.noImage .contentWrapper, .contentCard.onlyImage .contentWrapper {
    padding-left: 0 !important; }
  .contentCard.noImage .imageWrapper, .contentCard.onlyImage .imageWrapper {
    margin-bottom: 0 !important; }

.contentCardInner {
  flex-direction: column;
  display: flex;
  cursor: pointer; }
  .contentCardInner img {
    width: 100%;
    aspect-ratio: 16/9; }
  .contentCardInner .imageWrapper {
    width: 100%;
    margin-bottom: 1.71429rem; }
  .contentCardInner .contentWrapper {
    width: 100%; }

.contentCardText {
  color: #6c737a;
  margin-bottom: 0; }

@media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; }
            .base .inner .row.reversed .imageCell {
              padding-left: 0;
              padding-right: 2.85714rem !important; }
        .base .inner .row {
          padding: 5.71429rem 2.64286rem 0 !important; }
    .contentCardsRow {
      padding: 2.85714rem 0; }
    .contentCard {
      padding: 0.71429rem 0; }
    .contentCard {
      padding: 2.85714rem 0 !important;
      flex: 0 0 33.33%; }
    .contentCard[data-block-width='narrow'] {
      flex: 0 0 33.33%; }
    .contentCard[data-block-width='wide'] {
      flex: 0 0 50%; }
    .contentCardInner {
      flex-direction: column !important;
      margin: 0 2.14286rem !important; }
      .contentCardInner .imageWrapper {
        margin-bottom: 1.71429rem !important; }
      .contentCardInner .contentWrapper {
        padding-left: 0 !important; } }

@media screen and (min-width: 768px) {
      .base .inner {
        padding: 0 0 0.85714rem !important; }
        .base .inner .row {
          flex-direction: row; }
          .base .inner .row.reversed {
            flex-direction: row-reverse; }
          .base .inner .row.reversed .contentCell {
            padding-right: 0;
            padding-left: 2.85714rem; }
            .base .inner .row.reversed .imageCell {
              margin-bottom: 0;
              padding-left: 0;
              padding-right: 0.5rem; }
        .base .inner .row {
          padding: 2.85714rem 1.42857rem 0; }
        .base .inner .contentCell,
        .base .inner .imageCell {
          flex: 0 0 50%; }
      .base .inner .contentCell {
        padding-left: 0;
        padding-right: 2.85714rem; }
      .base .inner .imageCell {
        padding-right: 0;
        padding-left: 2.85714rem; }
    .contentCardsRow {
      margin: 0 -1.42857rem; }
    .contentCard.noImage, .contentCard.onlyImage {
      flex-grow: 1;
      flex-basis: 50%; }
      .contentCard.noImage .imageWrapper, .contentCard.onlyImage .imageWrapper {
        margin-bottom: 0 !important; }
    .contentCardInner {
      flex-direction: row;
      margin: 0 0.71429rem; }
      .contentCardInner .imageWrapper {
        margin-bottom: 0; }
      .contentCardInner .contentWrapper {
        padding-left: 2.85714rem; } }

@media screen and (min-width: 480px) {
    .contentCardsRow {
      margin: 0 -0.35714rem; }
    .contentCardInner {
      padding: 0 0.71429rem; } }

@media screen and (min-width: 768px) and (min-width: 992px) {
    .contentCard.noImage, .contentCard.onlyImage {
      flex: 1 0 33.33%; } }
