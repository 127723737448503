/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.sideMenu {
  height: auto; }
  .sideMenu .menuName {
    background: #eff0f2;
    margin: 0;
    padding: 1rem;
    font-size: 2.28571rem;
    border: 1px solid #dee1e4;
    border-bottom: 0; }
  .sideMenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 1rem;
    border: 1px solid #dee1e4; }
    .sideMenu ul > li {
      display: block; }
    .sideMenu ul li {
      margin-bottom: 1rem; }
      .sideMenu ul li:last-child {
        margin-bottom: 0; }
      .sideMenu ul li.current {
        font-weight: bold; }
    .sideMenu ul a {
      color: inherit; }

.hero {
  margin-bottom: 3rem; }

.pageName {
  margin-top: 0;
  margin-bottom: 2rem; }

.text {
  font-size: 12.6px;
  margin: 0; }
  .text a {
    text-decoration: underline;
    cursor: pointer; }
  @media print {
    .sideMenu {
      display: none; } }
