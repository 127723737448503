/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.salesperson_base {
  color: white;
  background-color: #aeaeae;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%; }

.salesperson_imgarea {
  display: inline-block; }

.salesperson_img {
  width: 100px;
  height: 100px; }

.salesperson_info {
  padding-left: 10px;
  display: inline-block; }
