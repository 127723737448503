/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.field {
  flex: 1;
  display: flex; }

.open {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .open ~ * {
    display: none; }
  .open .base {
    display: none; }
