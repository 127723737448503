/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.larger {
  width: 6rem;
  background: #fff; }

.short {
  width: 5rem; }

.medium {
  width: 6rem; }

.productName {
  width: 35%; }

.inline {
  width: 7rem;
  white-space: nowrap;
  text-align: right; }
  .inline p {
    margin: 10% auto;
    display: block; }
  .inline > * {
    display: inline-block; }

.row {
  position: relative; }
  .row .customRow {
    color: #f98b82;
    border-radius: 2rem;
    height: 2rem;
    width: 4.7rem;
    right: 1rem;
    line-height: 1rem; }

.lightGray {
  color: #999;
  display: block; }

.list {
  margin-top: 0.5rem;
  width: 100%; }
  .list tbody {
    width: 100%; }
  .list tr {
    background-color: #eff0f2; }
    .list tr:not(:first-child) {
      border-top: 1px solid #fff; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap;
    color: #999;
    background: #fff; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: initial; }
  .list .tdInputWrapper {
    display: flex;
    align-items: center;
    white-space: nowrap; }
    .list .tdInputWrapper input {
      width: 4.5rem;
      margin-right: 0.1rem; }
  .list .footer {
    white-space: nowrap; }
    .list .footer a {
      text-decoration: underline;
      cursor: pointer; }
  .list .right {
    text-align: right; }
  .list .removeIcon {
    cursor: pointer;
    display: inline-block; }
