/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrap {
  line-height: inherit;
  font-size: inherit; }

.disabledInfo {
  color: #f7594d;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 13px; }
  .disabledInfo.useNewDesign {
    margin-bottom: 0;
    line-height: 100%;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px; }

.replacementInfo {
  color: #999; }
  .replacementInfo.useNewDesign {
    padding-bottom: 24px;
    min-width: 132px;
    line-height: 19px; }

.replacementLink:hover {
  text-decoration: none; }

@media screen and (min-width: 1200px) {
    .disabledInfo {
      font-size: 14px; } }
