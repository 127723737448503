/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.priceItem:not(:last-child) {
  margin-bottom: 1rem; }

.campaignPrice {
  color: #f7594d;
  display: inherit;
  font-weight: bold;
  flex-direction: column; }

.strikethrough {
  text-decoration: line-through; }
