/*TODO - detailed colorschema will come later*/
:global .CalendarMonthGrid_month__horizontal .CalendarDay__selected,
:global .CalendarMonthGrid_month__horizontal .CalendarDay__selected:hover {
  background: black;
  border: 1px solid black; }

:global .CalendarDay__blocked_out_of_range {
  color: #e5e5e5; }
  :global .CalendarDay__blocked_out_of_range button:hover {
    color: #e5e5e5 !important;
    background-color: white !important; }

:global button.DayPickerKeyboardShortcuts_close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 4rem !important;
  height: 4rem !important; }

:global button.DayPickerKeyboardShortcuts_show__bottomRight {
  background-color: transparent !important; }
