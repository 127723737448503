/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.child .name {
  font-family: SFNS, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem; }

.child .description {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem; }

.product_list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .product_list.product_list__large .product_list__link:hover ~ .product_list__row {
    background-color: #F5D7D5; }
  .product_list li {
    position: relative; }
    .product_list li:not(:last-child) {
      border-bottom: 0.5px solid #343D46; }
  .product_list__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
    .product_list__link:hover ~ .product_list__row {
      background-color: #E7E7E7; }
  .product_list__row {
    display: flex;
    padding: 5px 0; }
  .product_list__image {
    flex: 0 1 45px;
    margin-left: 2px;
    padding: 2px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden; }
    .product_list__image > div {
      margin: 4px; }
  .product_list__title {
    padding-left: 24px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .product_list__title h5 {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      line-height: 1.42857rem;
      font-family: SFNS, sans-serif;
      font-weight: 600; }
    .product_list__title span {
      font-size: 0.85714rem;
      line-height: 0.85714rem; }
    .product_list__title h5, .product_list__title span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block; }
  .product_list__price {
    flex: 0 1 120px;
    font-size: 1rem;
    line-height: 1.42857rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
    .product_list__price .noPrice {
      margin: 0 0 0 1rem;
      font-size: 0.85714rem;
      line-height: 1rem; }
  .product_list__action {
    flex: 0 1 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .product_list__action a {
      display: inline-block;
      padding: 0 10px; }
      .product_list__action a svg {
        margin-top: -1px;
        height: 1.14286rem; }

.product_price__original,
.product_price__original * {
  text-decoration: line-through; }

.product_price__campaign {
  color: #F7594D; }

.child {
  width: 100%;
  height: auto;
  margin: 0.385rem 0.385rem;
  position: relative;
  overflow: hidden; }
  .child a {
    color: #23272a;
    text-decoration: none; }
  .child .name {
    margin-top: calc(0.714rem * 2);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold; }
  .child .description {
    color: #999;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    height: calc(1.5rem * 4);
    margin-bottom: calc(0.714rem); }
  .child .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    width: 100%;
    height: calc((100vw - 0.75rem * 2)/1.69231); }
  .child .footer,
  .child .header {
    text-transform: uppercase;
    color: #999999; }
  .child .footer {
    margin-top: 0.714rem; }
  .child.all {
    width: calc(100% - 0.385rem * 2); }
  .child.medium .image {
    height: 100% !important;
    width: 100%; }
  .child.news {
    width: calc(100% - 0.385rem * 2); }
    .child.news .image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      width: 100%; }
    .child.news .description {
      -webkit-line-clamp: 3;
      height: calc(1.5rem * 3); }
  .child.large {
    margin: 0 !important; }
    .child.large .child__inner {
      position: relative;
      background: #f5e8e7;
      margin: 5px 0; }
    .child.large .split {
      display: flex;
      height: 12.07143rem;
      overflow: hidden;
      align-items: center; }
    .child.large.large_with_image .split {
      height: 12.07143rem; }
    .child.large.large_with_products .split {
      height: 12.07143rem; }
    .child.large .child__text {
      padding: 0 26px;
      flex: 0 1 45%; }
    .child.large .child__image {
      flex: 1;
      height: 100%; }
    .child.large .child__products {
      flex: 1;
      display: flex;
      align-items: center; }
      .child.large .child__products ul {
        flex: 1; }
    .child.large .image {
      height: 100% !important;
      width: unset;
      background-size: cover;
      background-position: 0 50%; }
    .child.large .title {
      margin: 0; }

.content, .double, .medium {
  width: calc(100% - 0.385rem * 2); }

.double .image {
  background-size: cover;
  height: calc((100vw - 0.75rem* 2)/0.61972); }

.large {
  height: initial;
  width: 100%;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem; }
  .large .image {
    height: auto;
    width: 100%; }

.href {
  cursor: pointer; }

@media screen and (min-width: 992px) {
    .child .name {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.42857rem;
      line-height: 1.85714rem; }
    .child .description {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.42857rem;
      line-height: 1.85714rem; }
      .child .description {
        height: calc(1.85714rem * 4); }
        .child.news .description {
          height: calc(1.85714rem * 3); } }

@media screen and (min-width: 768px) {
    .child {
      margin: 0.73529vw 0.73529vw; }
      .child .image {
        height: calc((50vw - 1rem - 0.73529vw)/1.69231); }
      .child.all {
        width: calc(50% - 0.73529vw * 2); }
      .child.news {
        width: calc(50% - 0.73529vw * 2); }
        .child.news .image {
          height: calc((((50vw - 1rem - 0.73529vw) * 0.75) - 0.5rem)/1.69231); }
    .content, .double, .medium {
      width: calc(50% - 0.73529vw * 2);
      height: calc((50vw - 1rem - 0.73529vw)/1.02326); }
  .double {
    height: calc(((50vw - 1rem - 0.73529vw)/1.02326 + 0.73529vw)*2); }
    .double .image {
      width: 100%;
      height: calc((50vw - 1rem - 0.73529vw)/0.63768 + 0.73529vw * 2); } }

@media screen and (min-width: 1360px) {
    .child {
      margin: 0.714rem 0.714rem !important; }
      .child .image {
        height: calc(((1360px - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/1.69231) !important; }
      .child.all {
        width: calc(33.3333% - 0.714rem * 2) !important; }
      .child.medium .image {
        height: 100% !important; }
      .child.news {
        width: calc(33.3333% - 0.714rem * 2) !important; }
        .child.news .image {
          height: calc(((((1360px - (1rem - 0.73529vw)*2) * 0.75) - 0.75rem)/3 - 0.73529vw * 2)/1.69231) !important; }
    .content, .double, .medium {
      width: calc(33.3333% - 0.714rem * 2) !important;
      height: calc(((1360px - (1rem - 0.714rem)*2)/3 - 0.714rem * 2)/1.02326) !important; }
  .double {
    height: calc((((1360px - (1rem - 0.714rem)*2)/3 - 0.714rem * 2)/1.02326 + 0.714rem)*2) !important; }
    .double .image {
      height: calc(((1360px - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/0.63768 + 0.73529vw * 2) !important; } }

@media screen and (min-width: 1200px) {
      .child .image {
        height: calc(((100vw - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/1.69231); }
      .child.all {
        width: calc(33.3333% - 0.73529vw * 2); }
      .child.news {
        width: calc(33.3333% - 0.73529vw * 2); }
        .child.news .image {
          height: calc(((((100vw - (1rem - 0.73529vw)*2) * 0.75) - 0.75rem)/3 - 0.73529vw * 2)/1.69231); }
        .child.large .child__text {
          flex-basis: 489px; }
    .content, .double, .medium {
      width: calc(33.3333% - 0.73529vw * 2);
      height: calc(((100vw - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/1.02326); }
  .double {
    height: calc((((100vw - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/1.02326 + 0.73529vw)*2); }
    .double .image {
      height: calc(((100vw - (1rem - 0.73529vw)*2)/3 - 0.73529vw * 2)/0.63768 + 0.73529vw * 2); } }

@media screen and (max-width: 991px) {
      .child.large {
        display: none; } }
