/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.price {
  display: inline-block; }

.table {
  width: 100%; }
  .table .tablerow {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    display: flex; }
    .table .tablerow .col0 {
      display: flex; }
    .table .tablerow .name {
      flex: 3; }
    .table .tablerow .imageWrap {
      margin-right: 1rem;
      border: 1px solid #e5e5e5;
      position: relative; }
    .table .tablerow .meal img {
      position: relative;
      background-color: #ffffff; }
    .table .tablerow .meal:before {
      content: ' ';
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      right: -0.5rem;
      bottom: -0.5rem;
      border: 1px solid #e5e5e5; }
  .table .tableheader {
    display: flex;
    border: 1px solid #e5e5e5;
    color: #343d46;
    background-color: #fafafa; }
    .table .tableheader div {
      padding: 0.8rem 0 0.8rem 0; }
    .table .tableheader .textWrapper {
      padding-left: 25%; }
  .table .tablecell, .table .col0, .table .col1, .table .col2, .table .col3 {
    vertical-align: top; }
  .table .col0 {
    flex-grow: 20;
    min-width: 18em; }
  .table .col1 {
    flex-grow: 0;
    min-width: 18em; }
  .table .col2 {
    flex-grow: 0;
    min-width: 14em; }
  .table .col3 {
    flex-grow: 0;
    min-width: 2em; }
    .table .col3 .lock {
      min-width: 2.5rem;
      text-align: center; }

.mobile {
  margin: -0.5rem; }
  .mobile .mobileCard {
    width: 100%;
    display: flex;
    padding-bottom: 1rem; }
    .mobile .mobileCard .col0 {
      width: 25%; }
      .mobile .mobileCard .col0 .imageWrap {
        width: 100%;
        padding-top: 1rem;
        padding-right: 1rem; }
    .mobile .mobileCard .col1 {
      width: 70%; }
      .mobile .mobileCard .col1 .name {
        margin: 0.5rem 0 1rem; }
    .mobile .mobileCard .col2 {
      width: 5%;
      display: flex;
      flex-direction: column;
      align-items: center; }

.textWrapper {
  flex: 1;
  flex-grow: 4;
  min-width: 5rem; }
  .textWrapper .linkText {
    color: #f66d62;
    padding-bottom: 0.5rem;
    display: block; }
  .textWrapper .mobile {
    padding-top: 0.5rem; }

.linkText em {
  display: block;
  padding: 0.25rem 0; }

.imageWrap {
  width: 25%;
  flex: 1;
  align-self: center;
  text-align: center; }
  .imageWrap img {
    max-height: 4rem; }

.base {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1rem -0.5rem 1rem; }
  .base .card {
    height: 26.072rem;
    flex: 1;
    border: 1px solid #e5e5e5;
    border-radius: 0.25rem;
    margin: 1rem 0.5rem 0;
    position: relative;
    display: flex;
    flex-direction: column; }
    .base .card .remove {
      position: absolute;
      top: 0.5rem;
      right: 0; }
    .base .card .lock {
      min-width: 2rem;
      text-align: right; }
    .base .card .imageWrap {
      width: 100%;
      border-bottom: 1px solid #e5e5e5;
      height: 15.286rem;
      flex: 1 0 0;
      align-items: center;
      display: inline-flex;
      overflow: hidden; }
      .base .card .imageWrap a {
        width: 100%; }
      .base .card .imageWrap img {
        width: 100%;
        max-height: 100%; }
    .base .card .info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 2rem 2rem 1rem;
      flex: 1; }
    .base .card .cardRow {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .base .card .cardRow .price, .base .card .cardRow sup {
        color: #999;
        font-weight: normal; }
      @media screen and (min-width: 768px) {
      .table .tablecell, .table .col0, .table .col1, .table .col2, .table .col3 {
        padding: 1.2rem 0rem; } }
      @media (min-width: 768px) {
      .base .card {
        min-width: 25%; } }
