/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base .logo {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  display: block;
  max-width: 250px;
  max-height: 100px; }
  .base .logo svg {
    color: #eff0f2; }
