.base.\1to1:before, .base.\16to9:before, .base.\28to9:before, .base.\32to9:before {
  content: '';
  display: block; }

.stretchy:not(.left):not(.right) > :first-child, .cropx:not(.left):not(.right) > :first-child {
  margin-left: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.stretchx:not(.top):not(.bottom) > :first-child, .cropy:not(.top):not(.bottom) > :first-child {
  margin-top: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.base {
  position: relative;
  overflow: hidden; }
  .base.\1to1:before {
    padding-top: 100%; }
  .base.\16to9:before {
    padding-top: 56.25%; }
  .base.\28to9:before {
    padding-top: 32.14286%; }
  .base.\32to9:before {
    padding-top: 28.125%; }

.center > :first-child {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain; }

.top {
  composes: center; }
  .top > :first-child {
    margin-top: 0; }

.right {
  composes: center; }
  .right > :first-child {
    margin-right: 0; }

.bottom {
  composes: center; }
  .bottom > :first-child {
    margin-bottom: 0; }

.left {
  composes: center; }
  .left > :first-child {
    margin-left: 0; }

.stretchy {
  composes: center; }
  .stretchy > :first-child {
    max-width: none;
    height: 100%; }

.stretchx {
  composes: center; }
  .stretchx > :first-child {
    max-height: none;
    width: 100%; }

.cropx {
  composes: center; }
  .cropx > :first-child {
    max-width: none;
    max-height: 100%; }

.cropy {
  composes: center; }
  .cropy > :first-child {
    max-width: 100%;
    max-height: none; }
