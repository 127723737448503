/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  margin: 0 auto;
  max-width: 154.28571rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%; }
  .base img {
    opacity: 0;
    aspect-ratio: 4/1; }
