/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.quickSearch {
  display: flex;
  color: #23272a;
  flex-grow: 1; }

.smallspinner {
  height: 1.25rem;
  width: 1.25rem; }

.absoluteWrapper {
  position: absolute;
  width: auto;
  left: 4.6px;
  right: 4.6px;
  top: 11.5px; }

.inputContainer {
  background-color: white;
  position: relative;
  flex: 1;
  min-height: 46px;
  min-width: 200px;
  padding: 0 1rem;
  border: 1px solid #F7594D;
  border-radius: 5px;
  transition: all 100ms linear; }
  .inputContainer input {
    width: 100%;
    height: 23px;
    line-height: 23px;
    border-width: 0;
    background-color: transparent;
    font-size: 1rem !important;
    padding: 0.25rem;
    border-radius: 0;
    transition: all 100ms ease;
    border-bottom: 1px solid transparent; }
    .inputContainer input::-ms-clear, .inputContainer input::-ms-reveal {
      display: none; }
    html:global(.ie) .inputContainer input {
      line-height: inherit; }

.focused {
  top: 0.6rem;
  min-height: 60px;
  z-index: 2; }
  .focused input {
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #F7594D !important;
    font-weight: bold;
    padding-right: 50px; }

.clear {
  composes: bare from "../../../../../../styles/objects/buttons.scss";
  font-size: 1rem;
  height: 46px;
  line-height: 46px;
  width: 46px;
  color: black;
  background: transparent;
  position: absolute;
  right: 36.8px;
  top: 0;
  bottom: 0;
  height: auto;
  width: 20px; }
  .clear svg {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    left: 0px;
    margin: auto;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }

.querySuggestion {
  position: absolute;
  bottom: 15px;
  left: 0.55rem;
  line-height: 23px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  color: #6C737A;
  height: 32px;
  line-height: 32px;
  display: none; }
  .querySuggestion span {
    height: 32px;
    line-height: 32px; }
  .focused .querySuggestion {
    display: block; }

.querySuggestionPrefix {
  visibility: hidden; }

.button {
  position: absolute;
  right: 11.5px;
  top: 0;
  bottom: 0;
  height: auto;
  width: 20px; }
  .button svg {
    color: #F7594D;
    top: -5px; }

.autocomplete {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  z-index: -1; }
