/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.videoContainer {
  position: relative;
  overflow: hidden; }
  .videoContainer:before {
    content: "";
    float: left;
    padding-top: calc(9 / 16 * 100%); }
