/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative; }
  .base:disabled {
    color: white; }
    .base:disabled span {
      text-decoration: none;
      font-weight: normal; }

html:not(:global(.ios)) .active.succeeded[type='link'] {
  background: transparent !important; }

html:not(:global(.ios)) .active.failed[type='link'] {
  background: transparent !important; }

.active[data-feedback-text]:before {
  content: attr(data-feedback-text); }

html:not(:global(.ios)) .active.succeeded {
  background: #6fb172 !important; }

.active.succeeded[data-feedback-text=''][data-feedback-text]:before {
  content: '\2714';
  font-family: 'Zapf Dingbats'; }
  html:global(.ios) .active.succeeded[data-feedback-text=''][data-feedback-text]:before {
    color: #6fb172; }

html:not(:global(.ios)) .active.failed {
  background: #ef7172 !important; }

.active.failed[data-feedback-text=''][data-feedback-text]:before {
  content: '\2716';
  font-family: 'Zapf Dingbats'; }
  html:global(.ios) .active.failed[data-feedback-text=''][data-feedback-text]:before {
    color: #ef7172; }

.base .text, .active[data-feedback-text]:before {
  display: block;
  transition: opacity 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: opacity 200ms ease-out, transform 200ms ease-out;
  transition: opacity 200ms ease-out, transform 200ms ease-out, -webkit-transform 200ms ease-out; }

.active[data-feedback-text]:before, .active.spinning .text, .active.succeeded .text, .active.failed .text {
  opacity: 0.01;
  -webkit-transform: scale(0.01);
          transform: scale(0.01); }

.active.succeeded[data-feedback-text]:before, .active.failed[data-feedback-text]:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.active[data-feedback-text]:before {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  padding: inherit;
  font-size: 20px;
  padding-left: 0;
  padding-right: 0; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 1.5em; }
