/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  margin: 0 -1.42857rem; }
  .base .aspectRatio {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: 0;
    display: inline-flex; }
    .base .aspectRatio.secondaryImage, .base .aspectRatio.thiredImage {
      width: calc(50% - 8px);
      aspect-ratio: 4 / 5;
      margin-top: 10px;
      margin-left: 16px; }
    .base .aspectRatio.secondaryImage {
      margin-left: 0rem; }
    .base .aspectRatio .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0%; }
  .base img {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    opacity: 0; }

.text {
  margin: 0 -1.42857rem; }
  .text p {
    margin: 0.5rem 1.42857rem 0; }
  .text p + p {
    margin: 0 1.42857rem 0; }
  .text .imageCredits,
  .text .imageText {
    font-family: SFNS, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #0d0f11; }
  .text .imageCredits {
    color: #0d0f1199; }
  @media screen and (min-width: 768px) {
    .base {
      margin: 0 -45vw;
      overflow: hidden;
      max-height: 480px; }
    .base .wideWrapper {
      width: 100%;
      max-width: 100vw;
      margin: 0 auto;
      display: flex; } }
  @media screen and (min-width: 480px) {
    .base .wideWrapper {
      display: flex; }
      .base .aspectRatio {
        width: 50%; }
        .base .aspectRatio.secondaryImage, .base .aspectRatio.thiredImage {
          margin-top: 0;
          width: calc(25% - 16px);
          margin-left: 16px; }
        .base .aspectRatio.secondaryImage {
          margin-left: 16px; } }
  @media screen and (min-width: 1200px) {
        .base .aspectRatio.secondaryImage, .base .aspectRatio.thiredImage {
          margin-top: 0;
          margin-left: 20px;
          width: calc(25% - 20px); }
        .base .aspectRatio.secondaryImage {
          margin-left: 20px; } }
