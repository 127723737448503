/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  position: relative;
  display: flex; }
  .wrapper .quantityAndVariantWrap {
    flex: 0 0 auto;
    display: flex;
    margin-left: auto; }
    .wrapper .quantityAndVariantWrap .variantWrapper {
      display: flex; }

[data-ab-hide='b'] .quantityAndVariantWrap {
  margin-bottom: 0.75rem; }
