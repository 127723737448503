/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.h4 {
  margin-bottom: 1rem; }

.base h1 {
  overflow: visible; }

.iconWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center; }
  .iconWrapper .icon {
    display: flex;
    align-items: center;
    font-size: 2.71429rem;
    margin-right: 2rem; }
    .iconWrapper .icon img {
      height: 2.71429rem; }
    .iconWrapper .icon span {
      font-size: 1.42857rem;
      padding-right: 0.5rem;
      font-weight: bold; }

.leftSection {
  position: relative;
  margin-top: 0rem; }

.markerWrapper span {
  font-weight: bold; }

.productInformation {
  padding-top: 2rem; }
  .productInformation .priceAvailabilityWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem; }
  .productInformation .expiration .h4 {
    margin-top: 0;
    margin-bottom: 0; }
  .productInformation .article .h4,
  .productInformation .article ul {
    margin-top: 0.5rem; }
  .productInformation .article ul li {
    border-bottom: none; }
    .productInformation .article ul li a {
      text-decoration: none; }
      .productInformation .article ul li a:hover {
        color: #ff9900; }
  .productInformation .description {
    position: relative;
    height: inherit; }
    .productInformation .description h4,
    .productInformation .description .h4,
    .productInformation .description p {
      margin-top: 0.5rem; }
    .productInformation .description .descriptionLinks {
      width: 100%; }
      .productInformation .description .descriptionLinks a {
        color: #23272a;
        display: block;
        font-weight: bold;
        margin-top: 0.5rem;
        text-decoration: underline; }

.main {
  flex-grow: 1;
  padding-left: 0; }

.campaign,
.campaignPrice {
  margin-left: 1rem;
  color: #ff0000;
  font-weight: bold;
  font-size: 1.2rem; }

.strikethrough {
  color: #666;
  text-decoration: line-through; }

.productContent {
  max-width: 610px; }

.unitDropdown {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%; }
  .unitDropdown span {
    display: flex;
    align-items: center; }

.listQuantityWrapper {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0; }

.productVideo {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .productVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.availability {
  display: flex; }
  .availability b {
    margin-left: 0.5rem;
    border-left: 1px #343d46 solid;
    padding-left: 0.5rem; }

.priceDetails {
  color: #23272a;
  font-weight: lighter;
  padding: 0 0.5rem 1rem; }

.productDisabledInfoWrap {
  font-family: SFNS_bold, sans-serif; }

.addRemoveList {
  margin-right: 1rem; }

.handyInformationRow {
  background: #f9f9f9;
  margin-top: 0;
  padding: 1.5rem; }
  .handyInformationRow .h2 {
    margin-top: 0; }
  .handyInformationRow main {
    background: #f9f9f9; }

.handyInformationRow {
  padding-top: 0 !important; }

.descriptionWrapper {
  background: #f9f9f9;
  position: relative;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .descriptionWrapper > :first-child {
    padding-top: 2rem; }
  .descriptionWrapper :empty {
    padding-top: 0 !important;
    margin-top: 0 !important; }

.relatedContent {
  margin-top: 0; }
  .relatedContent main {
    padding: 0.5rem 1.5rem; }
  @media screen and (min-width: 480px) {
    .h2 {
      margin-top: 2.2rem; }
    .h3 {
      margin-top: 2.2rem; }
    .h4 {
      margin-top: 2.2rem; } }
  @media screen and (min-width: 992px) {
    .h2 {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
    .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; }
    .h4 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.42857rem; }
  .h2 {
    margin-top: 2rem; }
      .iconWrapper .icon {
        font-size: 3.85714rem; }
        .iconWrapper .icon img {
          height: 3.85714rem; }
    .markerWrapper span {
      font-family: SFNS_bold, sans-serif;
      font-weight: 400;
      font-size: 1.14286rem; }
    .productInformation .articleWrapper {
      padding-left: 0 !important; }
    .main {
      margin-top: 2rem; }
    .availability {
      height: 1.5rem;
      padding-top: 0.5rem; }
      .availability b {
        min-height: 1.5rem;
        display: inline-block; }
    .priceDetails {
      padding: 1rem 0.5rem 0.5rem 0; } }
  @media screen and (min-width: 768px) {
  .base h1 {
    font-size: 3.71429rem; }
      .relatedContent main {
        padding: 0.5rem 0; } }
