.max {
  max-width: 97.14286rem; }

.center {
  margin-left: auto;
  margin-right: auto; }

.full,
.narrow {
  composes: max center; }

.full {
  width: 100%; }

:not(.full).narrow {
  width: calc(100% - 2rem); }

@media screen and (min-width: 768px) {
    :not(.full).narrow {
      width: calc(100% - 3rem); } }
