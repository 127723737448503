/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  font-size: 14px; }
  .list .item {
    display: flex;
    color: #999; }
    .list .item .link {
      display: block;
      color: #999;
      white-space: nowrap; }
      .list .item .link:hover {
        text-decoration: none; }
      .list .item .link > svg {
        width: 1rem;
        height: 1rem;
        margin: -2px 0.5rem 0 0.5rem; }
    .list .item:last-of-type {
      min-width: 0; }
      .list .item:last-of-type .link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      @media print {
    .list {
      display: none; } }
      @media screen and (min-width: 1200px) {
    .list {
      font-size: 15px; } }
