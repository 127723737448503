@charset "UTF-8";
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.facebookButton {
  background-color: #6d84b4 !important;
  color: white !important;
  width: 100% !important; }
  .facebookButton:hover {
    background-color: #3b5998 !important; }
  .facebookButton > div {
    display: flex !important;
    align-items: center; }
    .facebookButton > div > svg {
      margin-right: 0.5rem;
      font-size: 1.4em; }

.separator {
  margin: 1rem 0 0 0;
  letter-spacing: 0.1rem;
  text-align: center;
  color: #aaa;
  background: linear-gradient(to bottom, #ccc, #ccc) 0 center/auto 2px repeat-x; }
  .separator span {
    padding: 0 1rem;
    background-color: white; }

.password {
  margin-top: 0.5rem; }

.rememberMe {
  display: block; }

.loginMessageFail {
  background-color: #f98b82;
  border: solid 1px #f7594d;
  margin: 0.5rem 0;
  padding: 1rem;
  color: white; }

.loginMessageOk {
  background-color: #d2e7d3;
  border: solid 1px #6fb172;
  margin: 0.5rem 0;
  padding: 1rem;
  color: white; }

.messageInfo {
  background-color: #f2f273;
  border: solid 1px #eff072;
  margin: 0.5rem 0;
  padding: 1rem; }

.belowInputsArea {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem; }

.loginForm {
  font-size: 0.85714rem;
  position: relative;
  white-space: normal;
  padding: 2rem;
  text-transform: uppercase; }

.footer {
  color: #fff;
  background-color: #343d46;
  padding: 2rem;
  text-transform: none;
  font-family: SFNS, sans-serif; }

.footerInner {
  max-width: 210px;
  margin-left: auto;
  margin-right: auto; }

.signUpText {
  font-size: 1.08rem; }

a.signUpLink {
  color: #fff;
  font-size: 1.5rem; }
  a.signUpLink:hover  {
    color: #ffffff; }
