/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  border: 1px solid #24733b;
  padding: 1rem;
  background-color: #ffffff;
  margin-bottom: 4rem; }

.infoIcon {
  margin-top: -0.4rem; }

.infoHeader {
  display: flex;
  color: #24733b; }

.infoHeaderText {
  margin-left: 1rem;
  margin-top: -0.1rem; }

.accordionHeader {
  display: flex;
  cursor: pointer;
  text-transform: uppercase; }
  .accordionHeader :hover {
    color: #f98b82; }
  .accordionHeader:hover h3,
  .accordionHeader:hover svg {
    color: #f98b82 !important; }

.plusMinusIcon {
  margin-top: 2.8rem;
  margin-left: 0.8rem; }
