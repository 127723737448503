/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 14px;
  font-family: SFNS_bold, sans-serif;
  font-size: 11px; }

.spinner {
  height: 20px;
  width: 20px; }

.cardInner {
  display: flex; }

.imageWrap {
  flex: 0 0 35px; }

.emvDetailWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px; }

.productTitleWrap {
  display: flex;
  justify-content: space-between; }

.newTabIcon {
  font-size: 21px;
  margin-left: 14px;
  cursor: pointer; }
  .newTabIcon:hover {
    color: #f8584c; }

.detailsWrap {
  margin-bottom: 5px; }

.unitAndPriceWrap {
  display: flex; }

.priceWrap {
  flex: 1;
  text-align: right; }

.bottomRowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px; }

.totalSavingsText {
  color: #6fb172; }

.unableToCalculateSavingsText {
  color: #ef7172; }

.addButtonWrapper {
  margin-left: 10px;
  flex-shrink: 0; }
  .addButtonWrapper .base {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    text-transform: uppercase; }
    .addButtonWrapper .base .text {
      display: inline-block;
      font-size: 11px; }
    .addButtonWrapper .base .icon {
      height: 1.5em;
      width: 1.5em;
      font-size: 13px; }
  .addButtonWrapper .inactive {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: #c2c5c8 !important; }
    .addButtonWrapper .inactive .text {
      display: inline-block;
      padding-right: 8px;
      font-size: 11px; }
    .addButtonWrapper .inactive .icon {
      height: 1.5em;
      width: 1.5em;
      font-size: 13px; }
  .addButtonWrapper .inactive:hover {
    background-color: #c2c5c8 !important; }

.emvTitleWrapper {
  height: 100%;
  margin-left: 48px;
  display: flex;
  justify-content: space-between;
  padding: 7px 14px;
  background: #704587;
  margin: -14px -14px 14px; }
  .emvTitleWrapper svg {
    color: #fff;
    height: 1.286rem;
    width: 1.286rem;
    margin-bottom: -4px;
    stroke-width: 0; }

.emvTitle {
  margin: 0;
  color: #fff;
  font-family: SFNS_thin, sans-serif;
  font-size: 1.143rem; }

.helpButton {
  width: 1.5rem;
  font-size: 1.1rem;
  height: 1.5rem;
  padding: 0 !important; }

@media screen and (min-width: 1200px) {
    .card {
      font-size: 12px; }
        .addButtonWrapper .base .text {
          font-size: 12px; }
        .addButtonWrapper .inactive .text {
          font-size: 12px; } }
