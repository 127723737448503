/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.base {
  flex: 1; }

.availabilityWrapper {
  display: inline-block;
  flex-wrap: wrap;
  width: 100%;
  padding: 0; }
  .availabilityWrapper .availabilityInfo {
    margin-left: 0.5rem; }
  .availabilityWrapper .monthWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #e5e5e5; }
    .availabilityWrapper .monthWrapper > span {
      width: 100%; }
    .availabilityWrapper .monthWrapper .month {
      display: flex;
      position: relative;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem 0.5rem;
      z-index: 2;
      position: relative;
      background: #f9f9f9; }
      .availabilityWrapper .monthWrapper .month:hover {
        cursor: pointer;
        background: #e5e5e5;
        transition: .3s; }
      .availabilityWrapper .monthWrapper .month .label {
        width: 5rem;
        margin-right: 0.5rem; }
      .availabilityWrapper .monthWrapper .month .arrow {
        position: absolute;
        right: 1rem; }
    .availabilityWrapper .monthWrapper .monthChildWrapper {
      padding: 1rem;
      background-color: #fafafa;
      width: 100%;
      display: inline-flex;
      align-items: center;
      z-index: 1;
      position: relative; }
      .availabilityWrapper .monthWrapper .monthChildWrapper .monthChild {
        width: 15rem;
        display: inline-block;
        padding-right: 1rem;
        margin: 0;
        padding: 0; }
      .availabilityWrapper .monthWrapper .monthChildWrapper .availabilityWrapper {
        display: inline-block;
        padding-right: 1rem;
        margin: 0;
        padding: 0; }

.availabilitycircle {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-left: 5px;
  background-color: #eee; }
  .availabilitycircle.good {
    background-color: #00913D; }
  .availabilitycircle.bad {
    background-color: #EB0000; }
  .availabilitycircle.neutral {
    background-color: #FEA700; }

.enter {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }
  .enter.enterActive {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 175ms ease-in-out;
    transition: transform 175ms ease-in-out;
    transition: transform 175ms ease-in-out, -webkit-transform 175ms ease-in-out; }

.leave {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }
  .leave.leaveActive {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: -webkit-transform 175ms ease-in-out;
    transition: transform 175ms ease-in-out;
    transition: transform 175ms ease-in-out, -webkit-transform 175ms ease-in-out; }
  @media screen and (min-width: 480px) {
    .h3 {
      margin-top: 2.2rem; } }
  @media screen and (min-width: 992px) {
    .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; } }
  @media screen and (min-width: 768px) {
    .enter {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
      .leave.leaveActive {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%); } }
