/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.printOptions {
  width: 100%; }
  .printOptions .printOptionsInner h4 {
    margin: calc(20px) 0 10px 0; }
  .printOptions .printOptionsInner .selectWrapper {
    display: flex;
    flex-direction: column; }
    .printOptions .printOptionsInner .selectWrapper .dropdownWrap {
      width: 100%; }
      .printOptions .printOptionsInner .selectWrapper .dropdownWrap > div {
        width: 100%; }
    .printOptions .printOptionsInner .selectWrapper .buttonWrapper {
      margin-top: 0.71429rem;
      display: flex;
      justify-content: space-between; }
      .printOptions .printOptionsInner .selectWrapper .buttonWrapper .printButton {
        width: calc(50% - 5px);
        text-transform: uppercase;
        font-size: 1.14286rem;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 44px;
        white-space: nowrap; }

.dropdownPrint {
  min-width: 213px; }

.dropdownAction {
  min-width: 241px; }

@media screen and (min-width: 768px) {
    .printOptions {
      width: auto;
      flex-flow: row wrap; }
      .printOptions .printOptionsInner h4 {
        margin: 0 0 14px 0; } }

@media screen and (max-width: 767px) {
        .printOptions .printOptionsInner .selectWrapper .buttonWrapper {
          margin-bottom: 1.07143rem; } }
