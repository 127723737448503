/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.categoryContainer ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  .categoryContainer ul li {
    margin: 1rem 0; }

.categoryContainer a {
  text-decoration: none; }
