/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit;
  z-index: 1; }
  .base.ios {
    position: static; }

.Cart__Empty, .Cart__NotEmpty {
  width: inherit;
  color: #fff;
  background: transparent; }
  .Cart__Empty > div > div:last-child, .Cart__NotEmpty > div > div:last-child {
    letter-spacing: 0;
    position: relative;
    bottom: -0.5rem;
    display: none; }

.Cart__NotEmpty {
  padding: 0.5rem 1.25rem 0.5rem 1rem !important; }

.CartIcon {
  position: relative;
  margin: 0.25rem 0rem;
  max-width: 45px;
  display: inline-block;
  color: #000; }
  .CartIcon svg {
    height: 24px !important;
    width: 24px !important; }

.inner {
  position: relative;
  padding: 0; }

.inactive {
  opacity: 0.5; }

.orderRows {
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #f7594d;
  color: #fff;
  height: 20px;
  width: 20px;
  line-height: 20px;
  right: -0.75rem;
  top: 0.75rem;
  font-size: 0.85714rem;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0; }

.button {
  background: transparent !important;
  border-radius: 0 !important;
  position: relative;
  height: 100%;
  min-height: 60px;
  text-align: center;
  display: flex !important;
  padding: 0rem;
  margin: 0 !important;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0);
  transition: background 200ms ease-in-out; }
  .button:hover, .button:active {
    color: #fff;
    background: rgba(255, 255, 255, 0.15) !important; }
  .button.open {
    background: #f9f9f9 !important;
    color: #23272a;
    min-height: 61px; }
    .button.open:before {
      position: absolute;
      content: "";
      border-radius: 50%;
      border: 2px solid transparent;
      border-bottom: 4px solid #f9f9f9;
      height: 15px;
      width: 15px;
      left: -12px;
      right: unset;
      bottom: -4px;
      top: unset;
      -webkit-transform: rotate(-40deg);
              transform: rotate(-40deg); }
  .button > div {
    display: inline-flex; }
  @media screen and (min-width: 992px) {
    .Cart__Empty, .Cart__NotEmpty {
      max-width: 11rem;
      color: #000; }
      .Cart__Empty > div > div:last-child, .Cart__NotEmpty > div > div:last-child {
        color: inherit;
        display: unset; }
    .Cart__NotEmpty {
      padding: 0.5rem 1rem !important; }
  .Cart__Empty {
    padding: 0.5rem 0 .5rem 1rem !important; }
    .CartIcon {
      color: currentColor;
      margin: 0.25rem 1rem 0.25rem 0rem; }
      .CartIcon svg {
        height: 26px !important;
        width: 26px !important; }
    .orderRows {
      top: -0.5rem; } }
