/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  width: 100%;
  margin: 0;
  padding: 1rem; }
  .base dt {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    width: 40%; }
    .base dt:first-of-type {
      display: none; }
  .base dd {
    display: inline-block;
    vertical-align: bottom;
    margin: 0;
    width: 60%; }
    .base dd:first-of-type {
      width: 100%;
      color: #f66d62;
      font-weight: bold; }
  .base:before, .base:after {
    content: ' ';
    display: table; }
  .base:after {
    clear: both; }

.container .textRight {
  text-align: right; }

.container ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  .container ul li:nth-child(even) {
    background: #eff0f2; }

.container table {
  margin-top: 1rem; }

.container th, .container td {
  padding: 0.5rem; }

.container tr:nth-child(even) {
  background: #eff0f2; }

@media screen and (min-width: 768px) {
  .container {
    padding: 0 0.5rem; } }
