/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.container {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .container h3 {
    margin-left: 1rem; }

.center {
  text-align: center; }

.buttons {
  display: flex;
  flex-direction: column; }

.button {
  margin-bottom: 1rem; }

.orderDetails {
  display: flex;
  flex-direction: column;
  padding: 0 2rem; }

.subTotal {
  text-align: right; }
  .subTotal > * {
    display: inline-block; }

.orderWrapperRow {
  margin-bottom: 3rem; }

.returnOrderLink {
  font-size: 1rem; }

@media screen and (min-width: 768px) {
    .container {
      padding-left: 3rem;
      padding-right: 3rem; }
    .orderDetails {
      flex-direction: row; }
      .orderDetails div {
        margin-right: 3rem; }
    .returnOrderLink {
      font-size: 1.5rem; } }

@media print {
    .buttons {
      display: none; } }

@media screen and (min-width: 480px) {
    .buttons {
      justify-content: flex-end;
      flex-direction: row; }
    .button {
      margin-bottom: 0;
      margin-right: 0.5rem; } }
