/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.card {
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  padding-right: 10px;
  padding-left: 10px; }

.cardInner {
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  font-family: SFNS_bold, sans-serif;
  font-size: 11px;
  height: 100%;
  justify-content: space-between; }

.innerFlexDiv {
  display: flex; }

.listBtnsWrap {
  display: flex;
  align-items: center;
  width: 85px;
  justify-content: center; }

.addToListBtnWrap {
  display: flex;
  justify-content: flex-end; }

.typeAndUnitWrap {
  text-align: right;
  margin-left: 10px; }

.topRow {
  display: flex;
  align-items: center; }

.contentRow {
  display: flex;
  margin-bottom: 10px; }

.bottomRow {
  display: flex;
  justify-content: space-between; }

.priceWrap {
  display: flex;
  flex: 1; }

.marksAndDisabledInfoWrap {
  display: flex;
  flex-direction: column; }

.marksWrap {
  display: flex;
  justify-content: flex-end; }

.detailsWrap {
  flex: 1; }

.availabilityWrap {
  margin-bottom: 5px;
  margin-left: 10px;
  flex-shrink: 0; }

.outerFlexWrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - 85px);
  padding-left: 10px; }

.titleWrap {
  flex: 1;
  min-width: 0; }

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.imageWrap {
  flex: 0 0 85px; }

.disabledFade {
  opacity: 0.3; }

.disabledIconWrap {
  position: absolute;
  top: 11px;
  left: 10px;
  border-radius: 50%;
  color: #f7594d;
  background-color: #fff;
  width: 34px;
  height: 34px;
  border: 3px solid #fff; }

.disabledIcon {
  color: #f7594d;
  width: 100% !important;
  height: 100% !important; }

.btn {
  text-transform: uppercase;
  width: 100%;
  font-size: 12px; }

@media screen and (min-width: 1200px) {
    .cardInner {
      font-size: 12px; } }
