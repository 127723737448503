/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.main {
  width: 100%;
  container-type: inline-size; }
  .main .grid {
    position: relative;
    grid-auto-flow: dense; }
    .main .grid::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: 0px 1px 0px #ffffff, 1px 0px 0px #ffffff; }
    .main .grid[data-list-type="viewLayout_List"] {
      display: flex;
      flex-direction: column;
      gap: 1px; }
    .main .grid[data-list-type="viewLayout_Grid"] {
      display: grid;
      gap: 1px;
      grid-template-columns: repeat(auto-fill, minmax(232px, 1fr)); }

@container (width > 630px) and (width < 768px) {
  .main .grid[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr 1fr; } }
    .main .grid.isCart {
      padding: 0 1rem; }
      .main .grid.isCart::after {
        width: calc(100% - 1rem);
        height: calc(100% - 1rem); }

@container (width > 630px) and (width < 768px) {
  .main .grid.isCheckout[data-list-type="viewLayout_List"], .main .grid.isCart[data-list-type="viewLayout_List"], .main .grid.isProductPage[data-list-type="viewLayout_List"] {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr; } }

@container (width < 630px) {
  .main .grid[data-list-type="viewLayout_Grid"] {
    border: 0px;
    display: flex;
    flex-direction: column;
    gap: 1px; } }
    .main .grid .ads {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
      border-bottom: 1px solid #e5e5e5; }
      .main .grid .ads > div {
        border: unset; }
        .main .grid .ads > div > div {
          margin: 0px; }

.closeOut {
  height: inherit;
  display: flex;
  padding: 0 1rem 1rem;
  justify-content: flex-end;
  font-family: SFNS_bold, sans-serif; }
  .closeOut svg {
    color: #ff0000;
    font-size: 1.2rem;
    margin-right: 5px; }

.items {
  width: 100%; }

.lineItem {
  flex: 3; }

.rowWrapper {
  display: flex; }
  .rowWrapper:last-child .lineItem {
    border-bottom: 1px solid #e5e5e5; }

.emvColumnWrapper {
  display: flex;
  padding-bottom: 48px; }

.emvProductArrow {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #c2c5c8;
  margin-left: 14px;
  margin-right: 14px; }

.emvCardWrapper {
  display: flex;
  flex: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  width: 280px;
  margin-top: 48px; }
