/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  composes: full from '../../../../styles/objects/layout.scss';
  position: relative; }

a.button {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent; }
  a.button.active {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5; }
    a.button.active > div {
      height: calc(70px + 0.25rem);
      background-color: #fff; }
  a.button > div {
    line-height: 70px;
    padding: 0 0.5rem 0 1.5rem; }
    a.button > div:hover, a.button > div:focus {
      color: #f7594d; }

.menuWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  padding: 2rem 0;
  z-index: -1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.04); }
  .menuWrapper ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .menuWrapper li {
    color: #23272a;
    padding: 0 1rem;
    width: 100%;
    border-right: 1px solid #e5e5e5; }
    .menuWrapper li:hover {
      text-decoration: none;
      color: #f7594d;
      background-color: #fafafa;
      cursor: pointer; }
    .menuWrapper li.active {
      background-color: #fafafa; }

.menuItemsList a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis; }

.menuItemsList.primary {
  flex: 1;
  line-height: 2.5rem; }
  .menuItemsList.primary li {
    font-weight: bold; }

.menuItemsList.tertiary {
  flex: 4; }
  .menuItemsList.tertiary ul {
    display: flex;
    flex-wrap: wrap; }
  .menuItemsList.tertiary li {
    width: 20%;
    padding: 0.25rem 1rem; }
    .menuItemsList.tertiary li:nth-child(-n + 5) {
      padding-top: 0; }
    .menuItemsList.tertiary li:hover {
      background-color: inherit;
      cursor: inherit; }

.menuItemsList.tertiary .menuSection a {
  font-size: 0.85714rem;
  text-decoration: none; }
  .menuItemsList.tertiary .menuSection a:first-of-type {
    font-weight: bold; }
  .menuItemsList.tertiary .menuSection a:first-of-type, .menuItemsList.tertiary .menuSection a.showMore {
    text-decoration: underline; }
  @media screen and (min-width: 1200px) {
      a.button > div {
        padding: 0 1.25rem 0 1.5rem; } }
