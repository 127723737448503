/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.base {
  position: relative; }
  .base.listBlock50 .card:last-child {
    display: none; }
  .base.listBlock33 .card:last-child {
    display: none; }
  .base.listBlock25 .card {
    width: calc(100% - 1rem); }

.title {
  margin-top: 0;
  margin-bottom: 2rem; }

.link {
  position: absolute;
  top: -0.15rem;
  right: 0;
  display: flex;
  align-items: center;
  color: #f7594d; }
  .link svg {
    margin-right: 0.25rem;
    font-size: 2rem; }
  .link span {
    margin-bottom: 0.125rem; }
  .link.mobile {
    font-size: 2.14286rem; }
    .link.mobile svg {
      font-size: 1.6rem; }
    @media screen and (min-width: 480px) {
    .title {
      margin-top: 2.2rem; }
    .base.listBlock100 .card {
      width: calc(50% - 1rem); }
    .base.listBlock66 .card {
      width: calc(33% - 1rem); }
      .base.listBlock66 .card:last-child {
        display: none; }
    .base.listBlock50 .card {
      width: calc(33% - 1rem); }
      .base.listBlock50 .card:last-child {
        display: flex; }
    .base.listBlock33 .card:last-child {
      display: flex; } }
    @media screen and (min-width: 992px) {
    .title {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
    .base.listBlock100 .card {
      width: calc(25% - 1rem); }
    .base.listBlock66 .card {
      width: calc(25% - 1rem); }
      .base.listBlock66 .card:last-child {
        display: flex; }
    .base.listBlock50 .card {
      width: calc(33% - 1rem); }
      .base.listBlock50 .card:last-child {
        display: flex; }
    .base.listBlock33 .card {
      width: calc(50% - 1rem); }
      .base.listBlock33 .card:nth-last-child(2) {
        display: flex; } }
    @media screen and (min-width: 768px) {
    .base.listBlock100 .card {
      width: calc(33% - 1rem); }
    .base.listBlock50 .card {
      width: calc(50% - 1rem); }
      .base.listBlock50 .card:last-child {
        display: none; }
    .base.listBlock33 .card {
      width: calc(100% - 1rem); }
      .base.listBlock33 .card:nth-last-child(-n+2) {
        display: none; } }
