/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .base h2 {
    margin-bottom: 0.25rem; }
  .base button {
    margin: 1rem 0; }
  .base.mobile {
    padding: 0 1rem; }

.totalPrice {
  color: #f7594d;
  font-size: 3rem; }

.agreements {
  color: #999999;
  text-align: center;
  margin: 0 1rem; }
  .agreements a {
    color: inherit;
    text-decoration: underline; }
  .agreements > div {
    margin-top: 1rem; }

.validationerror {
  margin: 1rem;
  color: #ef7172; }
  .validationerror p {
    font-weight: bold; }
  .validationerror ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1rem; }
  .validationerror li {
    text-align: center; }
  @media screen and (min-width: 768px) {
    .agreements {
      max-width: 80%; } }
