/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.menu {
  line-height: 1;
  margin: 0;
  width: 100%; }
  .menu__list {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0 !important;
    list-style: none; }
  .menu__item {
    display: block;
    margin: 0; }
  .menu__link {
    font-size: 17px;
    font-weight: 500;
    display: block;
    padding: 0.25em 0.5rem 1em 0.5rem;
    margin: 0.75rem 0 0 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    max-height: 60px; }
  .menu_menigo {
    display: block;
    position: relative;
    top: 1.25rem; }
    .menu_menigo.small {
      top: 0; }
      .menu_menigo.small:before {
        display: none; }
  .menu_menigo:before {
    content: "";
    top: 0;
    left: 0.5rem;
    right: 0.5rem;
    position: absolute;
    display: block;
    height: 1px;
    background: #F7594D; }
  .menu_menigo .menu__link {
    text-align: center;
    color: #0D0F11;
    transition: color 0.3s; }
    .menu_menigo .menu__link:hover, .menu_menigo .menu__link:focus {
      outline: none; }
  .menu_menigo .menu__item_current .menu__link,
  .menu_menigo .menu__item_current .menu__link:focus {
    color: #F7594D;
    transition: color 0.5s;
    position: relative; }

.menu_menigo .menu__item.menu__item_current .menu__link::after {
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #F7594D;
  display: block;
  margin-top: -12.5px;
  margin-left: -12.5px;
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transition: opacity 0.9s, -webkit-transform 0.9s;
  transition: transform 0.9s, opacity 0.9s;
  transition: transform 0.9s, opacity 0.9s, -webkit-transform 0.9s;
  transition-timing-function: ease-out;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.menu_menigo.small .menu__item.menu__item_current .menu__link::after {
  visibility: hidden; }

.menu_menigo:hover .menu__item.menu__item_current .menu__link::after {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0; }

.menu_menigo .menu__dot {
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #F7594D;
  display: block;
  margin-top: -12.5px;
  margin-left: -12.5px;
  position: absolute;
  top: 0;
  left: 0; }
  .menu_menigo .menu__dot.animated {
    transition: left 0.5s, opacity 0.5s, -webkit-transform 0.8s;
    transition: left 0.5s, transform 0.8s, opacity 0.5s;
    transition: left 0.5s, transform 0.8s, opacity 0.5s, -webkit-transform 0.8s;
    transition-timing-function: ease-in-out; }

.menu_menigo.small .menu__dot {
  visibility: hidden; }
