/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.base {
  flex: 1;
  width: 100%; }

.wrapper {
  display: flex;
  flex-direction: column; }
  .wrapper a {
    margin-top: 2rem;
    font-weight: bold;
    text-decoration: underline; }

.cuttingDetail {
  margin-top: 2rem;
  margin-bottom: 1rem; }

@media screen and (min-width: 480px) {
    .h3 {
      margin-top: 2.2rem; } }

@media screen and (min-width: 992px) {
    .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; } }
