/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/

.fillPageWidth {
  margin-left: -1rem;
  margin-right: -1rem; }

@media screen and (min-width: 320px) {
  .layout_1\:4_1\:2_1\:4 > :nth-child(2) {
    order: 1; }
  .layout_1\:4_1\:2_1\:4 > :nth-child(1),
  .layout_1\:4_1\:2_1\:4 > :nth-child(3) {
    order: 2; } }

@media screen and (min-width: 768px) {
  .layout_1\:4_1\:2_1\:4 > :nth-child(1),
  .layout_1\:4_1\:2_1\:4 > :nth-child(2),
  .layout_1\:4_1\:2_1\:4 > :nth-child(3) {
    order: 0; }
    .fillPageWidth {
      margin-left: 0;
      margin-right: 0; } }
