/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.list, .values, .selectedFacetValues, .categoryTreeNodeList {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list > li, .values > li, .selectedFacetValues > li, .categoryTreeNodeList > li {
    display: block; }

.values .facetValue .facetEllipsisWrap > label,
.values .facetValue .facetEllipsisWrap > div > label, .selectedFacetValues .selectedFacetValue > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: visible;
  padding-right: 0.25rem; }

.freeTextSearchWrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 14px; }

.freeTextSearch {
  position: relative;
  width: 100%; }
  .freeTextSearch h5 {
    display: inline-block;
    padding-right: 1rem; }
  .freeTextSearch .clearFreeText {
    display: inline-block;
    background: #23272a; }
  .freeTextSearch .searchWrapper {
    position: absolute;
    left: 1rem;
    top: 50%;
    line-height: 0;
    height: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #23272a;
    padding-right: 5px; }
    .freeTextSearch .searchWrapper.closeFreeTextSearch {
      color: #23272a;
      cursor: pointer; }
  .freeTextSearch input {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0rem;
    padding-right: 18px;
    padding-left: 40px; }
    .freeTextSearch input::-webkit-input-placeholder {
      color: #999999; }
    .freeTextSearch input::-ms-input-placeholder {
      color: #999999; }
    .freeTextSearch input::placeholder {
      color: #999999; }
    .freeTextSearch input::-ms-clear {
      display: none; }

.addToFreeText {
  width: 100%; }
  .addToFreeText button {
    width: 100%; }

.toggler {
  font-family: SFNS, sans-serif;
  font-size: 1rem;
  line-height: 1.42857rem;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem;
  margin: 0;
  transition: 0.3s ease-in-out; }
  .toggler:hover {
    cursor: pointer;
    background-color: #e7e7e7; }
  .open .toggler {
    background: #343d46;
    color: #fff; }
    .open .toggler:hover {
      background-color: #6c737a;
      color: #fff; }
  html:global(.ie) .toggler > span {
    justify-content: space-between; }

.title {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.facetGroup {
  max-height: 3.35rem;
  overflow: hidden;
  transition: max-height 0.15s; }
  .facetGroup.open {
    max-height: none;
    overflow: visible; }

.values li:first-child {
  margin-top: 8px; }

.values li:last-child {
  margin-bottom: 8px; }

.values li:hover {
  cursor: pointer;
  background-color: #e7e7e7; }

.values .facetValue {
  display: flex;
  padding: 4px 16px;
  justify-content: space-between; }
  .values .facetValue .facetEllipsisWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between; }
    .values .facetValue .facetEllipsisWrap > label,
    .values .facetValue .facetEllipsisWrap > div > label {
      cursor: pointer; }
  .values .facetValue .facetCheckbox {
    width: calc(100% - 1.75rem - 5px); }

.values a.facetValue {
  justify-content: space-between;
  color: #343d46; }
  .values a.facetValue:hover, .values a.facetValue:active {
    text-decoration: none;
    color: #343d46; }
    .values a.facetValue:hover > div > label, .values a.facetValue:active > div > label {
      text-decoration: underline; }

.popularValues + ul li:first-child {
  margin-top: 0; }

.popularValues :last-child {
  margin-bottom: 0 !important; }

.andOrConditionToggles {
  border-bottom: 1px solid #dee1e4;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem; }
  .andOrConditionToggles > div:first-child {
    margin-bottom: 1rem; }

.andOrFilterText {
  display: block;
  line-height: 1.5; }

.selectedFacetValues {
  position: relative;
  padding: 1rem;
  border-top: 1px solid #dee1e4;
  border-bottom: 1px solid #dee1e4;
  margin-top: 1rem; }
  .selectedFacetValues.extraMargin {
    margin-bottom: 0.5rem; }
  .selectedFacetValues .label {
    letter-spacing: 0.1rem;
    position: absolute;
    top: -0.75rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 0 0.5rem;
    font-size: 0.85714rem;
    background: white;
    color: #999999;
    white-space: nowrap; }
  .selectedFacetValues li {
    display: inline-block;
    margin-right: 0.5rem;
    max-width: 100%; }
  .selectedFacetValues .selectedFacetValue {
    display: flex;
    justify-content: space-between;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .selectedFacetValues .selectedFacetValue svg {
      margin-left: 0.25rem; }

.facetIndicator {
  background: #f7594d;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-right: -0.5rem;
  margin-left: 0.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  display: block; }

.count {
  color: #999999; }

.showall {
  padding-left: 0;
  padding-bottom: 1rem;
  font-size: 14px; }
  .showall > span {
    cursor: pointer;
    display: block;
    padding: .25rem;
    padding-left: 1.25rem;
    text-decoration: underline; }
    .showall > span:hover {
      background: #e7e7e7; }

.searchArea {
  position: relative;
  margin: 0 1rem; }
  .searchArea .filterSearch {
    width: 100%;
    height: 2.5rem;
    margin: 0.5rem 0; }
    .searchArea .filterSearch::-ms-clear {
      display: none; }
  .searchArea ::-webkit-input-placeholder {
    text-transform: uppercase; }
  .searchArea ::-ms-input-placeholder {
    text-transform: uppercase; }
  .searchArea ::placeholder {
    text-transform: uppercase; }
  .searchArea .clearFilterSearch {
    position: absolute;
    top: 0.5rem;
    right: 0;
    background: none !important;
    height: 2.5rem; }

.searchReasultLabel {
  margin: 0.5rem 1rem;
  color: #999999; }

.noHits {
  padding-left: 1rem;
  padding-bottom: 1rem; }

.categoryTreeNodeList {
  padding: 0.75rem; }

.categoryTreenode {
  width: 100%; }
  .categoryTreenode .header {
    display: flex;
    justify-content: space-between; }
    .categoryTreenode .header .toggler {
      width: auto;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0.5rem 0.25rem;
      line-height: 1.5; }
  .categoryTreenode .title {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0%;
    padding: 0.07rem 0.25rem; }
    .categoryTreenode .title .count {
      padding-left: 0.25rem; }
    .categoryTreenode .title > label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .categoryTreenode .children {
    padding-left: 1rem !important;
    list-style: none; }
  .categoryTreenode.firstLevel:not(:last-child) {
    border-bottom: 1px solid #dee1e4; }

.disabled {
  color: #999999; }

.hasSelectedValuesMark {
  border-radius: 100%;
  background: #343d46;
  height: .55rem;
  width: .55rem;
  display: inline-block;
  margin-left: .725rem; }
  .open .hasSelectedValuesMark {
    background: #fff; }

.categoryList {
  transition: max-height .3s ease-in-out;
  overflow: hidden;
  max-height: unset; }
  .categoryListClosed {
    max-height: 0; }

.categorySearch {
  margin: 12px 0;
  padding: 0 8px;
  position: relative; }
  .categorySearch input {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    background-color: #e7e7e7; }
    .categorySearch input:hover {
      border-color: #6c737a; }
    .categorySearch input:focus {
      border-color: #f2b52e;
      background-color: #fcf0d5; }
  .categorySearch .searchIcon {
    position: absolute;
    top: 8px;
    left: 20px;
    width: 20px;
    height: 20px; }
  .categorySearch .closeIcon {
    position: absolute;
    top: 1px;
    right: 12px; }
  @media screen and (min-width: 992px) {
    .facetGroup:first-child .toggler {
      border-top: none; }
      .andOrConditionToggles > div:first-child {
        margin-bottom: 0.5rem; }
    .andOrConditionToggles {
      margin: 1rem;
      padding-top: 0;
      padding-left: 0; }
    .andOrFilterText {
      padding-bottom: 0; }
      .categoryListClosed {
        max-height: 0; } }
  @media screen and (min-width: 768px) {
      .categoryTreenode .title {
        padding: 0.5rem 0.25rem; } }
