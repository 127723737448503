/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.link {
  text-decoration: none;
  color: inherit; }
  .link:hover {
    color: inherit;
    text-decoration: underline !important; }
