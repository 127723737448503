/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.reset {
  margin: 0; }

.accountSettingsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; }
  .accountSettingsWrapper .logotypeWrapper {
    display: none; }
    .accountSettingsWrapper .logotypeWrapper .logotype {
      transition: 0.3s;
      float: right; }
      .accountSettingsWrapper .logotypeWrapper .logotype svg {
        height: 9rem;
        width: 9rem; }

.showUsername {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px; }

.infoToCustomer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 452px;
  height: 412px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.base {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 452px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  gap: 4px; }
  .base .accountSettings {
    max-width: 375px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .base h1 {
    text-align: center;
    font-size: 1.57143rem;
    margin-top: 0;
    font-weight: 700;
    color: #343d46; }
  .base h3 {
    margin: 0;
    font-size: 1.42857rem;
    color: #999EA3; }
  .base p {
    color: #6c737a;
    line-height: 16.1px; }
  .base .accountSettingsIcon {
    width: 100px;
    height: 100px; }
  .base .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 40px; }
  .base .information {
    width: 375px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 12px;
    display: flex;
    flex-direction: column; }
  .base .informationHeader {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .base .form {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 40px; }
  .base .buttonsWrapper {
    width: 100%;
    height: 74px;
    gap: 16px;
    display: flex;
    flex-direction: column; }

.base_verified {
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px; }
  .base_verified .accountSettingsIcon {
    width: 100px;
    height: 100px; }
  .base_verified .informationTextWrapper {
    display: flex;
    flex-direction: column;
    height: 224px;
    width: 388px;
    gap: 18px; }
  .base_verified .changeOrSendAgain {
    display: flex;
    flex-direction: column;
    gap: 8px; }
  .base_verified .contactCustomerService {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .base_verified h1 {
    text-align: center;
    font-size: 1.57143rem;
    margin-top: 0;
    font-weight: 700;
    color: #343d46; }
  .base_verified h3 {
    margin: 0;
    font-size: 1.42857rem;
    color: #999EA3; }
  .base_verified p {
    color: #6c737a;
    line-height: 16.1px;
    margin: 0; }
  .base_verified b, .base_verified strong {
    color: #343d46; }
  .base_verified .clickableText {
    color: #f7594d;
    line-height: 16.1px;
    cursor: pointer; }
  .base_verified .row {
    display: flex;
    flex-direction: row;
    gap: 4px; }

.generalSpinner {
  height: 2rem;
  width: 2rem;
  color: #f7594d;
  text-align: center; }

.accountSettingsFormWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 40px; }
  .accountSettingsFormWrapper .nameWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px; }
  .accountSettingsFormWrapper .fieldWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 4px; }
  .accountSettingsFormWrapper .textInputs {
    display: flex;
    flex-direction: column;
    gap: 20px; }
  .accountSettingsFormWrapper input {
    display: flex;
    padding: 0.6rem; }
  .accountSettingsFormWrapper .saveChanges {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff !important; }
    .accountSettingsFormWrapper .saveChanges button {
      text-transform: uppercase; }
  .accountSettingsFormWrapper .doThisLater {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999EA3;
    text-decoration: underline; }

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: #f7594d; }

.requiredField {
  color: #b0406f;
  padding-left: 10px;
  font-weight: 500; }

@media screen and (min-width: 992px) {
    .reset {
      margin: 4rem 4rem 0 4rem !important; }
      .accountSettingsWrapper .logotypeWrapper {
        display: flex; } }
