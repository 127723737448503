/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  flex: 1.5;
  background: #fafafa; }
  .base,
  .base ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0.5rem; }
    .base > li,
    .base ul > li {
      display: block; }
  .base ul {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.totalPrice {
  color: #f7594d;
  font-size: 3rem; }

.discountPrice {
  padding-left: 0.25rem; }

.validationerror {
  margin-top: 1rem;
  color: #ef7172; }

.promotionListItem {
  margin-top: 0 !important;
  margin-left: 2.5rem !important;
  padding-bottom: 0 !important;
  margin-bottom: 0.5rem !important;
  margin-top: -0.5rem !important;
  font-style: italic !important; }
  .promotionListItem:last-child {
    padding-bottom: 0.5rem !important; }

.total {
  font-weight: bold; }

.delivery {
  font-weight: bold; }

.alcoholTax {
  font-weight: normal; }

.price {
  width: 6.813em; }
