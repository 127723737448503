/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.secondaryText,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }


.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }


.paragraph,
.secondaryText {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.base {
  background-color: #fff;
  display: flex;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 154.28571rem;
  margin: 0 auto; }
  .base .inner {
    max-width: 97.14286rem;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0; }
    .base .inner.darkBackground > div {
      background: radial-gradient(50% 170.41% at 50% 47.02%, #000000 0%, rgba(0, 0, 0, 0) 100%); }
    .base .inner > div {
      flex: 0 0 100%;
      background: radial-gradient(50% 170.41% at 50% 47.02%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      padding: 1.42857rem; }
    .base .inner button {
      padding: 0.75rem 1.25rem;
      font-size: 1.14286rem;
      text-transform: uppercase; }

h1.inspiration {
  margin-top: 0; }
  .darkBackground h1.inspiration {
    color: #fff; }


.preamble {
  margin-bottom: 2rem; }
  .darkBackground .preamble {
    color: #fff; }

.secondaryText {
  width: 100%;
  margin-top: .5rem; }
  .darkBackground .secondaryText {
    color: #fff; }
  .secondaryText a {
    color: #f7594d; }
  @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; } }
  @media screen and (min-width: 1200px) {
    .base {
      max-height: 40.71429rem; } }
  @media screen and (min-width: 768px) {
        .base .inner > div {
          flex: 0 0 50%;
          padding: 5.71429rem 1rem; }
    .secondaryText {
      width: 50%;
      margin-top: 2rem; } }
