/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  composes: full from '../../../styles/objects/layout.scss';
  padding-top: 2rem;
  padding-bottom: 2rem; }

.main {
  composes: base;
  background: white;
  padding-bottom: 3rem; }

.layout > .part:not(:first-child) {
  margin-top: 2rem; }

.layout > .part:not(:last-child) {
  margin-bottom: 2rem; }

.layout > .part.sidebar {
  margin-top: 0; }

:not(.layout) > .part:not(:first-child) {
  margin-top: 0rem; }

.layout:not(:first-child) {
  margin-top: 2rem; }

.layout:not(:last-child), :not(.layout) > .part:not(:last-child) {
  margin-bottom: 2rem; }

.main {
  padding: 0 0.75rem 0 0.75em; }

.divider:not(.layout) > .part + .part:before, .divider > .part + .layout:before, .divider.layout > :not(:last-child):after {
  content: '';
  position: absolute;
  background: #e5e5e5; }

.divider:not(.layout) > .part + .part, .divider > .part + .layout {
  position: relative; }
  .divider:not(.layout) > .part + .part:before, .divider > .part + .layout:before {
    height: 1px;
    top: -1rem;
    right: -1rem;
    left: -1rem; }

.divider.layout > :not(:last-child) {
  position: relative; }
  .divider.layout > :not(:last-child):after {
    height: 1px;
    bottom: -1rem;
    right: -1rem;
    left: -1rem; }

.\layout1to1 {
  composes: layout; }

.\layout2to1 {
  composes: layout; }

.\layout3to1 {
  composes: layout; }

.\layout1to2 {
  composes: layout; }

.\layout1to3 {
  composes: layout; }

.\layout2to5 {
  composes: layout; }

.\layout3to7 {
  composes: layout; }

@media screen and (min-width: 768px) {
    .base {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-margin-left: 1rem; }
  .layout {
    display: flex;
    flex-wrap: wrap; }
    .layout > :nth-child(n) {
      width: 100%;
      position: relative; }
    .layout:not(:first-child) {
      margin-top: 1.5rem; }
    .layout:not(:last-child), :not(.layout) > .part:not(:last-child) {
      margin-bottom: 1.5rem; }
    .main {
      padding: 0 1rem; }
      .divider:not(.layout) > .part + .part:before, .divider > .part + .layout:before {
        top: -0.75rem;
        right: -0.25rem;
        left: -0.25rem; }
      .divider.layout > :not(:last-child):after {
        width: 1px;
        height: auto;
        left: auto;
        top: -0.75rem;
        right: -0.75rem;
        bottom: -0.75rem; }
    .\layout1to1 > :nth-child(1) {
      width: calc(50% - 0.5rem);
      width: calc(50% - 0.5rem - 1px);
      margin-right: 1rem; }
    .\layout1to1 > :nth-child(2) {
      width: calc(50% - 0.5rem);
      width: calc(50% - 0.5rem - 1px);
      margin-right: 0; }
    .\layout2to1 > :nth-child(1) {
      width: calc(66.66667% - 0.33333rem);
      width: calc(66.66667% - 0.33333rem - 1px);
      margin-right: 1rem; }
    .\layout2to1 > :nth-child(2) {
      width: calc(33.33333% - 0.66667rem);
      width: calc(33.33333% - 0.66667rem - 1px);
      margin-right: 0; }
    .\layout3to1 > :nth-child(1) {
      width: calc(75% - 0.25rem);
      width: calc(75% - 0.25rem - 1px);
      margin-right: 1rem; }
    .\layout3to1 > :nth-child(2) {
      width: calc(25% - 0.75rem);
      width: calc(25% - 0.75rem - 1px);
      margin-right: 0; }
    .\layout1to2 > :nth-child(1) {
      width: calc(66.66667% - 0.33333rem);
      width: calc(66.66667% - 0.33333rem - 1px);
      margin-right: 0;
      order: 2; }
    .\layout1to2 > :nth-child(2) {
      width: calc(33.33333% - 0.66667rem);
      width: calc(33.33333% - 0.66667rem - 1px);
      margin-right: 1rem;
      order: 1; }
    .\layout1to3 > :nth-child(1) {
      width: calc(75% - 0.25rem);
      width: calc(75% - 0.25rem - 1px);
      margin-right: 0;
      order: 2; }
    .\layout1to3 > :nth-child(2) {
      width: calc(25% - 0.75rem);
      width: calc(25% - 0.75rem - 1px);
      margin-right: 1rem;
      order: 1; }
    .\layout2to5 > :nth-child(1) {
      width: calc(60% - 0.4rem);
      width: calc(60% - 0.4rem - 1px);
      margin-right: 0;
      order: 2; }
    .\layout2to5 > :nth-child(2) {
      width: calc(40% - 0.6rem);
      width: calc(40% - 0.6rem - 1px);
      margin-right: 1rem;
      order: 1; }
    .\layout3to7 > :nth-child(1) {
      width: calc(70% - 0.3rem);
      width: calc(70% - 0.3rem - 1px);
      margin-right: 0;
      order: 2; }
    .\layout3to7 > :nth-child(2) {
      width: calc(30% - 0.7rem);
      width: calc(30% - 0.7rem - 1px);
      margin-right: 1rem;
      order: 1; } }

@media screen and (min-width: 768px) and (min-width: 768px) {
    .\layout1to1 > :nth-child(1) {
      width: calc(50% - 1rem);
      width: calc(50% - 1rem - 1px);
      margin-right: 2rem; }
    .\layout1to1 > :nth-child(2) {
      width: calc(50% - 1rem);
      width: calc(50% - 1rem - 1px); }
    .\layout2to1 > :nth-child(1) {
      width: calc(66.66667% - 0.66667rem);
      width: calc(66.66667% - 0.66667rem - 1px);
      margin-right: 2rem; }
    .\layout2to1 > :nth-child(2) {
      width: calc(33.33333% - 1.33333rem);
      width: calc(33.33333% - 1.33333rem - 1px); }
    .\layout3to1 > :nth-child(1) {
      width: calc(75% - 0.5rem);
      width: calc(75% - 0.5rem - 1px);
      margin-right: 2rem; }
    .\layout3to1 > :nth-child(2) {
      width: calc(25% - 1.5rem);
      width: calc(25% - 1.5rem - 1px); }
    .\layout1to2 > :nth-child(1) {
      width: calc(66.66667% - 0.66667rem);
      width: calc(66.66667% - 0.66667rem - 1px); }
    .\layout1to2 > :nth-child(2) {
      width: calc(33.33333% - 1.33333rem);
      width: calc(33.33333% - 1.33333rem - 1px);
      margin-right: 2rem; }
    .\layout1to3 > :nth-child(1) {
      width: calc(75% - 0.5rem);
      width: calc(75% - 0.5rem - 1px); }
    .\layout1to3 > :nth-child(2) {
      width: calc(25% - 1.5rem);
      width: calc(25% - 1.5rem - 1px);
      margin-right: 2rem; }
    .\layout2to5 > :nth-child(1) {
      width: calc(60% - 0.8rem);
      width: calc(60% - 0.8rem - 1px); }
    .\layout2to5 > :nth-child(2) {
      width: calc(40% - 1.2rem);
      width: calc(40% - 1.2rem - 1px);
      margin-right: 2rem; }
    .\layout3to7 > :nth-child(1) {
      width: calc(70% - 0.6rem);
      width: calc(70% - 0.6rem - 1px); }
    .\layout3to7 > :nth-child(2) {
      width: calc(30% - 1.4rem);
      width: calc(30% - 1.4rem - 1px);
      margin-right: 2rem; } }

@media screen and (min-width: 768px) and (min-width: 1200px) {
    .\layout1to1 > :nth-child(1) {
      width: calc(50% - 1.5rem);
      width: calc(50% - 1.5rem - 1px);
      margin-right: 3rem; }
    .\layout1to1 > :nth-child(2) {
      width: calc(50% - 1.5rem);
      width: calc(50% - 1.5rem - 1px); }
    .\layout2to1 > :nth-child(1) {
      width: calc(66.66667% - 1rem);
      width: calc(66.66667% - 1rem - 1px);
      margin-right: 3rem; }
    .\layout2to1 > :nth-child(2) {
      width: calc(33.33333% - 2rem);
      width: calc(33.33333% - 2rem - 1px); }
    .\layout3to1 > :nth-child(1) {
      width: calc(75% - 0.75rem);
      width: calc(75% - 0.75rem - 1px);
      margin-right: 3rem; }
    .\layout3to1 > :nth-child(2) {
      width: calc(25% - 2.25rem);
      width: calc(25% - 2.25rem - 1px); }
    .\layout1to2 > :nth-child(1) {
      width: calc(66.66667% - 1rem);
      width: calc(66.66667% - 1rem - 1px); }
    .\layout1to2 > :nth-child(2) {
      width: calc(33.33333% - 2rem);
      width: calc(33.33333% - 2rem - 1px);
      margin-right: 3rem; }
    .\layout1to3 > :nth-child(1) {
      width: calc(75% - 0.75rem);
      width: calc(75% - 0.75rem - 1px); }
    .\layout1to3 > :nth-child(2) {
      width: calc(25% - 2.25rem);
      width: calc(25% - 2.25rem - 1px);
      margin-right: 3rem; }
    .\layout2to5 > :nth-child(1) {
      width: calc(60% - 1.2rem);
      width: calc(60% - 1.2rem - 1px); }
    .\layout2to5 > :nth-child(2) {
      width: calc(40% - 1.8rem);
      width: calc(40% - 1.8rem - 1px);
      margin-right: 3rem; }
    .\layout3to7 > :nth-child(1) {
      width: calc(70% - 0.9rem);
      width: calc(70% - 0.9rem - 1px); }
    .\layout3to7 > :nth-child(2) {
      width: calc(30% - 2.1rem);
      width: calc(30% - 2.1rem - 1px);
      margin-right: 3rem; } }
