/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  margin: 0 -2.85714rem; }
  .base p {
    margin: 0.5rem 2.85714rem 0; }
  .base p + p {
    margin: 0 2.85714rem 0; }
  .base .aspectRatio {
    position: relative;
    aspect-ratio: 4/3;
    width: 100%;
    margin: 0; }
    .base .aspectRatio.doubleImage {
      aspect-ratio: 4/5;
      display: inline-block;
      width: calc(50% - 8px); }
      .base .aspectRatio.doubleImage:first-of-type {
        margin-right: 16px; }
    .base .aspectRatio .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0%; }
  .base img {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    opacity: 0; }
  .base .imageCredits,
  .base .imageText {
    font-family: SFNS, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #0d0f11; }
  .base .imageCredits {
    color: #0d0f1199; }
  @media screen and (min-width: 1200px) {
    .base {
      margin: 0 -1.42857rem; }
      .base p {
        margin: 0 1.42857rem; }
      .base p + p {
        margin: 0 1.42857rem; }
        .base .aspectRatio.doubleImage {
          width: calc(50% - 10px); }
          .base .aspectRatio.doubleImage:first-of-type {
            margin-right: 20px; } }
