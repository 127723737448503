/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base li {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.base {
  position: absolute;
  right: 0;
  left: 0;
  top: 130px;
  background: #fff;
  overflow: auto;
  max-height: calc(100vh - 120px); }
  .base .section.margins {
    margin: 0 1rem; }
  .base .sectionProducts {
    margin-bottom: 1rem; }
    .base .sectionProducts h4 {
      margin-left: 1rem;
      margin-right: 1rem; }
  .base .noHitsSection {
    padding: 1rem;
    text-align: left;
    overflow: hidden;
    background-color: transparent;
    font-size: 1.143rem;
    padding-right: 60px; }
    .base .noHitsSection li {
      color: #000;
      font-size: 1.143rem;
      padding: .75rem .25rem; }
      .base .noHitsSection li:first-of-type {
        display: block; }
    .base .noHitsSection .button {
      display: inline; }
  .base h4 {
    color: #f66d62; }
    .base h4.searchHeading {
      padding: 0 10px; }
  .base ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -0.5rem; }
  .base li {
    display: inline-block;
    padding: 0.5rem 0.5rem; }
  .base a {
    text-decoration: none; }

.noSearchResults {
  padding: 14px; }

.searchSuggestionBackgroundBox {
  position: relative;
  background: #fff;
  overflow: hidden;
  background: linear-gradient(90deg, #cbdbd0 0, #cbdbd0 50%, transparent 50%, transparent 100%); }
  .searchSuggestionBackgroundBox .h4 {
    color: #000;
    font-weight: normal;
    font-size: 1.429rem;
    line-height: 1.571rem;
    margin-bottom: .5rem;
    font-family: SFNS, sans-serif;
    margin-top: .5rem; }
  .searchSuggestionBackgroundBox.noHitsSection::before {
    right: -35px; }
  .searchSuggestionBackgroundBox::before {
    content: "";
    position: absolute;
    background: #cbdbd0;
    border-radius: 100%;
    width: 1000px;
    height: 878px;
    right: -50px;
    top: -128px; }
  .searchSuggestionBackgroundBox.infoBlock li {
    display: list-item; }
  .searchSuggestionBackgroundBox ul {
    padding-top: .35rem; }
  .searchSuggestionBackgroundBox li {
    color: #000; }

.searchSuggestionBox {
  padding: 1rem;
  position: relative; }
  .noHitsSection .searchSuggestionBox {
    padding: 0; }

.searchSuggestionInfo {
  border-top: 10px solid #fff;
  padding: 1rem 2rem;
  font-size: 1.143rem;
  position: relative;
  background: #E6ECE8;
  color: #000; }
  .searchSuggestionInfo .button {
    display: inline-block; }
  .searchSuggestionInfo .helpText ul {
    margin: 0 0 1rem 0;
    padding: 0; }
  .searchSuggestionInfo .helpText li {
    line-height: 1.1;
    font-family: SFNS, sans-serif; }
  .searchSuggestionInfo .helpText li:before {
    height: 0.15rem;
    width: 0.15rem;
    background-color: #000;
    vertical-align: middle; }
  .searchSuggestionInfo .helpTextChat a {
    text-transform: uppercase;
    font-weight: bold;
    display: none;
    cursor: pointer; }
    .searchSuggestionInfo .helpTextChat a:active, .searchSuggestionInfo .helpTextChat a:focus, .searchSuggestionInfo .helpTextChat a:hover {
      color: #000;
      text-decoration: underline; }
    .searchSuggestionInfo .helpTextChat a svg {
      width: 1.25rem;
      top: -2px;
      position: relative; }
  .searchSuggestionInfo .helpTextChatIcon {
    margin-right: 1rem;
    display: none; }

.searchSuggestionList {
  padding: 1rem;
  border-bottom: solid 1px #F7594D; }
  .searchSuggestionList h4 {
    font-weight: normal;
    font-family: SFNS, sans-serif;
    color: #0D0F11;
    margin: .5rem 0; }
    .searchSuggestionList h4::after {
      content: ":"; }
  .searchSuggestionList .button {
    display: inline-block; }

.button {
  display: block;
  background: #fff;
  border: solid 1px #343D46;
  padding: .45rem 1.25rem;
  font-size: 1rem;
  color: #000;
  text-transform: capitalize;
  border-radius: 500px;
  white-space: pre;
  transition: background-color 200ms ease-in-out; }
  .button:hover, .button:focus, .button:active {
    color: #000;
    background: #DEDEDE !important; }

.showAll {
  margin: 1.5rem auto 2rem;
  background: #F7594D;
  color: #fff;
  border: solid 1px #F7594D;
  display: block;
  width: 149px;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.143rem; }
  .showAll:hover, .showAll:focus, .showAll:active {
    color: #fff;
    background: #F98B82 !important;
    border-color: #F98B82; }
  .showAll svg {
    width: 1.25rem;
    margin-left: .5rem;
    margin-top: -2px; }

.newsBanner {
  padding: 1rem;
  position: relative;
  overflow: hidden;
  border-top: 0.75rem solid #fff;
  display: block;
  align-items: center;
  color: #0D0F11;
  background-color: #E6ECE8; }
  .newsBanner .iconColumn {
    display: inline-block; }
  .newsBanner .headerColumn {
    display: inline-block;
    vertical-align: top;
    padding-top: .75rem;
    padding-left: .75rem; }
    .newsBanner .headerColumn strong {
      font-size: 1.15rem;
      line-height: 24px;
      text-transform: uppercase;
      position: relative;
      display: block;
      font-family: SFNS_bold, sans-serif; }
  .newsBanner .linkColumn a {
    font-weight: bold;
    font-size: 1.15rem;
    line-height: 24px;
    text-transform: uppercase;
    font-family: SFNS_bold, sans-serif;
    min-width: 100px;
    white-space: pre;
    position: relative;
    padding-right: 24px;
    bottom: -1px; }
    .newsBanner .linkColumn a:focus, .newsBanner .linkColumn a:active {
      color: #0D0F11; }
    .newsBanner .linkColumn a:hover {
      color: #0D0F11;
      text-decoration: underline; }
    .newsBanner .linkColumn a svg {
      width: 1.286rem;
      position: absolute;
      top: -2px; }

.marketingAd {
  border-top: 0.75rem solid #fff;
  background: #F9F9F9;
  display: flex;
  flex-direction: column-reverse; }
  .marketingAd img {
    width: 100%;
    max-width: 100%;
    opacity: 1; }
  .marketingAd .left {
    overflow: hidden;
    min-width: 40%; }
  .marketingAd .right {
    padding: 2rem 1rem; }
    .marketingAd .right h3 {
      font-weight: normal;
      font-size: 1.429rem;
      line-height: 1.429rem;
      font-family: SFNS, sans-serif;
      margin-bottom: 1rem;
      margin-top: 0; }
    .marketingAd .right p {
      font-family: SFNS, sans-serif;
      font-weight: normal;
      font-size: 1.143rem;
      line-height: 1.429rem;
      margin-bottom: 1.5rem; }
    .marketingAd .right .button {
      text-transform: uppercase;
      font-size: 1.143rem;
      line-height: 1.286rem;
      display: inline-block;
      padding: .45rem 1.25rem .35rem; }
      .marketingAd .right .button svg {
        width: 1.286rem;
        position: relative;
        top: -2px;
        margin-left: .5rem; }

.twoColumnWrapper {
  position: relative;
  padding-top: 0.5rem !important;
  display: flex;
  flex-direction: column; }
  .twoColumnWrapper .leftColumn {
    display: block;
    order: 2; }
    .twoColumnWrapper .leftColumn h4 {
      margin-left: 1rem; }
  .twoColumnWrapper .rightColumn {
    order: 1; }
    .twoColumnWrapper .rightColumn h4 {
      display: none; }

h4.listHeader {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-family: SFNS, sans-serif; }

.searchCategories {
  padding: 0; }
  .searchCategories ul {
    border-top: 1px solid #F7594D;
    border-bottom: 1px solid #F7594D;
    padding: 0 1rem; }
  .searchCategories ul, .searchCategories li {
    display: block; }
  .searchCategories ul li {
    border-bottom: 1px solid #E7E7E7;
    padding: 0.75rem 0.5rem; }
    .searchCategories ul li a span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: #0D0F11; }
      .searchCategories ul li a span:last-of-type {
        font-weight: bold; }
  .searchCategories .breadCrumbs {
    font-size: 12px; }
    .searchCategories .breadCrumbs svg {
      width: .75rem;
      height: 1rem;
      margin: 0 .25rem 2px; }
    @media screen and (min-width: 992px) {
    .base li {
      font-family: SFNS, sans-serif;
      font-size: 1rem;
      line-height: 2.28571rem; }
    .base {
      top: 123.5px; }
    .base {
      border: 1px solid #898F95;
      border-top: none;
      z-index: 1;
      max-height: calc(100vh - 195px); }
      .base > div:first-of-type:not(.sectionLists) {
        padding-top: 1.5rem; }
      .base .noHitsSection {
        background-color: #cbdbd0;
        text-align: right; }
          .base .noHitsSection li:first-of-type {
            display: inline-block; }
      .base li {
        padding: 0.25rem 0.5rem; }
    .searchSuggestionBackgroundBox {
      background: #F5F5F5; }
      .searchSuggestionBackgroundBox .h4 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 1rem; }
      .searchSuggestionBackgroundBox::before {
        border-radius: 100%;
        background: #cbdbd0;
        background: linear-gradient(180deg, #cbdbd0 0, #cbdbd0 250px, #E6ECE8 250px, #E6ECE8 100%);
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -85px;
        right: unset; }
    .searchSuggestionBackgroundBox.infoBlock::before {
      left: -380px;
      top: -170px;
      background: linear-gradient(180deg, #E6ECE8 0, #E6ECE8 100%); }
    .searchSuggestionBox {
      min-height: 100px;
      padding: 2rem 2rem 2rem 8.5rem; }
    .searchSuggestionInfo {
      background: transparent;
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.5rem 2rem 1rem;
      border-bottom: none; }
      .searchSuggestionInfo > div {
        flex: 1 1 50%;
        display: flex;
        padding: 0rem 2rem 0rem 6.5rem; }
      .searchSuggestionInfo .button {
        display: inline; }
      .searchSuggestionInfo .helpTextChat a {
        display: inline-block;
        padding: .45rem 0; }
      .searchSuggestionInfo .helpTextChatIcon {
        display: unset; }
    .searchSuggestionList {
      background: #cbdbd0;
      display: flex;
      align-items: center;
      padding: 1.5rem 2rem 1rem;
      border-bottom: none; }
      .searchSuggestionList h4 {
        margin: .6rem 1rem 0 auto;
        align-self: flex-start; }
      .searchSuggestionList .button {
        display: inline; }
    .button {
      color: #0D0F11;
      padding: .35rem 1.25rem; }
    .showAll {
      color: #fff; }
    .newsBanner {
      background-color: transparent;
      padding: 1rem 2rem;
      display: flex; }
      .newsBanner > div {
        margin-left: .75rem;
        display: flex; }
      .newsBanner .iconColumn {
        display: flex;
        width: 8.5rem;
        flex-direction: row-reverse; }
        .newsBanner .iconColumn:empty {
          width: 4.5rem; }
      .newsBanner .headerColumn {
        display: flex;
        vertical-align: unset;
        padding-top: 0; }
        .newsBanner .headerColumn strong {
          display: inline-block;
          padding-right: .75rem; }
          .newsBanner .headerColumn strong:after {
            position: absolute;
            right: -1px;
            bottom: 2px;
            content: "";
            height: 19px;
            width: 0;
            display: inline-block;
            border-right: 1px solid #0D0F11; }
      .newsBanner .textColumn {
        flex: 1;
        overflow: hidden;
        width: 0; }
        .newsBanner .textColumn p {
          margin: 0;
          height: 24px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre; }
      .newsBanner .linkColumn {
        flex: 1;
        margin-left: .75rem; }
    .twoColumnWrapper {
      display: flex;
      flex-direction: row; }
      .twoColumnWrapper.border:before {
        content: "";
        left: 1rem;
        right: 1rem;
        border-bottom: 1px solid #F7594D;
        position: absolute;
        top: 70px; }
      .twoColumnWrapper .leftColumn {
        flex: 0 0 270px;
        order: 1;
        margin: 0; }
        .twoColumnWrapper .leftColumn h4 {
          margin-left: 0; }
      .twoColumnWrapper .rightColumn {
        flex: 1;
        order: 2; }
        .twoColumnWrapper .rightColumn h4 {
          display: block;
          margin-left: 2rem; }
    .searchCategories {
      padding: 0 1rem; }
      .searchCategories ul {
        border-top: none;
        border-bottom: none;
        padding: 0; } }
    @media screen and (min-width: 480px) {
    .base .section {
      flex: 1; }
    .base .sectionLists {
      display: flex; } }
    @media screen and (min-width: 768px) {
        .base h4.searchHeading {
          padding: 0 14px; }
        .marketingAd .right h3 {
          font-size: 2.143rem;
          line-height: 2.143rem;
          margin-top: 0; }
    .marketingAd {
      flex-direction: row; }
      .marketingAd img {
        max-height: 260px;
        height: 100%;
        max-width: 100%;
        opacity: 0; }
      .marketingAd .left {
        max-height: unset;
        background-position: center;
        background-size: cover; }
      .marketingAd .right {
        flex-direction: column;
        padding: 1rem 5rem;
        align-items: flex-start;
        align-self: center; }
        .marketingAd .right h3 {
          font-size: 2.143rem;
          line-height: 2.143rem;
          margin-top: 0; }
        .marketingAd .right p {
          font-size: 1.143rem;
          line-height: 1.429rem;
          margin-bottom: 2rem; }
        .marketingAd .right .button {
          margin-top: auto;
          text-transform: uppercase;
          font-size: 1.143rem;
          line-height: 1.286rem; }
      .marketingAd > div {
        flex-grow: 0;
        align-items: center;
        display: flex; } }
