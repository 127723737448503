/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
  transition: opacity 200ms ease-out; }

.small {
  font-size: inherit; }

.medium {
  font-size: inherit; }

.large {
  font-size: inherit; }

.huge {
  font-size: inherit; }

.gigantic {
  font-size: inherit; }

.current {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: end; }

.noPrice {
  display: inline-block; }

.current_sup {
  display: block;
  white-space: nowrap;
  font-size: smaller;
  color: #666;
  padding-top: 0.2rem; }

.unit {
  padding-left: 0.313em; }

.comparePrice {
  padding-top: 0.5rem;
  display: block;
  white-space: nowrap;
  font-weight: 300; }

.isPending {
  opacity: 0.25; }
