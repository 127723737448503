/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.7rem;
  line-height: 1.7rem; }

.h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: SFNS_bold, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: SFNS_bold, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.h2 {
  font-size: 2.28571rem !important; }

.h4 {
  margin-bottom: 1rem; }

.base h1 {
  overflow: visible; }

.iconWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding-bottom: 1rem; }
  .iconWrapper .icon {
    display: flex;
    align-items: center;
    font-size: 2.71429rem;
    margin-right: 2rem; }
    .iconWrapper .icon img {
      height: 2.71429rem; }
    .iconWrapper .icon span {
      font-size: 1rem;
      line-height: 2rem;
      padding: 0 1rem;
      background-color: #cbdbd0; }

.leftSection {
  position: relative;
  margin-top: 0rem; }

.main {
  flex-grow: 1; }

.strikethrough {
  color: #666;
  text-decoration: line-through; }

.productContent {
  max-width: 610px; }

.productDisabledInfoWrap {
  font-family: SFNS_bold, sans-serif; }

.descriptionWrapper {
  background: #f9f9f9; }

.descriptionInner {
  background: #f9f9f9;
  display: flex; }

.descriptionRow,
.infoRow,
.iconMarkingsRow {
  margin-top: 0; }
  .descriptionRow .h2,
  .infoRow .h2,
  .iconMarkingsRow .h2 {
    margin-top: 0;
    font-family: SFNS, sans-serif; }

.descriptionRow,
.infoRow {
  max-width: 600px;
  padding: 0 0 3rem 0;
  flex: 1 1 0px; }

.descriptionRowCheckout,
.infoRowCheckout,
.handyInformationCheckout,
.flavorDescriptionCheckout,
.suitableForCheckout,
.iconMarkingsRowCheckout {
  max-width: unset;
  padding-left: 0; }

.iconMarkingsRow {
  padding-bottom: 2rem; }
  .iconMarkingsRow .h2 {
    border-bottom: 1px solid;
    padding-bottom: 1rem; }
  .iconMarkingsRow:not(.iconMarkingsRowCheckout) {
    padding: unset; }
    .iconMarkingsRow:not(.iconMarkingsRowCheckout) > div {
      padding-left: 0; }

.nutritionRow {
  padding: 1rem 0; }
  .nutritionRow h2 {
    margin-bottom: 0; }
  .nutritionRow .nutritionTable div:first-child {
    padding-top: 0; }
  .nutritionRow .nutritionInfo {
    padding: 0;
    margin: 0;
    margin-left: 1rem; }
    .nutritionRow .nutritionInfo a {
      text-decoration: underline; }
    .nutritionRow .nutritionInfo > div {
      margin-bottom: 3rem; }
      .nutritionRow .nutritionInfo > div .h4 {
        margin-top: 0; }
  .nutritionRow:not(.nutritionRowCheckout) > main {
    padding: 2rem 0; }

.classification {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1rem; }
  .classification li {
    display: flex;
    justify-content: flex-start; }
  .classification span {
    color: #999999;
    display: inline-block;
    flex-basis: 30%;
    margin-right: 0.25rem; }
  .classification span:nth-child(2) {
    color: #23272a; }

.productClassification__Box {
  padding: 1rem 1rem 1rem 0;
  margin-bottom: 1rem; }
  .productClassification__Box h4 {
    margin: 0.5rem 0 0 0; }
  .productClassification__Box button {
    margin: 0.75rem 0 0.25rem;
    font-size: 1.14286rem;
    line-height: 1.28rem;
    font-weight: 400;
    text-transform: uppercase; }
    .productClassification__Box button svg {
      margin-top: -2px;
      height: 1.429rem;
      width: 1.429rem; }

.productClassification__List {
  margin: 0 !important;
  padding: 0;
  width: 100%; }
  .isOpen .productClassification__List {
    height: 100%;
    max-height: 100%; }
  .productClassification__List ul {
    margin: 0 !important;
    padding: 0;
    width: 100%; }
  .productClassification__List ul li {
    border-bottom: none !important;
    padding: 0.25rem 0;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between; }
  .productClassification__List span {
    color: #000;
    display: inline-block; }
    .productClassification__List span:last-of-type {
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right; }
  .productClassification__List span a {
    text-decoration: underline !important; }
    .productClassification__List span a svg {
      margin-left: 0.5rem;
      height: 1rem;
      width: 1rem;
      vertical-align: middle; }
  .productClassification__List img {
    max-height: 1em; }

.showMoreLessButton {
  font-family: SFNS_bold, sans-serif;
  font-weight: 400; }
  .showMoreLessButton:hover {
    text-decoration: underline; }

.articleNumber {
  color: #999;
  display: flex;
  font-size: 1.1rem; }

.secondaryProductHeadingSection {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap; }
  .secondaryProductHeadingSection .productMarking__right {
    text-align: right; }
    .secondaryProductHeadingSection .productMarking__right > * {
      display: inline-block;
      margin: 0 0.2rem; }
    .secondaryProductHeadingSection .productMarking__right p {
      font-size: 1rem;
      bottom: 0.2rem;
      position: relative; }
  .secondaryProductHeadingSection .left {
    flex: 1; }
  .secondaryProductHeadingSection .right {
    align-items: flex-end; }

.campaignDatesWrap {
  background: #fff;
  padding: 1px 5px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1); }

.badgeIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 20%;
  max-width: 5rem; }
  .badgeIcon img {
    width: 100%;
    height: auto; }

.removePadding {
  padding: 0 !important; }

@media screen and (min-width: 480px) {
    .h2 {
      margin-top: 2.2rem; }
    .h3 {
      margin-top: 2.2rem; }
    .h4 {
      margin-top: 2.2rem; } }

@media screen and (min-width: 992px) {
    .h2 {
      font-family: SFNS_bold, sans-serif;
      font-size: 2.5rem;
      line-height: 2.85714rem; }
    .h3 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; }
    .h4 {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.42857rem; }
    .h2 {
      margin-top: 2rem; }
    .iconWrapper {
      padding-bottom: unset; }
      .iconWrapper .icon {
        font-size: 3.85714rem; }
        .iconWrapper .icon img {
          height: 3.85714rem; }
  .markerWrapper span {
    font-family: SFNS_thin, sans-serif;
    font-weight: 400;
    font-size: 1.14286rem; }
    .main {
      margin-top: 2rem; }
    .descriptionInner :first-child {
      padding-left: 0 !important; }
      .descriptionInner :first-child > main {
        padding-left: 0 !important; }
    .descriptionRow,
    .infoRow,
    .iconMarkingsRow {
      background: #f9f9f9; }
    .descriptionRow main,
    .infoRow main,
    .iconMarkingsRow main {
      background: #f9f9f9;
      padding: 0; }
        .iconMarkingsRow:not(.iconMarkingsRowCheckout) > div {
          padding: 0 calc(165px + 1rem) 3rem calc(165px + 1rem); }
      .nutritionRow:not(.nutritionRowCheckout) > main {
        padding: 0 calc(165px + 1rem); }
      .classification span {
        flex-basis: 50%; }
    .productClassification__Box {
      padding: 1rem 0;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7; } }

@media screen and (min-width: 768px) {
  .base h1 {
    font-size: 3.71429rem; }
      .nutritionRow .nutritionInfo {
        margin-left: inherit; }
      .nutritionRow .nutritionInfo {
        padding: 0; } }
