/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
@-webkit-keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }
@keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }

@-webkit-keyframes show {
  0% {
    max-height: 0px;
    opacity: 0; }
  100% {
    max-height: var(--listHeight, 500px);
    opacity: 1; } }

@keyframes show {
  0% {
    max-height: 0px;
    opacity: 0; }
  100% {
    max-height: var(--listHeight, 500px);
    opacity: 1; } }

@-webkit-keyframes hide {
  0% {
    max-height: var(--listHeight, 500px);
    opacity: 1; }
  100% {
    max-height: 0px;
    opacity: 0; } }

@keyframes hide {
  0% {
    max-height: var(--listHeight, 500px);
    opacity: 1; }
  100% {
    max-height: 0px;
    opacity: 0; } }

.base {
  cursor: pointer;
  position: relative;
  color: #000;
  height: 44px; }
  .base__normal {
    font-family: SFNS, sans-serif; }
    .base__normal .control:not(.open) {
      border-radius: 32px;
      border: thin solid #e5e5e5; }
    .base__normal .control.open {
      background: #fff5ce;
      -webkit-animation-name: shrink;
              animation-name: shrink;
      -webkit-animation-duration: 300ms;
              animation-duration: 300ms; }
    .base__normal .control:not(.open):hover {
      background: #e7e7e7; }
    .base__normal .item {
      padding: 0.5rem 1rem;
      font-family: SFNS, sans-serif; }
      .base__normal .item:not(.disabled):hover {
        background: #e7e7e7; }
    .base__normal .scrollable > div:not(:first-child) {
      border-top: thin solid #e5e5e5; }
  .base__big .control {
    font-family: SFNS_bold, sans-serif; }
    .base__big .control:not(.open) {
      border-radius: 32px;
      border: thin solid #e5e5e5; }
    .base__big .control.open {
      background: #fff5ce;
      -webkit-animation-name: shrink;
              animation-name: shrink;
      -webkit-animation-duration: 300ms;
              animation-duration: 300ms; }
    .base__big .control:not(.open):hover {
      background: #e7e7e7; }
  .base__big .options > div:last-child button {
    padding-bottom: 0.85714rem; }
  .base__big .item {
    padding: 0.5rem 1rem; }
    .base__big .item:not(.disabled):hover {
      background: #e7e7e7; }
  .base__big .scrollable > div:not(:first-child) {
    border-top: thin solid #e7e7e7; }

.innerWrapper {
  position: absolute;
  height: auto;
  width: 100%; }
  .innerWrapper.open {
    outline: 2px solid #f2b52e;
    border-radius: 16px;
    overflow: clip;
    border: 2px solid #fff;
    z-index: 10004; }
  .innerWrapper__invalid {
    border: 2px solid #b0406f !important;
    border-radius: 32px; }
  .innerWrapper:not(.open):hover {
    border-radius: 32px;
    border: thin solid black; }

.control {
  width: 100%;
  background: white;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center; }
  .control span.arrow {
    display: flex;
    align-items: center; }

.options {
  width: 100%;
  background: white;
  border-top-style: none;
  box-shadow: 0 0rem 0.14rem 0 rgba(0, 0, 0, 0.1), 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.07), 0 0.3rem 0.1rem -0.12rem rgba(0, 0, 0, 0.1);
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  overflow: auto; }
  .options.hideOptions {
    -webkit-animation-name: hide;
            animation-name: hide;
    overflow: clip; }

.item.disabled {
  opacity: 0.2;
  cursor: default; }

.separator {
  padding: 0.5rem 1rem;
  font-family: SFNS_bold, sans-serif;
  background: #f9f9f9;
  cursor: default; }

.scrollable {
  overflow-x: hidden; }

.button {
  width: 100%; }
