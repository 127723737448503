/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }
  .base .text {
    display: inline-block;
    padding-right: 8px; }
  .base .icon {
    height: 1.5em;
    width: 1.5em; }
  @media screen and (min-width: 768px) {
      .base .text {
        padding-left: 8px; } }
