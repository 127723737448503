/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.reset {
  margin: 4rem 4rem 0 4rem !important; }

.supplierWrapper {
  max-width: 97.14286rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; }
  .supplierWrapper .logotypeWrapper .logotype {
    transition: .3s;
    float: right; }
    .supplierWrapper .logotypeWrapper .logotype svg {
      height: 5rem;
      width: 5rem; }

.base fieldset {
  margin-bottom: 2rem; }
  .base fieldset legend {
    padding: 0 0.25rem; }

.input {
  margin-bottom: 0px !important; }

.searchCustomer {
  composes: input;
  composes: onehalf from '../../../Shared/Fields/trumps.scss'; }

.list {
  margin-top: 0.5rem; }
  .list tr:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .list tr:nth-child(2n) {
    background-color: #eff0f2; }
  .list tr:last-child {
    border-bottom: 1px solid #e5e5e5; }
  .list tr.link {
    color: #343d46; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem;
    width: 70px; }
  .list td:nth-child(2n), .list th:nth-child(2n) {
    max-width: 100px; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem;
    float: right; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }
  .list .tdInputWrapper {
    white-space: nowrap; }
    .list .tdInputWrapper input {
      width: 4.5rem;
      margin-right: 0.1rem; }

.generalSpinner {
  height: 2rem;
  width: 2rem;
  color: #f7594d;
  text-align: center; }

.searchInputWrapper {
  position: relative;
  padding-bottom: 1rem;
  width: 100%; }
  .searchInputWrapper input {
    width: 100%;
    padding: 0.6rem; }
  .searchInputWrapper button {
    margin-top: 3rem; }
  .searchInputWrapper .loginOut {
    display: flex;
    margin-left: -10px;
    margin-right: -10px; }
    .searchInputWrapper .loginOut > * {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px; }

.secondChoice {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  flex-flow: column; }
  .secondChoice button {
    margin-right: 4rem;
    margin-top: 2rem;
    padding: 0.5rem 1rem; }
    .secondChoice button:last-child {
      margin-right: 0; }

.spinnerWrapper {
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: #f7594d; }

.continueExhibitionOrder {
  margin-top: 15px; }
  .continueExhibitionOrder span {
    padding-left: 10px; }
  @media screen and (min-width: 768px) {
    .reset {
      margin-left: 0;
      margin-right: 0; }
    .searchInputWrapper {
      width: 740px; } }
  @media screen and (min-width: 992px) {
      .supplierWrapper .logotypeWrapper .logotype {
        position: relative;
        top: -2.5rem; }
        .supplierWrapper .logotypeWrapper .logotype svg {
          height: 9rem;
          width: 9rem; } }
