/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.row {
  align-items: center; }
  .row > * {
    word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    flex: 2.7 0 0; }

.deliveryGroupContainer {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  border: 1px solid #e5e5e5; }
  .deliveryGroupContainer .deliveryGroupItem {
    align-items: center;
    color: #23272a;
    display: flex;
    min-height: 4rem;
    height: auto;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    flex-basis: 5rem;
    padding-left: 3rem;
    position: relative; }
    .deliveryGroupContainer .deliveryGroupItem:hover {
      transition: 0.3s;
      cursor: pointer; }
    .deliveryGroupContainer .deliveryGroupItem .wrap {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .deliveryGroupContainer .deliveryGroupItem .wrap div {
        padding: 0 1rem;
        display: flex;
        align-items: center; }
        .deliveryGroupContainer .deliveryGroupItem .wrap div > span:first-child {
          margin-right: 5px; }
      .deliveryGroupContainer .deliveryGroupItem .wrap .totalShipmentWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .deliveryGroupContainer .deliveryGroupItem .wrap .totalShipmentWrap > span {
          margin-right: 0 !important; }
    .deliveryGroupContainer .deliveryGroupItem .selectBox {
      top: calc(50% - 10px);
      left: 12px;
      width: 23px;
      height: 20px;
      position: absolute; }
  .deliveryGroupContainer .collapse {
    flex-basis: 1.5em; }
  .deliveryGroupContainer .collapseBtn svg {
    color: #23272a; }

.checkBox {
  justify-content: center;
  flex-grow: 0;
  max-width: 2rem; }

.detailsContent {
  composes: row;
  height: 0;
  transition: 0.5s;
  opacity: 0;
  padding: 0;
  visibility: hidden;
  flex-wrap: wrap; }

.detailsContent.show {
  opacity: 1;
  visibility: visible;
  height: 100%; }
  .detailsContent.show + .detailsContent.show {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #fff; }

.mobileRow {
  margin-top: 5px;
  border-bottom: 1px dashed #e5e5e5;
  padding: 0.5rem 1rem; }
  .mobileRow .mobileRowData {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .mobileRow .mobileRowData .collapseWrap {
      width: 15%;
      flex: 1;
      align-self: center;
      text-align: center; }
      .mobileRow .mobileRowData .collapseWrap img {
        max-height: 4rem; }
    .mobileRow .mobileRowData .leftHeaderContent {
      padding: 0 1rem;
      display: block;
      flex: 3;
      font-size: 0.75em; }
      .mobileRow .mobileRowData .leftHeaderContent .rowLbl {
        font-weight: bold; }
      .mobileRow .mobileRowData .leftHeaderContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }
    .mobileRow .mobileRowData .rightHeaderContent {
      padding: 0 1rem;
      display: block;
      flex: 3 0 0;
      font-size: 0.75em; }
      .mobileRow .mobileRowData .rightHeaderContent .rowLbl {
        font-weight: bold; }
      .mobileRow .mobileRowData .rightHeaderContent > * {
        width: 100%;
        color: #23272a;
        padding-bottom: 5px; }
      @media screen and (min-width: 480px) {
        .deliveryGroupContainer .deliveryGroupItem .wrap {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between; } }
      @media screen and (min-width: 768px) {
          .deliveryGroupContainer .deliveryGroupItem .wrap .totalShipmentWrap {
            align-items: flex-end; } }
