/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base fieldset {
  border: 1px solid #dee1e4;
  padding: 1rem;
  margin-bottom: 2rem; }
  .base fieldset legend {
    padding: 0 0.25rem; }

.input {
  margin-bottom: 0px !important; }

.newPassword {
  composes: input;
  composes: onehalf from '../../../Shared/Fields/trumps.scss'; }

.checkbox {
  margin-top: 1rem; }

.changeFailed {
  color: #f7594d;
  padding-top: 1rem; }
