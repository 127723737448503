table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem; }
  table > thead th,
  table > thead td,
  table > tbody th,
  table > tbody td,
  table > tfoot th,
  table > tfoot td {
    vertical-align: top;
    text-align: left; }
