/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.fullChildBlock {
  width: 100%;
  padding: 0 1rem; }
  .fullChildBlock__item {
    width: 100%;
    height: auto; }

.requiredField {
  color: #b0406f;
  display: flex;
  line-height: 20px;
  padding: 2px;
  margin-top: 8px;
  font-size: 14px;
  vertical-align: middle; }
  .requiredField svg {
    width: 20px;
    height: 20px;
    margin-right: 0.35714rem;
    vertical-align: text-top; }

.divHalf {
  width: 295px;
  float: left;
  margin-right: 10px; }

.complaintForm h2 {
  color: black; }

.complaintForm h3 {
  color: black; }

.complaintForm .inputContainer {
  margin-bottom: 5px; }

.complaintForm .inputLabel {
  display: inline-block;
  width: 150px; }

.complaintForm .inputControlQty {
  margin-right: 10px; }

.complaintForm .radioUnit {
  margin-right: 5px;
  width: 80px; }

.complaintForm .inputControl {
  width: 300px; }

.complaintForm .additionalText {
  width: 300px; }

.complaintForm .anyMessageText {
  width: 45rem;
  height: 15rem; }

.complaintForm .additionalText2TextContainer {
  width: 400px; }

.complaintForm .additionalText2TextContainer .additionalText {
  width: 180px !important;
  margin-right: 10px !important; }

.complaintForm .customerHeader {
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .complaintForm .customerHeader .customerDetails {
    order: 1;
    flex: 1; }
  .complaintForm .customerHeader .customerTerms {
    order: 0;
    margin-bottom: 15px;
    flex: 1; }

.complaintForm .hide {
  display: none; }

.complaintForm .showComplaintReason {
  display: block;
  padding-top: 12px;
  padding-left: 22px; }

.complaintForm .clearboth {
  clear: both; }

.complaintForm .hideError {
  visibility: hidden; }

.complaintForm .showerror {
  display: block;
  visibility: visible !important;
  color: red !important;
  font-weight: bold !important; }

.complaintForm .termsHeader {
  cursor: pointer; }

.complaintForm .termsContentHide {
  visibility: hidden; }

.complaintForm .termsContentShow {
  visibility: visible;
  position: absolute;
  left: 10px;
  top: 10px;
  border: solid;
  padding: 5px;
  background-color: #f2f4d9;
  border-width: 1px;
  width: 400px; }

.complaintForm .buttonArea button {
  width: 100%; }
  .complaintForm .buttonArea button:last-child {
    margin-top: 5px; }
  @media screen and (min-width: 768px) {
    .complaintForm .customerHeader {
      flex-direction: row; }
      .complaintForm .customerHeader .customerDetails {
        order: 0; }
      .complaintForm .customerHeader .customerTerms {
        order: 1;
        margin-bottom: 0;
        margin-left: 10px; }
  .complaintForm .buttonArea button {
    width: auto; }
    .complaintForm .buttonArea button:last-child {
      margin-top: 0;
      margin-left: 10px; } }
