/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  padding-top: 0; }

.part {
  padding: 0 1rem; }

.row {
  display: flex;
  padding: 0 2rem 0 1rem;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  position: relative; }
  .row .hiddenRemove {
    opacity: 0;
    pointer-events: none; }
  .row.discontinued {
    background: #fef6f5; }

.header {
  background: #fff;
  padding: 10px;
  color: #0d0f11; }
  .header:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: #e7e7e7; }
  .header.isOpen {
    background: #e5e5e5; }
    .header.isOpen:hover {
      background: #D3D3D3; }
  .header .firstRow {
    margin-bottom: 10px; }
  .header .secondRow {
    margin-bottom: 5px; }
  .header .thirdRow {
    font-size: 0.85714rem;
    color: #0d0f11;
    font-family: SFNS_thin, sans-serif; }
  .header .iconsWrap button {
    height: 30px;
    width: 30px;
    padding: 0; }
  .header .iconsWrap .renameListButtonWrap + .removeListButtonWrap {
    margin-left: -5px; }
  .header .iconsWrap > div:not(:last-child) {
    padding-right: 6px; }
  .header .iconsWrap .removeListButtonWrap {
    font-size: 11px;
    display: flex; }
  .header .iconsWrap .renameListButtonWrap {
    display: flex; }
  .header .infoBottomRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .header .spinner {
    height: 16px;
    width: 16px;
    margin-right: 5px; }
  .header .placeholder {
    height: 21px;
    width: 21px; }
  .header .inventoryStatusInner {
    display: flex;
    align-items: center;
    margin-left: -5px; }
    .header .inventoryStatusInner > * {
      margin-left: 4px; }
  .header .inventoryTextWrap {
    font-weight: bold; }
  .header .inventoryStatusCircle {
    background-color: #666;
    width: 12px;
    height: 12px;
    border-radius: 50%; }
  .header .never .inventoryStatusCircle {
    background-color: #343d46; }
  .header .reOpened .inventoryStatusCircle,
  .header .ongoing .inventoryStatusCircle {
    background-color: #f2b52e; }
  .header .completed .inventoryStatusCircle {
    background-color: #24733b; }
  .header .editButtons {
    display: flex; }

.actionbar {
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  background: #f9f9f9; }
  .actionbar > * {
    margin: 0.5rem 0.5rem; }
  .actionbar .addToMenu {
    min-width: 15rem; }
  .actionbar .actionbarInner {
    justify-content: space-between;
    width: 100%; }
  .actionbar .actionMenuWrap {
    display: flex;
    flex-direction: column; }
    .actionbar .actionMenuWrap div:last-child {
      margin-top: 1rem; }
    .actionbar .actionMenuWrap .actionMenu {
      min-width: 15rem;
      margin: 0 0 1rem 0; }
      .actionbar .actionMenuWrap .actionMenu:last-child {
        margin: 0; }
  .actionbar .searchInListWrap {
    min-width: 15rem;
    margin-top: 1rem; }
    .actionbar .searchInListWrap .searchInList {
      width: 100%; }

.toolbar {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 0.5rem 0; }
  .toolbar > * {
    margin: 0.5rem 0.5rem; }
  .toolbar .buttonWrapper {
    margin-bottom: 35px;
    flex: 1; }

.actionMenu {
  min-width: 15rem; }

.rowHeader {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #e5e5e5; }

.dialog {
  overflow: inherit; }
  .dialog .listoptions {
    overflow-y: auto;
    max-height: 60vh; }

.list__row, .list__row_column {
  display: flex;
  flex-wrap: nowrap; }

.list__row .list__cell, .list__row > div {
  flex: 1 0; }

.list__header {
  font-size: 0.85714rem;
  padding: 10px; }

.list__row {
  align-items: center; }
  .list__row_column {
    flex-direction: column; }
  .list__row .list__cell__check {
    max-width: 30px; }
  .list__row .list__cell__title {
    font-size: 1.14286rem;
    line-height: 1.42857rem; }
  .list__row .list__cell__actions {
    display: flex;
    justify-content: flex-end;
    max-width: 150px; }
  .list__row .list__cell__status {
    color: #0d0f11; }
  .list__row .list__cell__totalprice {
    text-align: right; }
    .list__row .list__cell__totalprice strong, .list__row .list__cell__totalprice small {
      display: block; }
    .list__row .list__cell__totalprice strong {
      font-size: 1rem;
      font-weight: 400; }
    .list__row .list__cell__totalprice small {
      font-size: 0.85714rem;
      font-family: SFNS_thin, sans-serif; }

.wrapper {
  height: 10rem; }

.toggleSelected {
  padding: 0.5rem 0;
  width: 10%; }

.ordinalWrapper {
  padding: 0.5rem;
  flex: 0 0 8rem;
  overflow: hidden;
  flex: 3;
  min-width: 8rem;
  text-align: right; }

.ordinal {
  padding: 0.25rem 0.125rem;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  max-width: 7rem;
  max-height: 2.5rem; }
  .ordinal[disabled] {
    background: #fafafa; }

.topRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }

.deleteDialog,
.moveDialog {
  display: flex;
  justify-content: center; }

.deletedByMasterIcon {
  color: #ef7172 !important; }

@media screen and (min-width: 768px) {
    .row {
      flex-wrap: nowrap; }
    .header {
      height: 60px;
      padding: 0 14px; }
        .header .inventoryStatusInner > * {
          margin-left: 8px; }
      .header .inventoryTextWrap {
        margin-left: 10px; }
      .header .inventoryStatusCircle {
        width: 24px;
        height: 24px; }
    .actionbar {
      flex-flow: row wrap;
      padding: 0.5rem; }
      .actionbar .actionbarInner {
        display: flex; }
      .actionbar .actionMenuWrap {
        flex-direction: row; }
        .actionbar .actionMenuWrap .actionMenu {
          margin: 0 1rem 0 0; }
          .actionbar .actionMenuWrap .actionMenu:last-child {
            margin: 0; }
      .actionbar .searchInListWrap {
        margin: 0; }
    .toolbar {
      flex-flow: row wrap; }
    .list__header {
      padding: 10px 14px; }
      .list__row .list__cell__status {
        max-width: 240px; }
    .toggleSelected {
      width: initial; } }

@media screen and (max-width: 767px) {
    .header {
      align-items: flex-start; }
      .header .list__cell__check {
        padding-top: 6px; }
    .list__header > div:not(:first-child):not(:nth-child(2)) {
      display: none; }
      .list__row .list__cell__totalprice {
        font-size: 1rem;
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: -2.3rem; }
        .list__row .list__cell__totalprice > div {
          position: absolute; }
          .list__row .list__cell__totalprice > div strong {
            margin-bottom: 3px; } }

@media screen and (min-width: 1200px) {
      .header .iconsWrap .removeListButtonWrap {
        font-size: 12px; } }

@media screen and (min-width: 992px) {
    .header .iconsWrap > div:not(:last-child) {
      padding-right: 10px; }
    .header .iconsWrap .removeListButtonWrap button:hover,
    .header .iconsWrap .renameListButtonWrap button:hover {
      background: #D3D3D3; } }

@media screen and (min-width: 480px) {
      .actionbar .actionMenuWrap div:last-child {
        margin-top: 0; } }
