/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.card {
  border-bottom: 1px solid #e5e5e5; }
  .card__inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 0; }
    .card__inner__column {
      width: 33%; }
      .card__inner__column span {
        display: block; }
      .card__inner__column__header {
        color: #999; }
      .card__inner__column__content {
        color: #343d46;
        font-weight: bold;
        font-size: 16px; }
      .card__inner__column__link {
        color: #f8584c; }

.viewAllCard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 0; }
  .viewAllCard__viewAll {
    color: #f8584c;
    text-decoration: none;
    font-weight: bold;
    display: inherit;
    align-items: center; }
    .viewAllCard__viewAll__icon {
      margin-bottom: 2px;
      margin-left: 5px; }
    .viewAllCard__viewAll span {
      text-transform: uppercase; }

.header {
  padding-top: 15px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 18px; }

.widget {
  background-color: #fafafa;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0px 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

@media screen and (min-width: 768px) {
    .widget {
      width: 49%;
      padding: 0px 15px; } }
