/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.tableheader {
  border: 1px solid #e5e5e5;
  color: #343d46;
  background-color: #fafafa;
  width: 100%;
  display: flex; }
  .tableheader > div {
    display: block;
    padding: 1rem 1rem 1rem 0;
    font-weight: normal; }

.tablerow {
  width: 100%;
  display: flex; }
  .tablerow > div {
    border-bottom: 1px solid #e5e5e5;
    padding: 0.5rem 1rem 0.5rem 0; }
    .tablerow > div a {
      color: #23272a;
      text-decoration: none; }

.tablerow .imageColumn,
.tableheader .imageColumn {
  flex: 0 0 80px;
  width: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }

.tablerow .infoColumn,
.tableheader .infoColumn {
  flex: 1 1 auto;
  width: 100%; }

.tablerow .unitColumn,
.tableheader .unitColumn {
  flex: 0 0 150px;
  width: 150px; }

.tablerow .priceColumn,
.tableheader .priceColumn {
  flex: 0 0 170px;
  width: 170px; }

.tablerow .stockColumn,
.tableheader .stockColumn {
  flex: 0 0 110px;
  width: 110px; }

.tablerow .actionsColumn,
.tableheader .actionsColumn {
  flex: 0 0 180px;
  width: 180px; }

.tablerow .totalPriceColumn,
.tableheader .totalPriceColumn {
  flex: 0 0 130px;
  width: 130px;
  text-align: right;
  padding-right: 1rem; }

.tablerow .removeColumn,
.tableheader .removeColumn {
  min-width: 0;
  flex: 0 0 3rem;
  width: 3rem;
  padding-left: 0;
  display: flex;
  justify-content: flex-start; }

@media screen and (min-width: 1200px) {
    .tablerow .totalPriceColumn,
    .tableheader .totalPriceColumn {
      flex: 0 0 180px;
      width: 180px; } }
