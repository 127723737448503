/*TODO - detailed colorschema will come later*/
.base, button:not(.bare) {
  font: inherit;
  border: none;
  letter-spacing: 0.1rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border-radius: 500px;
  transition: color ease-out 200ms, background ease-out 200ms; }
  .base, button:not(.bare), .base:hover, button:hover:not(.bare), .base:focus, button:focus:not(.bare), .base:active, button:active:not(.bare) {
    text-decoration: none; }
  .base:empty:after, button:not(.bare):empty:after {
    display: inline-block;
    content: ''; }
  .base svg, button:not(.bare) svg {
    height: 1.5em;
    line-height: 1;
    vertical-align: bottom; }

.base.small, button.small {
  padding: 0.25rem 0.5rem;
  font-size: 0.85714rem;
  letter-spacing: 0px; }

.base.medium, button.medium {
  padding: 0.5rem 1.25rem;
  font-size: 1rem; }

.base.large, button.large {
  padding: 1rem 2rem;
  font-size: 1.14286rem; }

.base.full, button.full, .base.icon, button.icon, .base.round, button.round, .base.square, button.square {
  min-width: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  text-align: center; }
  .base.small.full, button.small.full, .base.small.icon, button.small.icon, .base.small.round, button.small.round, .base.small.square, button.small.square {
    min-width: 2rem; }
  .base.large.full, button.large.full, .base.large.icon, button.large.icon, .base.large.round, button.large.round, .base.large.square, button.large.square {
    min-width: 5.42857rem; }

.base.full, button.full {
  width: 100%; }

.base.wide, button.wide {
  width: 100%; }

.base.icon > svg, button.icon > svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

.base.icon:hover > svg, button.icon:hover > svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

.base.toolbar {
  text-transform: uppercase;
  line-height: 44px;
  padding: 0 1.35714rem;
  white-space: nowrap; }

.base.toolbarbutton, button.toolbarbutton {
  text-transform: uppercase;
  line-height: 44px;
  padding: 0 1.35714rem;
  white-space: nowrap;
  border: 1px solid #0d0f11; }

.base.round, button.round {
  padding: 0.5rem;
  border-radius: 50%;
  white-space: nowrap;
  font-size: 0; }
  .base.small.round, button.small.round {
    padding: 0.25rem; }
    .base.small.round > span, button.small.round > span,
    .base.small.round svg,
    button.small.round svg {
      font-size: 1rem; }
  .base.large.round, button.large.round {
    padding: 1rem; }
    .base.large.round > span, button.large.round > span,
    .base.large.round svg,
    button.large.round svg {
      font-size: 2.28571rem; }
  .base.round:after, button.round:after {
    content: '';
    display: inline-block;
    padding: 100% 0 0;
    vertical-align: middle; }
  .base.round > span, button.round > span,
  .base.round svg,
  button.round svg {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    font-size: 1rem; }

.base, .base.primary, button:not(.bare), button.primary {
  color: #fff;
  background: #f7594d; }
  .base > svg, .base.primary > svg, button:not(.bare) > svg, button.primary > svg {
    color: #fff; }
  html:not(:global(.ios)) .base:hover, html:not(:global(.ios)) button:hover:not(.bare), html:not(:global(.ios)) button.primary:hover,
  html:not(:global(.ios)) .base:focus,
  html:not(:global(.ios)) button:focus:not(.bare),
  html:not(:global(.ios)) button.primary:focus {
    background: #f98b82; }
  html:not(:global(.ios)) .base:active, html:not(:global(.ios)) button:active:not(.bare), html:not(:global(.ios)) button.primary:active {
    background: rgba(247, 89, 77, 0.8); }

.base.disabled, .base.disabled.primary, button[disabled], button.primary[disabled] {
  background: #fdcdca;
  background: #fdcdca; }

.base.positive, .base.disabled.positive, button.positive {
  color: #fff;
  background: #6fb172; }
  .base.positive > svg, .base.disabled.positive > svg, button.positive > svg {
    color: #fff; }
  html:not(:global(.ios)) .base.positive:hover, html:not(:global(.ios)) button.positive:hover,
  html:not(:global(.ios)) .base.positive:focus,
  html:not(:global(.ios)) button.positive:focus {
    background: #d2e7d3; }
  html:not(:global(.ios)) .base.positive:active, html:not(:global(.ios)) button.positive:active {
    background: #73b373; }

button.positive[disabled] {
  background: #fdcdca;
  background: #f3f9f4; }

.base.secondary, button.secondary {
  color: #fff;
  background: #343d46; }
  .base.secondary > svg, button.secondary > svg {
    color: #fff; }
  html:not(:global(.ios)) .base.secondary:hover, html:not(:global(.ios)) button.secondary:hover,
  html:not(:global(.ios)) .base.secondary:focus,
  html:not(:global(.ios)) button.secondary:focus {
    background: #495159; }
  html:not(:global(.ios)) .base.secondary:active, html:not(:global(.ios)) button.secondary:active {
    background: #2f373f; }

.base.disabled.secondary, button.secondary[disabled] {
  background: #fdcdca;
  background: #c2c5c8; }

.base.monochrome, button.monochrome {
  color: black;
  background: #eff0f2; }
  .base.monochrome > svg, button.monochrome > svg {
    color: black; }
  html:not(:global(.ios)) .base.monochrome:hover, html:not(:global(.ios)) button.monochrome:hover,
  html:not(:global(.ios)) .base.monochrome:focus,
  html:not(:global(.ios)) button.monochrome:focus {
    background: #e5e5e5; }

.base.disabled.monochrome, button.monochrome[disabled] {
  background: #fdcdca; }

.base.tertiary, button.tertiary {
  color: #fff;
  background: #f7594d; }
  .base.tertiary > svg, button.tertiary > svg {
    color: #fff; }
  html:not(:global(.ios)) .base.tertiary:hover, html:not(:global(.ios)) button.tertiary:hover,
  html:not(:global(.ios)) .base.tertiary:focus,
  html:not(:global(.ios)) button.tertiary:focus {
    background: #f84537; }

.base.disabled.tertiary, button.tertiary[disabled] {
  background: #fdcdca; }

.base.pale, button.pale {
  color: #23272a;
  background: white; }
  .base.pale > svg, button.pale > svg {
    color: #23272a; }
  html:not(:global(.ios)) .base.pale:hover, html:not(:global(.ios)) button.pale:hover,
  html:not(:global(.ios)) .base.pale:focus,
  html:not(:global(.ios)) button.pale:focus {
    background: #eff0f2; }

.base.disabled.pale, button.pale[disabled] {
  background: #fdcdca; }

.base.clear, button.clear {
  color: #23272a;
  background: rgba(0, 0, 0, 0); }
  .base.clear > svg, button.clear > svg {
    color: #23272a; }
  html:not(:global(.ios)) .base.clear:hover, html:not(:global(.ios)) button.clear:hover,
  html:not(:global(.ios)) .base.clear:focus,
  html:not(:global(.ios)) button.clear:focus,
  html:not(:global(.ios)) .base.clear:active,
  html:not(:global(.ios)) button.clear:active {
    color: inherit;
    background: none; }

button.clear[disabled] {
  background: #fdcdca;
  background: #e5e5e5; }

.base.icon, button.icon {
  color: #343d46;
  background: rgba(0, 0, 0, 0); }
  .base.icon > svg, button.icon > svg {
    color: #343d46; }
  html:not(:global(.ios)) .base.icon:hover, html:not(:global(.ios)) button.icon:hover,
  html:not(:global(.ios)) .base.icon:focus,
  html:not(:global(.ios)) button.icon:focus,
  html:not(:global(.ios)) .base.icon:active,
  html:not(:global(.ios)) button.icon:active {
    color: #343d46; }
    html:not(:global(.ios)) .base.icon:hover > svg, html:not(:global(.ios)) button.icon:hover > svg,
    html:not(:global(.ios)) .base.icon:focus > svg,
    html:not(:global(.ios)) button.icon:focus > svg,
    html:not(:global(.ios)) .base.icon:active > svg,
    html:not(:global(.ios)) button.icon:active > svg {
      color: #343d46; }
  html:not(:global(.ios)) .base.icon:hover, html:not(:global(.ios)) button.icon:hover,
  html:not(:global(.ios)) .base.icon:focus,
  html:not(:global(.ios)) button.icon:focus {
    background: rgba(0, 0, 0, 0); }
  
  html:not(:global(.ios)) .base.icon:active,
  html:not(:global(.ios)) button.icon:active {
    background: rgba(0, 0, 0, 0); }

.base.toolbarbutton, button.toolbarbutton {
  color: #0d0f11;
  background: #f9f9f9; }
  .base.toolbarbutton > svg, button.toolbarbutton > svg {
    color: #0d0f11; }
  html:not(:global(.ios)) .base.toolbarbutton:hover, html:not(:global(.ios)) button.toolbarbutton:hover,
  html:not(:global(.ios)) .base.toolbarbutton:focus,
  html:not(:global(.ios)) button.toolbarbutton:focus {
    background: #e7e7e7; }

.base.disabled, button[disabled] {
  pointer-events: none; }

.base.clear:hover {
  background: blue; }

button.icon.small {
  padding: 0;
  text-align: right; }

button.pale:hover {
  background: white !important; }

.bare {
  width: auto;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  text-align: inherit;
  color: inherit;
  background: none;
  border: none; }

.link:hover, .link:focus {
  text-decoration: underline;
  color: #f7594d; }

@media screen and (min-width: 992px) {
  button.pale:hover {
    color: #2f373f; } }
