/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.banner {
  padding: 1rem 0; }

.breadcrumbsWrap {
  margin-top: 20px;
  margin-bottom: 10px; }

.bottomBorder {
  padding-bottom: 40px; }

@media screen and (min-width: 992px) {
    .bottomBorder {
      position: relative;
      margin-top: 0 !important;
      padding-bottom: 0; }
      .bottomBorder:after {
        content: "";
        display: block;
        border-bottom: 1px solid #F7594D;
        height: 0px;
        width: 100%;
        position: absolute;
        bottom: -50px; } }
