/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.freezescroll {
  overflow: hidden; }

.base {
  position: relative; }

.overlay {
  background: #495159;
  opacity: 0.5;
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 105vh;
  width: 100vw;
  transition: opacity 200ms ease-out;
  will-change: opacity;
  z-index: 10008;
  opacity: 0; }

.active {
  opacity: 0.5; }

.wrapper {
  z-index: 10008;
  position: relative;
  height: inherit; }

.ignoreAnim {
  transition: none !important;
  opacity: 0.5; }

.transparent {
  background: transparent; }
