/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.descriptionImageContainer {
  width: 100%;
  background-color: #F5F5F5;
  height: 229px; }
  .descriptionImageContainer .textContainer {
    width: 40%;
    height: 100%;
    overflow: hidden;
    padding: 20px 15px;
    float: left;
    border: 1px solid #e5e5e5;
    border-right: none; }
  .descriptionImageContainer .imageContainer {
    width: 60%;
    height: 100%;
    float: left;
    background-size: cover;
    background-position: center; }
    .descriptionImageContainer .imageContainer .categoryImage {
      height: 100%;
      width: 100%; }
