.displayImage, .epiEditBase {
  height: 100%;
  min-height: 300px;
  flex: 1;
  position: relative; }

.displayImage p {
  margin-bottom: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%; }

.displayImage img {
  position: absolute;
  height: 100%;
  width: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.epiEditBase {
  max-height: 500px; }
