/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.placeholder {
  height: 61px; }

.base {
  width: 100%;
  position: relative;
  z-index: 10006; }

div.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: inherit; }

.layout {
  composes: full from "../../../../styles/objects/layout.scss";
  display: flex;
  top: 0px;
  position: absolute;
  align-items: center;
  justify-content: flex-start; }

.miniCartWrapper {
  position: absolute;
  right: 0;
  width: 100%; }

.noscript {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  padding: 0.5rem;
  background: #f66d62; }

@media screen and (min-width: 992px) {
    .placeholder {
      height: 230px; }
    .layout {
      position: relative; } }

@media print {
    .placeholder {
      display: none; } }
