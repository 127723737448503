/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.block h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  font-family: SFNS, sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase; }

.block > div:last-of-type {
  white-space: pre-line;
  padding: 7rem 1.5rem 3.5rem;
  margin: 0 -1.5rem -1.5rem;
  background: #fff;
  overflow: hidden; }
  .block > div:last-of-type:before {
    border-radius: 0 0 100% 100%;
    position: absolute;
    background: #f5e8e7;
    height: 150px;
    right: -50px;
    left: -50px;
    content: '';
    top: -100px; }

.block > div {
  position: relative; }

a.priceListPageLink {
  color: #0d0f11;
  font-size: 1rem;
  background-color: white;
  border-color: #343D46;
  border-width: 0.063rem;
  border-style: solid;
  padding: 0.688rem 1.25rem;
  border-radius: 100px;
  flex-direction: column;
  display: block;
  text-align: center;
  margin: 2.5rem auto 0;
  max-width: 300px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.05em; }
  a.priceListPageLink:hover {
    background-color: #E7E7E7;
    color: #343D46;
    text-decoration: none; }
  a.priceListPageLink > svg {
    position: relative;
    top: -1px; }

.icon {
  color: #0d0f11;
  height: 1rem;
  font-size: 0.938rem; }

@media screen and (min-width: 992px) {
      .block > div:last-of-type:before {
        display: none; }
  .block {
    display: flex;
    align-items: center; }
    .block > div:first-of-type {
      flex: 1 1 45%;
      padding-right: 4rem; }
    .block > div:last-of-type {
      background: transparent;
      flex: 1 1 45%;
      padding: 0 4% 0 8%;
      margin-top: -60px; }
    a.priceListPageLink {
      text-align: left;
      display: inline-block;
      margin: 2.5rem 0;
      max-width: unset; } }
