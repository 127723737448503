/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.full {
  width: 100%; }

.onehalf, .onethird, .twothirds {
  vertical-align: bottom; }

.onehalf {
  width: calc(50% - 0.5rem);
  width: calc(50% - 0.5rem - 1px);
  margin-right: 1rem; }

.onethird {
  width: calc(33.33333% - 0.66667rem);
  width: calc(33.33333% - 0.66667rem - 1px);
  margin-right: 1rem; }

.twothirds {
  width: calc(66.66667% - 0.33333rem);
  width: calc(66.66667% - 0.33333rem - 1px);
  margin-right: 1rem; }

.last.onehalf, .last.onethird, .last.twothirds {
  margin-right: 0; }

@media screen and (min-width: 768px) {
    .onehalf {
      width: calc(50% - 1rem);
      width: calc(50% - 1rem - 1px);
      margin-right: 2rem; }
    .onethird {
      width: calc(33.33333% - 1.33333rem);
      width: calc(33.33333% - 1.33333rem - 1px);
      margin-right: 2rem; }
    .twothirds {
      width: calc(66.66667% - 0.66667rem);
      width: calc(66.66667% - 0.66667rem - 1px);
      margin-right: 2rem; } }

@media screen and (min-width: 1200px) {
    .onehalf {
      width: calc(50% - 1.5rem);
      width: calc(50% - 1.5rem - 1px);
      margin-right: 3rem; }
    .onethird {
      width: calc(33.33333% - 2rem);
      width: calc(33.33333% - 2rem - 1px);
      margin-right: 3rem; }
    .twothirds {
      width: calc(66.66667% - 1rem);
      width: calc(66.66667% - 1rem - 1px);
      margin-right: 3rem; } }
