/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10010;
  padding: 0.35rem 0;
  background: #ef7172;
  will-change: transform;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out; }
  .base p {
    margin: 0; }

.wrapper {
  composes: max from '../../../styles/objects/layout.scss';
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem; }

.buttons {
  white-space: nowrap; }

.button {
  background: transparent !important;
  padding-right: 0 !important;
  border: 1px solid white !important;
  padding-right: 1rem !important; }
  .button:hover {
    text-decoration: underline !important; }
  .button + .button {
    margin-left: 1rem; }

.enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }
  .enter.enterActive {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }

.exit {
  -webkit-transform: translateY(0);
          transform: translateY(0); }
  .exit.exitActive {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  @media screen and (min-width: 768px) {
    .base {
      bottom: auto;
      top: 70px;
      padding: 0.25rem 0;
      z-index: 10005; }
    .wrapper {
      flex-direction: row; }
    .enter {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
      .exit.exitActive {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%); } }
