/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.overviewBox {
  background-color: #fff;
  padding: 1rem 1.5rem;
  margin-top: 1.875rem; }

.circleBackground {
  position: relative;
  overflow: hidden;
  background: #f5e8e7; }
  .circleBackground > div {
    position: relative; }

.selected {
  background: #e7e7e7;
  margin: -1rem -1.5rem;
  padding: 1rem 1.5rem; }

.header {
  font-size: 20px;
  margin-top: 0rem;
  font-weight: 400;
  font-family: SFNS; }

.bodyText {
  margin-bottom: 1rem; }

.general {
  display: flex;
  flex-direction: column; }

.columnInformation {
  order: 1; }

.columnButton {
  order: 3;
  display: flex;
  align-items: flex-start; }

.buttonDetails {
  color: #0d0f11;
  font-size: 1rem;
  background-color: white;
  border-color: #343D46;
  border-width: 0.063rem;
  border-style: solid;
  float: right;
  padding: 0.688rem 1.25rem;
  border-radius: 100px;
  flex-direction: column;
  display: inline;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.05em; }
  .buttonDetails:hover {
    background-color: #E7E7E7;
    color: #343D46;
    text-decoration: none; }
  .buttonDetails > svg {
    position: relative;
    top: -1px; }

.icon {
  color: #0d0f11;
  height: 1rem;
  font-size: 0.938rem; }

.labelWrapper {
  order: 2; }

.label {
  color: white;
  text-align: center;
  padding: 0.3rem 1rem;
  margin-right: 0.625rem;
  border-radius: 0.188rem;
  display: inline-block;
  margin-bottom: .5rem; }

.gray {
  background-color: #343D46; }

.lightgray {
  background-color: #e7e7e7;
  color: #6c737a; }

.green {
  background-color: #24733B; }

.yellow {
  background-color: #F2B52E;
  color: #343D46; }

.documentLink {
  text-decoration: underline;
  margin: 1.07143rem 0;
  line-height: 1.71429rem; }
  .documentLink > svg {
    width: 1.71429rem;
    height: 1.71429rem; }
  .documentLink > a {
    margin-left: 0.28571rem; }
  @media screen and (min-width: 992px) {
    .circleBackground {
      background: #fff;
      background: linear-gradient(90deg, #f5e8e7 0, #f5e8e7 40%, #fff 40%, #fff 100%); }
    .circleBackground:before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 100%;
      background: #f5e8e7;
      width: 200px;
      left: 34%;
      top: -10%;
      bottom: -10%; }
    .header {
      margin-bottom: 0rem; }
    .general {
      flex-direction: unset;
      flex-wrap: wrap; }
    .columnInformation {
      flex: 70%;
      order: unset; }
    .columnButton {
      flex: 30%;
      align-self: center;
      order: unset;
      align-items: flex-end; }
    .buttonDetails {
      margin-left: auto;
      margin-top: unset; }
    .labelWrapper {
      order: unset;
      width: 100%; } }
  @media (max-width: md) {
  #cookies {
    flex-direction: column; } }
