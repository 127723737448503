/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  display: block;
  flex: 1;
  width: 100vw;
  min-height: 60px;
  overflow-x: hidden; }
  .base .searchInput {
    height: 100%;
    width: 100%;
    min-height: 46px;
    max-height: 46px;
    font-size: 1.14286rem;
    box-sizing: border-box;
    background: #fff;
    padding: 0 2.5rem 0 1rem;
    border: 1px solid #F7594D;
    border-radius: 5px; }
    .base .searchInput::-webkit-input-placeholder {
      color: #fff; }
    .base .searchInput::-ms-input-placeholder {
      color: #fff; }
    .base .searchInput::placeholder {
      color: #fff; }
  .base .icon {
    position: absolute;
    right: 1.75rem;
    top: 1.4rem; }
    .base .icon svg {
      color: #F7594D;
      height: 24px;
      width: 24px; }

.mobileTopWrapper {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1; }
  .mobileTopWrapper .close button {
    padding: 0;
    background: transparent; }
    .mobileTopWrapper .close button svg {
      width: 1.25rem; }

.searchInputWrapper,
.searchTop {
  width: 100%;
  display: block;
  min-height: 60px;
  font-size: 20px; }

.searchInputWrapper {
  position: relative;
  top: 60px;
  padding: 1rem;
  z-index: 0; }

.searchTop {
  background: #F7594D; }
  .searchTop > span {
    min-height: 60px;
    line-height: 60px;
    vertical-align: middle;
    margin-left: 1rem;
    color: #fff;
    font-size: 20px; }

.close {
  composes: bare from "../../../../../../styles/objects/buttons.scss";
  color: #fff;
  height: 60px;
  width: 60px;
  line-height: 60px;
  float: right;
  text-align: center; }
  .close svg {
    height: 20px;
    width: 1.25rem;
    top: 5px;
    position: relative; }

.smallspinner {
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 3.5rem;
  top: 2.25rem;
  -webkit-animation: fadein 100ms ease-in-out;
          animation: fadein 100ms ease-in-out; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
