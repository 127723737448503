/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.leadParagraph {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem; }

.group, .description, label.text, .items {
  font-family: SFNS, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.buttonSection {
  text-align: right;
  justify-content: flex-end;
  margin-top: 0.25rem;
  float: right;
  margin-right: 8.5rem; }
  .buttonSection button {
    margin-left: 0.5rem;
    display: inline;
    float: right; }
  .buttonSection .removeBtn {
    display: inline; }
    .buttonSection .removeBtn > div {
      position: absolute;
      text-align: left; }

.mobileButtonSection {
  text-align: right;
  justify-content: flex-end;
  margin-top: 0.25rem;
  float: right; }
  .mobileButtonSection button {
    margin-left: 0.5rem;
    display: inline;
    float: right; }

.masterList {
  text-align: left;
  display: inline-block;
  width: 25rem; }

.visibleCalc {
  padding-bottom: 11rem; }

.mid {
  margin: auto;
  display: block;
  width: 65%;
  min-width: 50rem; }
  .mid > * {
    width: 100%; }

.share {
  font-size: 1.42857rem;
  display: inline-block;
  padding-right: 0.5rem; }

.addIngidientWrapper {
  position: relative;
  padding-bottom: 1rem; }
  .addIngidientWrapper input {
    width: 100%;
    padding: 0.9rem; }
  .addIngidientWrapper .inlineButton {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 8rem; }
    html:global(.ie) .addIngidientWrapper .inlineButton {
      top: 0.4rem; }

.addedImageWrapper {
  display: block; }
  .addedImageWrapper p {
    display: inline-block;
    max-width: 50%;
    margin-right: 1rem; }
  .addedImageWrapper .remove {
    display: inline-block; }
    .addedImageWrapper .remove:hover {
      cursor: pointer; }

.dropzone b {
  width: 100%; }

.dropzone > div {
  height: 20rem !important;
  margin: 2rem auto;
  width: 20rem !important;
  background: #fafafa; }
  .dropzone > div:hover {
    transition: 0.3s;
    background: #f9f9f9;
    cursor: pointer; }

.dropzone .dropzoneContent {
  position: relative;
  margin: 20%;
  height: 60%;
  padding: 3rem 0;
  vertical-align: middle;
  display: inline-block;
  text-align: center; }
  .dropzone .dropzoneContent svg {
    left: 50%;
    font-size: 1.5rem;
    top: 0.6rem; }

.previewImage img {
  width: 10rem;
  height: auto; }

.inputSearch {
  width: 100%; }

.portionInput {
  width: auto; }

.group {
  color: #999;
  padding-bottom: 2rem;
  display: inline-block; }

.topSection {
  position: relative;
  width: 100%; }

.leftSection {
  position: relative;
  margin-top: 0rem; }
  .leftSection .actionsWrapper {
    display: flex;
    margin-bottom: 1rem; }
    .leftSection .actionsWrapper > :first-child {
      margin-right: 0.5rem; }

.rightSection {
  padding-top: 2rem; }

.rightButtonBig {
  float: right;
  padding: 1rem !important;
  width: 10rem; }

.leadParagraph {
  padding-top: 2rem; }
  .leadParagraph .text {
    margin-bottom: 3rem; }

.shoppingListModal {
  top: 0;
  height: 100%;
  max-height: 100%;
  padding: 0.5rem 1rem; }
  .shoppingListModal h2 {
    padding: 1rem 0;
    font-size: 1.42857rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0;
    white-space: nowrap; }

.shoppingList {
  overflow: auto;
  height: 50vh;
  margin-bottom: 2rem;
  width: 100%; }

.shoppingListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.quill {
  border: 1px solid gray;
  padding: 1rem; }

.spinnerWrapper {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 9rem;
  top: 0.75rem;
  color: #f7594d; }

.spinnerWrapperSearchUser {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0.2rem;
  top: 1.8rem;
  color: #f7594d; }

.half {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }
  .half div:last-child,
  .half div:first-child {
    width: 50%;
    flex: 1; }

.description {
  padding-top: 2rem;
  white-space: pre-line; }

.shareWithAddedUsers {
  width: 100%;
  display: inline-block;
  padding: 0; }
  .shareWithAddedUsers li {
    padding: 0.5rem; }
    .shareWithAddedUsers li button {
      display: block;
      float: right;
      padding: 0; }
      .shareWithAddedUsers li button:hover {
        cursor: pointer; }
  .shareWithAddedUsers li:nth-child(odd) {
    background-color: #fff; }
  .shareWithAddedUsers li:nth-child(even) {
    background-color: #f9f9f9; }

.inputSearchUserWrapper {
  position: relative;
  min-height: 18rem;
  display: inline-block;
  width: 100%;
  z-index: 2; }
  .inputSearchUserWrapper .shareWithUsers {
    display: inline-block;
    position: absolute;
    top: 3.1rem;
    left: 0;
    padding: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0.5rem 1rem #e5e5e5; }
    .inputSearchUserWrapper .shareWithUsers li {
      padding: 0.5rem; }
      .inputSearchUserWrapper .shareWithUsers li:hover {
        cursor: pointer;
        transition: 0.3s;
        background-color: #e5e5e5 !important; }
    .inputSearchUserWrapper .shareWithUsers li:nth-child(odd) {
      background-color: #fff; }
    .inputSearchUserWrapper .shareWithUsers li:nth-child(even) {
      background-color: #f9f9f9; }

.text {
  color: #666; }
  .text ul > li:before {
    content: ' ';
    height: 0.75rem;
    width: 0.75rem;
    display: inline-block;
    background-color: rgba(247, 89, 77, 0.8);
    border-radius: 100%;
    vertical-align: baseline;
    margin: 0 0.5rem 0 0; }
  .text ol {
    counter-reset: item; }
  .text ol > li:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: rgba(247, 89, 77, 0.8);
    font-weight: bold; }

.markText:before {
  content: '|'; }

.markText:first-child:before {
  content: ''; }

.selected {
  background-color: #d2e7d3 !important; }

.price {
  display: inline-block; }

.recipeList_autoComplete_Wrapper {
  position: absolute;
  border: 1px solid lightgray;
  width: 100%;
  z-index: 10;
  background: white;
  display: block;
  padding: 0;
  margin: 0;
  top: -1px;
  box-shadow: 0 0.5rem 1rem #e5e5e5;
  max-height: 43rem;
  overflow-y: scroll; }
  .recipeList_autoComplete_Wrapper .listWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .recipeList_autoComplete_Wrapper .listWrapper:hover {
      background: #e5e5e5;
      transition: 0.3s;
      cursor: pointer; }
    .recipeList_autoComplete_Wrapper .listWrapper .portionCost {
      width: 10rem; }

.pullRight {
  float: right;
  top: -2rem;
  position: relative; }

.listWrapper__Recipies {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin: 0; }
  .listWrapper__Recipies .listWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    background-color: #eff0f2;
    padding: 0.5rem 1rem; }
    .listWrapper__Recipies .listWrapper.discontinued {
      background-color: #fef6f5; }
    .listWrapper__Recipies .listWrapper.listHeader {
      height: auto;
      font-weight: bold;
      border-top: none;
      color: #999;
      background: #fff; }
    .listWrapper__Recipies .listWrapper.footer {
      justify-content: flex-end;
      padding: 0.5rem 1rem;
      height: auto; }
      .listWrapper__Recipies .listWrapper.footer a {
        text-decoration: underline;
        cursor: pointer; }
    .listWrapper__Recipies .listWrapper .portionCost {
      width: 10rem; }

.listWrapper {
  display: inline-block;
  width: 100%;
  padding: large;
  height: 6rem; }
  .listWrapper .img {
    width: 8rem;
    display: inline-flex;
    height: 100%;
    margin-right: 1rem;
    padding: 0.25rem;
    justify-content: center; }
    .listWrapper .img img {
      max-height: 100%; }
  .listWrapper .name {
    width: 40%;
    display: inline-block;
    margin-right: 0.5rem; }
  .listWrapper .portionCost {
    width: 5rem;
    display: inline-block;
    margin-right: 0.5rem; }
  .listWrapper .grade {
    width: 7rem;
    display: inline-block; }
  .listWrapper .votes {
    width: 5rem;
    display: inline-block; }
  .listWrapper .remove {
    width: 5rem;
    display: inline-block;
    text-align: right; }
    .listWrapper .remove > div {
      cursor: pointer;
      display: inline-block; }

.autocompleteWrapper {
  position: relative; }
  .autocompleteWrapper .autocomplete {
    top: -1rem;
    z-index: 1;
    max-height: 50vh;
    padding-top: 7px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    position: absolute; }

.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem; }
  .inputWrapper label {
    margin-bottom: 0.5rem; }
  .inputWrapper .textarea {
    width: 100%;
    height: 8rem; }

.buttonWrapper {
  margin-top: 2rem; }
  .buttonWrapper button {
    margin-right: 1rem; }

.checkboxWrapper {
  display: flex;
  flex-wrap: wrap; }
  .checkboxWrapper .checkbox {
    margin-right: 1rem; }

.quantityWrapper {
  height: 2.5rem;
  display: inline-flex; }

.table {
  margin-top: 1rem; }
  .table th {
    white-space: nowrap;
    padding-right: 1rem; }
  .table tr:nth-child(even) {
    background-color: #dddddd; }

.resetList {
  width: 10rem; }

.react_quill button {
  margin-right: 0.2rem; }
  .react_quill button:hover {
    transition: 0.3s;
    background: #fafafa !important;
    color: initial !important; }

.textarea_description {
  height: 200px; }

.list {
  margin-top: 0.5rem;
  max-height: 32.5rem;
  overflow-y: scroll;
  display: block;
  border: 1px solid #f9f9f9;
  min-width: 56rem; }
  .list tbody {
    width: 100%; }
  .list tr:not(:first-child) {
    border-top: 1px solid #e5e5e5; }
  .list tr:nth-child(2n) {
    background-color: #eff0f2; }
  .list tr:last-child {
    border-bottom: 1px solid #e5e5e5; }
  .list tr.link {
    color: #343d46; }
  .list td:first-child,
  .list th:first-child {
    padding-left: 1rem; }
  .list td:last-child,
  .list th:last-child {
    padding-right: 1rem; }
  .list th {
    padding: 0 1rem 0.5rem 0;
    white-space: nowrap; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }
  .list .tdInputWrapper {
    white-space: nowrap; }
    .list .tdInputWrapper input {
      width: 4.5rem;
      margin-right: 0.1rem; }

.mobileTopNode {
  padding: 2rem 1rem 1rem; }

.borderTop {
  border-top: 1px #e5e5e5 solid; }
  .borderTop .addToMenu {
    margin-top: 0.5rem; }

.classifications {
  font-size: 1.14286rem;
  color: #666;
  text-align: right; }

label.text {
  padding-right: 2rem; }

.items {
  margin: 2rem 0.5rem; }
  .items td {
    padding: 0.25rem 0.25rem; }
  .items tr td:first-of-type {
    padding-right: 3rem;
    white-space: nowrap; }
  .items a {
    color: #000;
    text-decoration: underline; }
  .items h4 {
    color: #000; }

.footNote {
  color: #999; }

.addRecipeToCartModal {
  width: 95%;
  max-width: 1100px; }

.loaderSpinner {
  position: absolute;
  width: 3rem;
  padding: 0.5rem;
  color: #f7594d;
  top: 50%;
  left: calc(50% - 3rem / 2);
  border-radius: 50%;
  opacity: 1;
  pointer-events: none;
  z-index: 100109;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 0 1rem 0 rgba(255, 255, 255, 0.1), inset 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  transition: opacity 300ms ease-out, transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.visible {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.actionMenu {
  min-width: 15rem;
  margin-left: 1rem;
  float: right; }

.actionMenuWrap {
  display: flex;
  flex-direction: column; }
  .actionMenuWrap div:last-child {
    margin-top: 1rem; }
  .actionMenuWrap .actionMenu {
    min-width: 15rem;
    margin: 0 0 1rem 0; }
    .actionMenuWrap .actionMenu:last-child {
      margin: 0; }
    @media screen and (min-width: 992px) {
    .leadParagraph {
      font-family: SFNS_thin, sans-serif;
      font-size: 1.42857rem;
      line-height: 1.85714rem; }
    .group, .description, label.text, .items {
      font-family: SFNS, sans-serif;
      font-size: 1.14286rem;
      line-height: 1.71429rem; }
    .shoppingListModal {
      top: 12rem;
      max-height: 75vh;
      padding: 0.5rem 3rem; } }
    @media print {
    .buttonSection {
      display: none; }
    .mobileButtonSection {
      display: none; }
      .leftSection .actionsWrapper {
        display: none; }
  footer {
    display: none !important; }
  main,
  p {
    margin-top: 0 !important;
    font-size: 0.8em !important;
    page-break-after: avoid; }
  .text,
  body,
  h1,
  h2 {
    font-size: 0.8rem !important; }
  h2 {
    margin: 0 !important; }
  .hideInPrint {
    display: none !important; }
  .printSection {
    display: inline-block;
    page-break-after: avoid; }
  main section {
    width: 100%;
    margin: 0 !important;
    padding: 5px !important;
    page-break-after: avoid !important; }
  .leftSection {
    width: 50% !important;
    float: left !important;
    margin-top: 0 !important;
    margin-right: 0 !important; }
    .leftSection img {
      display: block !important;
      margin: 0 auto !important;
      position: relative;
      max-height: 200px; }
  .rightSection {
    width: 50% !important;
    float: left !important;
    margin: 0 !important;
    order: 2 !important;
    padding-top: 0 !important; }
  .description {
    float: left !important;
    width: 50% !important;
    margin: 0 !important;
    order: 2 !important;
    padding-top: 0 !important; }
  .leadParagraph {
    order: 2 !important;
    width: 50% !important;
    float: left !important;
    margin: 0 !important;
    padding-top: 0 !important; }
    .leadParagraph .text {
      line-height: 1.2rem !important; }
    .leadParagraph .group {
      font-size: 0.8rem !important; }
  .text {
    line-height: 1.2rem !important; }
  td {
    padding: 0.2rem 0.2rem !important; }
  svg {
    width: 180px !important;
    height: 180px !important; }
  #snapifyHiddenContentA {
    display: none !important; }
  #imboxWTitle {
    display: none !important; } }
    @media screen and (min-width: 768px) {
    .group {
      padding-bottom: 1rem; }
      .shoppingListModal h2 {
        font-size: 2rem; }
    .shoppingList {
      height: 44vh; }
    .description {
      padding-top: 2rem; }
    .classifications {
      text-align: left; }
      .items td {
        padding: 0.125rem 0.125rem; }
    .actionMenuWrap {
      flex-direction: row; }
      .actionMenuWrap .actionMenu {
        margin: 0 1rem 0 0; }
        .actionMenuWrap .actionMenu:last-child {
          margin: 0; } }
    @media screen and (min-width: 480px) {
    .actionMenuWrap div:last-child {
      margin-top: 0; } }
