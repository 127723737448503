/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.title {
  font-family: SFNS_bold, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%;
  letter-spacing: -0.32px; }

.description {
  font-family: SFNS_thin, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 100%; }
  .description--medium {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%; }
  .description--title {
    font-family: SFNS_medium, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase; }

.subtext {
  font-family: SFNS, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 115%;
  letter-spacing: 0.24px; }

@-webkit-keyframes drop {
  0% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  100% {
    -webkit-transform: sclale(1);
            transform: sclale(1); } }

@keyframes drop {
  0% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  100% {
    -webkit-transform: sclale(1);
            transform: sclale(1); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-500deg);
            transform: rotate(-500deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(500deg);
            transform: rotate(500deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-500deg);
            transform: rotate(-500deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(500deg);
            transform: rotate(500deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.drop {
  -webkit-animation-name: drop;
          animation-name: drop;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.isCartQuantity .inCart .useNewDesign, .isCartQuantity .inCart .unitHolder {
  color: #343d46 !important; }

.wrap {
  height: 34px;
  border-radius: 2rem;
  display: flex;
  font-size: 0.85714rem;
  font-family: SFNS_bold, sans-serif;
  overflow: hidden; }
  .wrap.disabled {
    color: #f9f9f9; }
  .wrap.useNewDesign:hover::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 32px;
    width: 100%;
    background: rgba(0, 0, 0, 0.025);
    pointer-events: none;
    border-radius: 2rem; }
  .wrap.useNewDesign.disabled::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 32px;
    width: 100%;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 2rem; }
  .wrap.useNewDesign .wrapInner {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    justify-content: space-between;
    height: 32px;
    position: relative; }
    .wrap.useNewDesign .wrapInner .unitHolder {
      display: flex;
      color: #6c737a; }
    .wrap.useNewDesign .wrapInner .minusbutton {
      background-color: #f5f5f5;
      color: #6c737a; }
    .wrap.useNewDesign .wrapInner input {
      flex: 1;
      min-width: 20px;
      width: 100%; }
      .wrap.useNewDesign .wrapInner input:focus {
        background-color: transparent; }
      .wrap.useNewDesign .wrapInner input.useNewDesign {
        color: #6c737a; }
    .wrap.useNewDesign .wrapInner span {
      display: flex; }
    .wrap.useNewDesign .wrapInner .plusbutton {
      background-color: #f5f5f5;
      color: #f7594d; }
    .wrap.useNewDesign .wrapInner .unitOffset {
      display: flex; }
    .wrap.useNewDesign .wrapInner.inCart .minusbutton, .wrap.useNewDesign .wrapInner.inCart .plusbutton {
      background-color: #fff5ce; }

.wrapInner {
  height: 34px;
  border-radius: 2rem;
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 2px;
  justify-content: center;
  align-content: center;
  background-color: #ffffff;
  position: relative; }
  .wrapInner .unitHolder {
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    display: none;
    pointer-events: none;
    flex-direction: row;
    justify-content: center;
    gap: 4px; }
    .wrapInner .unitHolder .amount, .wrapInner .unitHolder .unit {
      flex: 0;
      text-transform: lowercase; }
    .wrapInner .unitHolder .amount {
      opacity: 0; }
  .wrapInner .unitOffset {
    flex: 0;
    display: none;
    padding-right: 4px;
    opacity: 0;
    pointer-events: none;
    text-transform: lowercase; }
  .isCartQuantity .wrapInner.inCart {
    background-color: #fff5ce;
    border-color: #fff5ce; }
  .disabled .wrapInner {
    color: #f9f9f9;
    background-color: #fafafa; }

.unitShortName {
  color: #999;
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem; }

.input.input {
  border: none;
  border-radius: 0;
  padding: 0 0;
  width: 2.5rem;
  margin: 0.125rem 0.5rem;
  text-align: center;
  background-color: transparent; }
  .isCartQuantity .input.input:focus {
    border-left: none;
    border-right: none;
    background-color: #e6dcb8; }
  .input.input.isPantryQuantity {
    width: 3rem; }
  .input.input[type='number']:before, .input.input::-ms-clear {
    display: none; }
  .input.input[disabled] {
    background-color: inherit; }

.minusbutton,
.plusbutton,
.okbutton {
  line-height: 0.85714rem;
  background-color: #f7594d;
  color: #fff; }

.minusbutton,
.plusbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px !important; }

.minusbutton[disabled],
.plusbutton[disabled] {
  color: #fff;
  background-color: #c2c5c8; }

.okbutton {
  font-size: 12px !important;
  position: relative; }

.okbuttonInner {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%; }

@media screen and (min-width: 768px) {
    .title {
      font-size: 18px !important;
      letter-spacing: -0.36px; }
    .description {
      font-size: 14px !important; }
      .description--medium {
        font-size: 14px !important; } }
