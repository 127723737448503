/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.articleNumber,
.supplier,
.trademark {
  line-height: inherit;
  font-size: 12px;
  color: #343d46; }

.articleNumber {
  word-break: break-all; }

.trademark img {
  height: 12px; }
