/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.preview {
  position: relative; }

.spinner {
  height: 1em;
  width: 1em;
  position: absolute;
  top: 0;
  left: -1em; }
