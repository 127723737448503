/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.searchSuggestion li {
  font-family: SFNS_thin, sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem; }

.searchSuggestionBackgroundBox {
  position: relative;
  background: #fff;
  background: linear-gradient(90deg, #cbdbd0 0, #cbdbd0 50%, #fff 50%, #fff 100%);
  overflow: hidden;
  margin: -2.5rem -.75rem 0rem -.75rem;
  padding-bottom: 40px; }
  .searchSuggestionBackgroundBox .h4 {
    color: #000;
    font-weight: normal;
    font-size: 1.429rem;
    line-height: 1.571rem;
    margin-bottom: .5rem;
    font-family: SFNS, sans-serif;
    margin-top: .5rem; }
  .searchSuggestionBackgroundBox::before {
    content: "";
    position: absolute;
    background: #cbdbd0;
    border-radius: 100%;
    width: 621px;
    height: 535px;
    right: 0;
    top: -310px; }
  .searchSuggestionBackgroundBox ul {
    padding-top: .35rem; }

.searchSuggestionBox {
  padding: 1.5rem .75rem;
  position: relative;
  background: linear-gradient(90deg, #cbdbd0 0, #cbdbd0 50%, transparent 50%, transparent 100%); }
  .searchSuggestionBox .header {
    font-size: 20px;
    line-height: 24px;
    font-family: SFNS, sans-serif;
    margin: 0;
    color: #343D46; }
    .searchSuggestionBox .header + div {
      margin-top: .85rem;
      font-size: 1.143rem;
      line-height: 20px;
      color: #000; }
    .searchSuggestionBox .header strong {
      font-family: SFNS_bold, sans-serif; }

.searchSuggestionInfo {
  border-top: 10px solid #fff;
  padding: 1rem 2rem;
  font-size: 1.143rem;
  position: relative;
  background: #E6ECE8;
  color: #000; }
  .searchSuggestionInfo h4 {
    font-weight: normal;
    font-family: SFNS, sans-serif;
    color: #0D0F11;
    margin: .5rem 0; }
    .searchSuggestionInfo h4::after {
      content: ":"; }
  .searchSuggestionInfo .button {
    display: inline-block; }
  .searchSuggestionInfo .helpText ul {
    margin: 0 0 1rem 0;
    padding: 0; }
  .searchSuggestionInfo .helpText li {
    font-family: SFNS, sans-serif; }
  .searchSuggestionInfo .helpText li:before {
    height: 0.15rem;
    width: 0.15rem;
    background-color: #000;
    vertical-align: middle; }
  .searchSuggestionInfo .helpTextChat a {
    text-transform: uppercase;
    font-weight: bold;
    display: none;
    cursor: pointer; }
    .searchSuggestionInfo .helpTextChat a:active, .searchSuggestionInfo .helpTextChat a:focus, .searchSuggestionInfo .helpTextChat a:hover {
      color: #000; }
    .searchSuggestionInfo .helpTextChat a svg {
      width: 1.25rem;
      top: -2px;
      position: relative; }
  .searchSuggestionInfo .helpTextChatIcon {
    margin-right: 1rem;
    display: none; }

.searchSuggestion ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.searchSuggestion li {
  display: inline-block;
  padding: 0.5rem 0;
  font-size: 1.143rem; }
  .searchSuggestion li:first-child {
    display: block; }

.searchSuggestion a {
  text-decoration: none; }

.searchSuggestion .button {
  z-index: 10002;
  display: inline-block;
  background: #fff;
  border: solid 1px #343D46;
  padding: .35rem 1.1rem;
  font-size: inherit;
  line-height: 1.1;
  color: #000;
  text-transform: capitalize;
  margin-right: .5rem;
  border-radius: 500px;
  white-space: pre;
  transition: background-color 200ms ease-in-out; }
  .searchSuggestion .button:hover, .searchSuggestion .button:focus, .searchSuggestion .button:active {
    color: #000;
    background: #DEDEDE !important; }

.searchFilters {
  position: relative;
  display: none; }
  .searchFilters ul {
    margin-left: -.5rem; }
  .searchFilters li {
    margin-bottom: .25rem; }

.searchFilter {
  z-index: 10002; }
  .searchFilter > div {
    font-size: 1rem; }
  .searchFilter .button {
    color: #000;
    align-items: center;
    display: flex;
    padding: 0.715rem 1.25rem !important;
    text-transform: none;
    font-size: 1rem; }
    .searchFilter .button:active:not(:hover), .searchFilter .button:focus:not(:hover) {
      color: #000;
      background: #fff !important; }
    .searchFilter .button svg {
      color: #0D0F11;
      width: .75rem;
      height: .75rem; }
    .searchFilter .button svg:first-of-type {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: .5rem; }
    .searchFilter .button.customFilter {
      padding: 0.55rem 1.25rem !important; }
    .searchFilter .button.disabled {
      opacity: .5;
      cursor: initial; }
      .searchFilter .button.disabled:hover {
        background: #fff !important; }
    .searchFilter .button.small {
      border-radius: 0 !important;
      padding: 0.45rem 0.5rem !important;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      display: flex !important;
      font-family: SFNS_thin, sans-serif; }
      .searchFilter .button.small svg {
        width: .6rem;
        height: .6rem;
        margin: 0; }
      .searchFilter .button.small:hover {
        background: #525a62 !important;
        border-color: #525a62 !important; }
  .searchFilter .button.selected {
    background-color: #343D46 !important;
    color: #fff; }
    .searchFilter .button.selected:active:not(:hover), .searchFilter .button.selected:focus:not(:hover) {
      background-color: #343D46 !important;
      color: #fff; }
    .searchFilter .button.selected:hover {
      background: #6C737A !important;
      border-color: #6C737A !important; }
    .searchFilter .button.selected svg {
      color: #fff; }
    .searchFilter .button.selected.small:hover {
      background: #525a62 !important; }
  .searchFilterCustom {
    padding: .25rem 1rem 0rem;
    background: #F5F5F5;
    display: block; }
    .searchFilterCustom .button {
      padding: 0.515rem 1.25rem !important; }
    .searchFilterCustom li:first-child {
      display: inline-block; }

button.clearFacets {
  text-align: left;
  font-size: 12px !important;
  line-height: 28px !important;
  align-items: center;
  padding: 0.35rem 0.5rem !important;
  height: 28px;
  margin-bottom: 0.5rem !important;
  border: 1px solid #E7E7E7 !important;
  display: flex !important;
  border-radius: 0 !important; }
  button.clearFacets svg {
    margin-left: 0.5rem;
    height: 1.2rem !important;
    width: 1rem;
    font-size: 1rem;
    vertical-align: baseline; }
  button.clearFacets:hover, button.clearFacets:focus, button.clearFacets:active {
    background: #E7E7E7 !important; }

.selectedFilters {
  padding: .25rem 1rem 0;
  background: #F5F5F5; }
  .selectedFilters:first-child {
    padding-top: .5rem; }
  .selectedFilters li {
    padding-top: 0; }
    .selectedFilters li:first-child {
      display: inline-block; }
    @media screen and (min-width: 992px) {
    .searchSuggestion li {
      font-family: SFNS, sans-serif;
      font-size: 1rem;
      line-height: 2.28571rem; }
    .searchSuggestionBackgroundBox {
      background: #F5F5F5;
      margin: 0 0 1rem 0;
      padding-bottom: 0px; }
      .searchSuggestionBackgroundBox .h4 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 1rem; }
      .searchSuggestionBackgroundBox::before {
        border-radius: 100%;
        background: #cbdbd0;
        background: linear-gradient(180deg, #cbdbd0 0, #cbdbd0 220px, #E6ECE8 220px, #E6ECE8 100%);
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -85px;
        right: unset; }
    .searchSuggestionBackgroundBox.hasSuggestion::before {
      background: linear-gradient(180deg, #cbdbd0 0, #cbdbd0 240px, #E6ECE8 240px, #E6ECE8 100%); }
    .searchSuggestionBackgroundBox.hasFilters::before {
      background: linear-gradient(180deg, #cbdbd0 0, #cbdbd0 100%); }
      .searchSuggestionBox .header {
        font-size: 40px;
        line-height: 40px; }
    .searchSuggestionBox {
      padding: 2rem 2rem 2rem 8.5rem;
      background: transparent; }
    .searchSuggestionInfo {
      background: transparent;
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.5rem 2rem 1rem;
      border-bottom: none; }
      .searchSuggestionInfo > div {
        flex: 1 1 50%;
        display: flex;
        padding: 0rem 2rem 0rem 6.5rem; }
      .searchSuggestionInfo h4 {
        margin: .6rem 1rem 0 auto;
        align-self: flex-start; }
      .searchSuggestionInfo .button {
        display: inline; }
      .searchSuggestionInfo .helpTextChat a {
        display: inline-block;
        padding: .45rem 0; }
      .searchSuggestionInfo .helpTextChatIcon {
        display: unset; }
    .searchSuggestion li {
      padding: 0.25rem 0.5rem;
      font-size: 1.143rem; }
      .searchSuggestion li:first-child {
        display: inline-block; }
    .searchSuggestion .button {
      color: #0D0F11;
      padding: .45rem 1.25rem;
      margin: 0; }
    .searchFilters {
      display: block; }
      .searchFilterCustom {
        padding: .25rem 0 .5rem;
        background: transparent !important; }
    .selectedFilters {
      padding: 0;
      background: transparent !important; } }
