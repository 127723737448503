/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  flex: 0 0 auto;
  height: inherit;
  display: flex; }
  .base.disabled {
    cursor: default; }

.loginWrapper {
  position: relative;
  z-index: 10009; }

.myMenigoMenuHolder {
  position: relative; }
  .myMenigoMenuHolder > button {
    background-color: #F8584C;
    letter-spacing: inherit;
    color: white; }
  .myMenigoMenuHolder .arrowDownSpan {
    position: absolute;
    top: -2px;
    right: 0;
    bottom: 0;
    line-height: calc(1.5rem + 1rem);
    pointer-events: none;
    transition: border-color 200ms ease-out; }
    .myMenigoMenuHolder .arrowDownSpan > svg {
      font-size: calc(1.5rem + 1rem);
      padding: 0.75rem; }

.myMenigo {
  text-transform: none;
  background: url("./../../../../styles/images/mymenigo.png") no-repeat 12px 7px #f8584c !important;
  padding-left: 35px; }
  .myMenigo:hover {
    color: #23272a; }
  .myMenigo:active, .myMenigo:focus {
    color: white; }

.parentDropdown {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1), 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.125rem;
  position: absolute;
  right: 0;
  z-index: 10009;
  width: 25rem; }
  .parentDropdown > div {
    position: relative;
    top: 0; }
  .parentDropdown .dropdownBox .iconMenuBox {
    padding: 1.5rem 2rem;
    margin: 0; }
    .parentDropdown .dropdownBox .iconMenuBox .iconMenuBoxItem {
      display: inline-block;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem; }
    .parentDropdown .dropdownBox .iconMenuBox .iconItem {
      padding: 0.125rem; }
      .parentDropdown .dropdownBox .iconMenuBox .iconItem img {
        width: 4.5rem;
        height: 4.5rem;
        padding: 0.5rem;
        border: 1px solid #e5e5e5; }

.tertiary select {
  letter-spacing: 0.1rem; }

.tertiary > span {
  border-left-color: #f66d62; }

.tertiary:not(.disabled) {
  background: #f7594d;
  color: #fff; }
  .tertiary:not(.disabled) select {
    background-color: #f7594d; }

.light > span {
  border-left-color: #dee1e4;
  background: #343d46;
  color: white; }

.disabled {
  background: #eff0f2; }
  .disabled > span {
    border-left-color: #dee1e4;
    background: none; }
    .disabled > span svg {
      color: #dee1e4; }

.light,
.disabled {
  border-bottom-color: #dee1e4;
  box-shadow: inset 0 0 0 1px #dee1e4; }
