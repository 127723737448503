.base {
  position: relative;
  margin: 0 auto;
  width: 100%; }
  .base:before {
    content: '';
    display: block;
    padding-top: 100%; }

.graphics {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center; }

.path {
  stroke: currentColor;
  stroke-dasharray: 1px, 200px;
  stroke-dashoffset: 0px;
  stroke-linecap: round; }

.animate {
  -webkit-animation: presentation 400ms ease-out;
          animation: presentation 400ms ease-out;
  will-change: transform; }
  .animate .graphics {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite; }
  .animate .path {
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite; }

:global(.ie) .animate .graphics {
  -webkit-animation: rotate 1s linear infinite;
          animation: rotate 1s linear infinite; }

:global(.ie) .path {
  stroke-dasharray: 30px, 200px;
  -webkit-animation: none;
          animation: none; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 89px, 200px;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89px, 200px;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 89px, 200px;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89px, 200px;
    stroke-dashoffset: -124px; } }

@-webkit-keyframes presentation {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes presentation {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }
