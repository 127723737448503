/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1rem; }
  .base > * {
    width: calc(100% - 1rem);
    padding: 1rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.14);
    border-radius: 0.2rem;
    margin: 1rem 0.5rem 0; }
  .base .ad {
    border: none;
    border-radius: 0;
    padding: 0 0;
    margin: 1rem 0.5rem 0 !important;
    width: 100%; }
  @media screen and (min-width: 992px) {
    .base {
      margin: -1rem -0.5rem 1rem; } }
  @media screen and (min-width: 768px) {
      .base > * {
        padding: 1rem; }
    .base.full > * {
      width: calc(50% - 1rem); }
    .base.full .large {
      width: calc(50% - 1rem); } }
  @media screen and (min-width: 320px) {
    .base > * {
      width: calc(50% - 1rem); }
    .base .large {
      width: calc(100% - 1rem); } }
  @media screen and (min-width: 480px) {
    .base > * {
      width: calc(50% - 1rem); }
    .base .large {
      width: calc(66.66667% - 1rem); } }
  @media screen and (min-width: 1200px) {
    .base.full > * {
      width: calc(50% - 1rem); }
    .base.full .large {
      width: calc(50% - 1rem); }
    .base > * {
      width: calc(33.33333% - 1rem); }
    .base .large {
      width: calc(50% - 1rem); } }
