/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  height: 1em;
  width: 1em;
  display: inline-block;
  fill: currentColor; }
  .base.sans {
    margin-right: 0; }

.flip {
  -webkit-transform: scale(-1, 1) rotate(180deg);
          transform: scale(-1, 1) rotate(180deg); }

.large {
  height: 2em;
  width: 3em; }

.extra-large {
  height: 4em;
  width: 6em; }

.large-v {
  height: 4em;
  width: 2em; }

.large-h {
  height: 2em;
  width: 4em; }

.rotate90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.facebook {
  color: #4665ac; }

.instagram {
  color: #00487a; }

.linkedin {
  color: #1381b9; }

.twitter {
  color: #1da8e2; }

.youtube {
  color: #e02d28; }
