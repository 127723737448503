/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.footer {
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem; }

.logoutButton {
  background: none !important;
  color: #F8584C !important;
  text-transform: uppercase; }
