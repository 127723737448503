/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  display: inline-block;
  transition: background 200ms ease-out;
  color: #23272a;
  width: 15rem; }
  .base.invalid {
    border-color: #b0406f;
    background: #fef6f5; }
  .base:not(.big) {
    border-radius: 2rem; }
    .base:not(.big) select {
      height: calc(1.5rem + 1rem); }
    .base:not(.big):hover {
      color: #f7594d; }
  .base > span {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    line-height: 0;
    pointer-events: none;
    transition: border-color 200ms ease-out; }
    .base > span svg {
      font-size: calc(1.5rem + 1rem);
      padding: 0.75rem; }
  .base select {
    width: 100%;
    padding-right: calc(1.5rem + 1rem + 1rem);
    padding-left: 1rem;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    border: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .base select:focus {
      cursor: default; }
    .base select:disabled {
      cursor: default; }
  .base select::-ms-expand {
    display: none; }

.secondary select {
  letter-spacing: 0.1rem; }

.secondary > span {
  border-left-color: #f66d62; }

.secondary:not(.disabled) {
  background: #f7594d;
  color: #fff; }
  .secondary:not(.disabled) select {
    background-color: #f7594d; }

.tertiary {
  border: none; }
  .tertiary select {
    text-transform: uppercase;
    padding-left: 0;
    padding-right: calc(1.5rem + 1rem + 0.5rem); }

.light > span {
  border-left-color: #dee1e4;
  background: #343d46;
  color: white; }

.big {
  border-radius: 1.42857rem; }
  .big select {
    height: 44px; }

.disabled > span {
  background: none; }

.disabled svg {
  color: #23272a; }

.light {
  border-bottom-color: #dee1e4;
  box-shadow: inset 0 0 0 1px #dee1e4; }

.fadeDropDown {
  overflow: visible;
  color: #000;
  width: unset; }
  .fadeDropDown:hover {
    color: #000;
    text-decoration: underline; }
  .fadeDropDown > span > svg {
    font-size: inherit;
    padding: 0; }
  .fadeDropDown .sortList {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    z-index: 1000;
    background: #fff;
    right: -.75rem;
    top: -6px;
    display: none;
    border: 1px solid #dee1e4;
    border-top: none;
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms linear;
    transition-delay: 100ms; }
    .fadeDropDown .sortList > li {
      display: block; }
    .fadeDropDown .sortList li {
      border-top: 1px solid #dee1e4;
      white-space: pre; }
  .fadeDropDown input:checked + .sortList {
    display: block;
    opacity: 1;
    overflow: visible; }
  .fadeDropDown .selectedValue {
    padding-right: calc(1.5rem + 1rem);
    font-family: SFNS, sans-serif;
    font-size: 1.286rem;
    cursor: pointer; }
  .fadeDropDown .sortOptionButton {
    border-radius: 0;
    width: 100%;
    position: relative;
    padding: 0;
    text-align: left; }
    .fadeDropDown .sortOptionButton:hover {
      background: #e7e7e7 !important; }
    .fadeDropDown .sortOptionButton label {
      display: block;
      padding: 0.5rem 1rem;
      padding-left: 2.5rem;
      cursor: pointer; }
  .fadeDropDown .selectedSortOptionCheck {
    position: absolute;
    left: 1rem;
    color: #343d46; }
  .fadeDropDown button:disabled {
    color: #6c737a;
    background: transparent; }
    .fadeDropDown button:disabled span {
      display: block; }
      .fadeDropDown button:disabled span:last-child {
        color: #6c737a;
        font-size: 0.75rem; }

.requiredField {
  color: #b0406f;
  display: block;
  line-height: 1.42857rem;
  padding: 2px; }
  .requiredField svg {
    width: 1.21429rem;
    height: 1.21429rem;
    margin-right: 0.35714rem;
    vertical-align: text-top; }

.label {
  font-family: SFNS_medium, sans-serif;
  font-size: 12px;
  color: #ffffff;
  padding: 0 4px;
  border-radius: 4px;
  height: 16px;
  background: #24733b;
  align-self: start;
  display: inline-block;
  margin-left: 8px; }

@media screen and (min-width: 992px) {
      .fadeDropDown .sortList {
        margin-right: -2px;
        top: -1px; } }
