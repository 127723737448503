/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.quickSearch {
  display: flex;
  color: #23272a;
  border-left: 1px solid #e5e5e5; }

.smallspinner {
  height: 2rem;
  width: 2rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 1rem; }

.inputContainer {
  background-color: white;
  position: relative;
  flex: 1;
  height: 70px; }
  .inputContainer input {
    width: 100%;
    height: 70px;
    line-height: 70px;
    border: none !important;
    background-color: transparent;
    font-size: 1rem;
    position: relative;
    padding: 0.5rem; }
    .inputContainer input::-ms-clear, .inputContainer input::-ms-reveal {
      display: none; }
    html:global(.ie) .inputContainer input {
      line-height: inherit; }

.clear {
  composes: bare from "../../../styles/objects/buttons.scss";
  font-size: 1rem;
  height: 70px;
  line-height: 70px;
  width: 70px;
  color: black;
  background: white;
  position: relative; }
  .clear svg {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    left: 0px;
    margin: auto; }

.querySuggestion {
  position: absolute;
  left: 1rem;
  line-height: 70px;
  font-size: 1rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  color: gray; }

.querySuggestionPrefix {
  visibility: hidden; }

.button {
  composes: iconbuttonSecondary from "../../SiteLayout/Header/base.scss";
  composes: base 1to1 center from "../../../styles/objects/ratio.scss"; }

.autocomplete {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  z-index: -1; }
