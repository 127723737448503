/*! Source: https://github.com/inuitcss/base.images/blob/master/_base.images.scss */
img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle; }

.gm-style img,
img[width],
img[height] {
  max-width: none; }
