/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  max-width: 640px; }
  .wrapper label {
    width: 100%;
    display: inline-flex;
    padding: 1rem 0 0; }
  .wrapper .inputWrapperLabel {
    padding-top: 0; }
  .wrapper .accountInput {
    padding-top: 0;
    width: auto; }
    .wrapper .accountInput input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      width: 12rem;
      display: inline-block; }
  .wrapper .hidden {
    display: none !important; }

.row {
  display: flex;
  flex-direction: column; }

.column {
  flex: 1 0 50%;
  margin-bottom: 1rem; }

.small {
  font-size: 0.857rem;
  line-height: 1.3;
  display: block;
  padding: .5rem .125rem; }

.accountNumber {
  font-weight: bold;
  display: inline-block;
  padding: 0.75rem 0.75rem;
  margin: 0 0;
  border: 1px solid #e7e7e7;
  border-radius: 2rem 0 0 2rem;
  background-color: #e7e7e7; }

.accountNumberEdit {
  font-weight: bold;
  display: block;
  padding: 0.75rem 1rem;
  margin: 0 0;
  border: 1px solid #e7e7e7;
  border-radius: 2rem;
  background-color: #e7e7e7; }

@media screen and (min-width: 768px) {
    .row {
      flex-direction: row; }
    .column {
      margin-bottom: 0; }
      .column:first-of-type {
        padding-right: 2rem; } }
