/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  padding-top: 2rem; }

.toolButtons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2rem; }
  .toolButtons .toolButton {
    margin-left: 1rem; }
