/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  color: rgba(247, 89, 77, 0.8);
  display: inline-flex;
  align-items: center; }
  .base .text {
    color: rgba(247, 89, 77, 0.8);
    text-decoration: underline; }
  .base .icon {
    color: rgba(247, 89, 77, 0.8);
    margin-right: 5px; }
