/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  display: flex;
  cursor: pointer;
  padding-right: 5px; }

.input {
  composes: visuallyhidden from "../../../styles/trumps/visibility.scss"; }

.base.semiChecked .input[type="checkbox"] + .label .checkmark {
  border-color: #343d46; }

.base.semiChecked .input[type="checkbox"] + .label:after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background: #343d46;
  display: block;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem; }

.base.light.semiChecked .input[type="checkbox"] + .label:after {
  top: 50%;
  margin-top: -0.375rem; }

.base.light.semiChecked .input[type="checkbox"] + .label .checkmark {
  border-color: #343d46; }

.base.light .input[type="checkbox"] + .label {
  display: flex;
  align-items: center; }
  .base.light .input[type="checkbox"] + .label .checkmark {
    border-color: transparent; }

.base.light .input[type="checkbox"]:checked + .label .checkmark {
  padding: 0;
  background: none;
  color: #343d46; }

.label {
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  position: relative;
  display: inline-flex;
  align-content: flex-end; }

.toggleTextContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.input[type="checkbox"] + .label .checkmark, .base.disabled .input[type="checkbox"] + .label .checkmark, .base.disabled .input[type="radio"] + .label .checkmark,
.input[type="radio"] + .label .checkmark {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 1px 7px 1px 0;
  border: 1px solid black;
  background: white;
  color: transparent; }

.base.disabled .input[type="checkbox"] + .label .checkmark, .base.disabled .input[type="radio"] + .label .checkmark {
  border-color: #e5e5e5;
  background: #fafafa; }


.input[type="radio"] + .label .checkmark {
  border-radius: 50%; }

.input[type="radio"]:checked + .label .checkmark, .base.disabled .input[type="radio"]:checked + .label .checkmark {
  padding: 0.25rem; }

.base.disabled .input[type="radio"]:checked + .label .checkmark {
  color: #999; }

.input[type="checkbox"]:checked + .label .checkmark, .input[type="radio"]:checked + .label .checkmark {
  color: white;
  background: #343d46;
  border-color: #343d46;
  box-shadow: none; }

.input[type="checkbox"]:checked + .label .checkmark, .base.disabled .input[type="checkbox"]:checked + .label .checkmark {
  padding: 0.125rem; }

.base.disabled .input[type="checkbox"]:checked + .label .checkmark {
  color: #999; }

.input[type="checkbox"]:focus + .label:before, .input[type="radio"]:focus + .label:before,
.input[type="checkbox"]:focus + .label .checkmark,
.input[type="radio"]:focus + .label .checkmark {
  border-color: black;
  border-style: dotted; }

.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px; }
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0; }
    .toggle input[type="checkbox"]:focus .label:before {
      border: none; }
  .toggle svg {
    display: none; }
  .toggle .label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #24733b;
    transition: .4s;
    border-radius: 35px; }
  .toggle .label:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    transition: .4s;
    border-radius: 50%;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    border: none !important; }
  .toggle input:checked + .label {
    background-color: #f5f5f5; }
  .toggle input:focus + .label {
    box-shadow: 0 0 1px #f5f5f5; }
  .toggle input:checked + .label:before {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
