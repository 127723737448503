/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.TooltipWrapper {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center; }

.center {
  text-align: center;
  margin: auto;
  position: relative;
  width: 0.5rem;
  z-index: 10012; }

.Tooltip {
  display: inline-block;
  opacity: 1;
  -webkit-animation: fade 300ms linear;
          animation: fade 300ms linear;
  position: absolute;
  border-radius: .25rem;
  top: -100%;
  left: 0;
  min-width: 180px;
  max-width: 245px;
  font-size: .75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform: translateX(-80%);
          transform: translateX(-80%);
  padding: 1rem;
  color: #000;
  background: #FFFFFF;
  font-family: sans-serif;
  line-height: 1.5;
  z-index: 10012;
  border: 1px solid #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
  white-space: pre-wrap; }
  .Tooltip::before {
    content: " ";
    left: 80%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 14px;
    margin-left: -14px;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(99, 99, 99, 0.4));
            filter: drop-shadow(0px 0px 4px rgba(99, 99, 99, 0.4)); }
  .Tooltip strong {
    display: block;
    text-align: left; }
  .Tooltip > div {
    text-align: left; }
    .Tooltip > div::before {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border: 10px solid #FFFFFF;
      border-radius: .25rem; }
  .Tooltip.small {
    padding: .65rem; }
    .Tooltip.small::after {
      display: none !important; }
  .Tooltip.carbonCloud {
    color: #24733b;
    font-family: SFNS_medium, sans-serif;
    font-size: 12px;
    line-height: 115%; }

.top {
  top: auto;
  bottom: 100%;
  margin-bottom: 1rem; }
  .top::before {
    top: 100%;
    border-top-color: #FFFFFF; }
  .top.small {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%); }
    .top.small::before {
      left: 65%; }

.right {
  left: 100%;
  margin-left: 1rem;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }
  .right::before {
    left: -14px;
    top: 55%;
    -webkit-transform: translateX(0) translateY(-50%);
            transform: translateX(0) translateY(-50%);
    border-right-color: #FFFFFF; }

.bottom {
  top: 100%;
  margin-top: 1.5rem; }
  .bottom::before {
    bottom: 100%;
    border-bottom-color: #FFFFFF; }
  .bottom.small {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%); }
    .bottom.small::before {
      left: 65%; }

.left {
  left: auto;
  right: 100%;
  margin-right: 1rem;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }
  .left::before {
    left: auto;
    right: -28px;
    top: 55%;
    -webkit-transform: translateX(0) translateY(-50%);
            transform: translateX(0) translateY(-50%);
    border-left-color: #FFFFFF; }

div[data-index] .Tooltip:not(.small),
div[data-index] .Tooltip:not(.small).bottom,
div[data-index] .Tooltip:not(.small).top,
div[data-index] .Tooltip:not(.small).left,
div[data-index] .Tooltip:not(.small).right {
  top: 100%;
  margin-top: 1.5rem;
  -webkit-transform: translateX(-20%) translateY(0);
          transform: translateX(-20%) translateY(0); }
  div[data-index] .Tooltip:not(.small)::before,
  div[data-index] .Tooltip:not(.small).bottom::before,
  div[data-index] .Tooltip:not(.small).top::before,
  div[data-index] .Tooltip:not(.small).left::before,
  div[data-index] .Tooltip:not(.small).right::before {
    display: none; }
  div[data-index] .Tooltip:not(.small)::after,
  div[data-index] .Tooltip:not(.small).bottom::after,
  div[data-index] .Tooltip:not(.small).top::after,
  div[data-index] .Tooltip:not(.small).left::after,
  div[data-index] .Tooltip:not(.small).right::after {
    content: " ";
    left: 20%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 14px;
    margin-left: -14px;
    bottom: 100%;
    border-bottom-color: #FFFFFF; }
  @media screen and (min-width: 768px) {
    .Tooltip {
      min-width: 245px;
      max-width: 310px;
      font-size: .85rem; }
      .Tooltip.small {
        min-width: 175px; } }
