/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.campaignLabelWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px; }

.campaignLabelWrapperOnProductPage {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start; }
  .campaignLabelWrapperOnProductPage .campaignLabel {
    font-weight: 700;
    font-size: 20px;
    padding: 0px; }
    .campaignLabelWrapperOnProductPage .campaignLabel svg {
      width: 24px;
      height: 24px; }

.noToolTipWrapper {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center; }

.campaignLabel {
  font-family: SFNS_medium, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
  padding: 0 4px;
  color: #ffffff;
  white-space: pre;
  text-align: center;
  font-style: normal;
  height: 16px; }
  .campaignLabel.primaryTag {
    margin-bottom: 0;
    margin-right: 0;
    display: inline-flex;
    font-size: 14px;
    font-style: normal;
    font-family: SFNS_bold, sans-serif;
    font-weight: 700;
    letter-spacing: -0.32px;
    padding: 0; }
  .campaignLabel svg {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    vertical-align: middle;
    display: flex;
    align-self: center; }

.purpletext {
  color: #704587;
  background-color: #E7E7E7;
  border-radius: 4px; }

.hotpink {
  background-color: #B0406F;
  border-radius: 4px; }

.yellow {
  background-color: #f2b52e;
  color: #0d0f11; }

.red {
  color: #f7594d; }

.darkblue {
  background-color: #B0406F;
  border-radius: 4px; }

.green {
  background-color: #e6ece8;
  border-radius: 4px;
  color: #24733B; }

.gray {
  background-color: #343D46;
  border-radius: 4px;
  color: #ffffff; }

.black {
  background-color: #E7E7E7;
  border-radius: 4px;
  color: #000; }

@media screen and (min-width: 992px) {
      .campaignLabel.primaryTag {
        font-size: 16px; }
      .campaignLabel.primaryTag svg {
        width: 14px;
        height: 14px; } }
