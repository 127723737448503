/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.urlInput {
  color: black !important;
  width: 100%; }

.row {
  display: flex;
  width: 100%;
  padding: 5px 0; }
  .row div:first-child {
    width: 20%; }
  .row div:nth-child(2) {
    width: 80%; }
