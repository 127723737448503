/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.delivery__Body {
  display: flex;
  flex-direction: column; }
  .delivery__Body > div {
    width: 100%; }

.delivery__Block {
  width: 100%;
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.delivery__List {
  background-color: #FFFFFF;
  margin-bottom: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem; }

.delivery__Row {
  border-bottom: 1px solid #e5e5e5;
  margin: 1rem 0; }
  .delivery__Row__inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 1rem 0; }
    .delivery__Row__inner__column {
      width: 33%;
      margin-bottom: 0 !important; }
      .delivery__Row__inner__column__icon {
        width: 50px;
        float: left;
        margin-right: .5rem;
        display: none; }
        .delivery__Row__inner__column__icon svg {
          width: 50px;
          height: 50px;
          color: #343D46;
          fill: #343D46;
          stroke-width: 0; }
      .delivery__Row__inner__column__header {
        display: block;
        color: #999; }
      .delivery__Row__inner__column__content {
        display: block;
        color: #23272A;
        font-weight: 700;
        font-size: 1.15rem; }
      .delivery__Row__inner__column__link {
        display: block;
        font-size: .875rem;
        font-weight: 700;
        color: #f8584c;
        letter-spacing: 0.11em; }
        .delivery__Row__inner__column__link svg {
          margin-left: .25rem;
          vertical-align: middle;
          height: 12px;
          width: 12px; }

.delivery__ReadMoreRow {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.3rem;
  padding-left: .25rem; }
  .delivery__ReadMoreRow span {
    display: block;
    width: 65%; }
  .delivery__ReadMoreRow a {
    color: #f8584c;
    display: block;
    float: right;
    white-space: pre; }
    .delivery__ReadMoreRow a svg {
      margin-left: .2rem;
      margin-top: -.125rem;
      vertical-align: middle;
      height: 14px;
      width: 12px; }

.delivery__Row + .delivery__ReadMoreRow {
  margin: 0 !important; }
  .delivery__Row + .delivery__ReadMoreRow p {
    margin: 0 0 -0.25rem 0 !important; }

.loading .pictogram {
  opacity: .95; }

.pictogram {
  background-color: #5C5D5D;
  background-image: url("/styles/images/dark_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: #FFFFFF;
  margin-bottom: 0 !important;
  opacity: 1;
  transition: opacity 150ms ease-in-out, height 150ms linear, width 150ms linear; }
  .pictogram > div {
    width: auto;
    margin: auto;
    padding: 1rem;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    transition: all 100ms linear; }
    .pictogram > div p {
      margin: auto 0; }
    .pictogram > div svg {
      height: auto;
      width: 100%;
      max-width: 45px;
      order: 2;
      margin-left: 1rem; }
      .pictogram > div svg path {
        fill: #FFFFFF; }

.one_Delivery svg {
  height: auto;
  width: 100%;
  max-width: 70px;
  order: 2;
  margin-left: 1rem; }
  .one_Delivery svg path {
    fill: #FFFFFF; }

.no_Deliveries {
  background-color: #FFFFFF;
  background-image: url("/styles/images/light_background.png");
  color: #5C5D5D; }
  .no_Deliveries > div {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row; }
    .no_Deliveries > div svg {
      order: 2;
      max-width: 45px;
      margin-right: 1rem; }
    .no_Deliveries > div > svg path {
      fill: #5C5D5D; }
    .no_Deliveries > div p {
      order: 1; }

.columns {
  display: block;
  margin: 0 -1rem; }

.column {
  display: flex;
  padding: 0 1rem;
  flex: 1 0 50%;
  flex-flow: column;
  margin: 0 !important; }

.box__Outer {
  margin-bottom: 1rem;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  display: flex; }

.box__Inner {
  background-color: #FFFFFF;
  width: 100%;
  padding: 1rem 1rem 0px 1rem;
  height: 100%; }

.box__Title {
  font-size: 1.43rem;
  line-height: 1.7rem;
  font-weight: normal;
  margin-bottom: .5rem; }

.box__Footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 1.25rem 0; }
  .box__Footer__Link {
    color: #f8584c;
    text-decoration: none;
    font-weight: 700;
    display: inherit;
    align-items: center;
    font-size: 0.875rem; }
    .box__Footer__Link span {
      text-transform: uppercase; }
    .box__Footer__Link svg {
      margin-left: .5rem;
      margin-top: -.125rem;
      vertical-align: middle;
      height: 16px;
      width: 16px; }
    @media screen and (min-width: 992px) {
    .delivery__Body {
      flex-direction: row; }
      .delivery__Body > div {
        width: 33%; }
      .delivery__Body > div + div {
        width: 67%; }
    .delivery__Block {
      padding: 1rem; }
    .delivery__List {
      padding: 0;
      padding-left: 2rem; }
      .pictogram > div {
        margin: auto;
        text-align: center;
        display: block; }
    .pictogram > div {
      margin: auto;
      text-align: center;
      display: block; }
      .pictogram > div svg {
        order: 1;
        max-width: 133px;
        margin-left: 0; }
  .one_Delivery > div svg {
    order: 1;
    max-width: 70px;
    margin-left: 0; }
      .no_Deliveries > div {
        display: flex;
        flex-direction: row; }
        .no_Deliveries > div svg {
          order: 1;
          max-width: 70px; }
        .no_Deliveries > div p {
          order: 2; } }
    @media screen and (min-width: 768px) {
          .delivery__Row__inner__column__icon {
            display: block; }
    .delivery__ReadMoreRow {
      margin: 2rem 0 0 0; }
      .delivery__ReadMoreRow span {
        display: inline;
        width: unset; }
      .delivery__ReadMoreRow a {
        float: none;
        display: inline;
        margin-left: 0.5rem; }
    .columns {
      display: flex;
      justify-content: space-between; }
    .box__Title {
      padding: 0px 1rem; } }
