/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.hero .heroTitle {
  font-family: SFNS_bold, sans-serif;
  font-size: 2.5rem;
  line-height: 2.5rem; }

.hero {
  overflow: hidden;
  position: relative;
  padding: 0 0 0.385rem 0; }
  .hero > a {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .hero .bottomSection {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    color: white; }
    .hero .bottomSection > button, .hero .bottomSection a {
      z-index: 2;
      position: relative; }
  .hero .heroImage {
    width: 100%;
    height: 20rem;
    background-size: cover;
    background-position-y: 35%;
    background-position-x: 50%; }
  .hero .heroTitle {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); }
  .hero .linkHidden {
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }

.negativeTopMargin {
  margin-top: -3.5rem; }

.secondaryText {
  color: #fff; }

.primaryText {
  color: #23272a; }

.base {
  position: relative; }
  .base .next, .base .prev {
    transition: 0.5s ease-in-out opacity; }
  .base:hover .next, .base:hover .prev {
    opacity: 1; }
    .base:hover .next:hover, .base:hover .prev:hover {
      opacity: 1 !important; }

.prev, .next {
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 2.85714rem;
  color: #f7594d; }

.prev {
  left: 2rem; }
  .prev svg {
    margin-right: 0.5rem; }

.next {
  right: 2rem; }
  .next svg {
    margin-right: 0; }

.swiperPagination {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .swiperPagination li {
    display: inline-block;
    margin: 0.25rem;
    width: 1.5rem;
    cursor: pointer;
    background-color: #f7594d;
    border-radius: 50%;
    opacity: 0.5;
    width: 13px;
    height: 13px; }
    .swiperPagination li:after {
      color: #f7594d;
      content: "";
      opacity: 0.5; }
    .swiperPagination li.active {
      opacity: 1 !important; }
      .swiperPagination li.active:after {
        opacity: 1; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 768px) {
    .hero .heroTitle {
      font-family: SFNS_bold, sans-serif;
      font-size: 3rem;
      line-height: 3rem; }
    .hero {
      padding: 0 0 0.73529vw 0; } }

@media screen and (min-width: 992px) {
    .hero .heroTitle {
      font-family: SFNS_bold, sans-serif;
      font-size: 3.71429rem;
      line-height: 3.85714rem; } }

@media screen and (min-width: 1360px) {
    .hero {
      padding: 0 0 0.714rem 0 !important; } }
