/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.pageHeader {
  position: relative;
  background: #fff;
  background: linear-gradient(90deg, #f5e8e7, #f5e8e7 50%, #fff 50%, #fff 100%);
  overflow: hidden;
  margin: -2.5rem -.75rem 0;
  padding-bottom: 20px; }
  .pageHeader h1 {
    font-family: SFNS, sans-serif; }
  .pageHeader::before {
    content: "";
    position: absolute;
    background: #f5e8e7;
    border-radius: 100%;
    width: 621px;
    height: 535px;
    right: 55px;
    top: -310px; }
  .pageHeader .inner {
    position: relative;
    padding: 1rem .75rem; }
  .pageHeaderName {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 25px;
    line-height: 35px; }
    .pageHeaderName + div {
      margin-bottom: 1rem; }
    @media screen and (min-width: 992px) {
    .pageHeader {
      margin: 0 0 1rem 0;
      padding-bottom: 0;
      background: #f5f5f5; }
      .pageHeader::before {
        border-radius: 100%;
        background: #f5e8e7;
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -175px;
        right: unset; }
      .pageHeader .inner {
        padding: 2rem 2rem 1.25rem 8.5rem; } }
    @media screen and (min-width: 768px) {
      .pageHeaderName {
        font-size: 35px;
        line-height: 45px;
        margin-top: 0px; } }
    @media screen and (min-width: 1200px) {
      .pageHeaderName {
        font-size: 40px;
        line-height: 50px; } }
