/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }

.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }

.paragraph {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.base {
  margin-top: 40px;
  padding: 24px; }
  .base h3 {
    margin-top: 0; }
  .base p {
    white-space: pre-wrap;
    margin-bottom: 0; }

.iconRow {
  margin: 0 0 8px 0; }
  .iconRow svg {
    width: 32px;
    height: 32px;
    stroke-width: .5; }

.aspectRatio img {
  position: relative;
  aspect-ratio: 3/4;
  width: 100%;
  margin: 0 0 8px 0; }

.infoType {
  background-color: #f5d7d5; }

.tipsType {
  background-color: #cbdbd0; }

.reportType {
  background-color: #f9f9f9; }

.descriptionColor {
  color: #6c737a; }

.cta {
  margin-top: 18px;
  display: flex;
  flex-direction: row; }
  .cta .linkCol a {
    color: #f7594d; }
  .cta .iconCol {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .cta .iconCol svg {
      margin-top: 3px;
      width: 28px;
      height: 28px;
      color: #f7594d;
      stroke-width: .5; }
    @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration {
      font-size: 20px;
      letter-spacing: 0; }
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; } }
    @media screen and (min-width: 1200px) {
    .base {
      margin-top: 0; } }
