/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  position: relative;
  overflow: visible;
  white-space: nowrap;
  display: inline-block; }
  .wrapper span {
    font-size: 1rem; }
    .wrapper span:hover {
      cursor: pointer; }
  .wrapper.extraLarge {
    height: 2.35rem;
    width: 2.35rem; }
    .wrapper.extraLarge .icon {
      font-size: 2.35rem; }
  .wrapper.both {
    width: auto;
    margin-left: -1.75rem; }
    .wrapper.both span {
      line-height: 1.75rem;
      padding-left: 0.5rem; }

.icon {
  font-size: 1.75rem;
  display: flex; }
  .icon:hover {
    cursor: pointer; }

.tooltip {
  font-size: 1rem;
  padding: 0.5rem !important;
  text-align: center !important;
  color: black;
  min-width: 10rem;
  padding: 0.5rem 2.2rem 0.5rem 1rem !important; }
  .tooltip strong {
    display: inline-block;
    white-space: nowrap;
    padding: 1rem 1rem 0 1rem; }
  .tooltip > ul {
    text-align: left;
    padding-top: 0 !important;
    margin: 0.25rem 1rem 1rem 0.5rem !important; }
    .tooltip > ul li {
      list-style: disc inside;
      display: list-item;
      margin: 0;
      padding-top: 0 !important;
      font-size: 1rem !important;
      line-height: 1.5 !important; }
      .tooltip > ul li:hover {
        background: transparent; }
      .tooltip > ul li a {
        text-decoration: none !important;
        display: inline !important; }
        .tooltip > ul li a:hover {
          color: #f7594d !important;
          cursor: pointer !important; }
