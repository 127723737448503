/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  margin: 0;
  margin-bottom: 0.5rem; }
  .base tr > td:last-child {
    margin: 0; }
  .base tr > td:first-child {
    font-weight: bold;
    padding-right: 1rem; }

.paymentSummary {
  display: flex;
  justify-content: center; }

.orderDetails {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  padding-left: 1rem; }

.details {
  display: block;
  padding: 0.5rem 1rem;
  background-color: #dee1e4; }

.divleft {
  text-align: left; }

.divright {
  white-space: nowrap; }
  .divright > * {
    display: inline-block; }
  @media screen and (min-width: 480px) {
    .paymentSummary {
      justify-content: flex-end; } }
  @media screen and (min-width: 768px) {
    .orderDetails {
      flex-direction: row; }
      .orderDetails div {
        margin-right: 3rem; }
    .details {
      margin: 0 1rem;
      display: flex;
      justify-content: space-between; }
      .details > * {
        flex: 1; }
    .divright {
      text-align: right; } }
