.dropdown {
  width: 70%; }

.deleteBtn {
  margin-top: 1rem; }

.addCustomer {
  margin-top: 2rem; }

.inline {
  display: inline-block; }
