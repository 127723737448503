/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.recommendationsPanel {
  border: 2px dashed #f7594d !important;
  width: 100%; }
  .recommendationsPanel .contentRow {
    padding: 2px;
    position: relative;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px; }
    .recommendationsPanel .contentRow .imageColumn {
      width: 120px;
      max-height: 120px;
      display: flex;
      border-bottom-right-radius: 65px;
      -o-object-fit: cover;
         object-fit: cover; }
    .recommendationsPanel .contentRow .infoColumn {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
      padding: 16px 0; }
      .recommendationsPanel .contentRow .infoColumn .textColumn {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1; }
        .recommendationsPanel .contentRow .infoColumn .textColumn .textTitle {
          color: #0d0f11;
          font-family: SFNS_bold, sans-serif;
          font-size: 1.14286rem;
          line-height: 1.42857rem; }
        .recommendationsPanel .contentRow .infoColumn .textColumn .textBody {
          color: #6c737a;
          max-width: 360px;
          margin: 0; }
      .recommendationsPanel .contentRow .infoColumn .seeMore {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        align-items: start; }
        .recommendationsPanel .contentRow .infoColumn .seeMore a {
          color: #0d0f11;
          background-color: white;
          border-color: #343d46;
          border-width: 0.063rem;
          border-style: solid;
          padding: 0.688rem 1.25rem;
          border-radius: 100px;
          text-align: center;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.125rem;
          letter-spacing: 0.05em;
          text-wrap: nowrap;
          text-decoration: none; }
          .recommendationsPanel .contentRow .infoColumn .seeMore a:hover {
            background-color: #e7e7e7;
            color: #343d46; }
  .recommendationsPanel .products {
    padding: 1px 10px; }
  @media screen and (min-width: 992px) {
        .recommendationsPanel .contentRow .infoColumn {
          flex-direction: row; }
          .recommendationsPanel .contentRow .infoColumn .seeMore {
            padding: 0 16px 0 0; }
      .recommendationsPanel .products {
        padding: 1px; } }
