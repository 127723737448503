/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.wrapper {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .wrapper input {
    width: 100%; }

.list {
  z-index: 999;
  top: 40px;
  position: absolute;
  width: 100%;
  border-left: 1px solid #EFEFEF;
  box-shadow: 0px 5px 10px 0px #d8d8d8; }
  .list tbody {
    width: 100%; }
  .list tr {
    background-color: #eff0f2; }
  .list tbody tr:nth-child(odd) {
    background-color: #FFF; }
  .list td:first-child, .list th:first-child {
    padding-left: 1rem; }
  .list td:last-child, .list th:last-child {
    padding-right: 1rem; }
  .list th {
    padding: 1rem 1rem 0.5rem 0;
    white-space: nowrap;
    color: black;
    background: #fff; }
  .list td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle; }

.link {
  cursor: pointer; }

.highlighted {
  box-shadow: inset 0px 0px 5px 0px green; }
