/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative; }
  .base > main {
    flex-grow: 1;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }

.baseApp {
  min-height: 100%; }

@media print {
    .base {
      display: block; } }

@media screen and (min-width: 992px) {
      .base > main {
        margin-top: 2rem; } }
