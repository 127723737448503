.areaIcon {
  font-family: 'SFNS', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0.5rem; }
  .areaIcon svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    vertical-align: text-top; }
