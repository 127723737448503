/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.bodyMedium {
  font-size: 14px; }

.bodySmall {
  font-size: 12px; }

.bodyMedium, .bodySmall {
  font-family: SFNS_medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0; }

.main {
  width: 100%;
  max-width: 97.14286rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 0; }
  .main.disableTopPadding {
    padding-top: 0 !important; }
  .main h3 {
    margin: 0;
    padding: 0 0 0 1rem; }
  .main .container {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    overflow-x: scroll;
    padding: 8px 1rem 0 1rem;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none; }
    .main .container::-webkit-scrollbar {
      display: none; }
    .main .container .button {
      min-width: 100px;
      flex: 1;
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 2px;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      gap: 12px;
      transition: 0.3s ease-in-out;
      text-decoration: none; }
      .main .container .button:has(> img) {
        justify-content: flex-start; }
      .main .container .button:hover {
        background-color: #dadada;
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px); }
      .main .container .button img {
        width: 24px;
        height: 24px;
        -o-object-fit: contain;
           object-fit: contain; }
      .main .container .button .text {
        color: #343d46; }
      @media screen and (min-width: 768px) {
    .bodyMedium {
      font-size: 16px; }
    .bodySmall {
      font-size: 14px; }
      .main .container {
        gap: 22px; }
        .main .container .button {
          padding: 24px; }
          .main .container .button img {
            width: 40px;
            height: 40px; } }
      @media screen and (min-width: 992px) {
        .main .container .button {
          align-items: flex-start; } }
