/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.inspiration,
.preamble,
.paragraph,
.description {
  font-family: SFNS, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1.inspiration {
  font-size: 31px;
  letter-spacing: -1.38px;
  line-height: 1;
  font-family: SFNS_bold, sans-serif; }

h2.inspiration_big {
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.93px;
  font-family: SFNS_medium, sans-serif; }

h2.inspiration, body .base .paragraph h2 {
  font-size: 20px;
  letter-spacing: -0.93px;
  line-height: 1.25;
  font-family: SFNS_bold, sans-serif; }

h3.inspiration, body .base .paragraph h3 {
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1; }

.preamble {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.4; }

.paragraph {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  white-space: pre-wrap; }

.description {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5; }

.base {
  margin: auto; }

body .base .paragraph {
  white-space: unset; }
  body .base .paragraph ul li:before {
    content: ' ';
    height: 0.75rem;
    width: 0.75rem;
    display: inline-block;
    background-color: rgba(247, 89, 77, 0.8);
    border-radius: 100%;
    vertical-align: baseline;
    margin: 0 0.5rem 0 0; }
  body .base .paragraph a {
    text-decoration: underline; }
  @media screen and (min-width: 992px) {
    h1.inspiration {
      font-size: 46px;
      line-height: 1.2; }
    h2.inspiration_big {
      font-size: 36px;
      letter-spacing: 0;
      font-family: SFNS_bold, sans-serif; }
    h2.inspiration, body .base .paragraph h2 {
      font-size: 22px;
      letter-spacing: -0.2px; }
    h3.inspiration, body .base .paragraph h3 {
      font-size: 20px;
      letter-spacing: 0; }
    .preamble {
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px; } }
