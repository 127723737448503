/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.invoice__Container {
  background: #FFFFFF;
  transition: 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 1rem;
  width: 100%;
  margin: auto;
  padding: 1rem; }

.invoice__Header {
  font-size: 1.43rem;
  line-height: 1.7rem;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: .5rem; }

.invoice__WrapItems {
  background-color: #FFFFFF;
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem 0; }
  .invoice__WrapItems > div {
    display: flex;
    flex-wrap: wrap; }

.invoice__Footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.5rem 0 .25rem 0; }
  .invoice__Footer__Link {
    color: #f8584c;
    text-decoration: none;
    font-weight: 700;
    display: inherit;
    align-items: center;
    font-size: 0.875rem; }
    .invoice__Footer__Link svg {
      margin-left: .5rem;
      margin-top: -.125rem;
      vertical-align: middle;
      height: 16px;
      width: 16px; }
    .invoice__Footer__Link span {
      text-transform: uppercase; }

.cell {
  text-align: left;
  width: 50%;
  margin-bottom: 1rem; }
  .cell:nth-child(even) {
    text-align: right; }
  .cell__Data {
    color: #23272A;
    font-size: 1.1rem;
    font-weight: 700; }
    .cell__Data.datePassed {
      color: #f8584c; }
  .cell__Header {
    color: #999;
    font-size: 1rem;
    display: block; }

.download {
  margin-top: 1.25rem;
  display: block;
  color: #f8584c;
  text-transform: lowercase;
  font-weight: 700;
  cursor: pointer; }
  .download::first-letter {
    text-transform: uppercase; }
  .download svg {
    margin-left: .5rem;
    vertical-align: middle;
    width: 16px;
    height: 14px; }
  @media screen and (min-width: 768px) {
    .invoice__Header {
      padding: 0px 1rem; }
      .invoice__WrapItems > div {
        flex-direction: row; }
    .cell {
      flex-direction: row;
      flex: 1;
      width: auto;
      margin-bottom: 0;
      margin-right: 1rem; }
      .cell:nth-child(even) {
        text-align: left; }
      .cell__Data {
        font-size: 1.15rem; }
      .cell__Header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between; } }
