/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10010;
  padding: 0.35rem 0;
  will-change: transform;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; }
  .base p {
    margin: 0; }

.white {
  color: white !important; }

.wrapper {
  composes: max from '../../../styles/objects/layout.scss';
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem; }

.buttons {
  white-space: nowrap; }

.button {
  background: transparent !important;
  padding-right: 0 !important;
  border: 1px solid white !important;
  padding-right: 1rem !important; }
  .button:hover {
    text-decoration: underline !important; }
  .button + .button {
    margin-left: 1rem; }

.enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }
  .enter.enterActive {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.leave {
  -webkit-transform: translateY(0);
          transform: translateY(0); }
  .leave.leaveActive {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out; }

.error {
  background: #ef7172; }

.warning {
  background: #efc372;
  color: black; }

.success {
  background: #6fb172; }

.default {
  background: #dee1e4; }

@media screen and (min-width: 768px) {
    .base {
      bottom: auto;
      top: 0;
      padding: 0.25rem 0; }
    .wrapper {
      flex-direction: row; }
    .enter {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
      .leave.leaveActive {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%); } }
