/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/

.hero {
  position: relative;
  overflow: hidden;
  margin-bottom: 0.75rem; }
  .hero__image {
    background-position: 523px 50%;
    background-repeat: no-repeat; }
  .hero__title, .hero__link {
    line-height: 1.28571rem;
    font-size: 1.14286rem;
    font-weight: bold;
    letter-spacing: .7px;
    font-family: SFNS_bold, sans-serif; }
  .hero__title {
    margin: 0; }
  .hero__text {
    margin: 9px 0 18px;
    line-height: 1.42857rem;
    font-size: 1rem; }
  .hero__no_content {
    max-height: 20rem; }
    .hero__no_content .hero__image {
      background-position: 0 0;
      background-size: cover;
      aspect-ratio: 34/7; }
  .hero__with_content ~ .categoryHeader:before, .hero__with_products ~ .categoryHeader:before {
    left: -391px; }
  .hero__with_content .hero__content {
    min-height: 12.07143rem;
    height: auto;
    padding: 2rem 0; }
  .hero__with_content .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .hero__with_content ~ .categoryHeader:before {
    top: -270.5px; }
  .hero__with_content ~ .categoryHeader.withBanner {
    margin-top: -.75rem; }
    .hero__with_content ~ .categoryHeader.withBanner::before {
      content: "";
      position: absolute;
      background: #F5E8E7;
      border-radius: 100%;
      width: 621px;
      height: 535px;
      right: 55px;
      top: -310px; }
  .hero__with_products .hero__content {
    min-height: 12.07143rem;
    height: auto;
    background: #f9f9f9;
    padding: 2rem 0; }
  .hero__with_products .hero__content__right {
    padding-left: 210px;
    display: flex;
    align-items: center; }
    .hero__with_products .hero__content__right ul {
      flex: 1; }
  .hero__with_products ~ .categoryHeader:before {
    top: -270.5px; }
  .hero__with_products ~ .categoryHeader.withBanner {
    margin-top: -.75rem; }
    .hero__with_products ~ .categoryHeader.withBanner::before {
      content: "";
      position: absolute;
      background: #F5E8E7;
      border-radius: 100%;
      width: 621px;
      height: 535px;
      right: 55px;
      top: -310px; }
  .hero__link {
    text-transform: uppercase; }
    .hero__link:hover {
      color: inherit;
      text-decoration: underline; }
    .hero__link__wrapper {
      display: block; }
    .hero__link__hide {
      display: none; }
    .hero__link svg {
      height: 1.14286rem;
      margin-top: -3px; }
  .hero__content {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center; }
    .hero__content::before {
      content: "";
      position: absolute;
      background: #F5E8E7;
      border-radius: 100%;
      width: 621px;
      height: 535px;
      right: 55px;
      bottom: -772px; }
    .hero__content__left {
      position: relative;
      padding: 0 1rem 0 8.5rem;
      flex: 0 1 523px; }
    .hero__content__right {
      flex: 1;
      position: relative; }

.categoryHeader {
  position: relative;
  background: #fff;
  background: linear-gradient(90deg, #F5E8E7 0, #F5E8E7 50%, #fff 50%, #fff 100%);
  overflow: hidden;
  margin: 0 -.75rem;
  padding-bottom: 20px; }
  .categoryHeader h1 {
    font-family: SFNS, sans-serif; }
  .categoryHeader::before {
    content: "";
    position: absolute;
    background: #F5E8E7;
    border-radius: 100%;
    width: 621px;
    height: 535px;
    right: 55px;
    top: -310px; }
  .categoryHeader.withBanner::before {
    content: "";
    position: absolute;
    background: #F5E8E7;
    border-radius: 100%;
    width: 621px;
    height: 535px;
    right: 55px;
    top: -310px; }
  .categoryHeader .inner {
    position: relative;
    padding: 1rem .75rem; }
  .categoryHeaderName {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 25px;
    line-height: 35px; }
    .categoryHeaderName + div {
      margin-bottom: 1rem; }

.categoryList {
  margin: 0;
  margin-left: -.5rem;
  padding: 0;
  white-space: pre;
  overflow-x: auto; }
  .categoryList li {
    display: inline-block;
    padding: 0.5rem 0.25rem;
    font-size: 1.143rem; }
    .categoryList li svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: .5rem;
      margin-top: -2px; }
  .categoryList .button {
    display: inline-block;
    background: #fff;
    border: solid 1px #343D46;
    padding: 0.545rem 1.25rem;
    font-size: 1rem;
    line-height: 1.1;
    color: #0D0F11;
    text-transform: capitalize;
    letter-spacing: 0.1rem;
    text-decoration: none;
    border-radius: 500px;
    white-space: pre;
    transition: background-color 200ms ease-in-out; }
    .categoryList .button:hover, .categoryList .button:focus, .categoryList .button:active {
      color: #000;
      background: #DEDEDE !important;
      text-decoration: none; }
  .categoryList .link {
    color: #000; }
    .categoryList .link:hover {
      text-decoration: underline; }
    .categoryList .link:hover, .categoryList .link:focus, .categoryList .link:active {
      color: #000; }
    .categoryList .link + svg {
      margin-left: .25rem; }

.categoryBreadcrumbsWrap {
  position: relative; }

.main {
  margin-top: 0 !important; }

.banner {
  padding: 1rem 0; }

.breadcrumbsWrap {
  position: relative; }

.newsBanner {
  border-bottom: 0.75rem solid #fff;
  position: relative;
  z-index: auto;
  display: none; }

@media screen and (min-width: 992px) {
  .bottomBorder {
    position: relative; }
    .bottomBorder:after {
      content: "";
      display: block;
      border-bottom: 1px solid #F7594D;
      height: 0px;
      width: 100%;
      position: absolute;
      bottom: -50px; }
        .hero__with_content ~ .categoryHeader.withBanner::before {
          border-radius: 100%;
          background: linear-gradient(180deg, #f5d7d5 0, #f5d7d5 338px, #F5E8E7 338px, #F5E8E7 100%);
          height: 1036px;
          width: 1168px;
          left: -398px;
          top: -270.5px;
          right: unset; }
        .hero__with_products ~ .categoryHeader.withBanner::before {
          border-radius: 100%;
          background: linear-gradient(180deg, #f5d7d5 0, #f5d7d5 338px, #F5E8E7 338px, #F5E8E7 100%);
          height: 1036px;
          width: 1168px;
          left: -398px;
          top: -270.5px;
          right: unset; }
        .hero__content::before {
          border-radius: 100%;
          background: #f5d7d5;
          height: 1036px;
          width: 1168px;
          left: -391px;
          right: unset; }
    .categoryHeader {
      margin: 0 0 1rem 0;
      padding-bottom: 0;
      background: #F5F5F5; }
      .categoryHeader::before {
        border-radius: 100%;
        background: #F5E8E7;
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -175px;
        right: unset; }
      .categoryHeader.withBanner::before {
        border-radius: 100%;
        background: linear-gradient(180deg, #f5d7d5 0, #f5d7d5 245px, #F5E8E7 245px, #F5E8E7 100%);
        height: 1036px;
        width: 1168px;
        left: -445px;
        top: -175px;
        right: unset; }
      .categoryHeader .inner {
        padding: 2rem 2rem 1.25rem 8.5rem; }
    .categoryList {
      display: block;
      white-space: unset;
      overflow-x: unset;
      padding: 0; }
      .categoryList li {
        padding: 0.33333rem 0.5rem; }
      .categoryList .button {
        margin: 0; }
    .newsBanner {
      display: block; } }

@media screen and (min-width: 768px) {
      .categoryHeaderName {
        font-size: 35px;
        line-height: 45px;
        margin-top: 0px; } }

@media screen and (min-width: 1200px) {
      .categoryHeaderName {
        font-size: 40px;
        line-height: 50px; } }

@media screen and (max-width: 992px) {
    .categoryList.breadcrumbsList {
      display: block; }
      .categoryList.breadcrumbsList li {
        display: none;
        font-size: 0.857rem; }
        .categoryList.breadcrumbsList li svg {
          width: 0.857rem;
          margin-top: -2px; }
      .categoryList.breadcrumbsList li:nth-last-child(1),
      .categoryList.breadcrumbsList li:nth-last-child(2) {
        display: inline-block; }
      .categoryList.breadcrumbsList li:nth-last-child(2):before {
        content: "...";
        display: inline-block;
        padding-right: 0.5rem; }
      .categoryList.breadcrumbsList li:first-child:before {
        display: none; } }
