/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  padding-top: 0; }

.footerLinksContainer {
  background: #fafafa;
  border-top: 1px solid #e5e5e5;
  margin-bottom: 0; }

.subscriptionContainer {
  background: #e5e5e5;
  margin-top: 0;
  margin-bottom: 0; }

.lightBackground {
  background: #fafafa; }

.fixPadding {
  padding-left: 0.7rem;
  padding-right: 0.7rem; }

.removeMargin > * + * {
  margin: 1rem 0; }

.removeMargin > div:empty {
  display: none; }

.removeTopMargin {
  margin-top: 0 !important; }

.thirdArea {
  padding: 0.8rem; }

.copyright {
  text-align: right; }

.aboveFooterLinks {
  display: flex;
  padding: 1.42857rem 0 0;
  flex-direction: column; }
  .aboveFooterLinks > div {
    flex: 1 0;
    display: flex;
    margin: 0 1.42857rem !important; }

.socialMediaLinks,
ul.socialMediaLinks {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 4.28571rem;
  flex-wrap: wrap; }
  .socialMediaLinks > div,
  .socialMediaLinks li,
  ul.socialMediaLinks > div,
  ul.socialMediaLinks li {
    flex: 0 0 34px;
    display: flex;
    margin: 1.42857rem; }
    .socialMediaLinks > div a,
    .socialMediaLinks li a,
    ul.socialMediaLinks > div a,
    ul.socialMediaLinks li a {
      display: flex;
      align-items: center; }
    .socialMediaLinks > div img,
    .socialMediaLinks li img,
    ul.socialMediaLinks > div img,
    ul.socialMediaLinks li img {
      vertical-align: middle; }
    @media print {
    .footerLinksContainer {
      display: none; }
    .subscriptionContainer {
      display: none; } }
    @media screen and (min-width: 992px) {
  .footerLinks > div {
    display: flex; }
    .footerLinks > div > div {
      flex: 1 1 20%;
      margin: 0 2rem 0 0; }
    .aboveFooterLinks {
      padding: 2.85714rem 0 0 !important;
      flex-direction: row; } }
