@charset "UTF-8";
/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  margin-bottom: 1rem; }

.primary {
  composes: base 1to1 center from '../../../styles/objects/ratio.scss'; }

.media {
  composes: base 1to1 center from '../../../styles/objects/ratio.scss'; }
  .media img {
    -webkit-animation: fadein 1s;
            animation: fadein 1s; }

.prev,
.next {
  composes: bare from '../../../styles/objects/buttons.scss';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 2.85714rem;
  color: #f7594d; }

.prev {
  left: 1rem; }
  .prev svg {
    margin-right: 0.5rem; }

.next {
  right: 1rem; }
  .next svg {
    margin-right: 0; }

.swiperPagination {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .swiperPagination li {
    display: inline-block;
    margin: 0.25rem;
    width: 1.5rem;
    cursor: pointer; }
    .swiperPagination li:after {
      color: #f7594d;
      content: '⚫';
      opacity: 0.5; }
    .swiperPagination li.active:after {
      opacity: 1; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
