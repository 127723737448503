/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.base {
  position: relative;
  display: inline-flex;
  flex-direction: column-reverse;
  width: 100%; }

@-webkit-keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }

@keyframes shrink {
  0% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  95% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: none;
    box-shadow: none;
    background-color: inherit; }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; } }

.input {
  border-color: #e7e7e7;
  transition: all ease 500ms;
  height: 44px; }
  .input[type="number"] {
    text-align: center; }
  .input:hover:not(.focused) {
    border-color: #6c737a;
    background-color: rgba(0, 0, 0, 0.05); }
  .input.focused {
    -webkit-animation-name: shrink;
            animation-name: shrink;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    outline: 1px solid #fff;
    box-shadow: 0 0 0 3px #f2b52e;
    background-color: #fcf0d5; }
  .input.filled:not(.customValidation):valid:not(:focus), .input.edited:not(.customValidation):required:valid:not(:focus) {
    outline: none; }
  .input.filled:not(.customValidation):invalid:not(:focus), .input.edited:not(.customValidation):required:invalid:not(:focus) {
    border-color: #b0406f;
    outline: 1px solid #b0406f; }
  .input.disabled, .input:disabled {
    background: #e7e7e7 !important; }

.customValidation.invalid:not(:focus) {
  border-color: #b0406f;
  outline: 1px solid #b0406f; }

.customValidation.valid:not(:focus) {
  outline: none; }

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 1rem;
  text-align: left;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: .25;
  transition: opacity 200ms, ease-out, -webkit-transform 200ms ease-out;
  transition: opacity 200ms, ease-out, transform 200ms ease-out;
  transition: opacity 200ms, ease-out, transform 200ms ease-out, -webkit-transform 200ms ease-out;
  will-change: opacity, transform;
  top: 0; }
  .drifting > .label {
    position: absolute;
    -webkit-transform: translateY(0.5rem);
            transform: translateY(0.5rem); }
  :not(.drifting) > .label {
    -webkit-transform: translateY(2.375rem);
            transform: translateY(2.375rem); }
  .label:after {
    content: '!';
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    color: white;
    font-size: 1rem;
    border-radius: 50%;
    background: #ef7172;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: inherit; }
  .input:focus + .label,
  .input.filled + .label {
    color: inherit;
    opacity: 1; }
    .drifting > .input:focus + .label, .drifting > .input.filled + .label {
      -webkit-transform: translateY(-1.5rem);
              transform: translateY(-1.5rem); }
    :not(.drifting) > .input:focus + .label, :not(.drifting) > .input.filled + .label {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      text-transform: uppercase;
      font-size: 0.85714rem;
      line-height: 1.5; }

.requiredLabel {
  margin-left: 4px; }

.input.filled:not(.customValidation):invalid:not(:focus) + .label:after, .input.edited:not(.customValidation):required:invalid:not(:focus) + .label:after, .customValidation.invalid:not(:focus) + .label:after {
  opacity: 1;
  -webkit-transform: scale(0.66667);
          transform: scale(0.66667); }

.inputLabel {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px !important;
  display: flex; }

.requiredField {
  color: #b0406f;
  display: flex;
  line-height: 20px;
  padding: 2px;
  margin-top: 8px;
  font-size: 14px;
  vertical-align: middle; }
  .requiredField svg {
    width: 20px;
    height: 20px;
    margin-right: 0.35714rem;
    vertical-align: text-top; }
