/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.textBase ul > li:before {
  content: " ";
  height: 0.75rem;
  width: 0.75rem;
  display: inline-block;
  background-color: rgba(247, 89, 77, 0.8);
  border-radius: 100%;
  vertical-align: baseline;
  margin: 0 0.5rem 0 0; }

.textBase ol {
  counter-reset: item; }

.textBase ol > li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: rgba(247, 89, 77, 0.8);
  font-weight: bold; }
