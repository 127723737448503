/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.header, .secondary,
.primary {
  font-family: SFNS_bold, sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem; }

.base {
  composes: scroll from "../../Shared/Flyout/base.scss";
  background: #F9F9F9;
  min-height: 100%;
  width: 100%;
  padding-top: 60px;
  font-family: "SFNS"; }
  .base.loggedIn {
    background: #343D46; }
  .base a {
    cursor: pointer;
    color: #23272a;
    display: block;
    text-decoration: none; }
  .base svg {
    height: 1rem;
    width: 1rem; }

.loginWrapper {
  position: relative;
  background: #F9F9F9;
  padding: 1rem 1rem 2rem;
  text-align: center; }
  .loginWrapper > button,
  .loginWrapper > button:hover {
    flex-grow: 0;
    margin: 2rem auto;
    display: "block";
    text-transform: uppercase;
    letter-spacing: inherit;
    color: #fff;
    padding: 0.5rem 1.25rem; }
    .loginWrapper > button svg,
    .loginWrapper > button:hover svg {
      width: 1.75rem; }

.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #F7594D;
  color: #fff;
  padding-left: 1rem;
  font-family: "SFNS"; }
  .header a,
  .header span {
    line-height: 60px;
    color: #fff; }
    .header a.close,
    .header span.close {
      height: 60px;
      width: 60px;
      float: right;
      text-align: center; }
      .header a.close svg,
      .header span.close svg {
        width: 1.25rem;
        height: 1.3rem;
        margin-bottom: -3px; }
  .header > a:not(.close):first-of-type > svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    height: 24px;
    width: 24px;
    margin-right: 1rem; }

.secondary,
.primary {
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "SFNS";
  color: #343D46; }
  .secondary:hover, .secondary:active,
  .primary:hover,
  .primary:active {
    color: #343D46;
    background-color: #fff; }
  .secondary svg,
  .primary svg {
    height: 24px;
    width: 24px; }
  .secondary span,
  .primary span {
    font-size: 1.14rem;
    line-height: 24px; }
  .secondary span svg,
  .primary span svg {
    margin-right: 0.65rem; }
  .secondary.menuLinks,
  .primary.menuLinks {
    background: #fff;
    position: relative; }
  .secondary.topLinks,
  .primary.topLinks {
    background: #F9F9F9; }

.myMenigo {
  background: #343D46;
  color: #fff !important; }
  .myMenigoButton {
    text-transform: uppercase;
    background: transparent;
    border: solid 1px #fff;
    padding: 0.5rem 1.25rem;
    white-space: pre;
    border-radius: 500px; }
    .myMenigoButton:hover, .myMenigoButton:focus, .myMenigoButton:active {
      color: #fff;
      background: rgba(255, 255, 255, 0.15) !important; }
    .myMenigoButton:active, .myMenigoButton:focus {
      color: inherit; }
    .myMenigoButton svg {
      margin-right: 1rem; }
  .myMenigo .primary {
    background: #343D46;
    color: #fff !important; }

.userInfoWrapper {
  padding: 1rem 1rem 2rem;
  text-align: center; }
  .userInfoWrapper > * {
    margin-top: 1rem; }
  .userInfoWrapper .suppliersalesmanWrapper {
    position: relative; }
  .userInfoWrapper .suppliersalesmanInfo {
    position: absolute;
    top: 100%;
    left: 0; }
    .userInfoWrapper .suppliersalesmanInfo .suppliersalesmanExhibition {
      padding-left: 0.5rem; }

.userInfoLabel, .userInfoItem {
  font-size: 12px;
  text-transform: uppercase; }

.userInfoLabel {
  font-weight: normal;
  display: block;
  text-transform: unset; }

.forwardEnter {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  will-change: transform; }
  .forwardEnter.active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: 150ms ease-out; }

.forwardLeave {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  will-change: transform; }
  .forwardLeave.active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: 150ms ease-out; }

.backEnter {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  .backEnter.active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: 150ms ease-out; }

.backLeave {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }
  .backLeave.active {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: 150ms ease-out; }

.slideIn {
  -webkit-animation: slideIn 150ms ease-out;
          animation: slideIn 150ms ease-out; }

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.slideOut {
  -webkit-animation: slideOut 150ms ease-out;
          animation: slideOut 150ms ease-out; }

@-webkit-keyframes slideOut {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes slideOut {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

.menuTextMark {
  background-color: #24733B;
  color: white;
  text-transform: uppercase;
  font-size: 1rem !important;
  font-weight: 400;
  padding: 0 0.3rem;
  position: absolute;
  left: 11rem; }

@media screen and (min-width: 992px) {
    .header, .secondary,
    .primary {
      font-family: SFNS_bold, sans-serif;
      font-size: 1.57143rem;
      line-height: 1.85714rem; } }
