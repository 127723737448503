/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.product_list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .product_list.product_list__large .product_list__link:hover ~ .product_list__row {
    background-color: #F5D7D5; }
  .product_list li {
    position: relative; }
    .product_list li:not(:last-child) {
      border-bottom: 0.5px solid #343D46; }
  .product_list__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
    .product_list__link:hover ~ .product_list__row {
      background-color: rgba(231, 231, 231, 0.5); }
  .product_list__row {
    display: flex;
    padding: 5px 0; }
  .product_list__image {
    flex: 0 1 30px;
    margin-left: 2px;
    padding: 2px;
    height: 30px;
    align-items: center;
    display: flex;
    background: #704587;
    border-radius: 50%;
    overflow: hidden;
    align-self: center; }
    .product_list__image > svg {
      margin: auto;
      display: block;
      height: 0.857rem;
      width: 0.857rem;
      color: #fff; }
  .product_list__title {
    padding-left: 24px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .product_list__title h5 {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      line-height: 1.42857rem;
      font-family: SFNS, sans-serif;
      font-weight: 600; }
    .product_list__title span {
      font-size: 0.85714rem;
      line-height: 0.85714rem; }
    .product_list__title h5, .product_list__title span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block; }
  .product_list__price {
    flex: 0 1 120px;
    font-size: 1rem;
    line-height: 1.42857rem;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
    .product_list__price .noPrice {
      margin: 0 0 0 1rem;
      font-size: 0.85714rem;
      line-height: 1rem; }
  .product_list__action {
    flex: 0 1 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .product_list__action a {
      display: inline-block;
      padding: 0 10px; }
      .product_list__action a svg {
        margin-top: -1px;
        height: 1.14286rem; }

.product_price__original,
.product_price__original * {
  text-decoration: line-through; }

.product_price__campaign {
  color: #F7594D; }
