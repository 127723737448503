.footerLinksContainer {
  display: none; }

.pageBreak {
  page-break-after: always; }

.right {
  text-align: right; }

.reportOverview {
  padding: 2rem; }
  .reportOverview .infoHeader {
    display: flex; }
    .reportOverview .infoHeader span:first-child {
      display: block;
      width: 110px; }
  .reportOverview table {
    margin-top: 1rem; }
    .reportOverview table .headerRow th {
      border-bottom: 1px solid black;
      padding-bottom: 0.2rem; }
    .reportOverview table .groupSeparator .groupHeaderRow td {
      font-weight: bold;
      padding-bottom: 0.2rem 0; }
    .reportOverview table .groupSeparator .totalGroupRow td {
      font-weight: bold;
      padding: 1rem 0; }
    .reportOverview table .groupSeparator .totalSubgroup td {
      font-weight: bold;
      padding-bottom: 1rem; }
    .reportOverview table .itemSeparator {
      border-bottom: 1px solid #999; }
    .reportOverview table .totalOverall td {
      padding-top: 1rem;
      font-weight: bold; }
    .reportOverview table .missingPrice td {
      padding-top: 1rem;
      font-style: italic; }
    .reportOverview table .summaryByPantry td {
      font-weight: bold;
      padding: 0.3rem 0;
      border-bottom: 1px solid black; }
