/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.indent {
  padding: 0 1rem; }

.rowContainer {
  border-top: 1px solid #e5e5e5;
  padding: 1rem 0; }
  .rowContainer:nth-child(2n) {
    background-color: #eff0f2; }

.row {
  display: flex;
  font-weight: bold; }
  .row > div:first-of-type {
    padding-left: 1rem; }
  .row > div:last-of-type {
    padding-right: 1rem; }

.arrow {
  flex: 1; }

.expanded {
  width: 100%;
  padding: 0 2rem;
  margin-top: 0.5rem; }
  .expanded > div {
    border-top: 1px solid #e5e5e5;
    padding: 0.25rem 0; }

.header {
  font-weight: bold;
  display: flex;
  margin-bottom: 1rem; }
  .header > div:first-of-type {
    padding-left: 1rem; }
  .header > div:last-of-type {
    padding-right: 1rem; }

.eans {
  text-align: center;
  flex: 1; }

.displayName {
  flex: 8 auto;
  text-overflow: ellipsis; }

.productId {
  width: 20%; }

.quantity {
  width: 10%;
  text-align: right; }

.unitShortName {
  width: 10%; }

.table {
  padding-left: 0; }
