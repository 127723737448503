/**
 * Importables for JavaScript components. Stylesheets that only contains variables, functions and mixins that
 * doesn't generate styles directly.
 */
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
/*TODO - detailed colorschema will come later*/
.typeAndUnit {
  line-height: inherit;
  font-size: inherit;
  position: relative;
  margin-right: 5px;
  color: #343d46; }
  .typeAndUnit.isLoggedIn {
    cursor: pointer; }

.tooltip {
  padding: 10px 5px 5px 5px;
  text-align: center;
  min-width: 135px;
  min-height: 35px; }

.tallUnit .unit:first-child {
  height: 55px;
  margin-bottom: 0; }

.emvCardUnit .unit:first-child {
  padding-bottom: 15px;
  margin-bottom: 0; }

.unit:first-child.tallerUnit {
  height: 75px;
  margin-bottom: 0; }

.unit {
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 12px; }

.pantryCardUnit {
  font-size: 12px;
  color: black; }

.type {
  color: #999; }

.priceWrap {
  width: 12%; }

.rurInformation {
  color: #0d0f11;
  font-family: SFNS, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1rem;
  white-space: pre; }

@media screen and (min-width: 1200px) {
    .priceWrap {
      width: 15%; } }
